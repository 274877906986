import { FC, ReactElement } from 'react'
import styled from 'styled-components/macro'
import { flex } from '../styles/mixins'

const WrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.grey100};
  height: 100%;
`

export const HeaderStyled = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.colors.shadow100};
  padding: 24px;

`

export const BodyStyled = styled.div<{ overlay?: boolean }>`
  ${flex({ align: 'center' })};
  flex: 1 1 auto;
  overflow: auto;
  height: 100%;
  width: 100%;
  position: relative;
  opacity: ${({ overlay }) => (overlay ? 0.5 : 1)};
  background: ${({ overlay, theme }) => (overlay ? theme.colors.white : 'transparent')};
  pointer-events: ${({ overlay }) => (overlay ? 'none' : 'auto')};
`

export const BodyInnerStyled = styled.div<{ noBodyPadding?: boolean }>`
  ${flex({ justify: 'center', align: 'flex-start' })};
  padding: ${({ noBodyPadding }) => (noBodyPadding ? '0' : '24px')};
  height: 100%;
  width: 100%;
  flex: 1 1 auto;

`

type TProps = {
  header: ReactElement,
  children: ReactElement,
  noBodyPadding?: boolean
  overlay?: boolean
}

const InnerLayout: FC<TProps> = ({
  children,
  header,
  noBodyPadding,
  overlay
}) => (
  <WrapStyled>
    <HeaderStyled>
      {header}
    </HeaderStyled>
    <BodyStyled overlay={overlay}>
      <BodyInnerStyled noBodyPadding={noBodyPadding}>
        {children}
      </BodyInnerStyled>
    </BodyStyled>
  </WrapStyled>
)

export default InnerLayout
