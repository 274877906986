import styled, { css } from 'styled-components/macro'
import { useState } from 'react'
import Card, { CardStyled } from '../../../../core/components/Card'
import Affordability from './Affordability'
import Risk from './Risk'
import Income from './Income'
import PlendScore from './PlendScore'
import Bureaux from './Bureaux'
import SectionCard from './SectionCard'
import Expenditure from './Expenditure'
import AccountBalance from './AccountBalance'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { useHasPermission, useIsPlendOrganization, useIsSocialCreditOrganization } from '../../../auth/hooks'
import {
  ApplicationHeaderProvider,
  useApplicationHeaderContext
} from '../../../applicationHeader/state/useApplicationHeader'
import ModifyAccounts from './ModifyAccounts'

type TContainerStyledProps = {
  accountHeightCoefficient: number
  isPlendOrganization: boolean
  isModifyAccount?: boolean
}

const mixinPlendOrganization = css<{ accountHeightCoefficient: number, isModifyAccount?: boolean }>`
    & > :nth-child(3) {
        grid-column: 1;
        grid-row: 3/5;
    }

    & > :nth-child(5) {
        height: ${({ accountHeightCoefficient, isModifyAccount }) =>
    ((isModifyAccount ? 320 : 280) + (accountHeightCoefficient * 60))}px;
    }

    & > :nth-child(6) {
        height: 290px;
    }
`
const mixinOtherOrganization = css<{ accountHeightCoefficient: number, isModifyAccount?: boolean }>`
    & > :nth-child(4) {
        grid-column: 1/3;
        height: ${({ accountHeightCoefficient, isModifyAccount }) =>
    ((isModifyAccount ? 320 : 280) + (accountHeightCoefficient * 60))}px;
    }

    & > :nth-child(5) {
        grid-column: 1/3;
        height: 290px;
    }
`

export const ContainerStyled = styled.div<TContainerStyledProps>`
    ${fontFamily('Inter')};
    padding: 24px;
    display: grid;
    grid-template-columns: minmax(310px, 384px) minmax(690px, 1fr);
    gap: 16px 24px;
    flex: 1;
    max-width: 1248px;
    overflow-x: hidden;

    & > :first-child {
        grid-column: 1;
    }

    & > :nth-child(2) {
        grid-column: 1;
        grid-row: 2;
    }

    ${({ isPlendOrganization }) => (isPlendOrganization ? mixinPlendOrganization : mixinOtherOrganization)}
    ${CardStyled} {
        ${flex({ align: 'flex-start' })};
        flex-direction: column;
    }
`

const BodyStyled = styled.div`
    ${fontFamily('Inter')};
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 0 24px;
    grid-column-start: 2;
    grid-row: 1/3;
`

const Overview = () => {
  const [accountHeightCoefficient, setAccountHeightCoefficient] = useState(1)
  const isPlendOrganization = useIsPlendOrganization()
  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()
  const isSocialCreditOrganization = useIsSocialCreditOrganization()
  const titleTooltip = `The Plend score is a guide to how creditworthy this individual is.
 It is a blend of Transunion's
 score and spending behaviours from their banking activity.
 Click the tab to see a more detailed breakdown of positive and negative score traits.`
  const showBureaux = isPlendOrganization || isSocialCreditOrganization
  const isUnderwriter = useHasPermission(['UNDERWRITER_VIEW'])
  const isModifyAccount = ['IN_PROGRESS', 'ACCOUNT_REQUESTED', 'ACCOUNT_UPDATED'].includes(headerData?.status ?? '') && isUnderwriter
  return (
    <ApplicationHeaderProvider>
      <ContainerStyled
        isModifyAccount={isModifyAccount}
        isPlendOrganization={showBureaux}
        accountHeightCoefficient={accountHeightCoefficient}
      >
        <Card>
          <Income />
        </Card>
        <Card>
          <PlendScore titleTooltip={titleTooltip} navigationUrl="score" />
        </Card>
        {
          (showBureaux) && (
            <Card>
              <Bureaux />
            </Card>
          )
        }
        <BodyStyled>
          <Card>
            <Affordability />
          </Card>
          <Card>
            <Risk />
          </Card>
        </BodyStyled>
        <Card key={headerData?.status}>
          <AccountBalance setAccountHeightCoefficient={setAccountHeightCoefficient} />
          {isModifyAccount && <ModifyAccounts />}
        </Card>
        <Card>
          <SectionCard label="Income and Expenditure" isIcon={false} />
          <Expenditure />
        </Card>
      </ContainerStyled>
    </ApplicationHeaderProvider>
  )
}

export default Overview
