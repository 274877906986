/* eslint-disable no-underscore-dangle,consistent-return */
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { useNotFoundContext } from '../../notFound/guards/NotFoundGuard'
import { apiClient } from '../../../core/api/apiClient'
import { useAuthContext } from '../../auth/state/useAuthState'
import { useNotificationContext } from '../../notifications/state/useNotification'
import ErrorNotification from '../../notifications/components/ErrorNotification'
import { passwordResetLinkUrl, logoutUrl } from '../../auth/queries'
import { useRefreshTokenMutation } from '../queries'

function updateAuthorizationHeader(config: AxiosRequestConfig, authToken: string) {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${authToken}`
  }
}

function useRequestInterceptor() {
  const { authData } = useAuthContext()
  useEffect(() => {
    const interceptor = apiClient.interceptors.request.use(
      (config) => {
        if (config.headers && authData?.token) {
          updateAuthorizationHeader(config, authData.token)
        }
        return config
      },
      (error) => Promise.reject(error)
    )
    return () => apiClient.interceptors.request.eject(interceptor)
  }, [authData, authData?.token])
}

function useTokenRefresh() {
  const { mutateAsync: refreshTokenMutate } = useRefreshTokenMutation()
  const { setAuthData } = useAuthContext()

  // Status if refresh token loading started
  const isUpdating = useRef(false)
  // Token after update cache
  const tokenAfterUpdate = useRef(null)

  // There is situation when we perform several application requests simultaneously.
  // This code exists to perform ONLY one token refreshing request
  return async () => {
    // Perform single refresh token request
    if (!isUpdating.current) {
      isUpdating.current = true
      try {
        // Refresh request
        const refreshRes = await refreshTokenMutate()
        // Setting of new auth data
        setAuthData(refreshRes.data)

        // Setting token to cache to use in awaiting usecase
        tokenAfterUpdate.current = refreshRes.data.token

        // Return new token
        return refreshRes.data.token
      } finally {
        // Clear flag in any case
        isUpdating.current = false
      }
    }

    // Await for token refreshing request
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        // Checking process flag
        if (!isUpdating.current) {
          clearInterval(interval)

          // return new token from cache
          resolve(tokenAfterUpdate.current)
        }
      }, 100)
    })
  }
}

export const useUnauthorisedHandle = () => {
  const navigate = useNavigate()
  const refreshToken = useTokenRefresh()
  const { setIsInactive } = useAuthContext()

  return useCallback(async ({ response, config }: AxiosError) => {
    if (!response
      || (response.request.responseURL.includes(logoutUrl)) || (response.request.responseURL.includes('auth/refresh-token'))) {
      return
    }

    // @ts-ignore
    if (response && response.status === 401 && !config._retry) {
      // @ts-ignore
      config._retry = true

      try {
        const newToken = await refreshToken()
        updateAuthorizationHeader(config, newToken)

        return axios(config)
      } catch {
        navigate('/logout')
        setIsInactive(true)
      }
    }

    if (response.status === 403) {
      throw new Error(response.data.message)
    }
  }, [])
}

export const useOtherErrorsHandling = () => {
  const { setShowNotFound } = useNotFoundContext()
  const { showErrorNotification } = useNotificationContext()
  return useCallback(async ({ response }: AxiosError) => {
    if (!response || (response.request.responseURL.includes(logoutUrl))) {
      return
    }

    if (response.status === 401) {
      return
    }

    if (response.status === 403) {
      throw new Error(response.data.message)
    }

    if (response.status === 404 && !response.request.responseURL.includes(passwordResetLinkUrl)) {
      setShowNotFound(true)
      return
    }

    showErrorNotification(<ErrorNotification message={response.data.message} />)
    throw new Error(response.data.message)
  }, [])
}

function useErrorResponseInterceptor() {
  const unauthorisedHandle = useUnauthorisedHandle()
  const otherErrorsHandling = useOtherErrorsHandling()

  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(
      (res) => res,
      async (err: AxiosError) => {
        if (axios.isAxiosError(err)) {
          await otherErrorsHandling(err)
          return unauthorisedHandle(err)
        }
      }
    )
    return () => apiClient.interceptors.request.eject(interceptor)
  }, [unauthorisedHandle, otherErrorsHandling])
}

const ApiClient = () => {
  useRequestInterceptor()
  useErrorResponseInterceptor()

  return null
}
export default ApiClient
