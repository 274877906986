import { useApplicationsContext } from '../state/useApplications'
import { TFilter } from '../types'
import CustomSearch from '../../../../core/components/CustomSearch'
import { integerFormat, letterFormat } from '../../../../core/utils/formUtils'

const filterMap: any = {
  NEW: { placeholder: 'Type application ID', validationRule: letterFormat, type: 'number' },
  IN_PROGRESS: { placeholder: 'Type application ID', validationRule: letterFormat, type: 'number' },
  APPROVED_PENDING_RETRIEVER: {
    placeholder: 'Type application name',
    validationRule: integerFormat,
    type: 'text'
  },
  APPROVED: { placeholder: 'Type application name', validationRule: integerFormat, type: 'text' },
  DECLINED: { placeholder: 'Type application name', validationRule: integerFormat, type: 'text' },
  CANCELLED: { placeholder: 'Type application name', validationRule: integerFormat, type: 'text' }
}

const Search = () => {
  const { filter, setSearch } = useApplicationsContext()
  return (
    <CustomSearch
      setSearch={setSearch}
      filter={filter}
      type={filterMap[filter as TFilter]?.type}
      placeholder={filterMap[filter as TFilter]?.placeholder || 'Type application ID or name'}
      handleChange={filterMap[filter as TFilter]?.validationRule}
    />
  )
}
export default Search
