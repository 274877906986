import { useMutation } from 'react-query'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { TAuthData, TResetLinkFormData, TFormData, TChangePasswordParams } from './types'
import { useAuthContext } from './state/useAuthState'
import { apiClient } from '../../core/api/apiClient'
import { useNotificationContext } from '../notifications/state/useNotification'

import { initialNavigateMap as afterLoginNavigateMap } from '../../Routes'

enum QueryKeys {
  Login = 'Login',
  ChangePassword = 'ChangePassword',
  ForgotPassword = 'ForgotPassword'
}

export const logoutUrl = '/auth/logout'
export const passwordResetLinkUrl = '/auth/users/password/reset-link'

async function logout(): Promise<void> {
  try {
    await apiClient.post(logoutUrl)
  } catch (error) {
    throw new Error('Something went wrong')
  }
}

async function changePassword(data: TChangePasswordParams): Promise<void> {
  try {
    const { userId, ...rest } = data
    await apiClient.post(`/auth/users/${userId}/password/forgot`, rest)
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function resetLink(data: TResetLinkFormData): Promise<void> {
  try {
    await apiClient.post(passwordResetLinkUrl, data)
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function useLogin(
  formData: TFormData,
  setAuthData: (data: TAuthData) => void
): Promise<TAuthData> {
  try {
    const response = await apiClient.post<TAuthData>('/auth/login', { ...formData })
    setAuthData(response.data)
    return response.data
  } catch (err: any) {
    if (axios.isAxiosError(err) && err.response) {
      throw new Error(err.response.data.message)
    } else {
      throw new Error(err.message || 'Something went wrong')
    }
  }
}

export function useLoginMutation() {
  const navigate = useNavigate()
  const key = QueryKeys.Login
  const { setAuthData } = useAuthContext()

  return useMutation<TAuthData, Error, TFormData>(
    (formData) => useLogin(formData, setAuthData),
    {
      mutationKey: key,
      onSuccess: (data) => navigate(
        afterLoginNavigateMap[data.permissions[0]] || '/home'
      )
    }
  )
}

export function useChangePasswordMutation() {
  const key = QueryKeys.ChangePassword
  const navigate = useNavigate()
  const { showSuccessNotification } = useNotificationContext()

  return useMutation<void, Error, TChangePasswordParams>(
    [key],
    (data) => changePassword(data),
    {
      onSuccess: () => {
        showSuccessNotification('Your password has been successfully changed.')
        navigate('/login')
      }
    }
  )
}

export function useResetLinkMutation() {
  const key = QueryKeys.ForgotPassword
  const { showSuccessNotification } = useNotificationContext()
  return useMutation<void, Error, TResetLinkFormData>(
    [key],
    (data) => resetLink(data),
    {
      onSuccess: () => {
        showSuccessNotification('Reset link has been sent, please check you mail box.')
      }
    }
  )
}

export function useLogoutMutation() {
  return useMutation<void, Error>(logout)
}
