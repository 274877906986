export const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    const { result } = reader
    if (result) {
      resolve(result.toString())
    }
  }
  reader.onerror = (error) => reject(error)
})
