import { useMemo } from 'react'
import { useConfigurationUsersQuery } from '../queries'
import { useHomeContext } from '../state/useHome'
import { useHasPermission } from '../../auth/hooks'
import CustomSelect from '../../../core/components/CustomSelect'
import { formatConfigurationUsers } from '../utils/formatUtils'

const UsersDropdown = () => {
  const { underwriter, setUnderwriter } = useHomeContext()

  const { data: configurationUsers } = useConfigurationUsersQuery()

  const options = useMemo(
    () =>
      configurationUsers && formatConfigurationUsers(configurationUsers),
    [configurationUsers]
  )

  if (!useHasPermission(['ORGANIZATION_ADMIN_VIEW']) || !options) {
    return null
  }

  return (
    <CustomSelect
      currentValue={underwriter}
      handleChange={(value) => setUnderwriter(value)}
      options={options}
    />
  )
}

export default UsersDropdown
