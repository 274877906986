import { useMemo } from 'react'
import { CustomCheckboxStyled } from '../../../../core/components/CustomCheckbox'
import { useTransactionContext } from '../state/useTransactions'
import { TTransaction } from '../types'

export function differenceTransaction(transactions: TTransaction[], chunk: TTransaction[]) {
  return transactions.filter((transaction) => !chunk
    .some((item) => item.id === transaction.id))
}

export function intersectionTransaction(transactions: TTransaction[], chunk: TTransaction[]) {
  return transactions.filter((transaction) => chunk
    .some((item) => transaction.id === item.id))
}

export function useCheckAllChecked({ data, transactions }:
  { data?: TTransaction[], transactions: TTransaction[] }) {
  return useMemo(() => {
    if (!data) {
      return false
    }
    return intersectionTransaction(transactions, data).length === data.length
  }, [transactions.length, data])
}

const TableHeadCheckbox = () => {
  const {
    transactionsQuery: { data },
    selectedTransactions, setSelectedTransactions
  } = useTransactionContext()

  return (
    <CustomCheckboxStyled
      checked={useCheckAllChecked({
        data: data?.content,
        transactions: selectedTransactions
      })}
      onChange={(event, checked) => {
        const chunkTransactions = data!.content
        setSelectedTransactions(checked
          ? [
            ...selectedTransactions,
            ...differenceTransaction(chunkTransactions, selectedTransactions)]
          : differenceTransaction(selectedTransactions, chunkTransactions))
      }}
    />
  )
}

export default TableHeadCheckbox
