import React, { FC, ReactNode, useEffect, useMemo } from 'react'
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  FormControlLabel as Label
} from '@mui/material'
import { Control, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components/macro'
import CustomInput from '../../../core/components/CustomInput'
import { fontFamily } from '../../../core/styles/mixins'
import { CloseLineIcon } from '../../../core/components/icons/CloseLineIcon'
import { BaseButton } from '../../../core/components/Button'
import { useHasPermission } from '../../auth/hooks'
import { emailValidation, requiredRule } from '../../../core/utils/formRules'
import { useEmailValidationQuery } from '../queries'
import { EMAIL_VALIDATION } from '../../../core/const/regexes'
import OrganizationsDropdown from './OrganizationsDropdown'
import RoleDropdown from './RoleDropdown'
import PartnerAdminDropDown from './PartnerAdminDropDown'

const Dialog = styled.div`
  width: 480px;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  outline: none;
  box-shadow: ${({ theme }) => theme.colors.shadow200};
  border-radius: 6px;
  padding: 24px;
  margin: 0;
`

const DialogTitle = styled(MuiDialogTitle)`
  && {
    ${fontFamily('Inter')};
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 0;
    margin-bottom: 24px;
  }
`

const IconContainerStyled = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlack};
`
export const DialogActions = styled(MuiDialogActions)`
  padding: 0 !important;
`

const ButtonCancelStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 8px 16px;
    max-width: 98px;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }

    &:disabled {
      opacity: .5;
      pointer-events: all;
      cursor: not-allowed;
    }
  }
`

const ButtonActionStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px 16px;
    margin-left: 18px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }

    &:disabled {
      opacity: .5;
      pointer-events: all;
      cursor: not-allowed;
    }
  }
`

const FormStyled = styled.form`
  .MuiTypography-root {
    ${fontFamily('Inter')};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.darkBlack};

  }

  && .MuiTypography-root.Mui-disabled {
    color: ${({ theme }) => theme.colors.darkBlack};
  }

  .MuiFormControlLabel-root {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0 0 24px 0;
  }
`

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`

const SubTitleStiled = styled.div`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

function useAsyncEmailValidation(
  setErrorEmail: (message: string) => void,
  control: Control,
  isDirty: boolean,
  clearError: () => void
) {
  const { mutateAsync } = useEmailValidationQuery()

  const email = useWatch({ control, name: 'email' })

  useEffect(() => {
    let timer: NodeJS.Timeout
    clearError()
    if (EMAIL_VALIDATION.test(email) && isDirty) {
      timer = setTimeout(async () => {
        const response = await mutateAsync(email)
        if (response) setErrorEmail('This email address is already being used')
      }, 200)
    }
    return () => clearTimeout(timer)
  }, [email])
}

type TProps = {
  isOpen: boolean
  title: string
  subTitle?: JSX.Element
  buttonText: string
  children?: JSX.Element
  rules?: any
  iconError?: ReactNode
  onSubmit?: () => void
  handleClose?: () => void
  isButtonDisable?: boolean
  isEditPartnerAdminDisabled?: boolean
}

const Modal: FC<TProps> = ({
  isOpen,
  title,
  subTitle,
  buttonText,
  children,
  rules,
  iconError,
  onSubmit,
  handleClose,
  isButtonDisable,
  isEditPartnerAdminDisabled
}) => {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors, dirtyFields }
  } = useFormContext()

  useAsyncEmailValidation(
    setErrorEmail,
    control,
    dirtyFields.email,
    () => clearErrors('email')
  )
  const isSuperAdmin = useHasPermission(['GENERAL_VIEW'])
  const isPartnerAdmin = useHasPermission(['PARTNERS_ADMIN_VIEW'])

  function setErrorEmail(message: string) {
    setError('email', { message, type: 'asyncEmailValidation' })
  }

  const isErrorAsyncEmailValidation = useMemo(() => (errors && errors.email
    ? errors.email.type === 'asyncEmailValidation'
    : false), [errors, errors?.email])

  return (
    <MuiDialog open={isOpen} onClose={handleClose} transitionDuration={0}>
      <Dialog>
        <DialogTitle>{title}</DialogTitle>
        <IconContainerStyled onClick={handleClose}>
          <CloseLineIcon />
        </IconContainerStyled>

        <FormStyled onSubmit={onSubmit}>
          {title !== 'Delete User' ? (
            <FormWrapper>
              <Label
                control={<CustomInput name="firstName" control={control} rules={rules} iconError={iconError} />}
                label="First Name"
                labelPlacement="top"
              />
              <Label
                control={<CustomInput name="lastName" control={control} rules={rules} iconError={iconError} />}
                label="Last Name"
                labelPlacement="top"
              />
              <Label
                control={(
                  <CustomInput
                    name="email"
                    control={control}
                    rules={{
                      required: requiredRule('This field is required'),
                      validate: emailValidation
                    }}
                    iconError={iconError}
                  />
                )}
                label="Email"
                labelPlacement="top"
              />
              {isSuperAdmin ? (
                <Label
                  control={<OrganizationsDropdown name="organizationId" control={control} />}
                  label="Organization"
                  labelPlacement="top"
                />
              ) : null}
              {isPartnerAdmin
                ? <PartnerAdminDropDown isDisabled={isEditPartnerAdminDisabled} />
                : !isSuperAdmin ? (
                  <Label
                    control={<RoleDropdown control={control} name="role" placeholder="Select a role" />}
                    labelPlacement="top"
                    label="Role" />
                )
                  : null}
            </FormWrapper>
          ) : (
            <SubTitleStiled>{subTitle}</SubTitleStiled>
          )}
          <DialogActions>
            <>{children}</>
            <div>
              <ButtonCancelStyled
                disabled={isButtonDisable}
                onClick={handleClose}>
                Cancel
              </ButtonCancelStyled>
              <ButtonActionStyled
                disabled={isButtonDisable || isErrorAsyncEmailValidation}
                type="submit">
                {buttonText}
              </ButtonActionStyled>
            </div>
          </DialogActions>
        </FormStyled>
      </Dialog>
    </MuiDialog>
  )
}

export default Modal
