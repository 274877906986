import styled from 'styled-components/macro'
import KeywordsHeader from './KeywordsHeader'
import KeywordsTable from './KeywordsTable'
import InnerLayout, { HeaderStyled } from '../../../core/components/InnerLayout'
import Card, { CardStyled } from '../../../core/components/Card'
import { KeywordsProvider } from '../state/useKeywords'

const Wrapper = styled.div`
  ${HeaderStyled}{
    padding-bottom: 16px;
  }
  
  ${CardStyled}{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: auto;
  }
`

const Keywords = () => (
  <KeywordsProvider>
    <Wrapper>
      <InnerLayout header={<KeywordsHeader/>}>
        <Card noPadding>
          <KeywordsTable/>
        </Card>
      </InnerLayout>
    </Wrapper>
  </KeywordsProvider>

)

export default Keywords
