import constate from 'constate'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCategoriesGraphQuery, useCategoriesQuery, useTransactionsQuery } from '../queries'
import { useMetricsContext } from './useMetricsState'
import { TAffordabilityFilterKey, TCategoriesData, TCategory } from '../types'
import { TSortBy } from '../../../../../core/components/table/TableContext'
import { TTransaction } from '../../../transactions/types'

function useCategorySelection(categoriesData?: TCategoriesData) {
  const [category, setSelectedCategory] = useState<TCategory>()

  useEffect(() => {
    const firstCategory = categoriesData?.categories[0]
    setSelectedCategory(firstCategory)
  }, [categoriesData])

  return {
    selectedCategory: category,
    setSelectedCategory
  }
}

function useTransactions(
  selectedCategory?: TCategory,
  filterKey?: TAffordabilityFilterKey
) {
  const { activeMetricKey } = useMetricsContext()

  const [page, setPage] = useState(1)

  const initialSortBy = { id: 'date', desc: true }
  const [sortBy, setSortBy] = useState<TSortBy | undefined>(initialSortBy)

  useEffect(() => {
    setPage(1)
  }, [selectedCategory])

  return {
    query: useTransactionsQuery(
      activeMetricKey,
      page,
      sortBy,
      selectedCategory,
      filterKey
    ),
    setPage,
    sortBy,
    setSortBy
  }
}

type TProps = {
  affordabilityFilterKey?: TAffordabilityFilterKey
}

const useMetrics = ({ affordabilityFilterKey }: TProps) => {
  const { activeMetricKey } = useMetricsContext()
  const [selectedTransactions, setSelectedTransactions] = useState<TTransaction[]>([])
  const preparedFilterKey = useMemo(
    () => (
      activeMetricKey === 'NON_DISCRETIONARY_EXPENDITURE' || activeMetricKey === 'NON_ESSENTIAL_EXPENDITURE'
        ? affordabilityFilterKey
        : null
    ),
    [activeMetricKey, affordabilityFilterKey]
  )

  const categoriesQuery = useCategoriesQuery(activeMetricKey, preparedFilterKey)

  const { selectedCategory, setSelectedCategory } = useCategorySelection(categoriesQuery.data)

  const categoriesGraphQuery = useCategoriesGraphQuery(
    activeMetricKey,
    selectedCategory,
    preparedFilterKey
  )

  return {
    categoriesQuery,
    categoriesGraphQuery,
    selectedCategory,
    selectedTransactions,
    setSelectedTransactions,
    setSelectedCategory,
    handleClearTransactions: useCallback(
      () => setSelectedTransactions([]),
      []
    ),
    transactionsState: useTransactions(selectedCategory, affordabilityFilterKey)
  }
}

export const [MetricsContentProvider, useMetricsContentContext] = constate(useMetrics)
