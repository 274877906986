import {
  Bar,
  BarChart as RechartBarChart,
  CartesianGrid,
  Cell,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  XAxis,
  YAxis
} from 'recharts'
import styled, { useTheme } from 'styled-components/macro'
import { FC, useState } from 'react'
import Tooltip from './Tooltip'
import ChartLegend from '../../../../core/components/charts/ChartLegend'
import { formatToDay } from '../../utils/formatUtils'
import CustomizedTick from '../../../../core/components/charts/CustomizedTick'
import { TStatuses } from '../../types'
import { useIsCurrentOrganization, useIsSocialCreditOrganization } from '../../../auth/hooks'

const Wrapper = styled.div`
  margin: 24px 0 8px -35px;
  width: 100%;
  height: 288px;

  && {
    & .recharts-legend-wrapper {
      bottom: -50px !important;
    }
  }
`

type TProps = {
  data: TStatuses
}

const BarChart: FC<TProps> = ({ data }) => {
  const { colors: { grey600a6, red, orange, darkBlack, chart: { green } } } = useTheme()
  const isSocialCreditOrganization = useIsSocialCreditOrganization()
  const isShowFailedRequest = !useIsCurrentOrganization(['lendology', 'purpleshoots', 'robertowen'])
    && !isSocialCreditOrganization

  const { daily, ...legend } = data
  const [isTooltipActive, setTooltipActive] = useState<boolean>()
  const [activeBarIndex, setActiveBarIndex] = useState<number>()

  const organizationLegend = {
    approvedTotal: legend.approvedTotal,
    declinedTotal: legend.declinedTotal,
    ...(!isSocialCreditOrganization ? { cancelledTotal: legend.cancelledTotal } : {}),
    ...(isShowFailedRequest ? { failedTotal: legend.failedTotal } : {})
  }

  return (
    <Wrapper>
      <ResponsiveContainer width="100%" height={248}>
        <RechartBarChart data={daily}>
          <CartesianGrid vertical={false} horizontal />
          <XAxis
            padding={{ left: 1, right: 10 }}
            interval={0}
            tickLine={false}
            axisLine={false}
            dataKey="date"
            allowDataOverflow={false}
            tick={(props) => {
              const toDay = formatToDay(props.payload.value)
              return <CustomizedTick fontSize={10} formattedValue={toDay} {...props} dy={12} />
            }}
          />
          <YAxis
            allowDataOverflow
            allowDecimals={false}
            tick={(props) => (
              <CustomizedTick
                formattedValue={props.payload.value}
                {...props}
                dy={2}
              />
            )}
            tickLine={false}
            axisLine={false} />

          <RechartTooltip
            cursor={false}
            content={<Tooltip setTooltipActive={setTooltipActive} formatType="statistics" />} />
          <Legend content={<ChartLegend {...organizationLegend} label="Requests" />} iconType="circle" align="left" />
          <ReferenceLine y="0" stroke={darkBlack} strokeWidth={1.5} />
          <Bar
            onMouseEnter={(_, index) => setActiveBarIndex(index)}
            onMouseLeave={() => setActiveBarIndex(undefined)}
            barSize={10}
            dataKey="approved"
            fill={green}>
            {
              daily.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell
                  key={`cell-${entry.date}`}
                  style={{ opacity: (index === activeBarIndex || !isTooltipActive) ? 1 : 0.75 }} />
              ))
            }
          </Bar>
          <Bar
            onMouseEnter={(_, index) => setActiveBarIndex(index)}
            onMouseLeave={() => setActiveBarIndex(undefined)}
            barSize={10}
            dataKey="declined"
            fill={red}>
            {
              daily.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell
                  key={`cell-${entry.date}`}
                  style={{ opacity: (index === activeBarIndex || !isTooltipActive) ? 1 : 0.75 }} />
              ))
            }
          </Bar>
          {!isSocialCreditOrganization && (
            <Bar
              onMouseEnter={(_, index) => setActiveBarIndex(index)}
              onMouseLeave={() => setActiveBarIndex(undefined)}
              barSize={10}
              dataKey="cancelled"
              fill={orange}>
              {
                daily.map((entry, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Cell
                    key={`cell-${entry.date}`}
                    style={{
                      opacity: (index === activeBarIndex || !isTooltipActive) ? 1 : 0.75
                    }}
                  />
                ))
              }
            </Bar>
          )}
          {isShowFailedRequest && (
            <Bar
              onMouseEnter={(_, index) => setActiveBarIndex(index)}
              onMouseLeave={() => setActiveBarIndex(undefined)}
              barSize={10}
              dataKey="failed"
              fill={grey600a6}>
              {
                daily.map((entry, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Cell
                    key={`cell-${entry.date}`}
                    style={{
                      opacity: (index === activeBarIndex || !isTooltipActive) ? 1 : 0.75
                    }} />
                ))
              }
            </Bar>
          )}
        </RechartBarChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

export default BarChart
