import { Column, Row } from 'react-table'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import Table from '../../../../core/components/table/Table'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { ChevronRightIcon } from '../../../../core/components/icons/ChevronRightIcon'
import { useBorrowerScoreContext } from '../state/useBorrowerScore'
import PlendScore from '../../overview/components/PlendScore'
import { ScoreType } from '../types'
import { formatObjectToArray } from '../utils/plendScoreUtil'

const Wrapper = styled.div`
  && .Mui-selected {
    background-color: ${({ theme }) => theme.colors.blue300};
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue300};
    }
  }
  && {
    && .Mui-selected {
      background-color: ${({ theme }) => theme.colors.blue300};;
    }
    ${HeaderCellStyled} {
      display: none;
    }
    ${BodyCellStyled} {
      padding: 16px 0;
      cursor: pointer;
      :first-child {
        text-align: start;
        padding-left: 16px;
      }
      :nth-child(2) {
        text-align: right;
      }
      :last-child {
        padding-right: 16px;
      }
    }
  }
`

const ContainerStyled = styled.div`
  ${flex({ justify: 'space-between', align: 'center' })};
`

type TTextStyledProps = {
  fontWeight: 400 | 500
}

const TextStyled = styled.span<TTextStyledProps>`
  ${fontFamily('Inter')};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
  text-transform: capitalize;
`

const IconContainerStyled = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
`

const titleToolTip = `
The Plend score is a guide to how creditworthy this individual is. It is a blend of 
Transunion's score and spending behaviours from their 
banking activity. See the breakdown below for a more detailed breakdown of 
positive and negative score traits.
`

type TFormattedScores = {
  title: string
  score: number
  scoreType: ScoreType
}

const BorrowerScoreTable = () => {
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: '',
        accessor: 'title',
        Cell: ({ row, value }) => {
          if (row.values.scoreType === ScoreType.totalScore) {
            return (
              <ContainerStyled>
                <div>
                  <PlendScore isIcon={false} titleTooltip={titleToolTip} score={row.values.score} />
                </div>
              </ContainerStyled>
            )
          }
          return <TextStyled fontWeight={400}>{value}</TextStyled>
        }
      },
      {
        Header: '',
        accessor: 'score',
        Cell: ({ row, value }) => {
          if (row.values.scoreType !== ScoreType.totalScore) {
            return (
              <TextStyled fontWeight={500}>
                {value > 0 ? `+${value}` : value}
                pts
              </TextStyled>
            )
          }
          return <></>
        }
      },
      {
        Header: '',
        accessor: 'scoreType',
        Cell: () => (
          <IconContainerStyled>
            <ChevronRightIcon />
          </IconContainerStyled>
        )
      }
    ],
    []
  )
  const {
    borrowerScoreQuery: { isLoading, data: borrowerTable, isSuccess },
    selectedBorrowerScore,
    sort,
    setBorrowerScore
  } = useBorrowerScoreContext()

  const formattedScores: TFormattedScores[] = formatObjectToArray(borrowerTable)
  const data = useMemo(() => formattedScores, [borrowerTable, sort, selectedBorrowerScore])

  if (isLoading) return null

  if (isSuccess) {
    return (
      <Wrapper>
        <Table
          selectionConfig={{
            rowIdentityProp: 'scoreType',
            selectedRowIds: selectedBorrowerScore
          }}
          onRowClick={({ original }: Row<TFormattedScores>) => setBorrowerScore(original.scoreType)}
          columns={columns}
          data={data}
        />
      </Wrapper>
    )
  }
  return null
}

export default BorrowerScoreTable
