import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { apiClient } from '../../core/api/apiClient'
import { TEdit, TForm, TOrganizationContent, TOrganizations, TUser, TUserData } from './types'
import { useHasPermission } from '../auth/hooks'
import { prepareTablePaginationRequestParams } from '../../core/utils/tableData'

export enum QueryKeys {
  Users = 'Users',
  PartnerAdminUsers = 'PartnerAdminUsers',
  ResendPassword = 'ResendPassword',
  InProgressRequest = 'InProgressRequest',
  Organizations = 'Organizations'
}

async function resendPassword(id?: number) {
  try {
    const { data } = await apiClient(`/configuration/users/${id}/password/reset`)
    return data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function getUsers(currentPage: number): Promise<TUserData> {
  try {
    const { data } = await apiClient('/configuration/users?enabled=true', {
      params: {
        ...prepareTablePaginationRequestParams(currentPage, 10)
      }
    })
    return data
  } catch (e) {
    throw new Error('Error data')
  }
}

async function getPartnerAdminUsers(): Promise<TOrganizations> {
  try {
    const { data } = await apiClient('/configuration/partner-organizations?unpaged=true')
    return data
  } catch (e) {
    throw new Error('Error data')
  }
}

async function editUser(
  user: TForm,
  id?: number
): Promise<TEdit> {
  const { data } = await apiClient.put(`/configuration/users/${id}`, user)
  return data
}

async function deleteUser(id?: number): Promise<number> {
  try {
    const { data } = await apiClient.post(`/configuration/users/${id}`)
    return data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function addUser(user: TForm): Promise<TUser> {
  try {
    const { data } = await apiClient.post('/configuration/users', user)
    return data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function validateEmail(email: string): Promise<boolean> {
  try {
    const response = await apiClient.get(`/auth/users/email/validate?email=${email}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useAddUserQuery() {
  const queryClient = useQueryClient()

  return useMutation<TUser, AxiosError, TForm>(
    (user) => addUser(user),
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.Users) }
  )
}

export function useEditUserQuery() {
  const queryClient = useQueryClient()
  return useMutation<TEdit, AxiosError, TEdit>(
    ({ user, id }: TEdit) => editUser(user, id),
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.Users) }
  )
}

export function useDeleteUserQuery() {
  const queryClient = useQueryClient()
  return useMutation((id?: number) => deleteUser(id), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.Users)
  })
}

export function useResendPasswordQuery(id?: number) {
  return useQuery(QueryKeys.ResendPassword, () => resendPassword(id), {
    enabled: false
  })
}

export function useUsersQuery(currentPage: number) {
  const key = [QueryKeys.Users, currentPage]

  return useQuery(key, () => getUsers(currentPage), {
    keepPreviousData: true
  })
}

async function getInProgressRequests(id?: number): Promise<number[]> {
  const { data } = await apiClient(`/dashboard/loan-requests/in-progress-requests?userId=${id}`)
  return data
}

export function useInProgressRequestsQuery(id?: number) {
  const isAdmins = useHasPermission(['GENERAL_VIEW', 'PARTNERS_ADMIN_VIEW'])
  return useQuery(
    [QueryKeys.InProgressRequest, id],
    () => getInProgressRequests(id),
    {
      enabled: !!id && !isAdmins,
      keepPreviousData: false
    }
  )
}

async function resetProgressRequests(id?: number) {
  const response = await apiClient.post(`/dashboard/loan-requests/reset?userId=${id}`)
  return response
}

export function useResetProgressRequestsQuery() {
  const queryClient = useQueryClient()
  return useMutation((id?: number) => resetProgressRequests(id), {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.InProgressRequest)
  })
}

async function getOrganization(): Promise<TOrganizations | undefined> {
  const response = await apiClient('/configuration/organizations')
  return response.data
}

export function useOrganizationsQuery() {
  const isSuperAdmin = useHasPermission(['GENERAL_VIEW'])
  const key = [QueryKeys.Organizations]

  return useQuery(key, () => getOrganization(), {
    keepPreviousData: true,
    enabled: isSuperAdmin
  })
}

export function useEmailValidationQuery() {
  return useMutation<boolean, Error, string>((email) => validateEmail(email))
}

export function usePartnerAdminQuery() {
  const isPartnerAdmin = useHasPermission(['PARTNERS_ADMIN_VIEW'])
  const key = [QueryKeys.PartnerAdminUsers]
  return useQuery<TOrganizations, Error>(
    key,
    () => getPartnerAdminUsers(),
    {
      enabled: isPartnerAdmin
    }
  )
}

async function createPartnerOrganization(name: string): Promise<TOrganizationContent> {
  try {
    const response = await apiClient.post('/configuration/partner-organizations', { name })
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useCreatePartnerOrganizationMutation() {
  const queryClient = useQueryClient()
  return useMutation<TOrganizationContent, Error, string>(
    (name) => createPartnerOrganization(name),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.PartnerAdminUsers])
    }
  )
}
