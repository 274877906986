import { FC } from 'react'
import styled from 'styled-components/macro'
import CustomTooltip from '../../../../../core/components/CustomTooltip'
import { InformationIcon } from '../../../../../core/components/icons/InformationIcon'
import { flex, fontFamily } from '../../../../../core/styles/mixins'
import { IconWrapperStyled } from '../../../overview/components/PlendScore'
import { TMetric } from '../types'
import { formatToPound } from '../../../../../core/utils/currencyFormat'

const CardStyled = styled.div`
  ${flex({ align: 'flex-start' })};
  flex-direction: column;
  max-height: 140px;
  letter-spacing: 0;

  ${IconWrapperStyled} {
    margin-left: 5.67px;
    color: ${({ theme }) => theme.colors.grey600};
  }
`

const CardTitle = styled.div`
  ${fontFamily('Inter')};
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const CardSubTitle = styled.div`
  ${fontFamily('Inter')};
  margin-top: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.grey600};
`

const MonthIncome = styled.div`
  ${fontFamily('Inter')};
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const ThreeMonthIncome = styled.div`
  ${fontFamily('Inter')};
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.grey600};
`
const TitleWrapper = styled.div`
  display: flex;
`

const affordabilityTitle: string = `
The Affordability Threshold is calculated by deducting Essential Expenditure from Reliable Income for the 30 and 90 day periods. 
It should be carefully compared to the monthly repayments the borrower needs to service the loan. The FCA recommends an additional 
£100 buffer to be added to this for monthly additional spending. Depending on the individual and economic circumstances, a larger
 buffer than £100 per month may be prudent.
`

const nonDiscretionaryExpenditureTitle = `
Essential Expenditure is what the Plend engine has classified for the borrower as costs they cannot cut back on to service the loan. 
It combines all costs from Essential Expenditure (the subcategory), Basic Quality of Living, Committed Expenditure and a portion of 
the costs in Part-Discretionary spend into one number over the 30 and 90 day periods.
`

type TProps = {
  metric: TMetric
  title: string
}

const MetricsCard: FC<TProps> = ({ metric: { last30days, last90days, type }, title }) => (
  <CardStyled>
    <TitleWrapper>
      <CardTitle>{title}</CardTitle>
      {type === 'AFFORDABILITY_THRESHOLD' && (
        <CustomTooltip maxWidth={360} title={affordabilityTitle} placement="top-end" arrow>
          <IconWrapperStyled>
            <InformationIcon />
          </IconWrapperStyled>
        </CustomTooltip>
      )}
      {type === 'NON_DISCRETIONARY_EXPENDITURE' && (
        <CustomTooltip maxWidth={344} title={nonDiscretionaryExpenditureTitle} placement="top-end" arrow>
          <IconWrapperStyled>
            <InformationIcon />
          </IconWrapperStyled>
        </CustomTooltip>
      )}
    </TitleWrapper>

    <CardSubTitle>Last 30 days (total)</CardSubTitle>
    <MonthIncome>{formatToPound(last30days)}</MonthIncome>

    <CardSubTitle>Last 90 days (monthly average)</CardSubTitle>
    <ThreeMonthIncome>{formatToPound(last90days)}</ThreeMonthIncome>
  </CardStyled>
)
export default MetricsCard
