import { TPermission } from '../../core/types/permissions'
import { useAuthContext } from './state/useAuthState'
import { TOrganization } from './types'

export function useHasPermission(permissions: TPermission[]) {
  const { authData } = useAuthContext()

  const currentUserPermission = authData?.permissions?.[0]

  if (!currentUserPermission) {
    return false
  }

  return permissions.includes(currentUserPermission)
}

export function useHasCurrentUserCustomerServiceRole() {
  const { authData } = useAuthContext()
  return authData?.permissions.includes('CUSTOMER_SERVICE_VIEW')
}

export function useIsPlendOrganization() {
  return useIsCurrentOrganization('plend')
}

export function useIsSocialCreditOrganization() {
  return useIsCurrentOrganization(['socialcreditcymru', 'socialcreditplend'])
}

export function useIsCurrentOrganization(organizationSubdomains: TOrganization['subdomain'][] | TOrganization['subdomain']) {
  const { authData } = useAuthContext()
  if (!authData) {
    return false
  }
  const subdomainName = authData?.organization?.subdomain
  const organizations = Array.isArray(organizationSubdomains)
    ? organizationSubdomains
    : [organizationSubdomains]

  return organizations?.includes(subdomainName)
}

export function useGetPermission() {
  const { authData } = useAuthContext()
  return authData?.permissions?.[0]
}
