import styled, { useTheme } from 'styled-components/macro'
import { LinearProgress as MuiLinearProgressBar, linearProgressClasses } from '@mui/material'
import { CheckIcon } from '../../../core/components/icons/CheckIcon'
import { WarningIcon } from '../../../core/components/icons/WarningIcon'
import { useKeywordsContext } from '../state/useKeywords'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { SpinnerIcon } from '../../../core/components/icons/SpinnerIcon'

const IconWrapper = styled.div`
  margin-top: 5px;
`

const ContentStyled = styled.div`
  ${flex({ justify: 'space-between', align: 'center' })};
`

const TextStyled = styled.p`
  ${fontFamily('Inter')};
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const LinearProgressBar = styled(MuiLinearProgressBar)<{shade?: string}>`
  && {
    margin-top: 4px;
    margin-left: 4px;
    width: 320px;
    height: 4px;
    & .${linearProgressClasses.colorPrimary}{
      background-color: ${({ theme }) => theme.colors.grey600};
    }
    & .${linearProgressClasses.bar}{
      background-color: ${({ theme, shade }) => shade || theme.colors.blue500};
    }
  }
`

const CustomProgressBar = () => {
  const { colors: { red, chart: { green } } } = useTheme()
  const {
    upload: {
      isError,
      isLoading,
      fileName,
      uploadedFileId,
      progressbarValue,
      setUploadedFileId,
      isStatusSuccess,
      isIdle
    },
    keywordsQuery: { refetch }
  } = useKeywordsContext()

  const progressBarCompletedColor = () => {
    if (progressbarValue?.progress === 100) return green
    if (isError) return red
    return undefined
  }

  if (uploadedFileId && progressbarValue && progressbarValue.progress === 100) {
    setUploadedFileId(undefined)
    refetch()
  }

  return (
    <>
      <IconWrapper>
        {(
          (isLoading || (isStatusSuccess && progressbarValue?.progress < 100))
            || progressbarValue?.progress < 100)
        && <SpinnerIcon/>}
        {progressbarValue?.progress === 100 && <CheckIcon width={24} height={24}/>}
        {isError && <WarningIcon width={24} height={24}/>}
      </IconWrapper>
      <div>
        <ContentStyled>
          <TextStyled>
            {(isLoading || progressbarValue?.progress < 100)
            && `Uploading ${fileName || progressbarValue.fileName} (${progressbarValue?.progress || 0}%)`}
            {progressbarValue?.progress === 100 && `${fileName || progressbarValue.fileName} has been successfully uploaded`}
            {isError && `Upload failed. ${fileName || progressbarValue.fileName}`}
          </TextStyled>
        </ContentStyled>

        { (!isIdle || isError || isLoading || progressbarValue?.progress) ? (
          <LinearProgressBar
            shade={progressBarCompletedColor()}
            variant="determinate"
            value={!isError ? (progressbarValue?.progress || 0) : 100}
          />
        ) : null}

      </div>
    </>
  )
}
export default CustomProgressBar
