import styled from 'styled-components/macro'
import { FC } from 'react'
import { format } from 'date-fns'
import { flex, fontFamily } from '../styles/mixins'
import { HOUR_MINUTE, SLASH_DATE_FORMAT } from '../const/date'

const WrapperStyled = styled.div`
  ${flex({})};
  flex-direction: column;
`

const TextStyled = styled.p`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`

type Classification = {
  previousFirstClass: string
  previousSecondClass: string
  newFirstClass: string
  newSecondClass: string
  underwriterFirstName: string
  createdAt: Date
}

type TProps = {
  classification: Classification[]
}

const getClassChangeText = (
  type: string,
  previousClass: string,
  newClass: string,
  createdAt: Date,
  underwriterFirstName: string
) => (
  <TextStyled>
    {` OB Class ${type} was changed from ${previousClass} to ${newClass} - ${format(
      new Date(createdAt),
      SLASH_DATE_FORMAT
    )} at ${format(new Date(createdAt), HOUR_MINUTE)} by ${underwriterFirstName}`}
  </TextStyled>
)

const ClassificationText: FC<TProps> = ({ classification }) => (
  <WrapperStyled>
    {classification.map(({
      previousFirstClass,
      previousSecondClass,
      newFirstClass,
      newSecondClass,
      createdAt,
      underwriterFirstName
    }) => (
      <>
        {previousFirstClass && newFirstClass && getClassChangeText(
          '1',
          previousFirstClass,
          newFirstClass,
          createdAt,
          underwriterFirstName
        )}
        {previousSecondClass && newSecondClass && getClassChangeText(
          '2',
          previousSecondClass,
          newSecondClass,
          createdAt,
          underwriterFirstName
        )}
      </>
    ))}
  </WrapperStyled>
)

export default ClassificationText
