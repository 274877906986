import { Column } from 'react-table'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { fontFamily } from '../../../../core/styles/mixins'
import Table from '../../../../core/components/table/Table'
import { useBorrowerScoreContext } from '../state/useBorrowerScore'
import Spinner from '../../../../core/components/Spinner'
import { useIsPlendOrganization, useIsSocialCreditOrganization } from '../../../auth/hooks'

const Wrapper = styled.div`
  && {
    ${HeaderCellStyled} {
      padding: 24px 16px 4px 0;

      :first-child {
        width: 660px;
      }

      :nth-child(2) {
        text-align: right;
      }
    }

    ${BodyCellStyled} {
      ${fontFamily('Inter')};
      padding: 10px 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      :first-child {
        padding-left: 16px;
      }

      :nth-child(2) {
        padding-right: 16px;
        text-align: right;
      }
    }
  }
`

const AlignLeftStyled = styled.span`
  padding-left: 16px;
  width: 100%;
`

const AlignRightStyled = styled.span`
  width: 142px;
  padding-right: 16px;
`

const ScoreStyled = styled.span`
  text-align: left;
`

const DetailedScoreTable = () => {
  const isPlendOrganization = useIsPlendOrganization()
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: () => <AlignLeftStyled>Rule Description</AlignLeftStyled>,
        accessor: 'description'
      },
      {
        Header: 'Score',
        accessor: 'score',
        Cell: ({ value }) => <ScoreStyled>{value}</ScoreStyled>
      },
      {
        Header: 'Value',
        accessor: 'value',
        disableSortBy: true,
        Cell: ({ value }) => <AlignRightStyled>{value}</AlignRightStyled>
      }
    ],
    []
  )

  const {
    setSort,
    sort,
    selectedBorrowerScore,
    borrowerScoreQuery: { data: detailedData, isSuccess, isLoading, isFetching }
  } = useBorrowerScoreContext()

  const data = useMemo(
    () => (
      (isPlendOrganization || isSocialCreditOrganization) && detailedData
        ? detailedData.data
        : detailedData?.data.filter((elem) => elem.key !== 'ob_char_01')
        || []),
    [detailedData?.data, sort, selectedBorrowerScore]
  )

  if (isLoading) return <Spinner widthHeight={50} />

  if (isSuccess && detailedData) {
    return (
      <Wrapper>
        <Table
          columns={columns}
          data={data}
          showLoader={isFetching}
          sortingConfig={{
            sortable: true,
            initialSortBy: [sort],
            onSortByChange: (sort) => {
              const defaultParams = {
                id: 'score',
                desc: true
              }
              setSort(sort?.length ? sort[0] : defaultParams)
            }
          }}
        />
      </Wrapper>
    )
  }
  return <></>
}

export default DetailedScoreTable
