import styled, { css } from 'styled-components/macro'
import {
  Menu,
  MenuItem,
  MenuProps
} from '@mui/material'
import React, { FC, useState } from 'react'
import { fontFamily } from '../../../../../core/styles/mixins'
import { MoreIcon } from '../../../../../core/components/icons/MoreIcon'
import { useAssessmentContext } from '../../state/useAssessment'
import { useApplicationHeaderContext } from '../../../../applicationHeader/state/useApplicationHeader'
import { TStatus } from '../../../../applicationHeader/type'
import GetLinkModal from './GetLinkModal'
import SuccessModal from './SuccessModal'
import { BaseButton } from '../../../../../core/components/Button'
import { ChainIcon } from '../../../../../core/components/icons/ChainIcon'
import { useIsCurrentOrganization } from '../../../../auth/hooks'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))`
  && .MuiPaper-root {
    border-radius: 3px;
    margin-top: 2px;
    min-width: 160px;
    color: ${({ theme }) => theme.colors.darkBlack};
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

    & .MuiMenuItem-root {
      ${fontFamily('Inter')};
      color: ${({ theme }) => theme.colors.darkBlack};
      padding: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    }

    & .MuiMenu-list {
      padding: 0;
    }
  }
`

const mixinSecondaryButton = css`
&&{
  height: 32px;
  padding: 12px;
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: ${({ theme }) => theme.colors.grey300};
  color: ${({ theme }) => theme.colors.darkBlack};

  &:hover {
    background: ${({ theme }) => theme.colors.grey500};
  }
}
`

const ButtonStyled: any = styled(BaseButton)`
  && {
    margin-top: 16px;
    ${mixinSecondaryButton}
  }
`

const ButtonTextStyled = styled.div`
  margin-left: 8px;
`

const MoreButtonWrapper = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 4px 4px 4px;
  margin: 0 20px;
  background: ${({ theme }) => theme.colors.grey200};
`

function useHasAppropriateStatus() {
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()

  return headerData?.status && (['APPROVED', 'DECLINED'] as TStatus[]).includes(headerData.status)
}

function useMenuHandle() {
  const moreButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return {
    open,
    anchorEl,
    setAnchorEl,
    moreButtonClick
  }
}

function useOpenGetLinkModal() {
  const {
    setIsModalOpen,
    reConnect: {
      reconnectRulesQuery: {
        data: reconnectRulesData,
        refetch: fetchReconnectRules
      }
    }
  } = useAssessmentContext()

  const openModal = () => setIsModalOpen({ getReconnectLink: true })

  return () => {
    if (!reconnectRulesData) {
      fetchReconnectRules().then(() => {
        openModal()
      })
      return
    }

    openModal()
  }
}

type TProps = {
  asMenu?: boolean
}

function useAvailableForOrganization() {
  return useIsCurrentOrganization(['plend', 'makemyhousegreen'])
}

export const AssessmentSendReConnectLink: FC<TProps> = ({ asMenu }) => {
  const {
    moreButtonClick,
    anchorEl,
    setAnchorEl
  } = useMenuHandle()

  const openGetLinkModal = useOpenGetLinkModal()

  const availableForOrganization = useAvailableForOrganization()

  if (!useHasAppropriateStatus() || !availableForOrganization) {
    return null
  }

  return (
    <>
      {asMenu && (
        <>
          <MoreButtonWrapper onClick={moreButtonClick}>
            <MoreIcon onClick={moreButtonClick} />
          </MoreButtonWrapper>
          <StyledMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                openGetLinkModal()
                setAnchorEl(null)
              }}
            >
              Send a re-connect link
            </MenuItem>
          </StyledMenu>
        </>
      )}

      {!asMenu && (
        <ButtonStyled
          onClick={() => openGetLinkModal()}
          variant="contained"
        >
          <ChainIcon/>
          <ButtonTextStyled>
            Send a re-connect link
          </ButtonTextStyled>
        </ButtonStyled>
      )}

      <GetLinkModal />
      <SuccessModal />
    </>
  )
}
export default AssessmentSendReConnectLink
