import styled from 'styled-components/macro'
import { useMetricsContentContext } from '../../base/state/useMetricsContentState'
import EditTransaction from '../../../editTransaction/components/EditTransaction'
import { flex } from '../../../../../core/styles/mixins'

const EditTransactionWrap = styled.div`
  ${flex({ justify: 'flex-end' })};
  margin-top: 16px;
  padding-right: 16px;
`

const AffordabilityEditTransactions = () => {
  const { selectedTransactions, handleClearTransactions } = useMetricsContentContext()

  return (
    <EditTransactionWrap>
      <EditTransaction
        handleClearTransactions={handleClearTransactions}
        transactions={selectedTransactions} />
    </EditTransactionWrap>
  )
}

export default AffordabilityEditTransactions
