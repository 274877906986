import { useMemo } from 'react'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { TReclassify } from '../type'
import { useAuthContext } from '../../../auth/state/useAuthState'
import { useIsCurrentOrganization } from '../../../auth/hooks'

const reclassifyStatuses: TReclassify[] = ['IN_PROGRESS', 'NEW', 'ACCOUNT_UPDATED', 'ACCOUNT_REQUESTED']

export function useIsReClassifiable() {
  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()
  const isOrganizationsHasPermissionReclassify = useIsCurrentOrganization([
    'plend', 'purpleshoots', 'lendology', 'makemyhousegreen', 'robertowen'
  ])

  const { authData } = useAuthContext()
  return useMemo(() => (headerData && authData?.id
    ? reclassifyStatuses.includes(headerData.status as TReclassify)
    && authData.permissions.includes('UNDERWRITER_VIEW')
    && isOrganizationsHasPermissionReclassify
    : undefined), [headerData, authData])
}
