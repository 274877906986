import { TOrganizations } from './types'
import { TOption } from '../../core/components/CustomSelect'

export const formattedOrganizations = (organizations: TOrganizations): TOption[] => {
  const formattedOptions = organizations.content.map(({ name, id }) => ({
    label: name,
    value: id
  }))
  return [...formattedOptions]
}
