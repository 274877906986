import { FC } from 'react'
import { useNotFoundContext } from '../../../notFound/guards/NotFoundGuard'
import { useIsPlendOrganization, useIsSocialCreditOrganization } from '../../../auth/hooks'

type TBureauxGuard = {
  children: JSX.Element
}

const BureauxGuard: FC<TBureauxGuard> = ({ children }) => {
  const { setShowNotFound } = useNotFoundContext()

  const isPlendOrganization = useIsPlendOrganization()
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  if (!isPlendOrganization && !isSocialCreditOrganization) setShowNotFound(true)

  return children
}

export default BureauxGuard
