import { TSortBy } from '../components/table/TableContext'

export function prepareTablePaginationRequestParams(
  pageFrom1: number,
  itemsPerPage: number,
  sort?: TSortBy,
  firstClass?: string
) {
  return {
    sort: sort && `${sort.id},${sort.desc ? 'desc' : 'asc'}`,
    page: pageFrom1 - 1,
    size: itemsPerPage,
    firstClass
  }
}
