import styled from 'styled-components/macro'
import DetailedTransactionTable from '../../base/components/DetailedTransactionTable'
import MetricsCategoryTable from '../../base/components/MetricsCategoryTable'
import { WrapStyled } from '../../base/components/LayoutWrap'
import { MetricsContentProvider } from '../../base/state/useMetricsContentState'
import { AffordabilityFilterProvider, useAffordabilityFilterContext } from '../state/useAffordabilityFilterState'
import Filters from './Filters'
import CategoryChart from '../../base/components/CategoryChart'
import AffordabilityEditTransactions from './AffordabilityEditTransactions'

const Wrapper = styled(WrapStyled)`
  flex-direction: column;
`

const Main = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1px;
`

const CategoriesStyled = styled.div`
  flex: 0.5;
  border-right: 1px solid ${({ theme }) => theme.colors.grey300};
`

const TransactionStyled = styled.div`
  flex: 0.5;
  padding-bottom: 18px;
`

const Affordability = () => {
  const { filterKey } = useAffordabilityFilterContext()

  return (
    <MetricsContentProvider affordabilityFilterKey={filterKey}>
      <Wrapper>
        <Filters />
        <Main>
          <CategoriesStyled>
            <MetricsCategoryTable />
            <CategoryChart />
          </CategoriesStyled>
          <TransactionStyled>
            <AffordabilityEditTransactions />
            <DetailedTransactionTable />
          </TransactionStyled>
        </Main>
      </Wrapper>
    </MetricsContentProvider>
  )
}

export default () => (
  <AffordabilityFilterProvider>
    <Affordability />
  </AffordabilityFilterProvider>
)
