import React, { FC } from 'react'
import styled from 'styled-components/macro'
import Card, { CardStyled } from '../../../core/components/Card'
import InnerLayout, { HeaderStyled } from '../../../core/components/InnerLayout'
import Tabs from './Tabs'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { useValidationRulesContext, ValidationRulesProvider } from '../state/validationRulesState'
import { ValidationRulesBaseTable } from '../../validationRulesBase'
import { AddException, ExceptionsTable } from '../../exceptions'

const WrapStyled = styled.div`
  height: 100%;

  ${HeaderStyled} {
    ${flex({ justify: 'space-between', align: 'flex-start' })};
    flex-direction: column;
    min-height: 116px;
    position: relative;
    padding-bottom: 0;
  }

  ${CardStyled} {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
    overflow: auto;
    height: auto;
  }
`

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
`
const HeadStyled = styled.div`
  ${flex({ align: 'center', justify: 'space-between' })};
  width: 100%;
`

const ValidationRules: FC = () => {
  const { isBureaux, validationRulesQuery, filter } = useValidationRulesContext()
  const isExceptions = filter === 'EXCEPTIONS'
  return (
    <WrapStyled>
      <InnerLayout
        header={(
          <>
            <HeadStyled>
              <TitleStyled>Validation Rules</TitleStyled>
              {isExceptions && <AddException />}
            </HeadStyled>
            <Tabs />
          </>
        )}
      >
        <Card noPadding>
          {isExceptions
            ? <ExceptionsTable />
            : (
              <ValidationRulesBaseTable
                isBureaux={isBureaux}
                validationRulesQuery={validationRulesQuery}
              />
            )}
        </Card>
      </InnerLayout>
    </WrapStyled>
  )
}

export default () => (
  <ValidationRulesProvider>
    <ValidationRules />
  </ValidationRulesProvider>
)
