import constate from 'constate'
import { useMemo } from 'react'
import { TOption } from '../../../core/components/CustomSelect'
import { useIsSocialCreditOrganization } from '../../auth/hooks'
import { useEmploymentStatus, useLoanPurposeRawQuery } from '../querries'

type TRuleKeyOptions = 'rule' | 'daysPeriod' | 'value' | 'loanPurposeOptions'

export const ruleOptions: TOption[] = [
  {
    label: 'is less than',
    value: 'LESS'
  },
  {
    label: 'is less than or equal to',
    value: 'LESS_OR_EQUAL'
  },
  {
    label: 'is equal to',
    value: 'EQUAL'
  },
  {
    label: 'is greater than',
    value: 'MORE'
  },
  {
    label: 'is greater than or equal to',
    value: 'MORE_OR_EQUAL'
  }
]

export const daysPeriodOptions: TOption[] = [
  {
    label: '30',
    value: 30
  },
  {
    label: '60',
    value: 60
  },
  {
    label: '90',
    value: 90
  },
  {
    label: '120',
    value: 120
  },
  {
    label: '150',
    value: 150
  },
  {
    label: '180',
    value: 180
  },
  {
    label: '210',
    value: 210
  },
  {
    label: '240',
    value: 240
  },
  {
    label: '270',
    value: 270
  },
  {
    label: '300',
    value: 300
  },
  {
    label: '330',
    value: 330
  },
  {
    label: '360',
    value: 360
  }
]

type TProps = {
  isEmploymentStatusQueryEnabled: boolean
}
const useOptions = ({
  isEmploymentStatusQueryEnabled
}: TProps): { [key in TRuleKeyOptions]: TOption[] } => {
  const { data: loanPurposeRaw } = useLoanPurposeRawQuery()
  const { data: employmentStatus } = useEmploymentStatus(isEmploymentStatusQueryEnabled)
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  const employmentStatusOptions = useMemo(
    () => employmentStatus?.map(({ id, value }) => ({ label: value, value: id })) || [],
    [employmentStatus]
  )

  const loanPurposeOptions = useMemo(
    () => loanPurposeRaw?.map(({ id, child, parent }) =>
      ({ label: child || parent, value: id })) || [],
    [loanPurposeRaw]
  )

  return {
    rule: ruleOptions,
    daysPeriod: isSocialCreditOrganization
      ? daysPeriodOptions.filter(({ label }) => ['30', '90'].includes(label))
      : daysPeriodOptions,
    value: employmentStatusOptions,
    loanPurposeOptions
  }
}
export const [OptionsProvider, useOptionsContext] = constate(useOptions)
