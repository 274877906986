import React, { FC, useMemo } from 'react'
import { Column, Row } from 'react-table'
import styled from 'styled-components/macro'
import Table from '../../../../../core/components/table/Table'
import { ChevronRightIcon } from '../../../../../core/components/icons/ChevronRightIcon'
import { useMetricsContentContext } from '../state/useMetricsContentState'
import { TCategory } from '../types'
import Spinner from '../../../../../core/components/Spinner'
import { flex } from '../../../../../core/styles/mixins'
import { formatToPound } from '../../../../../core/utils/currencyFormat'
import { HeaderCellStyled } from '../../../../../core/components/table/TableHeadRow'
import NoDataComponent from '../../../../../core/components/NoDataComponent'
import { CheckIcon } from '../../../../../core/components/icons/CheckIcon'
import { ExclamationIcon } from '../../../../../core/components/icons/ExclamationIcon'
import { useIsPlendOrganization } from '../../../../auth/hooks'

const Wrapper = styled.div<{ selected?: boolean }>`
    && .Mui-selected {
        background-color: ${({ theme }) => theme.colors.blue300};

        &:hover {
            background-color: ${({ theme }) => theme.colors.blue300};
        }
    }

    padding: 10px 0 16px;
    border-bottom: 1px solid ${({ theme, selected }) => (selected ? theme.colors.grey300 : 'transparent')};

    .MuiTableCell-body {
        min-height: 40px;
        padding: 8px 0;
    }

    ${HeaderCellStyled} {
        :last-child {
            padding-right: 49px;
        }
    }
`

const ChevronStyled = styled.div`
    ${flex({ justify: 'center' })};
    margin: 0 15px 0 17px;
    color: ${({ theme }) => theme.colors.grey600};
`

const AlignRightStyled = styled.div`
    text-align: right;
    margin-left: 16px;
`

const AlignLeftStyled = styled.div<{ selected?: boolean }>`
    padding-left: 16px;
    color: ${({ theme, selected }) => (selected ? theme.colors.darkBlack : theme.colors.grey600)};
`

const OBlassWrap = styled.div`
    ${flex({})};
    gap: 4px;
`
const IconWrap = styled.div<{ isColorBlue?: boolean }>`
    ${flex({ justify: 'center', align: 'center' })}
    color: ${({ theme }) => theme.colors.blue500};
    cursor: pointer;


`

export const SpinnerWrapperStyled = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
    margin: 66px 0;
`

const MetricsCategoryTable: FC = () => {
  const {
    categoriesQuery: { data: categoryData, isLoading, isIdle },
    setSelectedCategory,
    selectedCategory
  } = useMetricsContentContext()
  const isPlendOrganization = useIsPlendOrganization()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: () => <AlignLeftStyled>OB Class 1</AlignLeftStyled>,
        accessor: 'firstClass',
        Cell: ({ value, row: { isSelected, original } }): any => {
          const {
            hasAppliedClassifications,
            hasNonAppliedClassifications
          } = original as TCategory
          return (
            <OBlassWrap>
              <AlignLeftStyled selected={isSelected}>{value}</AlignLeftStyled>
              {hasAppliedClassifications && (
                <IconWrap>
                  <CheckIcon width={16} height={16} />
                </IconWrap>
              )}
              {hasNonAppliedClassifications && (
                <IconWrap isColorBlue>
                  <ExclamationIcon width={16} height={16} />
                </IconWrap>
              )}
            </OBlassWrap>
          )
        }
      },
      {
        Header: () => <AlignRightStyled>Last 30 Days</AlignRightStyled>,
        accessor: 'last30Days',
        Cell: ({ value }): any => <AlignRightStyled>{formatToPound(value)}</AlignRightStyled>
      },
      {
        Header: () => <AlignRightStyled>Last 90 Days</AlignRightStyled>,
        accessor: 'last90Days',
        Cell: ({ value }): any => <AlignRightStyled>{formatToPound(value)}</AlignRightStyled>
      },
      {
        Header: () => <AlignRightStyled>Last 180 Days</AlignRightStyled>,
        accessor: 'last180Days',
        Cell: ({ value }): any => <AlignRightStyled>{formatToPound(value)}</AlignRightStyled>
      },
      {
        Header: ' ',
        accessor: ' ',
        Cell: () => (
          <ChevronStyled>
            <ChevronRightIcon />
          </ChevronStyled>
        )
      }
    ],
    []
  )

  return (
    <Wrapper selected={!!selectedCategory}>
      <Table
        selectionConfig={
          selectedCategory && {
            rowIdentityProp: 'firstClass',
            selectedRowIds: selectedCategory?.firstClass
          }
        }
        onRowClick={({ original }: Row<TCategory>) => {
          setSelectedCategory(original)
        }}
        columns={columns}
        data={categoryData?.categories || []}
      />
      {isLoading && !selectedCategory && (
        <SpinnerWrapperStyled>
          <Spinner />
        </SpinnerWrapperStyled>
      )}
      {!categoryData?.categories.length && !isLoading && !isIdle && (
        <NoDataComponent iconWidth={52} iconHeight={48} text="There are no categories" />
      )}
    </Wrapper>
  )
}
export default MetricsCategoryTable
