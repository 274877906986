import { FC } from 'react'
import styled from 'styled-components/macro'
import { TValidationValues } from '../type'
import FailedListHeader from './FailedListHeader'
import FailedListBody from './FailedListBody'

const FailedListStyled = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.colors.shadow100};
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  border-radius: 6px;
  width: 100%;
  height: min-content;
  margin-right: 24px;
`

const FailedList: FC<TValidationValues> = ({ header, status, value }) => (
  <FailedListStyled key={header}>
    <FailedListHeader status={status} text={header} />
    <FailedListBody details={value} />
  </FailedListStyled>
)

export default FailedList
