import styled from 'styled-components/macro'
import { FC } from 'react'
import { fontFamily } from '../../styles/mixins'

const AxisTextStyled = styled.text<{fontSize: number}>`
  ${fontFamily('Inter')};
  font-weight: normal;
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 16px;
  fill: ${({ theme }) => theme.colors.grey600};
  padding-left: 16px;
`

type TProps = {
  x: number
  y: number
  textAnchor: string
  orientation: string
  height: number
  dy: number
  formattedValue: string
  fontSize?: number
}

const CustomizedTick: FC<TProps> = ({
  x,
  y,
  textAnchor,
  orientation,
  height,
  dy,
  formattedValue,
  fontSize
}) => (
  <AxisTextStyled
    fontSize={fontSize || 12}
    orientation={orientation}
    x={x}
    y={y}
    height={height}
    textAnchor={textAnchor}>
    <tspan x={x} dy={dy}>
      {formattedValue}
    </tspan>
  </AxisTextStyled>
)
export default CustomizedTick
