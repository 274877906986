import styled from 'styled-components/macro'
import Tab from '../../../../../core/components/tabs/Tab'
import Tabs from '../../../../../core/components/tabs/Tabs'
import { useMetricsContext } from '../state/useMetricsState'
import MetricsCard from './MetricsCard'
import { flex } from '../../../../../core/styles/mixins'
import Spinner from '../../../../../core/components/Spinner'

const HeaderStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  min-height: 140px;

  .MuiTabs-flexContainer {
    ${flex({ justify: 'space-between' })};
    width: 100%;
  }

  .MuiTab-root {
    ${flex({ align: 'flex-start' })};
    flex: 1;
    padding: 16px 0 0 16px;
    letter-spacing: 0.02857em;
  }

  .Mui-selected {
    background: ${({ theme }) => theme.colors.blue200};
  }
`

const SpinnerWrapperStyled = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  min-height: 140px;
`

function useMetricsData() {
  const {
    metricsQuery: { data }
  } = useMetricsContext()

  if (!data) {
    return []
  }

  return [
    { metric: data.totalIncome, title: 'Total Income', disabled: false },
    { metric: data.eligibleIncome, title: 'Eligible Income', disabled: false },
    { metric: data.ineligibleIncome, title: 'Ineligible Income', disabled: false },
    { metric: data.nonDiscretionaryExpenditure, title: 'Non-Discretionary Expenditure', disabled: false },
    { metric: data.nonEssentialExpenditure, title: 'Discretionary Expenditure', disabled: false },
    { metric: data.affordabilityThreshold, title: 'Affordability Threshold', disabled: true }
  ]
}

const MetricsHeader = () => {
  const {
    metricsQuery: { isLoading },
    setActiveMetric,
    activeMetricKey
  } = useMetricsContext()

  const metricsData = useMetricsData()

  if (isLoading || !metricsData) {
    return (
      <SpinnerWrapperStyled>
        <Spinner />
      </SpinnerWrapperStyled>
    )
  }

  return (
    <HeaderStyled>
      <Tabs
        value={metricsData.findIndex(({ metric: { type } }) => type === activeMetricKey)}
        onChange={(_, value) => setActiveMetric(metricsData[value].metric.type)}
      >
        {metricsData.map(({ title, disabled, metric }) => (!disabled
          ? (
            <Tab
              key={metric.type}
              label={<MetricsCard metric={metric} title={title} />} />
          )
          : (
            <div className="MuiTab-root">
              <MetricsCard metric={metric} title={title} />
            </div>
          )))}
      </Tabs>
    </HeaderStyled>
  )
}

export default MetricsHeader
