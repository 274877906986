import React, { FC, ReactNode, useState } from 'react'
import { Select } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { Control, Controller } from 'react-hook-form'
import { BaseButton } from '../../core/components/Button'
import { flex, fontFamily } from '../styles/mixins'
import { SelectedIcon } from './icons/SelectedIcon'
import { ArrowUpIcon } from './icons/ArrowUpIcon'
import { ArrowDownIcon } from './icons/ArrowDownIcon'
import NoDataComponent from './NoDataComponent'
import { useIsPlendOrganization } from '../../features/auth/hooks'

export const GlobalStyles = createGlobalStyle`
  .multipleSelectContainer {
    max-height: 314px !important;
    transform: translate(0, -8px) !important;
    box-shadow: 0 1px 10px 0 #0000001A !important;

    && {
      .MuiList-root {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      li.MuiButtonBase-root {
        padding: 8px 12px;
      }
    }
  }
`

const ContainerStyled = styled.div<{ isOptionChecked: boolean }>`
  ${fontFamily('Inter')};
  margin-right: 8px;
  overflow: hidden;

  && {
    .MuiMenuItem-root {
      padding: 0;
    }

    .MuiOutlinedInput-root {
      width: 161px;
      height: 32px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${({ theme, isOptionChecked }) =>
    (isOptionChecked ? theme.colors.white : theme.colors.darkBlack)};
      background: ${({ theme, isOptionChecked }) =>
    (isOptionChecked ? theme.colors.blue500 : theme.colors.grey200)};
      cursor: pointer;
    }

    .MuiSelect-select {
      padding-right: 5px !important;
    }

    .MuiSvgIcon-root {
      color: ${({ theme, isOptionChecked }) =>
    (isOptionChecked ? theme.colors.white : theme.colors.darkBlack)};
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`

const ButtonWrapper = styled.div`
  ${flex({ justify: 'flex-end', align: 'center' })};
  gap: 8px;
  padding: 0 10px 8px 12px;
  margin-top: 8px;
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white};
`

const CustomButton = styled(BaseButton)`
  height: 32px;

  && {
    color: ${({ theme }) => theme.colors.darkBlack};
    background: ${({ theme }) => theme.colors.grey200};

    &:hover {
      background: ${({ theme }) => theme.colors.grey200};
    }
  }
`

const CustomApplyButton = styled(CustomButton)`
  && {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue500};

    &:disabled {
      background: ${({ theme }) => theme.colors.grey200};
    }

    &:hover {
      background: ${({ theme }) => theme.colors.blue500};
    }
  }
`

const MenuItemContent = styled.div`
  ${flex({ justify: 'space-between', align: 'center' })};
  width: 100%;
`

const IconWrapper = styled.div<{ isOptionChecked: boolean }>`
  color: ${({ theme, isOptionChecked }) =>
    (isOptionChecked ? theme.colors.white : theme.colors.darkBlack)};
  padding-right: 12px;
`

const SearchStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  margin-top: 8px;
  background: ${({ theme }) => theme.colors.white};
`

const NoDataWrapper = styled.div`
  height: 176px;
  margin-top: 0;
`

const TitleStyled = styled.p`
  ${fontFamily('Inter')};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
  margin-top: 30px;
`

type TProps = {
  options: string[]
  name: string
  control: Control<any>
  title: string
  onClose?: () => void
  onApply: () => void
  onReset: () => void
  isOptionChecked: boolean
  search?: ReactNode
  onOpen?: () => void
}

const CustomMultipleSelect: FC<TProps> = ({
  options,
  name,
  control,
  title,
  onClose,
  onApply,
  onReset,
  isOptionChecked,
  search,
  onOpen
}) => {
  const [isOpen, setOpen] = useState(false)
  const isPlendOrganization = useIsPlendOrganization()

  const handleOpen = () => {
    setOpen(!isOpen)
    onOpen?.()
  }

  return (
    <ContainerStyled isOptionChecked={isOptionChecked}>
      <GlobalStyles />
      <FormControl>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ...otherOptions } }) => (
            <Select
              multiple
              value={value || []}
              displayEmpty
              MenuProps={{
                classes: {
                  paper: 'multipleSelectContainer'
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                }
              }}
              IconComponent={(props) => {
                const selectOpenClass = 'MuiSelect-iconOpen'
                const isSelectOpen = props?.className?.includes(selectOpenClass)
                return (
                  <IconWrapper onClick={handleOpen} isOptionChecked={isOptionChecked}>
                    {isSelectOpen ? (
                      <ArrowDownIcon width={10} height={10} />
                    ) : (
                      <ArrowUpIcon width={10} height={10} />
                    )}
                  </IconWrapper>
                )
              }}
              open={isOpen}
              onOpen={handleOpen}
              onClose={() => {
                onClose?.()
                setOpen(false)
              }}
              renderValue={() => title}
              onChange={(e) => onChange(e.target.value.filter(Boolean))}
              {...otherOptions}
            >
              <SearchStyled>{search}</SearchStyled>
              {options.map((option) => {
                const isOptionSelected = value?.includes(option)
                return (
                  <MenuItem key={option} value={option}>
                    <MenuItemContent>
                      {option}
                      {isOptionSelected && <SelectedIcon />}
                    </MenuItemContent>
                  </MenuItem>
                )
              })}
              {!options.length && (
                <NoDataWrapper>
                  <NoDataComponent iconWidth={40} iconHeight={38}>
                    <TitleStyled>No results found</TitleStyled>
                  </NoDataComponent>
                </NoDataWrapper>
              )}

              <ButtonWrapper>
                {isPlendOrganization && (
                  <CustomButton
                    form="multiselectForm"
                    type="button"
                    onClick={onReset}
                    disabled={!value?.length}
                  >
                    Clear all
                  </CustomButton>
                )}
                <CustomApplyButton
                  form="multiselectForm"
                  type="button"
                  onClick={() => {
                    onApply()
                    setOpen(false)
                  }}
                  disabled={!value?.length}
                >
                  Apply
                </CustomApplyButton>
              </ButtonWrapper>
            </Select>
          )}
        />
      </FormControl>
    </ContainerStyled>
  )
}

export default CustomMultipleSelect
