import React, { FC } from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { BaseButton } from '../../../core/components/Button'
import CustomModal from '../../../core/components/CustomModal'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { LocationIcon } from '../../../core/components/icons/LocationIcon'
import UserInfoItem, { textMaxWidth } from './UserInfoItem'
import { useAddressQuery } from '../queries'
import MarkApplication from './MarkApplication'
import { useHasCurrentUserCustomerServiceRole, useHasPermission, useIsPlendOrganization } from '../../auth/hooks'
import { TStatus } from '../type'

const ModalGlobalStyles = createGlobalStyle`
  .muiRoot {
    .MuiDialog-container.MuiDialog-container .MuiPaper-root {
      max-width: 740px;
      width: 100%;
    }

  }
`

const ContentStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TitleStyled = styled.h1`
  ${fontFamily('Inter')};
  ${flex({ align: 'center' })};
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: ${({ theme }) => theme.colors.darkBlack};
  margin-bottom: 16px;
  gap: 4px;
`

const ButtonCancelStyled = styled(BaseButton)`
  && {
    margin-top: 32px;
    padding: 8px 16px;
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    width: min-content;
    align-self: flex-end;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const AddressInfoWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const FieldContentStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 16px;
`

const IconStyled = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
`

const TooltipContentStyled = styled.div`
  ${flex({})};
  ${fontFamily('Inter')};
  margin: 2px 2px 0 -16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkBlack};
  gap: 4px;

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${textMaxWidth}px;
  }
`

const LineStyled = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.grey200};
}

;
`

type TProps = {
  open: boolean
  close: () => void
  status?: TStatus
}

const AddressInformationModal: FC<TProps> = ({ open, close, status }) => {
  const { data } = useAddressQuery()
  const isPlendOrganization = useIsPlendOrganization()

  if (!data) {
    return <></>
  }

  const isCustomerService = useHasCurrentUserCustomerServiceRole()
  const isUnderwriter = useHasPermission(['UNDERWRITER_VIEW'])

  const { address, prevAddress } = data
  const isPlendApprovedPendingRetriever = isPlendOrganization && status === 'APPROVED_PENDING_RETRIEVER'

  return (
    <>
      <ModalGlobalStyles />

      <CustomModal isOpen={open} onClose={close}>
        <ContentStyled>
          <TitleStyled>
            Address information
            <MarkApplication
              iconWidthHeight={16}
              width={376}
              match={['FULL_ADDRESS']}
              placement="top"
            />
          </TitleStyled>
          <AddressInfoWrapStyled>
            <FieldContentStyled>
              <UserInfoItem
                noOverlap
                title="Postcode"
                withCopy={(isCustomerService || isUnderwriter) && isPlendApprovedPendingRetriever}
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={address.postCode || '-'}
                valueComponent={(
                  <TooltipContentStyled>
                    {address.postCode ? (
                      <>
                        <MarkApplication
                          iconWidthHeight={16}
                          width={376}
                          match={['POSTCODE']}
                          placement="top"
                        />
                        <span>{address.postCode}</span>
                      </>
                    ) : (
                      '-'
                    )}
                  </TooltipContentStyled>
                )}
                isInputByUser
              />
              <UserInfoItem
                noOverlap
                title="Building No."
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={address.buildingNo || '-'}
                isInputByUser
              />

              <UserInfoItem
                noOverlap
                title="Apartment"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={address?.abodeNo || '-'}
                isInputByUser
              />

              <UserInfoItem
                noOverlap
                title="Building Name"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={address.buildingName || '-'}
                isInputByUser
              />
              <UserInfoItem
                noOverlap
                title="Street"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={address.street || '-'}
                isInputByUser
              />
              <UserInfoItem
                noOverlap
                title="Country"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={address.locality || '-'}
                isInputByUser
              />
              <UserInfoItem
                noOverlap
                title="Town"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={address.postTown || '-'}
                isInputByUser
              />
            </FieldContentStyled>
            <LineStyled />
            <FieldContentStyled>
              <UserInfoItem
                noOverlap
                title="Previous Postcode"
                withCopy={(isCustomerService || isUnderwriter) && isPlendApprovedPendingRetriever}
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={prevAddress?.postCode || '-'}
                isInputByUser
              />
              <UserInfoItem
                noOverlap
                title="Previous Building No."
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={prevAddress?.buildingNo || '-'}
                isInputByUser
              />

              <UserInfoItem
                noOverlap
                title="Previous Apartment"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={address.abodeNo || '-'}
                isInputByUser
              />

              <UserInfoItem
                noOverlap
                title="Previous Building Name"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={prevAddress?.buildingName || '-'}
                isInputByUser
              />
              <UserInfoItem
                noOverlap
                title="Previous Street"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={prevAddress?.street || '-'}
                isInputByUser
              />
              <UserInfoItem
                noOverlap
                title="Previous Country"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={prevAddress?.locality || '-'}
                isInputByUser
              />
              <UserInfoItem
                noOverlap
                title="Previous Town"
                icon={(
                  <IconStyled>
                    <LocationIcon />
                  </IconStyled>
                )}
                value={prevAddress?.postTown || '-'}
                isInputByUser
              />
            </FieldContentStyled>
          </AddressInfoWrapStyled>
          <ButtonCancelStyled onClick={close}>Cancel</ButtonCancelStyled>
        </ContentStyled>
      </CustomModal>
    </>
  )
}

export default AddressInformationModal
