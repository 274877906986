import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import SectionCard from './SectionCard'
import { AmountStyled } from './styled'
import { useOverview } from '../queries'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import Spinner from '../../../../core/components/Spinner'

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  width: 100%;
  margin: 98px 0;
`

const TextContainerStyled = styled.div<TTextContainerStyledProps>`
  ${fontFamily('Inter')};
  ${flex({ justify: 'space-between', align: 'start' })};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  width: 100%;
`

type TTextStyledProps = {
  textColor: 'darkBlack' | 'grey600'
  fontWeight: 'normal' | 600
  fontSize: 16 | 14
  lineHeight: 24 | 20
  marginLeft?: 15
}
const TextStyled = styled.p<TTextStyledProps>`
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => `${lineHeight}px`};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`} ;
`

type TTextContainerStyledProps = {
  marginTop: 24 | 16
}

const Affordability = () => {
  const { data, isIdle, isLoading } = useOverview()
  if (!data || isLoading || isIdle) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }
  const { affordabilityThreshold, averageIncome, nonDiscretionaryExpenditure } = data
  return (
    <>
      <div>
        <SectionCard
          navigationUrl="affordability"
          label="Affordability"
          caption="Average affordability threshold (90 days)" />
      </div>
      <AmountStyled>{formatToPound(affordabilityThreshold)}</AmountStyled>
      <TextContainerStyled marginTop={24}>
        <div>
          <TextStyled fontSize={16} lineHeight={24} fontWeight="normal" textColor="darkBlack">
            Average
            Income
          </TextStyled>
          <TextStyled fontSize={14} lineHeight={20} fontWeight="normal" textColor="grey600">Last 90 days</TextStyled>
        </div>
        <TextStyled
          fontSize={16}
          lineHeight={24}
          fontWeight={600}
          textColor="darkBlack"
          marginLeft={15}
        >
          {formatToPound(averageIncome)}
        </TextStyled>
      </TextContainerStyled>
      <TextContainerStyled marginTop={16}>
        <div>
          <TextStyled fontSize={16} lineHeight={24} fontWeight="normal" textColor="darkBlack">
            Average Non-Discretionary Expenditure
          </TextStyled>
          <TextStyled fontSize={14} lineHeight={20} fontWeight="normal" textColor="grey600">Last 90 days</TextStyled>
        </div>
        <TextStyled
          fontSize={16}
          lineHeight={24}
          fontWeight={600}
          textColor="darkBlack"
          marginLeft={15}
        >
          {formatToPound(nonDiscretionaryExpenditure)}
        </TextStyled>
      </TextContainerStyled>
    </>
  )
}

export default Affordability
