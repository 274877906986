import styled from 'styled-components/macro'
import SectionCard from './SectionCard'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { useOverview } from '../queries'
import Spinner from '../../../../core/components/Spinner'

const ContentStyled = styled.div`
  padding: 16px 0 20px 0;
  width: 100%;

  & > div:not(:first-child) {
    margin-top: 20px;
  }

  & > div {
    ${flex({ justify: 'space-between', align: 'flex-start' })};
  }
`

const TextStyled = styled.p`
  ${fontFamily('Inter')};
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.darkBlack};
  width: 100%;
  max-width: 224px;
`

const TextValueStyled = styled.p`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
  width: 100%;
  max-width: 112px;
  text-align: right;
  height: 24px;
`

const Bureaux = () => {
  const { data, isIdle, isLoading } = useOverview()

  if ((isIdle && isLoading) || !data) {
    return <Spinner />
  }

  return (
    <>
      <SectionCard navigationUrl="bureaux" label="Bureaux Data" />
      <ContentStyled>
        {
          data.bureauxData?.map(({ description, value }) => (
            <div>
              <TextStyled>{description}</TextStyled>
              <TextValueStyled>{value}</TextValueStyled>
            </div>
          ))
        }
      </ContentStyled>
    </>
  )
}

export default Bureaux
