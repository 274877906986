import { useState, useEffect, FC } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { SearchIcon } from './icons/SearchIcon'
import { CloseIcon } from './icons/CloseIcon'
import Button, { BaseButton } from './Button'
import CustomInput, { CustomTextField } from './CustomInput'
import { flex, fontFamily } from '../styles/mixins'

export const SearchWrapperStyled = styled.div`
  margin-top: 24px;
`

const SearchIconWrapper = styled.div<{ isOnFocus: boolean }>`
  color: ${({ theme, isOnFocus }) => (isOnFocus ? theme.colors.blue500 : theme.colors.darkBlack)};
`

export const FormStyled = styled.form`
  ${flex({ justify: 'flex-start', align: 'center' })};
  margin-left: 16px;
`

const CloseIconWrapper = styled.span`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  right: 10px;
  cursor: pointer;
`

const Wrapper = styled.div<{ isOnFocus: boolean }>`
  && {
    && .MuiOutlinedInput-root {
      height: 32px;
      padding-right: 30px;
    }

    ${CustomTextField} {
      width: 300px;
      height: 32px;
      color: black;
    }

    .MuiOutlinedInput-root {
      width: 300px;
      height: 32px;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;

      input[type='number'] {
        -moz-appearance: textfield;
      }

      && input {
        padding: 10px 0;
        height: 20px;
      }

      ::placeholder {
        ${fontFamily('Inter')};
        color: ${({ theme }) => theme.colors.darkBlack};
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        order: 1;
        flex-grow: 1;
      }
    }

    &&.MuiOutlinedInput-input::placeholder {
      color: ${({ theme }) => theme.colors.grey200};
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, isOnFocus }) =>
    (isOnFocus ? theme.colors.blue500 : theme.colors.grey600)};
    }

    && ${BaseButton} {
      height: 32px;
      padding: 6px 12px;
      margin-left: 16px;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.darkBlack};
      background: ${({ theme }) => theme.colors.grey300};
      text-transform: capitalize;
    }
  }
`

type TProps = {
  setSearch?: any
  setIsExactMatch?: (value: boolean) => void
  // todo remove this prop
  filter?: string
  placeholder: string
  handleChange?: (value: string) => string
  type?: string | number | any
}

const CustomSearch: FC<TProps> = ({
  setSearch,
  setIsExactMatch,
  filter,
  placeholder,
  handleChange,
  type
}) => {
  const [isOnFocus, setIsOnFocus] = useState(false)
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      searchedValue: ''
    }
  })
  const searchedValue = watch('searchedValue')

  useEffect(() => {
    if (!searchedValue) {
      setSearch('')
    }
  }, [searchedValue])

  useEffect(() => {
    reset({ searchedValue: '' }, { keepValues: false })
  }, [filter])

  const onSubmit = ({ searchedValue }: FieldValues) => {
    if (!searchedValue) {
      return
    }
    setSearch(searchedValue.toLowerCase().trim())
    setIsExactMatch?.(false)
  }

  const handleDelete = () => {
    reset({ searchedValue: '' }, { keepValues: false })
  }

  return (
    <Wrapper isOnFocus={isOnFocus}>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="searchedValue"
          control={control}
          placeholder={placeholder}
          type={type}
          icon={(
            <>
              <SearchIconWrapper isOnFocus={isOnFocus}>
                <SearchIcon />
              </SearchIconWrapper>
              {watch('searchedValue') && (
                <CloseIconWrapper>
                  <CloseIcon onClick={() => handleDelete()} />
                </CloseIconWrapper>
              )}
            </>
          )}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation()
            }
          }}
          handleChange={handleChange}
          iconError={<CloseIcon />}
          onFocus={() => setIsOnFocus(true)}
          onBlur={() => setIsOnFocus(false)}
        />

        <Button text="Search" type="submit" />
      </FormStyled>
    </Wrapper>
  )
}

export default CustomSearch
