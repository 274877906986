import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../../core/api/apiClient'
import {
  TDeclineReason,
  ReConnectLinkResponse,
  TAdditionalDeclineReason,
  TCancelReason,
  TDocument,
  TDocumentKey,
  TFileNames,
  TGetReConnectLinkFormData,
  THistoryLog, TReconnectRulesResponse,
  TTab
} from './type'
import { useApplicationHeaderContext } from '../../applicationHeader/state/useApplicationHeader'

export enum QueryKey {
  Export = 'Export',
  TabsIncrement = 'TabsIncrement',
  TabsVisitedQuery = 'TabsVisitedQuery',
  DeclinedReasons = 'DeclinedReasons',
  ReConnectLink = 'ReConnectLink',
  ReconnectRules = 'ReconnectRules',
  HistoryLog = 'HistoryLog',
  CancelReasons = 'CancelReasons',
  AdditionalDeclineReason = 'AdditionalDeclineReason'
}

async function getDocument(loanRequestId?: string) {
  const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
  try {
    apiClient.get<TDocument>(`dashboard/loan-requests/${loanRequestId}/report/export`)
      .then(({
        data: {
          classificationReportBase64,
          classificationReportFileName,
          scoringReportBase64,
          scoringReportFileName
        }
      }) => {
        const fileNames: TFileNames = {
          scoringReportBase64: scoringReportFileName,
          classificationReportBase64: classificationReportFileName
        }

        Object.entries(
          {
            classificationReportBase64,
            scoringReportBase64
          }
        )
          .forEach(([key, value]) => {
            const link = document.createElement('a')
            link.href = mediaType + value
            link.download = `${fileNames[key as TDocumentKey]}.xlsx`
            link.click()
          })
      })
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function checkIsAllTabsVisited(loanRequestId?: string): Promise<boolean> {
  try {
    const response = await apiClient(`/dashboard/loan-requests/${loanRequestId}/tabs/visited`)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function postVisitedTab(tab: TTab, loanRequestId?: string): Promise<TTab[]> {
  try {
    const response = await apiClient.post(`/dashboard/loan-requests/${loanRequestId}/tabs/increment?tab=${tab}`)
    return response.data
  } catch (e) {
    throw Error('Something went wrong')
  }
}

export function useExport() {
  const key = QueryKey.Export
  const { loanRequestId } = useParams()
  return useQuery([key, loanRequestId], () => getDocument(loanRequestId), { enabled: false })
}

export function useTabsIncrement() {
  const key = QueryKey.TabsIncrement
  const { loanRequestId } = useParams()
  return useMutation<TTab[], Error, TTab>(
    (tab: TTab) => postVisitedTab(tab, loanRequestId),
    { mutationKey: key }
  )
}

async function interestFreeLink(loanApplicationId: number): Promise<{ link: string }> {
  try {
    const response = await apiClient.get(`/dashboard/loan-requests/${loanApplicationId}/interest-free-link`)
    return response.data
  } catch (e) {
    throw Error('Something went wrong')
  }
}

export function useTabsVisited() {
  const key = QueryKey.TabsVisitedQuery
  const { loanRequestId } = useParams()
  return useQuery<boolean, Error>(
    [key, loanRequestId],
    () => checkIsAllTabsVisited(loanRequestId),
    { enabled: false }
  )
}

export function useInterestFreeLinkQuery(loanApplicationId: number) {
  const key = QueryKey.TabsVisitedQuery

  return useQuery<{ link: string }, Error>(
    [key, loanApplicationId],
    () => interestFreeLink(loanApplicationId),
    { enabled: false }
  )
}

async function getHistoryLog(id?: string | number): Promise<THistoryLog[]> {
  try {
    const { data } = await apiClient(`dashboard/loan-requests/${id}/history`)
    return data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useHistoryLog() {
  const { loanRequestId } = useParams()
  return useQuery([QueryKey.HistoryLog, loanRequestId], () => getHistoryLog(loanRequestId), {
    enabled: false
  })
}

async function getDeclineReasons(): Promise<TDeclineReason[]> {
  try {
    const response = await apiClient('/dashboard/loan-requests/dictionaries/decline-reason')
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useDeclineReasonsQuery(enabled: boolean) {
  return useQuery<TDeclineReason[], Error>(
    QueryKey.DeclinedReasons,
    getDeclineReasons,
    { enabled }
  )
}

async function getReconnectRules(): Promise<TReconnectRulesResponse> {
  try {
    const response = await apiClient('/dashboard/loan-requests/dictionaries/reconnect-rules')
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useReconnectRulesQuery() {
  return useQuery<TReconnectRulesResponse, Error>(
    QueryKey.ReconnectRules,
    getReconnectRules,
    {
      enabled: false
    }
  )
}

async function getReConnectLink(
  {
    repostingReason,
    rePull,
    validationLayer
  }: TGetReConnectLinkFormData,
  borrowerId: number,
  loanApplicationId: number
): Promise<any> {
  try {
    const response = await apiClient.post(`/dashboard/loan-requests/${loanApplicationId}/reconnect-link`, {
      borrowerId,
      loanApplicationId,
      repostReason: repostingReason,
      repullTypes: rePull,
      validationTypes: validationLayer
    })
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useReConnectLinkQuery(formData: TGetReConnectLinkFormData) {
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()

  return useQuery<ReConnectLinkResponse, Error>(
    QueryKey.ReConnectLink,
    async () => headerData?.borrowerId && headerData?.id
      && getReConnectLink(formData, headerData.borrowerId, headerData.id),
    { enabled: false }
  )
}

async function rescore(loanApplicationId: string): Promise<void> {
  try {
    await apiClient.post(`/dashboard/loan-requests/${loanApplicationId}/rescore`)
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export const useRescoreMutation = () => {
  const { loanRequestId } = useParams()
  return useMutation<void, Error>(() => rescore(loanRequestId!))
}

async function clearReclassification(loanApplicationId: string) {
  try {
    await apiClient.delete(`/dashboard/loan-requests/${loanApplicationId}/clear-reclassification`)
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export const useClearReclassificationMutation = () => {
  const { loanRequestId } = useParams()
  return useMutation<void, Error>(() => clearReclassification(loanRequestId!))
}

async function cancelReasons(): Promise<TCancelReason[]> {
  try {
    const response = await apiClient('/dashboard/loan-requests/dictionaries/cancel-reason')
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export const useCancelReasons = (enabled: boolean) => {
  const keys = [QueryKey.CancelReasons, enabled]
  return useQuery<TCancelReason[], Error>(
    keys,
    () => cancelReasons(),
    {
      enabled
    }
  )
}

export function useAdditionalDeclineReasonQuery(enabled: boolean) {
  const keys = [QueryKey.AdditionalDeclineReason]
  return useQuery<TAdditionalDeclineReason[], Error>(
    keys,
    async (): Promise<TAdditionalDeclineReason[]> => {
      try {
        const response = await apiClient('/dashboard/loan-requests/dictionaries/additional-decline-reason')
        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    },
    {
      enabled
    }
  )
}
