import styled from 'styled-components/macro'
import InnerLayout from '../../../core/components/InnerLayout'
import { TitleStyled } from '../../applications/applicationDetail/components/ApplicationDetail'
import Card, { CardStyled } from '../../../core/components/Card'
import Table from './Table'
import { UsersProvider } from '../state/useUserManagement'
import AddUser from './AddUser'

const WrapStyled = styled.div`
  ${CardStyled} {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
    overflow: auto;
  }
`
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const UserManagement = () => (
  <UsersProvider>
    <WrapStyled>
      <InnerLayout
        header={(
          <HeaderWrapper>
            <TitleStyled>User Management</TitleStyled>
            <AddUser />
          </HeaderWrapper>
        )}
      >
        <>
          <Card noPadding>
            <Table />
          </Card>
          {' '}
        </>
      </InnerLayout>
    </WrapStyled>
  </UsersProvider>
)

export default UserManagement
