import styled from 'styled-components/macro'
import { useFailedValidationDetailsQuery } from '../queries'
import Spinner from '../../../../core/components/Spinner'
import { organizeValidationData } from '../utils/failedValidationUtils'
import {
  ContentStyled,
  WrapStyled
} from '../../../applications/applicationDetail/components/ApplicationDetail'
import InnerLayout from '../../../../core/components/InnerLayout'
import ApplicationHeader from '../../../applicationHeader/components/ApplicationHeader'
import FailedHeader from './FailedHeader'
import FailedList from './FailedList'

const ContainerStyled = styled.div<{ column: number }>`
  display: grid;
  padding: 24px;
  width: 100%;
  gap: 16px;
  grid-template-columns: repeat(${({ column }) => column}, minmax(auto, 384px));
`

const ColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const FailedValidationDetails = () => {
  const { isLoading, data } = useFailedValidationDetailsQuery()

  if (isLoading || !data) {
    return <Spinner />
  }
  const formattedData = organizeValidationData(data)

  return (
    <WrapStyled>
      <InnerLayout
        noBodyPadding
        header={(
          <ApplicationHeader
            header={(
              <ContentStyled>
                <FailedHeader />
              </ContentStyled>
            )}
          />
        )}>
        <ContainerStyled column={formattedData.length}>
          {
            formattedData
              .map((column) => (Array.isArray(column)
                ? (
                  <ColumnStyled>
                    {
                      column.map(({ header, status, value }) => (
                        <FailedList
                          key={header}
                          header={header}
                          status={status}
                          value={value} />
                      ))
                    }
                  </ColumnStyled>
                )
                : (
                  <ColumnStyled>
                    <FailedList
                      key={column.header}
                      header={column.header}
                      status={column.status}
                      value={column.value} />
                  </ColumnStyled>
                )))
          }
        </ContainerStyled>
      </InnerLayout>
    </WrapStyled>
  )
}

export default FailedValidationDetails
