import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { fontFamily } from '../../../../../core/styles/mixins'

export const WrapStyled = styled.div`
  ${fontFamily('Inter')};
  display: flex;
  font-weight: 500;
  font-size: 14px;
`

const LayoutWrap: FC = ({ children }) => <WrapStyled>{children}</WrapStyled>

export default LayoutWrap
