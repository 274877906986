import styled from 'styled-components/macro'
import { FC } from 'react'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { TValidationRule } from '../../types'
import { isDisabled } from '../../utils/tableUtils'
import { useFormContext } from '../../state/formState'
import CustomInput, { CustomTextField } from '../../../../core/components/CustomInput'
import { floatFormat } from '../../../../core/utils/formUtils'
import { employmentStatusDescription, loanPurposeDescription } from './RuleTableCell'
import { useOptionsContext } from '../../state/useOptions'
import RuleChipDropDown from '../RuleChipDropDown'

export const DisabledTextStyled = styled.div`
  opacity: 0.5;
`

const RuleDropDownContainer = styled.div`
  ${flex({ justify: 'flex-start' })};
  flex-direction: column;
`

const ValueInputWrapStyled = styled.div`
  ${flex({ justify: 'flex-start' })};
  flex-direction: column;

  ${CustomTextField} {
    width: 64px;
    height: 32px;
    margin-bottom: 4px;
  }
`

const ValueTextStyled = styled.p<{ isInline?: boolean }>`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: ${({ isInline }) => (isInline ? 'inline' : 'block')};
`

type TProps = {
  validationRule: TValidationRule
}

const ValueTableCell: FC<TProps> = ({ validationRule }) => {
  const { selectedRowId, control, isEditFormValueInitialized } = useFormContext()
  const { value: valueOptions, loanPurposeOptions } = useOptionsContext()

  const { validationRules, id, description } = validationRule
  const isEmploymentStatus = description === employmentStatusDescription
  const isLoanPurpose = description === loanPurposeDescription

  const validationRulesValues = validationRules.map(({ value }) => (
    isLoanPurpose
      ? loanPurposeOptions.find(({ value: val }) => val === value)?.label
      : valueOptions.find(({ value: optionValue }) => optionValue === value)?.label))
    .join(', ')

  if (selectedRowId === String(id) && isEditFormValueInitialized) {
    if (isEmploymentStatus || isLoanPurpose) {
      return (
        <RuleDropDownContainer>
          <RuleChipDropDown control={control} isLoanPurpose={isLoanPurpose} />
        </RuleDropDownContainer>
      )
    }
    return (
      <ValueInputWrapStyled>
        {validationRules.map((item, index) => (
          <CustomInput
            key={item.id}
            handleChange={floatFormat}
            name={`rules.${index}.value`}
            control={control}
            rules={{
              required: true
            }}
          />
        ))}
      </ValueInputWrapStyled>
    )
  }
  const Wrap = isDisabled(validationRule) ? DisabledTextStyled : ValueTextStyled

  return (
    <>
      {
        isEmploymentStatus || isLoanPurpose
          ? <Wrap>{validationRulesValues}</Wrap>
          : validationRules.map(({ id, value }) => <Wrap key={id}>{value}</Wrap>)
      }

    </>
  )
}

export default ValueTableCell
