import { TNumberFormatOperators, TOperators, TScoring } from '../types'

const numberFormatOperators: TNumberFormatOperators = {
  PERCENT: (scoringValue) => `${scoringValue}%`,
  POUND: (scoringValue) => `£${scoringValue}`,
  YEAR: (scoringValue) => `${scoringValue} years`,
  NONE: (scoringValue) => scoringValue.toString(),
  AOSCORE: (scoringValue) => scoringValue.toString()
}

const operators: TOperators = {
  LESS_OR_EQUAL: (scoring, valueType) => `≤ ${numberFormatOperators[valueType](scoring.from)}`,
  MORE: (scoring, valueType) => `> ${numberFormatOperators[valueType](scoring.from)}`,
  MORE_OR_EQUAL: (scoring, valueType) => `≥ ${numberFormatOperators[valueType](scoring.from)}`,
  LESS: (scoring, valueType) => `< ${numberFormatOperators[valueType](scoring.from)}`,
  EQUAL: (scoring, valueType) => `= ${numberFormatOperators[valueType](scoring.from)}`,
  FROM_TO: (scoring, valueType) => `> ${numberFormatOperators[valueType](scoring.from)} and 
  ≤ ${numberFormatOperators[valueType](scoring.to)}`,
  FROM_TO_INCLUSIVE: (scoring, valueType) => `≥ ${numberFormatOperators[valueType](scoring.from)} 
  and ≤ ${numberFormatOperators[valueType](scoring.to)}`
}

export const ruleFormat = (scoring: TScoring) => {
  if (scoring.valueType === 'AOSCORE') {
    return `TransUnion Score (AOSCORE)*${scoring.scoringRules[0].from} = Plend score number`
  }
  return scoring.scoringRules.map((scoringRule) =>
    `if value ${operators[scoringRule.rule](scoringRule, scoring.valueType)} then assign ${scoringRule.point} points`)
}
