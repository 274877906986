import styled from 'styled-components/macro'
import InnerLayout, { HeaderStyled } from '../../../../core/components/InnerLayout'
import Card, { CardStyled } from '../../../../core/components/Card'
import Table from './Table'
import { ApplicationsProvider, useApplicationsContext } from '../state/useApplications'
import Tabs from './Tabs'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import Search from './Search'
import Button, { BaseButton } from '../../../../core/components/Button'
import { DownloadIcon } from '../../../../core/components/icons/DownloadIcon'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'
import { useLoanDetailsDocumentQuery } from '../queries'
import Filter from './Filter'

const WrapStyled = styled.div`
  ${HeaderStyled} {
    min-height: 116px;
    position: relative;
  }

  ${CardStyled} {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
    overflow: auto;
  }
`

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
`

const BodyWrapStyled = styled.div`
  ${flex({ justify: 'space-between', align: 'flex-end' })};

  ${BaseButton} {
    color: ${({ theme }) => theme.colors.darkBlack};
    background: ${({ theme }) => theme.colors.grey300};
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
    margin-right: 24px;
    height: 32px;

    :hover {
      background: ${({ theme }) => theme.colors.grey500};
    }

    :disabled {
      opacity: 0.5;
      background: ${({ theme }) => theme.colors.grey300};
    }
  }
`

const FilterContentWrapStyled = styled.div`
  ${flex({ align: 'center' })};
  margin-top: 24px;
`

const LineStyled = styled.div`
  width: 1px;
  height: 32px;
  margin-right: 24px;
  background: ${({ theme }) => theme.colors.grey300};
`

const ApplicationsList = () => {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()
  const { filter } = useApplicationsContext()
  const { refetch, isLoading } = useLoanDetailsDocumentQuery()

  return (
    <WrapStyled>
      <InnerLayout
        header={(
          <>
            <TitleStyled>Applications</TitleStyled>
            <Tabs />
          </>
        )}
      >
        <Card noPadding>
          <BodyWrapStyled>
            <FilterContentWrapStyled>
              <Search />
              {filter === 'NEW' && (
                <>
                  <LineStyled />
                  <Filter />
                </>
              )}
            </FilterContentWrapStyled>

            {isSocialCreditOrganization && filter === 'APPROVED' && (
              <Button
                disabled={isLoading}
                onClick={() => refetch()}
                text="Download loans details"
                startIcon={<DownloadIcon width={16} height={16} />}
              />
            )}
          </BodyWrapStyled>

          <Table />
        </Card>
      </InnerLayout>
    </WrapStyled>
  )
}

export default () => (
  <ApplicationsProvider>
    <ApplicationsList />
  </ApplicationsProvider>
)
