import { Control, useController } from 'react-hook-form'
import { FC } from 'react'
import styled from 'styled-components/macro'
import CustomSelect, { CustomSelectWrapper, SelectMui, TOption } from '../../../core/components/CustomSelect'
import { fontFamily } from '../../../core/styles/mixins'

const Wrapper = styled.div<{ isError: boolean }>`
  && {
    width: 100%;

    && {
      ${SelectMui} {
        & > .MuiSelect-select[aria-expanded='true'] ~ fieldset.MuiOutlinedInput-notchedOutline {
          border: 2px solid ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.blue500)};
        }

        & > .MuiSelect-select:focus ~ fieldset.MuiOutlinedInput-notchedOutline {
          border: 1px solid ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.blue500)};
        }

        & > .MuiSelect-select:hover ~ fieldset.MuiOutlinedInput-notchedOutline {
          border: 1px solid ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.darkBlack)};
        }
      }
    }
  }

  && .MuiOutlinedInput-root {
    height: 40px;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.grey500)};
  }

  ${CustomSelectWrapper} {
    margin-top: 0;
  }
}
`
const ErrorMessage = styled.div`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`

type TProps = {
  control: Control<any>
  name: string,
  placeholder: string
}

const options: TOption[] = [
  {
    label: 'Underwriter',
    value: 'UNDERWRITER'
  },
  {
    label: 'Customer service',
    value: 'CUSTOMER_SERVICE'
  }
]

const RoleDropdown: FC<TProps> = ({ name, control, placeholder }) => {
  const { field: { value: currentValue, onChange }, formState: { errors } } = useController({
    name,
    control,
    rules: { required: 'This field is required' }
  })
  return (
    <Wrapper isError={errors.role}>
      <CustomSelect
        width={232}
        placeholder={placeholder}
        currentValue={currentValue}
        handleChange={onChange}
        options={options}
      />
      <ErrorMessage>
        {errors.role ? errors.role.message : ''}
      </ErrorMessage>
    </Wrapper>

  )
}
export default RoleDropdown
