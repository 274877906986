import React, { FC, useState } from 'react'
import styled from 'styled-components/macro'
import { Menu, MenuItem, MenuProps } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { MoreIcon } from '../../../core/components/icons/MoreIcon'
import { fontFamily } from '../../../core/styles/mixins'
import Modal from './Modal'
import { useUsersContext } from '../state/useUserManagement'
import { TUser } from '../types'
import { useDeleteUserQuery, useResetProgressRequestsQuery } from '../queries'
import { useHasPermission } from '../../auth/hooks'

type TProps = {
  isOpen?: boolean
}

const Wrapper = styled.div``

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))`
  && .MuiPaper-root {
    border-radius: 3px;
    margin-top: 2px;
    min-width: 160px;
    color: ${({ theme }) => theme.colors.darkBlack};
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

    & .MuiMenuItem-root {
      ${fontFamily('Inter')};
      color: ${({ theme }) => theme.colors.darkBlack};
      padding: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    }

    & .MuiMenu-list {
      padding: 0;
    }
  }
`

const MoreButtonWrapper = styled.button<TProps>`
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 4px 7px 4px;
  margin-left: 20px;
  background: ${({ isOpen, theme }) => (isOpen ? theme.colors.grey200 : 'transparent')};
`

const RequestsInProgress = styled.div`
  color: ${({ theme }) => theme.colors.red};
`

type TDeleteProp = {
  onHandleClick: () => void
}

const DeleteUser: FC<TDeleteProp> = ({ onHandleClick }) => {
  const [openModal, setOpenModal] = useState(false)
  const isAdmins = useHasPermission(['GENERAL_VIEW', 'PARTNERS_ADMIN_VIEW'])
  const {
    usersQuery: { data },
    inProgressRequestQuery: { data: requestsData },
    selectedId
  } = useUsersContext()

  if (!data?.content) {
    return null
  }

  const { mutate } = useDeleteUserQuery()
  const { mutate: requestsMutation } = useResetProgressRequestsQuery()

  const methods = useForm()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const currentUser = data.content.find((item: TUser) => item?.id === selectedId)
  const currentUserName = `${currentUser?.firstName} ${currentUser?.lastName}`

  const onSubmit = () => {
    if (!isAdmins) {
      requestsMutation(selectedId)
    }
    mutate(selectedId)
    setOpenModal(false)
  }

  return (
    <Wrapper onClick={onHandleClick}>
      <MoreButtonWrapper isOpen={openModal} onClick={handleClick}>
        <MoreIcon onClick={handleClick} />
      </MoreButtonWrapper>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setOpenModal(true)
            setAnchorEl(null)
          }}
        >
          Delete
        </MenuItem>
      </StyledMenu>

      <FormProvider {...methods}>
        <Modal
          onSubmit={methods.handleSubmit(onSubmit)}
          isOpen={openModal}
          title="Delete User"
          subTitle={(
            <div>
              {requestsData
                && requestsData?.length > 0
                && (
                  <RequestsInProgress>
                    This underwriter has application(s) in progress:
                    {' '}
                    {requestsData.join(', ')}
                  </RequestsInProgress>
                )}
              <div>
                Are you sure you want to delete
                {' '}
                {currentUserName}
                ?
              </div>
            </div>
          )}
          buttonText="Delete"
          handleClose={() => {
            setOpenModal(false)
            setAnchorEl(null)
          }}
        />
      </FormProvider>
    </Wrapper>
  )
}

export default DeleteUser
