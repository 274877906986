import styled from 'styled-components/macro'
import { TransactionsProvider, useTransactionContext } from '../state/useTransactions'
import Search from './Search'
import TransactionsTable from './Table'
import { BaseButton } from '../../../../core/components/Button'
import Filter from './Filter'
import { flex } from '../../../../core/styles/mixins'
import EditTransaction from '../../editTransaction/components/EditTransaction'
import { useIsPlendOrganization } from '../../../auth/hooks'

const Wrapper = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.colors.white};

    && ${BaseButton} {
        height: 32px;
        padding: 6px 12px;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.darkBlack};
        background: ${({ theme }) => theme.colors.grey300};
        text-transform: capitalize;
    }
`

const LineStyled = styled.div`
    width: 1px;
    height: 32px;
    margin: 0 24px;
    background: ${({ theme }) => theme.colors.grey300};
`

const FilteringStyled = styled.div`
    ${flex({ align: 'center', justify: 'space-between' })};

`

const FilterContentWrapStyled = styled.div`
    ${flex({ align: 'center' })};
`

const EditTransactionWrapperStyled = styled.div`
    margin-right: 24px;
`

const Transactions = () => {
  const { selectedTransactions, handleClearTransactions } = useTransactionContext()
  const isPlendOrganization = useIsPlendOrganization()

  return (
    <Wrapper>
      <FilteringStyled>
        <FilterContentWrapStyled>
          <Search />
          <LineStyled />
          <Filter />
        </FilterContentWrapStyled>
        <EditTransactionWrapperStyled>
          <EditTransaction
            isAmountCalculation={isPlendOrganization}
            handleClearTransactions={handleClearTransactions}
            transactions={selectedTransactions}
          />
        </EditTransactionWrapperStyled>
      </FilteringStyled>
      <TransactionsTable />
    </Wrapper>
  )
}

export default () => (
  <TransactionsProvider>
    <Transactions />
  </TransactionsProvider>
)
