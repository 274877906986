import React, { FC, useMemo } from 'react'
import styled from 'styled-components/macro'
import ChartComposed from '../../../../../core/components/charts/ChartComposed'
import { fontFamily } from '../../../../../core/styles/mixins'
import { useMetricsContentContext } from '../state/useMetricsContentState'
import Spinner from '../../../../../core/components/Spinner'

const ChartStyled = styled.div`
  height: 300px;
  padding: 8px 16px 0;
`

const CategoryNameStyled = styled.div`
  ${fontFamily('Inter')};
  padding-left: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const SpinnerWrapperStyled = styled.div`
  height: 300px;
`

const CategoryChart: FC = () => {
  const {
    categoriesGraphQuery: {
      data,
      isLoading: categoriesGraphIsLoading
    },
    categoriesQuery: { isLoading: categoriesQueryIsLoading },
    selectedCategory
  } = useMetricsContentContext()

  const sortedChartData = useMemo(
    () =>
      (data ? data?.sort((a, b) => (b.day > a.day ? -1 : 1)) : []),
    [data]
  )

  if (categoriesGraphIsLoading || categoriesQueryIsLoading) {
    return (
      <SpinnerWrapperStyled>
        <Spinner />
      </SpinnerWrapperStyled>
    )
  }

  if (!selectedCategory) {
    return <SpinnerWrapperStyled />
  }

  return (
    <ChartStyled>
      <CategoryNameStyled>{selectedCategory?.firstClass}</CategoryNameStyled>
      <ChartComposed
        chartData={sortedChartData}
        chartConfig={{
          isLegend: false,
          nameDataKey: 'day',
          valuesConfig: [
            {
              color: 'blue',
              dataKey: 'amount'
            }
          ]
        }}
      />
    </ChartStyled>
  )
}

export default CategoryChart
