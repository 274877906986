import { useMutation, useQuery } from 'react-query'
import { TDownloadKeyword, TKeywordsTable, TUploadKeyword } from './types'
import { apiClient } from '../../core/api/apiClient'

enum QueryKeys {
  Keywords = 'Keywords',
  UploadKeywords = 'UploadKeywords',
  UploadProgress = 'UploadProgress',
  DownloadKeyword = 'DownloadKeyword',
  Status = 'Status'
}

async function getKeywords(page: number): Promise<TKeywordsTable> {
  try {
    const response = await apiClient(`/keywords?page=${page}`)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useKeywordsQuery(page: number) {
  const key = QueryKeys.Keywords
  return useQuery(
    [key, page],
    () => getKeywords(page),
    { keepPreviousData: true }
  )
}

async function downloadKeyword({ id, callback }: TDownloadKeyword) {
  try {
    const response = await apiClient.get(`/keywords/${id}/download`, {
      responseType: 'blob',
      onDownloadProgress: (e) => {
        callback(e.loaded / e.total)
      }
    })

    const fileName = decodeURI(response?.headers['content-disposition'].split('filename="')[1].slice(0, -1))
    const url = window.URL.createObjectURL(response.data)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useDownloadMutation() {
  return useMutation<void, Error, TDownloadKeyword>(
    QueryKeys.DownloadKeyword,
    downloadKeyword
  )
}

async function uploadKeyword(params: TUploadKeyword) {
  const { file } = params

  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await apiClient.post(
      'keywords/upload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return response.data
  } catch (err: any) {
    throw new Error(err || 'Something went wrong')
  }
}

export function useUploadMutation(onSuccess: () => void) {
  const key = QueryKeys.UploadKeywords

  return useMutation<void, Error, TUploadKeyword>(
    key,
    uploadKeyword,
    { onSuccess }
  )
}

async function getProgress(id?: number) {
  try {
    const response = await apiClient(`/keywords/${id}/progress`)
    return response.data
  } catch (err: any) {
    throw new Error(err || 'Something went wrong')
  }
}

export function useProgressQuery(id?: number) {
  return useQuery(
    [QueryKeys.UploadProgress, id],
    () => getProgress(id),
    { enabled: !!id, refetchInterval: 1000, keepPreviousData: true }
  )
}

async function getStatus() {
  try {
    const response = await apiClient('/keywords/upload/status')
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useStatusQuery() {
  return useQuery(QueryKeys.Status, () => getStatus())
}
