import { FC, useEffect, useState } from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'
import styled, { useTheme } from 'styled-components/macro'
import { format } from 'date-fns'
import CustomizedTick from './CustomizedTick'
import { formatToPound } from '../../utils/currencyFormat'
import { MONTH_FORMAT, YEAR_MONTH_FORMAT } from '../../const/date'
import { TChartAccountBalance } from '../../../features/applications/overview/types'
import ChartLegend, { LegendItemStyled, LegendListStyled } from './ChartLegend'
import ChartLegendBalance from './ChartLegendBalance'

const ContainerResponsiveStyled = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 24px;

    ${LegendListStyled} {
        display: grid;
        justify-items: start;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        margin: -24px 0 0 42px;
        grid-column-gap: 5px;
    }

    ${LegendItemStyled} {
        margin: 0;
    }

    && {
        & .recharts-legend-wrapper {
            bottom: -15px !important;
        }
    }
`

export type TChartConfig = {
  nameDataKey: string
  valuesConfig: { dataKey: string, color: string }[]
  isLegend?: boolean
}

type TChartComposedProps = {
  isChartTypeBar?: boolean
  chartData: any[]
  chartConfig: TChartConfig
  legendAdditionalInfo?: TChartAccountBalance[]
  balanceLegend?: boolean
}

function useMonthsPoints(chartData: any[], chartConfig: TChartConfig, isChartTypeBar?: boolean) {
  const [datePoints, setDatePoints] = useState<string[]>([])

  useEffect(() => {
    const monthDatePoints = chartData.reduce((acc, { [chartConfig.nameDataKey]: date }) => {
      const pointsMonths = acc.map((date: string) => format(new Date(date), YEAR_MONTH_FORMAT))
      if (!pointsMonths.includes(format(new Date(date), YEAR_MONTH_FORMAT))) acc.push(date)
      return acc
    }, [])
    const [_, ...uniqueMonths] = monthDatePoints
    setDatePoints(isChartTypeBar
      ? monthDatePoints
      : monthDatePoints.length === 13 ? uniqueMonths : monthDatePoints)
  }, [chartData])
  return datePoints
}

const ChartComposed: FC<TChartComposedProps> = ({
  isChartTypeBar,
  chartData,
  chartConfig,
  legendAdditionalInfo, balanceLegend
}) => {
  const { colors: { white, darkBlack } } = useTheme()
  const monthsPoints = useMonthsPoints(chartData, chartConfig, isChartTypeBar)

  return (
    <ContainerResponsiveStyled>
      <ResponsiveContainer>
        <ComposedChart barGap={0} data={chartData}>
          <CartesianGrid vertical={false} horizontal />
          <XAxis
            padding={{ left: 1, right: 10 }}
            dataKey={chartConfig.nameDataKey}
            interval={0}
            allowDataOverflow
            tickLine={false}
            axisLine={false}
            tick={(props) => {
              const month = props.payload.value
              return (
                <CustomizedTick
                  formattedValue={monthsPoints.includes(month)
                    && format(new Date(month), MONTH_FORMAT)}
                  {...props}
                  dy={12}
                />
              )
            }}
          />
          <YAxis
            tick={(props) => (
              <CustomizedTick
                formattedValue={formatToPound(props.payload.value)}
                dy={3}
                {...props}
              />
            )}
            tickLine={false}
            axisLine={false}
            type="number"
          />
          {chartConfig.isLegend && (
            <Legend
              content={balanceLegend
                ? <ChartLegendBalance legendAdditionalInfo={legendAdditionalInfo} />
                : <ChartLegend legendAdditionalInfo={legendAdditionalInfo} />}
              iconType="circle"
              align="left" />
          )}
          <ReferenceLine y="0" stroke={darkBlack} strokeWidth={1} />
          {chartConfig.valuesConfig.map(({ dataKey, color }) => (
            isChartTypeBar
              ? <Bar dataKey={dataKey} barSize={14} fill={color} />
              : <Line key={dataKey} strokeWidth={2} dataKey={dataKey} stroke={color} dot={false} />
          ))}
          <ReferenceArea
            y2={0}
            fill={white}
            strokeOpacity={1} />
        </ComposedChart>
      </ResponsiveContainer>
    </ContainerResponsiveStyled>
  )
}
export default ChartComposed
