import { FC } from 'react'
import styled from 'styled-components/macro'
import Card from '../../../../core/components/Card'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { TBureauxData, TTooltip } from '../types'
import CustomTooltip from '../../../../core/components/CustomTooltip'

const mapTooltip: { [key in TTooltip]: string } = {
  '-7': 'Not Found on Electoral Roll',
  '-6': 'No accounts opened for required time',
  '-5': 'Cannot calculate (required data for calculation is missing)',
  '-4': 'No accounts on file (if attribute relates to SHARE data)\n'
    + 'OR No public records on file (if attribute relates to public data)\n'
    + 'OR No searches on file (if attribute relates to search data)',
  '-3': 'No open accounts this type',
  '-2': 'Not verified in time period',
  '-1': 'No accounts this type',
  999: 'No detected event for "Months Since" attributes'
}

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const BodyStyled = styled.div`
  ${flex({ justify: 'space-between' })};
  ${fontFamily('Inter')};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 0;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: default;
`

const CategoryStyled = styled.div`
  max-width: 464px;
  width: 100%;
`

type TProps = {
  bureauxData: TBureauxData
}

const BureauxCard: FC<TProps> = ({ bureauxData: { groupName, data } }) => (
  <Card key={groupName}>
    <TitleStyled>{groupName}</TitleStyled>
    {data?.map((elem) => (
      <BodyStyled>
        <CategoryStyled>{elem.description}</CategoryStyled>
        {mapTooltip[elem.value as TTooltip]
          ? (
            <CustomTooltip
              arrow
              placement="top"
              title={mapTooltip[elem.value as TTooltip]}
            >
              <div>
                {elem.value}
              </div>
            </CustomTooltip>
          )
          : (
            <div>
              {elem.value}
            </div>
          )}
      </BodyStyled>
    ))}
  </Card>
)

export default BureauxCard
