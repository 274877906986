export const floatFormat = (value: string) => value && value.replace(/[^0-9.,|]+/, '')
export const rulesFormat = (value: string) => value && value.replace(/[^0-9-]+/, '')

export const integerFormat = (value: string) => value && value.toString().replaceAll(/\d/gi, '')
export const letterFormat = (value: string) => value && value.replaceAll(/[A-Za-z,ЁёА-я]/gi, '')

export const decimalFormat = (value: string | number, decimalNumber = 2) => {
  const factor = 10 ** decimalNumber
  return (Math.floor(parseFloat(value.toString()) * factor) / factor).toFixed(decimalNumber)
}
