import styled from 'styled-components/macro'
import { Radio } from '@mui/material'
import { RadioProps } from '@mui/material/Radio/Radio'
import { RadioSelectIcon } from './icons/RadioSelectIcon'
import { RadioUnselectIcon } from './icons/RadioUnselectIcon'

const CustomRadioStyled = styled(Radio)`
  && {
    padding: 0;
  
  }
`

export const CustomRadio = (props: RadioProps) => (
  <CustomRadioStyled
    icon={<RadioUnselectIcon />}
    checkedIcon={<RadioSelectIcon />}
    {...props} />
)
