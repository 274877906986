export function hiddenColumns(isReclassify: boolean, isPlendOrganization: boolean) {
  const hiddenColumns = []
  if (!isReclassify) {
    hiddenColumns.push('id')
  }
  if (!isPlendOrganization) {
    hiddenColumns.push('accountMask')
  }
  return hiddenColumns
}
