import { FC } from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import { WarningIcon } from '../../../../core/components/icons/WarningIcon'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { TitleStyled } from '../../../applications/applicationDetail/components/ApplicationDetail'
import { HOUR_MINUTE, SLASH_DATE_FORMAT } from '../../../../core/const/date'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'
import { DeclineCircledFilledIcon } from '../../../../core/components/icons/DeclineCircledFilledIcon'
import {
  useGenerateSccLinkOrganizationCheck
} from '../../../applications/applicationDetail/hooks/useGenerateSccLinkOrganizationCheck'
// import GenerateSCCLink from '../../../applications/applicationDetail/components/GenerateSCCLink'

const Container = styled.div`
  ${flex({ align: 'center' })};
  margin-top: 8px;
  width: 300px;
`

const ContentStyled = styled.div`
  margin-left: 8px;
`

const TextStyled = styled.p`
  ${fontFamily('Inter')};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const CaptionStyled = styled.p`
  ${fontFamily('Inter')};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey600};
`
const DeclineIconWrap = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  color: ${({ theme }) => theme.colors.red};
`

function useShowGenerateSCCLink() {
  const { headerQuery: { data } } = useApplicationHeaderContext()

  return useGenerateSccLinkOrganizationCheck()
    && [
      'DEMOGRAPHIC_AND_BUREAUX_VALIDATION',
      'OB_DECLINES_VALIDATION'
    ].includes(data?.failedValidation as string)
}

const FailedHeader: FC = () => {
  const { headerQuery: { data } } = useApplicationHeaderContext()

  const isSocialCreditOrganization = useIsSocialCreditOrganization()
  const showGenerateSCCLink = useShowGenerateSCCLink()

  if (!data) {
    return null
  }

  const { statusAt, borrowerName } = data
  const date = format(new Date(statusAt), SLASH_DATE_FORMAT)
  const time = format(new Date(statusAt), HOUR_MINUTE)

  return (
    <>
      <TitleStyled>
        {borrowerName}
      </TitleStyled>
      <Container>
        {isSocialCreditOrganization
          ? <DeclineIconWrap><DeclineCircledFilledIcon width={20} height={20} /></DeclineIconWrap>
          : <WarningIcon width={24} height={24} />}
        <ContentStyled>
          <TextStyled>{isSocialCreditOrganization ? 'Declined' : 'Failed Validation'}</TextStyled>
          <CaptionStyled>
            {`${date} at ${time}`}
          </CaptionStyled>
          {/* {showGenerateSCCLink && ( */}
          {/*  <GenerateSCCLink loanApplicationId={data.id} /> */}
          {/* )} */}
        </ContentStyled>
      </Container>
    </>
  )
}

export default FailedHeader
