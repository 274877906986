import { useForm, FormProvider } from 'react-hook-form'
import styled from 'styled-components/macro'
import { FormControlLabel as Label } from '@mui/material'
import React from 'react'
import { BaseButton } from '../../../core/components/Button'
import CustomInput from '../../../core/components/CustomInput'
import CreditAdjustment from './CreditAdjustment'
import { ClearScorePricing, InputNames } from '../types'
import FinancialMetrics from './FinancialMetrics'
import { useClearScorePricingContext } from '../state/clearScorePricing'
import useOnBlur from '../hooks/useOnBlur'
import { decimalFormat } from '../../../core/utils/formUtils'
import { useResetClearScorePricing, useUpdateScorePricing } from '../queries'
import { percentValidationRule } from '../utils/percentValidation'

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.grey200};
  margin: 16px 0;
`

const ResetButton = styled(BaseButton)`
  && {
    padding: 8px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.darkBlack};
    background: ${({ theme }) => theme.colors.grey200};
    border-radius: 3px;

    &:hover {
      background: ${({ theme }) => theme.colors.grey200};
    }
  }
`

const SubmitButton = styled(BaseButton)`
  && {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue500};
    border-radius: 3px;
    margin-right: 8px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue500};
    }

    &:disabled {
      opacity: 0.5;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

const Form = styled.form`
  padding: 24px 24px 16px;

  & .MuiFormHelperText-root {
    display: none;
  }

  & .MuiFormControl-root {
    width: 70px;

    & input {
      padding: 6px 0;
    }

    & input[type='number'] {
      -moz-appearance: textfield;
    }

    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
      display: none;
    }
  }
`

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;

  & .MuiFormControlLabel-root {
    margin: 0;
  }

  & .MuiFormControlLabel-label {
    margin-left: 0;
    margin-right: 8px;
  }
`

const normalizeFormData = (data?: ClearScorePricing) => {
  if (!data) return {} as ClearScorePricing
  return {
    ...data,
    charterMaxPercentage:
      typeof data.charterMaxPercentage === 'string'
        ? Number(data.charterMaxPercentage)
        : decimalFormat(data.charterMaxPercentage),
    maxValuePercentage:
      typeof data.maxValuePercentage === 'string'
        ? Number(data.maxValuePercentage)
        : decimalFormat(data.maxValuePercentage, 1),
    creditAdjustments: data.creditAdjustments.map((adj) => ({
      ...adj,
      creditAdjustment:
        typeof adj.creditAdjustment === 'string'
          ? Number(adj.creditAdjustment)
          : decimalFormat(adj.creditAdjustment, 1)
    })),
    financialMetrics: data.financialMetrics.map((metric) => ({
      ...metric,
      costOfFunds:
        typeof metric.costOfFunds === 'string'
          ? Number(metric.costOfFunds)
          : decimalFormat(metric.costOfFunds, 1),
      loanManagement:
        typeof metric.loanManagement === 'string'
          ? Number(metric.loanManagement)
          : decimalFormat(metric.loanManagement, 1)
    }))
  }
}

const ScoringForm = () => {
  const {
    clearScorePricingData: { data, isClearScorePricingLoading },
    resetAoScoreFilter
  } = useClearScorePricingContext()

  const methods = useForm({
    defaultValues: normalizeFormData(data)
  })

  const {
    control,
    setValue,
    handleSubmit,
    formState: { dirtyFields, errors }
  } = methods
  const onBlur = useOnBlur(setValue)

  const { updateScorePricingAsync, isUpdatingProcess } = useUpdateScorePricing()
  const { resetScorePricingAsync, isResettingProcess } = useResetClearScorePricing()

  const onSubmit = async (formData: ClearScorePricing) => {
    const preparedFormData = normalizeFormData(formData)
    await updateScorePricingAsync(preparedFormData)
    resetAoScoreFilter()
  }

  const handleResetFormToDefaultDValues = async () => {
    const defaultData = await resetScorePricingAsync(data?.id as number)
    const normalizedDefaultData = normalizeFormData(defaultData)

    Object.entries(normalizedDefaultData).forEach(([key, value]) =>
      setValue(key as InputNames, value))
    resetAoScoreFilter()
  }

  const isButtonDisabled = isClearScorePricingLoading || isUpdatingProcess || isResettingProcess

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CreditAdjustment />
        <Divider />
        <FinancialMetrics />
        <Divider />
        <FormFooter>
          <Label
            control={(
              <CustomInput
                type="number"
                name="fixedCost"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => Number(value) <= 999.9 && Number(value) >= 1
                }}
              />
            )}
            label="Fixed Cost, £"
            labelPlacement="start"
          />
          <Label
            control={(
              <CustomInput
                type="number"
                name="maxValuePercentage"
                control={control}
                onBlur={onBlur('maxValuePercentage', 1)}
                rules={percentValidationRule}
              />
            )}
            label="Maximum, %"
            labelPlacement="start"
          />
          <Label
            control={(
              <CustomInput
                disabled
                type="number"
                name="charterMaxPercentage"
                control={control}
                onBlur={onBlur('charterMaxPercentage', 2)}
                rules={percentValidationRule}
              />
            )}
            label="Plend Charter max, %"
            labelPlacement="start"
          />
        </FormFooter>
        <SubmitButton
          disabled={
            isButtonDisabled || !!Object.values(errors).length || !Object.values(dirtyFields).length
          }
          type="submit"
        >
          Save changes
        </SubmitButton>
        <ResetButton
          disabled={isButtonDisabled}
          onClick={handleResetFormToDefaultDValues}
          type="submit"
        >
          Reset to default
        </ResetButton>
      </Form>
    </FormProvider>
  )
}

export default ScoringForm
