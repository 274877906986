import styled from 'styled-components/macro'
import { FC } from 'react'
import { LegendProps } from 'recharts'
import { flex, fontFamily } from '../../styles/mixins'
import { formatLegendText, getTooltipContent } from '../../utils/legendUtils'
import { TChartAccountBalance } from '../../../features/applications/overview/types'
import CustomTooltip from '../CustomTooltip'
import { InformationIcon } from '../icons/InformationIcon'
import ChartTooltipContent from './ChartTooltipContent'
import { useHasCurrentUserCustomerServiceRole } from '../../../features/auth/hooks'

export const LegendListStyled = styled.ul`
    ${flex({ justify: 'flex-start' })};
    list-style-type: none;
    margin: -24px 8px 0 42px;
`

export const LegendItemStyled = styled.li<{ isOpacity?: boolean }>`
    margin-right: 48px;
    opacity: ${({ isOpacity }) => (isOpacity ? 0.5 : 1)};
`

const SectionStyled = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
`

const LabelStyled = styled.span<{ isTextGrey?: boolean, noMargin?: boolean }>`
    ${fontFamily('Inter')};
    ${flex({})}
    color: ${({ theme, isTextGrey }) => (isTextGrey ? theme.colors.grey600 : theme.colors.darkBlack)};
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-left: ${({ noMargin }) => (noMargin ? 0 : 8)}px;
    overflow-wrap: break-word;
    text-transform: capitalize;

`

const CircleStyled = styled.div<TProps>`
    width: 14px;
    height: 14px;
    border-radius: 50px;
    background: ${({ color }) => color};
`

const LegendValueStyled = styled.h3`
    ${fontFamily('Inter')};
    font-size: 18px;
    margin: 3px 0 0 20px;
    line-height: 22px;
    font-weight: bold;
`

const IconWrapper = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.grey600};
    margin-left: 6px;
`

export type TLegendProps = {
  label?: string
  approvedTotal?: number,
  declinedTotal?: number,
  cancelledTotal?: number,
  failedTotal?: number
  legendAdditionalInfo?: TChartAccountBalance[]
} & LegendProps

type TProps = {
  color?: string
}

type TTooltipContent = {
  key: string
  value: string
}
const ChartLegend: FC<TLegendProps> = ({
  payload,
  label,
  approvedTotal,
  declinedTotal,
  cancelledTotal,
  failedTotal,
  legendAdditionalInfo
}) => {
  const TOTALS = [approvedTotal, declinedTotal, cancelledTotal, failedTotal]

  return (
    <LegendListStyled>
      {
        payload?.map(({ value, color }, idx) => {
          const { legendText, opacity, found } = formatLegendText(value, legendAdditionalInfo)

          const tooltipContent = legendAdditionalInfo?.length
            && getTooltipContent(legendAdditionalInfo, found?.accountId)
          return (
            <LegendItemStyled key={value} isOpacity={!opacity && opacity !== undefined}>
              <SectionStyled>
                <CircleStyled color={color} />
                <LabelStyled>
                  {`${legendText} ${label || ''}`}
                  {
                    tooltipContent && tooltipContent?.length ? (
                      <CustomTooltip
                        arrow
                        disableFocusListener
                        disableTouchListener
                        title={(
                          <ChartTooltipContent
                            tooltipContent={tooltipContent as TTooltipContent[]}
                          />
                        )}
                        placement="top"
                      >
                        <IconWrapper>
                          <InformationIcon width={16} height={16} />
                        </IconWrapper>
                      </CustomTooltip>
                    ) : null
                  }
                </LabelStyled>
              </SectionStyled>
              <LegendValueStyled>{TOTALS[idx]}</LegendValueStyled>
            </LegendItemStyled>
          )
        })
      }
    </LegendListStyled>
  )
}

export default ChartLegend
