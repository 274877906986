import { useCallback, useEffect, useState } from 'react'
import constate from 'constate'
import { useForm, UseFormReturn } from 'react-hook-form'
import { TEditValidationForm, TExceptionContent } from '../types'
import { useEditValidationExceptionsMutation } from '../querries'

function useResetFormValue(
  { setValue, watch }: UseFormReturn<TEditValidationForm>,
  currentValidationRule?: TExceptionContent
) {
  useEffect(() => {
    setValue('validationTypes', currentValidationRule?.validationTypes || [])
  }, [currentValidationRule])
}

function useSubmitHandle(
  { getValues, setError }: UseFormReturn<TEditValidationForm>,
  onSubmitCallback: () => void,
  currentValidationRule?: TExceptionContent
) {
  const { mutateAsync: editValidationRule } = useEditValidationExceptionsMutation()
  return useCallback(
    () => {
      if (!currentValidationRule) {
        return
      }
      const { validationTypes } = getValues()
      if (!validationTypes?.length) {
        setError('validationTypes', { type: 'required' })
        return
      }

      editValidationRule({
        exceptionSourceId: currentValidationRule.id,
        validationTypes
      })
        .then(() => onSubmitCallback())
    },
    [currentValidationRule]
  )
}

function useContext() {
  const [selectedRow, setSelectedRow] = useState<TExceptionContent>()
  const useFormReturn = useForm<TEditValidationForm>({
    defaultValues: {
      validationTypes: []
    }
  })
  useResetFormValue(useFormReturn, selectedRow)
  return {
    selectedRow,
    setSelectedRow,
    useFormReturn,
    handleSubmit: useSubmitHandle(
      useFormReturn,
      () => setSelectedRow(undefined),
      selectedRow
    )
  }
}

export const [ExceptionFormProvider, useExceptionFormContext] = constate(useContext)
