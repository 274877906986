import { Chip, OutlinedInput, Select as MuiSelect } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { FC, useState } from 'react'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import styled from 'styled-components/macro'
import * as React from 'react'
import { MenuItemContent, MenuItemMui, TOption } from './CustomSelect'
import { CloseLineIcon } from './icons/CloseLineIcon'
import { SelectedIcon } from './icons/SelectedIcon'
import { flex, fontFamily } from '../styles/mixins'
import { ArrowUpIcon } from './icons/ArrowUpIcon'
import { ArrowDownIcon } from './icons/ArrowDownIcon'

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const SelectStyled = styled(MuiSelect)`
  &&& {
    & .MuiSelect-select {
      padding: 6px 8px;
    }

    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.grey600};
    }
  }

`

const DeclineCircleIcon = styled.button`
  all: unset;
  cursor: pointer;
  ${flex({ justify: 'center', align: 'center' })};

`

const PlaceholderTextStyled = styled.p`
`

export const IconWrapperStyled = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey600};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
`

const ChipWrapStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 4px;
  margin-right: 20px;
`
const ChipStyled = styled(Chip)`
  && {
    ${fontFamily('Inter')};
    color: ${({ theme }) => theme.colors.darkBlack};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 4px;
    height: 25px;

    & .MuiChip-label {
      padding: 2px 12px 2px 8px;
    }
  }
`

const ErrorMessageStyled = styled.p`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`
type TProps = {
  name: string
  control: Control<any>
  options: TOption[]
  label?: string | null,
  rules?: RegisterOptions
  placeholder?: string
  isRemovable?: boolean
  handleChange?: (value: string[]) => string[]
}
const CustomSelectChip: FC<TProps> = ({
  name,
  options,
  label,
  control,
  rules,
  placeholder,
  isRemovable,
  handleChange
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { ref, ...fieldRest }, formState }) => {
        const { error } = fieldRest as any
        return (
          <WrapperStyled>
            <SelectStyled
              {...fieldRest}
              error={!!error}
              label={label}
              open={isOpen}
              onChange={({ target: { value } }) => {
                // eslint-disable-next-line no-unused-expressions
                fieldRest.onChange(handleChange ? handleChange(value as string[]) : value)
              }}
              value={fieldRest.value as unknown as TOption['value'][]}
              input={<OutlinedInput />}
              multiple
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              IconComponent={(props) => {
                const selectOpenClass = 'MuiSelect-iconOpen'
                const isSelectOpen = props?.className?.includes(selectOpenClass)
                return (
                  <IconWrapperStyled onClick={() => setIsOpen(true)}>
                    {
                      isSelectOpen
                        ? <ArrowUpIcon width={14} height={8} />
                        : <ArrowDownIcon width={14} height={8} />
                    }
                  </IconWrapperStyled>
                )
              }}
              renderValue={(value) => {
                const selectedValues = value as string[]
                if (!selectedValues.length) {
                  return <PlaceholderTextStyled>{placeholder}</PlaceholderTextStyled>
                }
                return (
                  <ChipWrapStyled>
                    {selectedValues?.map((selectedValue: string, index: number) => {
                      const label = options?.find(
                        ({ value }) => value === selectedValue
                      )?.label
                      return (
                        <ChipStyled
                          deleteIcon={(
                            <DeclineCircleIcon>
                              <CloseLineIcon width={12} height={12} />
                            </DeclineCircleIcon>
                          )}
                          onDelete={isRemovable ? () =>
                            fieldRest.onChange(fieldRest.value
                              .filter((value: string) => value !== selectedValue))
                            : undefined}
                          key={selectedValue}
                          label={label}
                          onMouseDown={(event) => {
                            event.stopPropagation()
                          }}
                        />
                      )
                    })}
                  </ChipWrapStyled>
                )
              }}
            >
              {
                options.map(({ label, value }) => {
                  const isSelected = fieldRest.value.includes(value)
                  return (
                    <MenuItemMui value={value} key={value}>
                      <MenuItemContent>
                        {label}
                        {isSelected && <SelectedIcon />}
                      </MenuItemContent>
                    </MenuItemMui>
                  )
                })
              }
            </SelectStyled>
            {error?.message && <ErrorMessageStyled>{(error as any).message}</ErrorMessageStyled>}
          </WrapperStyled>
        )
      }}
    />
  )
}

export default CustomSelectChip
