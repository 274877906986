import React, { FC, useMemo } from 'react'
import { Column } from 'react-table'
import { format } from 'date-fns'
import styled, { css } from 'styled-components/macro'
import Table from '../../../../../core/components/table/Table'
import { SLASH_DATE_FORMAT } from '../../../../../core/const/date'
import { HeaderCellStyled } from '../../../../../core/components/table/TableHeadRow'
import { useMetricsContentContext } from '../state/useMetricsContentState'
import Spinner from '../../../../../core/components/Spinner'
import NoDataComponent from '../../../../../core/components/NoDataComponent'
import { formatToPound } from '../../../../../core/utils/currencyFormat'
import { BodyCellStyled } from '../../../../../core/components/table/TableBodyRow'
import { useIsReClassifiable } from '../../../applicationDetail/hooks/useIsReClassifiable'
import CustomTooltip from '../../../../../core/components/CustomTooltip'
import ClassificationText from '../../../../../core/components/ClassificationText'
import { ExclamationIcon } from '../../../../../core/components/icons/ExclamationIcon'
import { CheckIcon } from '../../../../../core/components/icons/CheckIcon'
import { flex } from '../../../../../core/styles/mixins'
import TableHeadCheckbox from './TableHeadCheckbox'
import { TTransaction } from '../../../transactions/types'
import { CustomCheckboxStyled } from '../../../../../core/components/CustomCheckbox'
import { hiddenColumns } from '../utils'
import { useIsPlendOrganization } from '../../../../auth/hooks'

const defaultBodyMixin = css`
    :first-child {
        width: 10%;
        padding-right: 10px;
    }

    :nth-child(2) {
        width: 40%;
        padding-right: 10px;
    }

    :nth-child(3) {
        width: 35%;
    }

    :last-child {
        width: 5%;
    }
`

const reclassifyBodyMixin = css`
    :first-child {
        width: 24px;
        padding-right: 12px;
    }

    :nth-child(2) {
        width: 10%;
        padding-right: 10px;
    }

    :nth-child(3) {
        width: 38%;
        padding-right: 10px;
    }

    :nth-child(4) {
        width: 35%;
    }

    :last-child {
        width: 5%;
    }
`
const Wrapper = styled.div<{ isReClassifiable?: boolean }>`
    margin-top: 10px;

    ${HeaderCellStyled} {
        padding-right: 16px;

        :first-child {
            padding-left: 16px;
            padding-right: ${({ isReClassifiable }) => (isReClassifiable ? 0 : 16)}px;

        }

    }

    .MuiTableCell-body {
        min-height: 60px;
        padding: 18px 0;
        cursor: default;
    }

    ${BodyCellStyled} {

        :first-child {
            padding-left: 16px;
        }

        ${({ isReClassifiable }) => (
    isReClassifiable ? reclassifyBodyMixin : defaultBodyMixin)}
    }
`

const AlignRightStyled = styled.span`
    padding-right: 16px;
`

const SpinnerContainer = styled.div`
    margin-top: 170px;
`

const NameWrapStyled = styled.div`
    ${flex({ align: 'center' })};
    gap: 4px;
`

const IconStyled = styled.div<{ isColorBlue?: boolean }>`
    ${flex({ justify: 'center', align: 'center' })};
    cursor: pointer;
    color: ${({ theme, isColorBlue }) => (
    isColorBlue ? theme.colors.blue500 : 'transparent')};
`

const DetailedTransactionTable: FC = () => {
  const {
    transactionsState: {
      query: { data, isLoading, isFetching },
      setPage,
      setSortBy,
      sortBy
    },
    categoriesQuery: { isLoading: categoriesIsLoading },
    setSelectedTransactions, selectedTransactions
  } = useMetricsContentContext()

  const isPlendOrganization = useIsPlendOrganization()

  const columns: Column[] = [
    {
      Header: <TableHeadCheckbox />,
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ value, row }) => (
        <CustomCheckboxStyled
          checked={selectedTransactions.some((transaction) => transaction.id === value)}
          onChange={(event, checked) => {
            setSelectedTransactions((prevTransactions) => (checked ? [
              ...prevTransactions,
              row.original as TTransaction
            ] : prevTransactions.filter(
              (transaction) => transaction.id !== (row.original as TTransaction).id
            )))
          }}
        />
      )
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }) => (
        <span>{format(new Date(value), SLASH_DATE_FORMAT)}</span>
      ),
      sortDescFirst: true
    },
    {
      Header: 'Description',
      accessor: 'name',
      Cell: ({ value, row }) => {
        const {
          nonAppliedClassifications,
          appliedClassifications
        } = row.original as TTransaction
        return (
          <NameWrapStyled>
            <span>{value}</span>
            {nonAppliedClassifications.length ? (
              <CustomTooltip
                arrow
                maxWidth={400}
                placement="top"
                title={(
                  <ClassificationText
                    classification={nonAppliedClassifications}
                  />
                )}>
                <IconStyled isColorBlue>
                  <ExclamationIcon width={16} height={16} />
                </IconStyled>
              </CustomTooltip>
            ) : null}
            {
              appliedClassifications.length ? (
                <CustomTooltip
                  arrow
                  maxWidth={400}
                  placement="top"
                  title={(
                    <ClassificationText
                      classification={appliedClassifications}
                    />
                  )}>
                  <IconStyled>
                    <CheckIcon width={16} height={16} />
                  </IconStyled>
                </CustomTooltip>
              ) : null
            }
          </NameWrapStyled>
        )
      }
    },
    {
      Header: 'OB Сlass 2',
      accessor: 'taxonomySecondClass'
    },
    {
      Header: 'Account №',
      accessor: 'accountMask',
      Cell: ({ value }) => <span>{value ? `· ${value}` : null}</span>,
      sortDescFirst: true
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => <AlignRightStyled>{formatToPound(value)}</AlignRightStyled>,
      sortDescFirst: true
    }
  ]

  const isReClassifiable = useIsReClassifiable()
  const tableData = useMemo(() => data?.content || [], [data])

  return (
    <Wrapper isReClassifiable={isReClassifiable}>
      {(isLoading || categoriesIsLoading)
        ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )
        : (
          <Table
            columns={columns}
            data={tableData}
            showLoader={isFetching}
            hiddenColumns={hiddenColumns(isReClassifiable ?? false, isPlendOrganization)}
            paginationConfig={
              data && {
                isEnabled: data.totalPages > 1,
                pageSize: data.size,
                pageCount: data.totalPages,
                totalRows: data?.totalElements,
                manual: true,
                onPageChange: (num) => setPage(num)
              }
            }
            sortingConfig={{
              sortable: true,
              initialSortBy: sortBy && [sortBy],
              manual: true,
              onSortByChange: (sortBy) => {
                setSortBy(sortBy && sortBy[0])
              }
            }}
          />
        )}
      {!isLoading && !categoriesIsLoading && !isFetching && !tableData.length && (
        <NoDataComponent
          iconWidth={52}
          iconHeight={48}
          text="There are no transactions in this category"
        />
      )}
    </Wrapper>
  )
}

export default DetailedTransactionTable
