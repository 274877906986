import constate from 'constate'
import { useCallback, useMemo } from 'react'

import { useSetState } from 'react-use'
import { useCheckLeadMutation, useGenerateLinkMutation, usePartnerDashboardQuery } from '../queries'
import { TPartnerDashboardParams } from '../types'

const initialDashboardState = {
  page: 1,
  search: ''
}

const PartnerDashboardState = () => {
  const [
    dashboardParams,
    setDashboardParams
  ] = useSetState<TPartnerDashboardParams>(initialDashboardState)

  const [modalOpen, setModalOpen] = useSetState({
    generateLink: false,
    success: false,
    failed: false
  })

  return {
    setPage: useCallback((page: number) => setDashboardParams({ page }), []),
    setSearch: useCallback((search) => setDashboardParams({ search }), []),
    search: useMemo(() => dashboardParams.search, [dashboardParams.search]),
    modalOpen,
    setModalOpen,
    partnerDashboardQuery: usePartnerDashboardQuery(dashboardParams),
    generateLinkMutation: useGenerateLinkMutation(),
    checkLeadMutation: useCheckLeadMutation()
  }
}

export const [
  PartnerDashboardProvider,
  usePartnerDashboardContext
] = constate(PartnerDashboardState)
