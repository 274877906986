import { Link, useLocation } from 'react-router-dom'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import styled from 'styled-components/macro'
import Tabs from '../../../../core/components/tabs/Tabs'
import Tab from '../../../../core/components/tabs/Tab'
import { useTabsIncrement } from '../queries'
import { TTab } from '../type'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { getLastWord } from '../utils/tabUtils'
import { useIsPlendOrganization, useIsSocialCreditOrganization } from '../../../auth/hooks'
import { useAuthContext } from '../../../auth/state/useAuthState'

const Wrapper = styled.div`
  margin-left: 24px;
  margin-bottom: -1px;

  .MuiTabs-root {
    min-height: 40px;
    padding-bottom: 1px;
  }

  && .MuiTabs-indicator {
    z-index: 90;
  }
`
const Line = styled.div`
  position: relative;
  height: 1px;
  margin-top: -2px;
  background: ${({ theme }) => theme.colors.grey100};
  z-index: 50;
`

const TabStyled = styled(Tab)<{ isVisited?: boolean }>`
  && {
    max-height: 40px;
    padding: 10px 12px;
    background: ${({ theme, isVisited }) =>
    (isVisited ? theme.colors.white : theme.colors.blue100)};
    color: ${({ theme }) => theme.colors.grey600};
  }
`

const allTabsList: TTab[] = [
  'OVERVIEW',
  'INCOME',
  'AFFORDABILITY',
  'RISK',
  'BUREAUX',
  'SCORE',
  'TRANSACTIONS'
]

const tabsListWithoutBureaux: TTab[] = allTabsList.filter((tab) => tab !== 'BUREAUX')

function useBureauxTabVisible() {
  const isPlendOrganization = useIsPlendOrganization()
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  return isPlendOrganization || isSocialCreditOrganization
}

function useVisitedTabs(tabValue: number) {
  const { authData } = useAuthContext()
  const isBureauxTabVisible = useBureauxTabVisible()

  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()
  const { mutateAsync: tabsIncrementMutate } = useTabsIncrement()

  const [incrementedTabs, setIncrementedTabs] = useState<TTab[]>([])

  const tabsList = isBureauxTabVisible ? allTabsList : tabsListWithoutBureaux

  const visitedTabsFromHeaderData = useMemo(
    () => headerData?.loanApplicationTabs
      ?.filter(({ visitCount }) => !!visitCount)
      .map((item) => item.code) ?? [],
    [headerData]
  )

  useDeepCompareEffect(() => {
    setIncrementedTabs([])
  }, [headerData])

  useEffect(() => {
    if (
      headerData
      && headerData.underwriterId === authData?.id
      && (headerData.status === 'IN_PROGRESS' || headerData.status === 'ACCOUNT_REQUESTED')
    ) {
      tabsIncrementMutate(tabsList[tabValue] as TTab)
        .then((tabs) => setIncrementedTabs(tabs))
    }
  }, [headerData?.status, tabValue])

  return useMemo(
    () => Array.from(new Set([...visitedTabsFromHeaderData, ...incrementedTabs])),
    [incrementedTabs, visitedTabsFromHeaderData]
  )
}

const TabNavigation: FC = () => {
  const isBureauxTabVisible = useBureauxTabVisible()

  const tabs = isBureauxTabVisible
    ? allTabsList
    : tabsListWithoutBureaux

  const { pathname } = useLocation()
  const lastWord = getLastWord(pathname)
  const lastToUpper = lastWord.toUpperCase() as TTab
  const index = tabs.indexOf(lastToUpper)
  const tabValue = index === -1 ? 0 : index

  const allVisitedTabs = useVisitedTabs(tabValue)
  return (
    <Wrapper>
      <Tabs value={tabValue}>
        <TabStyled
          label="Overview"
          component={Link}
          isVisited={allVisitedTabs?.includes('OVERVIEW') || false}
          to=""
        />
        <TabStyled
          label="Income"
          component={Link}
          isVisited={allVisitedTabs?.includes('INCOME') || false}
          to="income"
        />
        <TabStyled
          label="Affordability"
          component={Link}
          isVisited={allVisitedTabs?.includes('AFFORDABILITY') || false}
          to="affordability"
        />
        <TabStyled
          label="Risk"
          component={Link}
          isVisited={allVisitedTabs?.includes('RISK') || false}
          to="risk"
        />
        {isBureauxTabVisible && (
          <TabStyled
            label="Bureaux Data"
            component={Link}
            isVisited={allVisitedTabs?.includes('BUREAUX') || false}
            to="bureaux"
          />
        )}
        <TabStyled
          label="Plend Score"
          component={Link}
          isVisited={allVisitedTabs?.includes('SCORE') || false}
          to="score"
        />
        <TabStyled
          label="Transactions"
          component={Link}
          isVisited={allVisitedTabs?.includes('TRANSACTIONS') || false}
          to="transactions"
        />
      </Tabs>
      <Line />
    </Wrapper>
  )
}

export default TabNavigation
