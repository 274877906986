import styled, { useTheme } from 'styled-components/macro'
import React, { FC } from 'react'
import { EditIcon } from '../../../core/components/icons/EditIcon'
import { SelectedIcon } from '../../../core/components/icons/SelectedIcon'
import { CloseLineIcon } from '../../../core/components/icons/CloseLineIcon'
import { useExceptionFormContext } from '../state/exceptionsState'

const IconStyled = styled.span<{ isDisabled?: boolean; color?: string }>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  color: ${({ color }) => color || 'inherit'};
`

const IconWrapStyled = styled.div`
  gap: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`

type TProps = {
  onHandleClick: () => void
  exceptionId: string
}
const EditException: FC<TProps> = ({ onHandleClick, exceptionId }) => {
  const { colors: { green } } = useTheme()
  const {
    selectedRow,
    handleSubmit,
    setSelectedRow
  } = useExceptionFormContext()
  return (
    <>
      {
        selectedRow?.id === exceptionId
          ? (
            <IconWrapStyled>
              <IconStyled color={green} onClick={handleSubmit}>
                <SelectedIcon />
              </IconStyled>
              <IconStyled onClick={() => setSelectedRow(undefined)}>
                <CloseLineIcon />
              </IconStyled>
            </IconWrapStyled>
          )
          : (
            <IconStyled onClick={onHandleClick}>
              <EditIcon width={20} height={20} />
            </IconStyled>
          )
      }
    </>
  )
}

export default EditException
