import { Control, Controller } from 'react-hook-form'
import { Autocomplete } from '@mui/material'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import styled from 'styled-components/macro'
import * as React from 'react'
import { CustomTextField } from './CustomInput'
import { ChevronDownIcon } from './icons/ChevronDownIcon'
import { TOption } from './CustomSelect'

const AutoCompleteStyled = styled(Autocomplete)`
  && {

    & .MuiOutlinedInput-root {
      height: 40px;
      padding: 0 36px 0 12px
    }

    & .MuiOutlinedInput-root .MuiAutocomplete-input {
      padding: 0;
    }

    & .MuiOutlinedInput-root.Mui-disabled {
      border: 1px solid ${({ theme }) => theme.colors.grey600};
      background: ${({ theme }) => theme.colors.grey100};
    }

    & .MuiAutocomplete-endAdornment {
      padding-right: 4px;
      top: auto;
    }


  }
`

type TProps = {
  name: string
  control: Control<any>
  rules?: RegisterOptions
  options: TOption[]
  placeholder?: string
  disabled?: boolean
}
const CustomAutoComplete = (
  {
    name,
    control,
    rules, options,
    placeholder,
    disabled,
    ...rest
  }: TProps
) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({
      field,
      fieldState
    }) => (
      <AutoCompleteStyled
        {...field}
        fullWidth
        disabled={disabled}
        freeSolo
        disableClearable
        options={options}
        forcePopupIcon
        autoHighlight={false}
        value={field.value}
        popupIcon={<ChevronDownIcon width={16} height={16} />}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            placeholder={placeholder}
            onChange={({ target: { value } }) => field.onChange(value)}
          />
        )}
        onChange={(event, option) => field.onChange((option as TOption).value)}
        renderOption={(props, option) => {
          const { value: optionValue } = option as TOption
          return (
            // eslint-disable-next-line jsx-a11y/role-supports-aria-props
            <li
              {...props}
              aria-selected={optionValue === field.value}>
              {optionValue}
            </li>
          )
        }}
        {...rest}
      />
    )}
  />
)

export default CustomAutoComplete
