import { useQuery } from 'react-query'
import { apiClient } from '../../../core/api/apiClient'
import { TClasses, TTransactionsData } from './types'
import { prepareTablePaginationRequestParams } from '../../../core/utils/tableData'
import { TSortBy } from '../../../core/components/table/TableContext'
import { transactionsSortByParams } from './utils/transactionsSortParams'

export enum QueryKey {
  Transactions = 'Transactions',
  Classes = 'Classes'
}

function getUndefinedInCaseOfEmptyArray(array?: string[]) {
  return array?.length ? array : undefined
}

async function getTransactions(
  currentPage: number,
  search: string,
  firstClasses?: string[],
  secondClasses?: string[],
  sortBy?: TSortBy,
  id?: string,
  signal?: AbortSignal
): Promise<TTransactionsData> {
  try {
    const { data } = await apiClient.post(
      `dashboard/loan-requests/${id}/transactions`,
      {
        firstClasses: secondClasses?.length
          ? undefined
          : getUndefinedInCaseOfEmptyArray(firstClasses),
        secondClasses: getUndefinedInCaseOfEmptyArray(secondClasses)
      },
      {
        params: {
          ...prepareTablePaginationRequestParams(currentPage, 50, transactionsSortByParams(sortBy)),
          search
        },
        signal
      }
    )

    return data
  } catch (error) {
    throw new Error('Error data')
  }
}

export function useTransactionsQuery(
  currentPage: number,
  search: string,
  classes: TClasses,
  sortBy?: TSortBy,
  id?: string
) {
  const key = [QueryKey.Transactions, id, currentPage, search, sortBy, classes]

  return useQuery(
    key,
    ({ signal }) =>
      getTransactions(
        currentPage,
        search,
        classes.firstClasses,
        classes.secondClasses,
        sortBy,
        id,
        signal
      ),
    {
      keepPreviousData: true,
      cacheTime: 0
    }
  )
}

async function getTransactionsClasses(
  firstClass?: string,
  secondClass?: string,
  exactMatch?: boolean
) {
  try {
    const { data } = await apiClient('dashboard/loan-requests/taxonomy/classes', {
      params: {
        firstClass,
        secondClass,
        exactMatch
      }
    })
    return data
  } catch (error) {
    throw new Error('Error data')
  }
}

export function useTransactionsClassesOptionsQuery(
  extraKey: string,
  firstClassSearch?: string,
  secondClassSearch?: string,
  notKeepValues?: boolean,
  isExactMatch?: boolean
) {
  const key = [QueryKey.Classes, extraKey, firstClassSearch, secondClassSearch, isExactMatch]
  return useQuery(
    key,
    () => getTransactionsClasses(firstClassSearch, secondClassSearch, isExactMatch),
    {
      keepPreviousData: !notKeepValues
    }
  )
}
