import { TFormData, TFormDataToServer, TValidationRule, TValidationRules } from '../types'
import { ruleOptions } from '../state/useOptions'

export function prepareRulesToFormData(rules?: TValidationRules[]): TFormData['rules'] {
  if (rules) {
    return rules.map(({ id, daysPeriod, rule, value, version }) => ({
      daysPeriod,
      rule,
      value,
      version,
      id: +id
    }))
  }
  return []
}

export function isDisabled({ enabled }: TValidationRule) {
  return !enabled
}

export function getRuleValue(rule: string) {
  return ruleOptions.find(({ value }) => value === rule)?.label
}

export function prepareRulesToServer(rules: TValidationRules[]): TFormDataToServer['rules'] {
  if (rules) {
    return rules.map(({ daysPeriod, rule, value, version, id }) => ({
      daysPeriod,
      rule,
      version,
      value,
      id
    }))
  }
  return []
}
