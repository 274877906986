import { FC, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { BaseButton } from '../../../../core/components/Button'
import EditModal from './EditModal'
import { TTransaction } from '../../transactions/types'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import { useIsReClassifiable } from '../../applicationDetail/hooks/useIsReClassifiable'
import { useAuthContext } from '../../../auth/state/useAuthState'

const WrapperStyled = styled.div`
  ${flex({ align: 'center' })};
  gap: 16px;
`

const ButtonStyled = styled(BaseButton)`
  && {
    max-width: none;
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const WrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
const TextStyled = styled.p`
  ${fontFamily('Inter')};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const ClearAll = styled.a`
  color: ${({ theme }) => theme.colors.blue500};
  cursor: pointer;
  font-weight: 600;
`

type TProps = {
  transactions: TTransaction[]
  handleClearTransactions: () => void
  isAmountCalculation?: boolean
}

const EditTransaction: FC<TProps> = ({
  transactions,
  handleClearTransactions,
  isAmountCalculation
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { authData } = useAuthContext()

  const transactionsAmount = useMemo(
    // eslint-disable-next-line no-return-assign
    () =>
      transactions.reduce((acc, curr) => {
        // eslint-disable-next-line no-unused-expressions,no-param-reassign
        acc += curr.amount * (curr.type === 'DEBIT' ? 1 : -1)
        return acc
      }, 0),
    [transactions]
  )

  const isReClassifiable = useIsReClassifiable()

  if (!transactions.length) {
    return <></>
  }
  return (
    <>
      <EditModal
        transactions={transactions}
        handleClearTransactions={handleClearTransactions}
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
      <WrapperStyled>
        <WrapStyled>
          <TextStyled>
            {`${transactions.length} transaction${transactions.length > 1 ? 's' : ''} selected `}
            {isAmountCalculation && (
              <ClearAll onClick={handleClearTransactions}>Clear all</ClearAll>
            )}
          </TextStyled>

          {isAmountCalculation && (
            <TextStyled>{`Amount selected: ${formatToPound(transactionsAmount)}`}</TextStyled>
          )}
        </WrapStyled>
        {isReClassifiable && <ButtonStyled onClick={() => setIsModalOpen(true)}>Edit</ButtonStyled>}
      </WrapperStyled>
    </>
  )
}

export default EditTransaction
