import constate from 'constate'
import { useState } from 'react'
import { useBorrowerScoreQuery } from '../queries'
import { ScoreType } from '../types'
import { TSortBy } from '../../../../core/components/table/TableContext'

function useBorrowerScore() {
  const [selectedBorrowerScore, setBorrowerScore] = useState<ScoreType>(ScoreType.totalScore)
  const [sort, setSort] = useState<TSortBy>({
    id: 'score',
    desc: true
  })

  return {
    setSort,
    selectedBorrowerScore,
    sort,
    setBorrowerScore,
    borrowerScoreQuery: useBorrowerScoreQuery(selectedBorrowerScore, sort)
  }
}

export const [BorrowerScoreProvider, useBorrowerScoreContext] = constate(useBorrowerScore)
