import styled from 'styled-components/macro'
import { ReactNode } from 'react'
import { WarningIcon } from '../../../../core/components/icons/WarningIcon'
import { HelpIcon } from '../../../../core/components/icons/HelpIcon'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { CheckIcon } from '../../../../core/components/icons/CheckIcon'
import SectionCard from './SectionCard'
import { useOverview } from '../queries'
import { formatTextByLength } from '../utils/riskUtils'
import Spinner from '../../../../core/components/Spinner'
import { TGamblingStatus } from '../../risk/types'

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  width: 100%;
  margin: 98px 0;
`

const RiskStatusContainerStyled = styled.div`
  width: 100%;
`
const RiskName = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CardIconStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
`

const TextStyled = styled.p`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  width: 100%;
  max-width: 146px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-left: 6px;
`

const RiskValue = styled.div`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`

const iconStatus: { [key in TGamblingStatus]: ReactNode } = {
  NEGATIVE: <WarningIcon />,
  NEUTRAL: <HelpIcon />,
  POSITIVE: <CheckIcon />
}

const Risk = () => {
  const { data, isIdle, isLoading } = useOverview()
  if (!data || isLoading || isIdle) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }
  const { risks } = data
  return (
    <>
      <SectionCard navigationUrl="risk" label="Risk" />
      <RiskStatusContainerStyled>
        {
          risks?.map(({ name, status, value }) => (
            <CardIconStyled>
              <RiskName>
                {iconStatus[status]}
                <TextStyled>
                  {formatTextByLength(name)}
                </TextStyled>
              </RiskName>
              <RiskValue>{value}</RiskValue>
            </CardIconStyled>
          ))
        }
      </RiskStatusContainerStyled>
    </>
  )
}
export default Risk
