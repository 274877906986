import styled from 'styled-components/macro'
import MonthChanging from './MonthChanging'
import { CardStyled } from '../../../core/components/Card'
import InnerLayout, { HeaderStyled } from '../../../core/components/InnerLayout'
import { flex, fontFamily } from '../../../core/styles/mixins'
import BarChart from './charts/BarChart'
import LineChart from './charts/LineChart'
import { HomeProvider, useHomeContext } from '../state/useHome'
import { humanizeTime } from '../utils/formatUtils'
import { formatToPound } from '../../../core/utils/currencyFormat'

import ChartTemplate from './ChartTemplate'
import UsersDropdown from './UsersDropdown'
import { useIsSocialCreditOrganization } from '../../auth/hooks'

const ContainerStyled = styled.div`
  height: 100%;
  width: 100%;

  ${HeaderStyled} {
    ${flex({ justify: 'space-between', align: 'center' })};
    min-height: 84px;
  }
`

const HeaderContentStyled = styled.div`
  width: 100%;
`

const HeaderDashboardStyled = styled.div`
  ${flex({ justify: 'space-between', align: 'center' })};
`

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
`

const BodyStyled = styled.div`
  max-width: 1248px;
  padding-bottom: 24px;
  width: 100%;
  
  ${CardStyled}{
    ${flex({ justify: 'flex-start', align: 'flex-start' })};
    flex-direction: column;
    min-height: 400px;
  }
`

const SectionChartStyled = styled.div<{ isSingle: boolean }>`
  margin-top: 16px;
  display: grid;
  ${({ isSingle }) => (isSingle ? undefined : 'grid-template-columns: 49% 49%')};
  grid-column-gap: 24px;
  width: 100%;
`

const LabelStyled = styled.p<LabelStyledProps>`
  ${fontFamily('Inter')};
  font-size: 18px;
  font-weight: 500;
  line-height: ${({ lineHeight }) => lineHeight}px;
  margin-top: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`

const MeanArithmeticStyled = styled.h2`
  ${fontFamily('Inter')};
  font-size: 24px;
  margin-top: 4px;
  line-height: 36px;
  font-weight: bold;
`

type LabelStyledProps = {
  lineHeight: 24 | 22
}

const Home = () => {
  const {
    approvalStatisticsQuery: {
      data: approvalStatisticsData,
      isLoading: isApprovalStatisticsLoading
    },
    sizeStatisticsQuery: {
      data: sizeStatisticsData,
      isLoading: isSizeStatisticsLoading
    },
    statusStatisticsQuery: {
      data: statusStatisticsData,
      isLoading: isStatusStatisticsLoading
    }
  } = useHomeContext()

  const isApproveTime = approvalStatisticsData?.approvalTime
  const isLoanSize = sizeStatisticsData?.loanSizeTotal !== '0'
  const time = isApproveTime
    ? humanizeTime(approvalStatisticsData?.approvalTime)
    : '-'

  const loanSize = isLoanSize
    ? formatToPound(Number(sizeStatisticsData?.loanSizeTotal))
    : '-'

  const loanSizeDaily = sizeStatisticsData?.daily.map(
    (item) => ({ ...item, loanSize: Number(item.loanSize) })
  )

  const showLongestWaitTime = !useIsSocialCreditOrganization()

  return (
    <ContainerStyled>
      <InnerLayout
        header={(
          <HeaderContentStyled>
            <HeaderDashboardStyled>
              <TitleStyled>Dashboard</TitleStyled>
              <MonthChanging/>
            </HeaderDashboardStyled>
            <UsersDropdown />
          </HeaderContentStyled>
        )}>
        <BodyStyled>
          <ChartTemplate showSpinner={isStatusStatisticsLoading}>
            <LabelStyled lineHeight={24}>Request Statistics</LabelStyled>
            { statusStatisticsData && <BarChart data={statusStatisticsData}/>}
          </ChartTemplate>
          <SectionChartStyled isSingle={!showLongestWaitTime}>
            <ChartTemplate showSpinner={isSizeStatisticsLoading}>
              <LabelStyled lineHeight={22}>Average Loan Size</LabelStyled>
              <MeanArithmeticStyled>
                {loanSize}
              </MeanArithmeticStyled>
              <LineChart
                dataKey="loanSize"
                data={loanSizeDaily}
              />
            </ChartTemplate>
            {showLongestWaitTime && (
              <ChartTemplate showSpinner={isApprovalStatisticsLoading}>
                <LabelStyled lineHeight={22}>Longest Wait Time for Approval</LabelStyled>
                <MeanArithmeticStyled>
                  {time}
                </MeanArithmeticStyled>
                <LineChart
                  data={approvalStatisticsData?.daily || []}
                  dataKey="time"
                />
              </ChartTemplate>
            )}
          </SectionChartStyled>
        </BodyStyled>
      </InnerLayout>
    </ContainerStyled>
  )
}

export default () => (
  <HomeProvider>
    <Home/>
  </HomeProvider>
)
