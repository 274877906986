import React, { FC, useLayoutEffect } from 'react'
import { useHasPermission } from '../hooks'
import { useNotFoundContext } from '../../notFound/guards/NotFoundGuard'
import { TPermission } from '../../../core/types/permissions'

type TProps = {
  children: JSX.Element,
  permission: TPermission[]
  extraRestriction?: boolean
}

const RoleGuard: FC<TProps> = ({
  children,
  permission,
  extraRestriction
}) => {
  const { setShowNotFound } = useNotFoundContext()
  const hasPermission = useHasPermission(permission)

  useLayoutEffect(() => {
    if (!hasPermission || extraRestriction) {
      setShowNotFound(true)
    }
  }, [])

  return children
}

export default RoleGuard
