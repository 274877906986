import { Tooltip, TooltipProps } from '@mui/material'
import styled from 'styled-components/macro'
import { FC } from 'react'
import { fontFamily } from '../styles/mixins'

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  && .MuiTooltip-tooltip {
    ${fontFamily('Inter')};
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkBlack};
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    max-width: ${({ maxWidth }) => (maxWidth || 280)}px;
    padding: 5px 8px;
    filter: drop-shadow(${({ theme }) => theme.colors.shadow300});
    margin-bottom: 0;
    mix-blend-mode: normal;
  }

  && .MuiTooltip-tooltipPlacementTop {
    margin-bottom: 8px !important;
  }

  && .MuiTooltip-arrow {
    border-top: none;
    color: ${({ theme }) => theme.colors.white};
  }
`

type TProps = {
  maxWidth?: number
} & TooltipProps

const CustomTooltip: FC<TProps> = (
  props
) => <StyledTooltip {...props} />

export default CustomTooltip
