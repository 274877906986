import styled from 'styled-components/macro'
import InnerLayout from '../../../core/components/InnerLayout'
import PartnerDashboardHeader from './PartnerDashboardHeader'
import PartnerDashboardTable from './PartnerDashboardTable'
import { PartnerDashboardProvider } from '../state/PartnerDashboardState'
import Search from './Search'
import GenerateLinkModal from './generateLinkModal/GenerateLinkModal'
import SuccessModal from './generateLinkModal/SuccessModal'
import FailedModal from './generateLinkModal/FailedModal'
import Card, { CardStyled } from '../../../core/components/Card'

const WrapStyled = styled.div`

  ${CardStyled} {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
    padding: 24px 16px;
  }
`
const PartnerDashboard = () => (
  <WrapStyled>
    <GenerateLinkModal />
    <SuccessModal />
    <FailedModal />
    <InnerLayout header={<PartnerDashboardHeader />}>
      <Card noPadding>
        <Search />
        <PartnerDashboardTable />
      </Card>
    </InnerLayout>
  </WrapStyled>
)

export default () => (
  <PartnerDashboardProvider>
    <PartnerDashboard />
  </PartnerDashboardProvider>
)
