import styled from 'styled-components/macro'
import InnerLayout from '../../../core/components/InnerLayout'
import { fontFamily } from '../../../core/styles/mixins'
import ScoringForm from './ScoringForm'
import ScoringPreview from './ScoringPreview'
import { ClearScorePricingProvider, useClearScorePricingContext } from '../state/clearScorePricing'
import Spinner from '../../../core/components/Spinner'

const TitleStyled = styled.h1`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  height: auto;
  min-height: 100%;
`

const SpinnerWrapper = styled.div`
  height: 300px;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey500};
`

const WrappedSpinner = () => (
  <SpinnerWrapper>
    <Spinner />
  </SpinnerWrapper>
)

const ClearScorePricing = () => {
  const {
    clearScorePricingData: { isClearScorePricingLoading }
  } = useClearScorePricingContext()

  return (
    <>
      <InnerLayout header={<TitleStyled>ClearScore Pricing</TitleStyled>}>
        <Wrapper>
          {isClearScorePricingLoading ? <WrappedSpinner /> : <ScoringForm />}
          <Divider />
          <ScoringPreview />
        </Wrapper>
      </InnerLayout>
    </>
  )
}

export default () => (
  <ClearScorePricingProvider>
    <ClearScorePricing />
  </ClearScorePricingProvider>
)
