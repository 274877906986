import { Dialog as MuiDialog } from '@mui/material'
import { FC, ReactNode, SyntheticEvent } from 'react'
import { DialogProps } from '@mui/material/Dialog/Dialog'
import styled from 'styled-components/macro'
import { CloseLineIcon } from './icons/CloseLineIcon'

export const MuiDialogStyled = styled(MuiDialog)`
  && {
    & .MuiPaper-root {
      margin: 0;
    }
  }
`

const IconContainerStyled = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const ContentWrapperStyled = styled.div`
  padding: 24px;
`

type TProps = {
  isOpen: boolean
  onClose: (e: SyntheticEvent) => void
  children: ReactNode
} & Omit<DialogProps, 'open'>

const CustomModal: FC<TProps> = ({
  isOpen,
  onClose,
  children,
  ...rest
}) => (
  <MuiDialogStyled
    {...rest}
    classes={{
      root: 'muiRoot'
    }}
    open={isOpen}
    onClose={onClose}
  >
    <ContentWrapperStyled>
      <IconContainerStyled onClick={onClose}>
        <CloseLineIcon />
      </IconContainerStyled>
      {children}
    </ContentWrapperStyled>
  </MuiDialogStyled>
)

export default CustomModal
