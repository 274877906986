import { FC } from 'react'
import { IconWrapper } from './base/IconWrapper'
import { ReactComponent as Icon } from './svg/UserManagement.svg'
import { TIconProps } from './types'

export const UsersManagementIcon: FC<TIconProps> = ({ width, height }) => (
  <IconWrapper width={width} height={height}>
    <Icon />
  </IconWrapper>
)
