import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../../core/api/apiClient'
import { TBureauxData, TBureauxDocument } from './types'

export enum QueryKey {
  Bureaux = 'Bureaux',
  BureauxDownload = 'BureauxDownload'
}

async function getDocument(loanRequestId?: string) {
  const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'

  const { data } = await apiClient.get<TBureauxDocument>(`/dashboard/loan-requests/${loanRequestId}/bureaux/report/download`)
  const link = document.createElement('a')
  link.href = mediaType + data.contentBase64
  link.download = `${data.fileName}.xlsx`
  link.click()
}

export function useDownloadBureauxQuery() {
  const key = QueryKey.BureauxDownload
  const { loanRequestId } = useParams()
  return useQuery([key, loanRequestId], () => getDocument(loanRequestId), { enabled: false })
}

export function useBureauxQuery() {
  const { loanRequestId } = useParams()

  return useQuery(
    [QueryKey.Bureaux, loanRequestId],
    async (): Promise<TBureauxData[]> => {
      try {
        const { data } = await apiClient(`dashboard/loan-requests/${loanRequestId}/bureaux`)
        return data
      } catch (e) {
        throw new Error('useBureauxQuery')
      }
    },
    { keepPreviousData: true }
  )
}
