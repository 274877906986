import { TChartAccountBalance } from '../../features/applications/overview/types'
import { formatToPound } from './currencyFormat'

export const formatLegendText = (text: string, legendAdditionalInfo?: TChartAccountBalance[]) => {
  const [additionalTextLegend, textLegend] = text.split('.')

  if (legendAdditionalInfo) {
    const found = legendAdditionalInfo.find((item) => item.accountId === Number(textLegend))
    return {
      legendText: found?.accountName ?? '',
      opacity: found?.includedIntoProcessing,
      found
    }
  }

  return {
    legendText: textLegend || additionalTextLegend
  }
}

export const getTooltipContent = (
  additionalInfo: TChartAccountBalance[],
  accountId?: number
) => {
  const contentTooltip = additionalInfo.find((accounts) => accounts.accountId === accountId) || {}
  return Object.entries(contentTooltip)
    .map(([key, value]) => {
      if ((key === 'availableBalance' || key === 'currentBalance')) {
        return {
          key,
          value: formatToPound(value as number)
        }
      }
      return undefined
    })
    .filter(Boolean)
}
