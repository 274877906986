import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components/macro'
import { useKeywordsContext } from '../state/useKeywords'
import { DownloadIcon } from '../../../core/components/icons/DownloadIcon'

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: pointer;
`

const LoaderWrapper = styled(CircularProgress)`
  && {
    && .MuiCircularProgress-svg {
    width: 24px;
    height: 24px;
    }
  }
`

const Loader = ({ fileId }: { fileId: number }) => {
  const [progress, setProgress] = useState<number>()

  const { downloadMutation: { mutateAsync: download } } = useKeywordsContext()

  useEffect(() => {
    if (progress === 100) {
      setProgress(undefined)
    }
  }, [progress])

  if (progress) {
    return <LoaderWrapper style={{ width: '24px', height: '24px' }}/>
  }

  return (
    <IconWrapper>
      <DownloadIcon onClick={() => {
        download({ id: fileId, callback: (num) => setProgress(num * 100) })
      }}/>
    </IconWrapper>
  )
}

export default Loader
