import { FC, memo } from 'react'
import { Tabs as MaterialTabs, TabsProps } from '@mui/material'
import styled from 'styled-components/macro'

export const MaterialTabsStyled = styled(MaterialTabs)`
  && {
    min-height: 40px;

    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.colors.blue500};
    }
  }
`

const Tabs: FC<TabsProps> = ({ children, ...rest }) => (
  <MaterialTabsStyled {...rest} >{children}</MaterialTabsStyled>
)

export default memo(Tabs)
