import React, { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import Modal from './Modal'
import { useAssessmentContext } from '../state/useAssessment'
import { BaseButton } from '../../../../core/components/Button'
import { useDeclineReasonsQuery, useTabsVisited } from '../queries'
import { fontFamily } from '../../../../core/styles/mixins'
import { DeclineCircledIcon } from '../../../../core/components/icons/DeclineCircledIcon'
import { useIsPlendOrganization } from '../../../auth/hooks'
import DeclineAdditionalReasons from './DeclineAdditionalReasons'
import { TDeclineReason } from '../type'
import { prepareDeclineReasonLabel } from '../utils/prepareDeclineReasonLabel'
import { CustomRadio } from '../../../../core/components/CustomRadio'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'

const mixinMainButton = css`
  && {
    min-width: 158px;
    padding: 8px 16px;
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

export const FormControlStyled = styled(FormControl)`
  && {
    display: flex;

    .MuiRadio-root {
      padding: 0;
      margin-left: 9px;

      :not(.Mui-checked) {
        color: ${({ theme }) => theme.colors.grey500};
      }
    }

    .MuiFormControlLabel-root {
      margin-top: 8px;
      margin-left: -8px;
    }

    .MuiTypography-root {
      margin-left: 8px;
    }
  }
`

const ReasonTitleStyled = styled.div`
  margin-top: 10px;
`

const mixinSecondaryButton = css`
  && {
    width: 98px;
    height: 32px;
    padding: 12px 6px 14px;
    ${fontFamily('Inter')};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const DeclineButtonStyled: any = styled(BaseButton)<{ isSecondaryButton: boolean }>`
  && {
    ${({ isSecondaryButton }) => (isSecondaryButton ? mixinSecondaryButton : mixinMainButton)}
  }
`

const Title = styled.span`
  margin-left: 8px;
`

type TProps = {
  isSecondaryButton?: boolean
}

const AssessmentDecline: FC<TProps> = ({ isSecondaryButton }) => {
  const {
    setIsModalOpen,
    isModalOpen
  } = useAssessmentContext()

  const {
    headerUpdateMutation: { mutate, isLoading: isUpdating }
  } = useApplicationHeaderContext()

  const { refetch, data: isAllTabVisited } = useTabsVisited()

  const isPlendOrganization = useIsPlendOrganization()

  const { data: declineReasons } = useDeclineReasonsQuery(isModalOpen.decline)

  const [selectedReason, setSelectedReason] = useState<TDeclineReason>()

  useEffect(() => {
    if (isModalOpen.decline) {
      setSelectedReason(undefined)
    }
  }, [isModalOpen.decline])

  return (
    <>
      <DeclineButtonStyled
        variant="contained"
        isSecondaryButton={isSecondaryButton}
        onClick={() => {
          refetch()
            .then(() => setIsModalOpen({ decline: true }))
        }}>
        {isSecondaryButton && <DeclineCircledIcon />}
        <Title>Decline</Title>
      </DeclineButtonStyled>
      <Modal
        title="Decline application"
        content={(
          <div>
            Are you sure you want to decline this application?

            {isPlendOrganization && (
              <>
                <ReasonTitleStyled>
                  Please specify the main reason you want to decline this application:
                </ReasonTitleStyled>

                <FormControlStyled>
                  <RadioGroup>
                    {declineReasons?.map((item) => (
                      <FormControlLabel
                        key={item.code}
                        value={item.code}
                        checked={selectedReason?.code === item.code}
                        onChange={() => setSelectedReason(item)}
                        control={<CustomRadio />}
                        label={prepareDeclineReasonLabel(item.value)}
                      />
                    ))}
                  </RadioGroup>
                </FormControlStyled>
              </>
            )}
          </div>
        )}
        buttonText={isPlendOrganization ? 'Next' : 'Decline'}
        isActionDisabled={isPlendOrganization && !selectedReason}
        error={!isAllTabVisited || false}
        queryRequest={() => {
          if (isPlendOrganization) {
            setIsModalOpen({ decline: false, declineAdditionalReasons: true })
            return
          }
          if (!isUpdating) {
            mutate({ status: 'DECLINED' })
            setIsModalOpen({ decline: false })
          }
        }}
        isOpen={isModalOpen.decline}
        handleClose={() => setIsModalOpen({ decline: false })}
      />
      <DeclineAdditionalReasons declineReason={selectedReason} />
    </>
  )
}

export default AssessmentDecline
