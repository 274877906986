import { FC } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { ChevronRightIcon } from '../../../../core/components/icons/ChevronRightIcon'
import { flex, fontFamily } from '../../../../core/styles/mixins'

const ContainerStyled = styled.div`
  ${flex({ justify: 'flex-start', align: 'center' })};
  color: ${({ theme }) => theme.colors.darkBlack};
`

export const LabelStyled = styled(NavLink)<{isCursor: boolean}>`
  ${flex({ align: 'center' })};
  ${fontFamily('Inter')};
  font-weight: 600;
  line-height: 24px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'default')};
`

export const CaptionStyled = styled.p`
  ${fontFamily('Inter')};
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: block;
  color: ${({ theme }) => theme.colors.grey600};
  margin-top: 2px;
`

type TProps = {
  label: string
  caption?: string
  isIcon?: boolean
  navigationUrl?: string
}

const SectionCard: FC<TProps> = ({ label, caption, navigationUrl, isIcon = true }) => (
  <>
    <ContainerStyled>
      <LabelStyled isCursor={isIcon} to={navigationUrl || ''}>
        {label}
        {isIcon && <ChevronRightIcon width={20} height={20} />}
      </LabelStyled>

    </ContainerStyled>
    <CaptionStyled>{caption}</CaptionStyled>
  </>
)

export default SectionCard
