import { ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { formatToPound } from '../../../core/utils/currencyFormat'
import { formatSeconds } from './formatUtils'

const formatValues = {
  loanSize: {
    formatter: (value?: ValueType) => formatToPound(Number(value))
  },
  time: {
    formatter: (value?: ValueType) => (Number(value) <= 4 ? `${Number(value)}hr` : formatSeconds(Number(value)))
  }
}
type TProps = {
  formatType: 'loanSize' | 'time',
  value: string | number
}

export const formatAxis = ({ formatType, value }: TProps) =>
  formatValues[formatType].formatter(value)
