import styled from 'styled-components/macro'
import DetailedScoreTable from './DetailedScoreTable'
import BorrowerScoreTable from './BorrowerScoreTable'
import { BorrowerScoreProvider } from '../state/useBorrowerScore'

const Wrapper = styled.section`
  height: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: minmax(250px,314px) 1fr;
  grid-column-gap: 1px;
`

const CardStyled = styled.div`
  padding-bottom: 32px;
  background: ${({ theme }) => theme.colors.white};
`

const PlendScore = () => (
  <BorrowerScoreProvider>
    <Wrapper>
      <CardStyled>
        <BorrowerScoreTable/>
      </CardStyled>
      <CardStyled>
        <DetailedScoreTable/>
      </CardStyled>
    </Wrapper>
  </BorrowerScoreProvider>
)
export default PlendScore
