import { TChartAccountBalance } from '../types'

export const prepareAccountExclude = (
  excludeAccountIds: number[],
  allAccounts: TChartAccountBalance[]
) => {
  const includeAccountIds = allAccounts
    .filter(({ accountId }) => !excludeAccountIds.includes(accountId))
    .map(({ accountId }) => accountId)

  return {
    excludedAccountIds: excludeAccountIds,
    includedAccountIds: includeAccountIds
  }
}
