import { FC, ForwardRefExoticComponent, memo, RefAttributes } from 'react'
import { Tab as MaterialTab, TabProps } from '@mui/material'
import styled from 'styled-components/macro'
import { LinkProps } from 'react-router-dom'
import { fontFamily } from '../../styles/mixins'

export const TabStyled = styled(MaterialTab)`
  && {
    ${fontFamily('Inter')};
    min-height: 40px;
    padding: 10px 12px;
    font-size: 16px;
    font-weight: normal;
    line-height: 18px;
    text-transform: none;
    color: ${({ theme }) => theme.colors.grey600};
    letter-spacing: 0;

    &.Mui-selected {
      color: ${({ theme }) => theme.colors.darkBlack};
    }
  }
`

type TComponentProps = {
  component?: ForwardRefExoticComponent<LinkProps & RefAttributes<HTMLAnchorElement>>
  to?: string
}

type TProps = TabProps & TComponentProps

const Tab: FC<TProps> = ({ ...rest }) => <TabStyled {...rest} />

export default memo(Tab)
