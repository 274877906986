import styled from 'styled-components/macro'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { fontFamily } from '../../../core/styles/mixins'
import CustomInput from '../../../core/components/CustomInput'
import useOnBlur from '../hooks/useOnBlur'
import { ClearScorePricing, InputNames } from '../types'

const MAX_AO_SCORE = 1000
const MAX_PERCENTAGE = 100

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  & .MuiFormControl-root {
    width: 54px !important;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.span`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const Label = styled.span`
  font-size: 16px;
  line-height: 32px;
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
`

const CreditAdjustment = () => {
  const { control, setValue, getValues } = useFormContext<ClearScorePricing>()
  const onBlur = useOnBlur(setValue)
  const { fields } = useFieldArray({
    control,
    name: 'creditAdjustments',
    keyName: 'key'
  })

  const validation = (
    value: string,
    nextKey: InputNames,
    targetName: 'aoScoreRange' | 'creditAdjustment'
  ) => {
    const nextValue = getValues(nextKey)

    if (!Number(value)
      || (targetName === 'aoScoreRange' && Number(value) >= MAX_AO_SCORE)
      || (targetName === 'creditAdjustment' && Number(value) >= MAX_PERCENTAGE)
    ) return false

    if (nextValue === undefined) return true
    return targetName === 'aoScoreRange'
      ? Number(nextValue) > Number(value)
      : Number(value) >= Number(nextValue)
  }

  return (
    <>
      <Title>Credit Adjustments:</Title>
      <Row>
        <Col>
          <Label>AO Score cutoff (min)</Label>
          <Label>Credit Adjustment, %</Label>
        </Col>
        {fields.map((field, index) => (
          <Col key={field.key}>
            <CustomInput
              type="number"
              control={control}
              disabled={index === 0}
              name={`creditAdjustments.${index}.aoScoreRange`}
              rules={{
                required: true,
                validate: (value) =>
                  validation(value, `creditAdjustments.${index + 1}.aoScoreRange`, 'aoScoreRange')
              }}
            />
            <CustomInput
              type="number"
              control={control}
              name={`creditAdjustments.${index}.creditAdjustment`}
              rules={{
                required: true,
                validate: (value) =>
                  validation(
                    value,
                    `creditAdjustments.${index + 1}.creditAdjustment`,
                    'creditAdjustment'
                  )
              }}
              onBlur={onBlur(`creditAdjustments.${index}.creditAdjustment`, 1)}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

export default CreditAdjustment
