import { useCallback, useEffect, useMemo, useState } from 'react'
import constate from 'constate'
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import { UseQueryResult } from 'react-query/types/react/types'
import { TFormData, TValidationRule } from '../types'
import { prepareRulesToFormData, prepareRulesToServer } from '../utils/tableUtils'
import { useEditValidationRule, useToggleValidationRule } from '../querries'

function useEditingHandle(validationRulesQuery: UseQueryResult<TValidationRule[], unknown>) {
  const [selectedId, setSelectedId] = useState<string>()
  const { data: validationRules } = validationRulesQuery
  return {
    setSelected: useCallback(
      (validationRule?: TValidationRule) =>
        setSelectedId(validationRule ? validationRule.id.toString() : undefined),
      []
    ),
    selectedId,
    selectedRow: useMemo(
      () => validationRules?.find((rule) => String(rule.id) === selectedId),
      [validationRules, selectedId]
    )
  }
}

function useResetFormValue(
  { setValue, watch }: UseFormReturn<TFormData>,
  currentValidationRule?: TValidationRule
) {
  const [isEditFormValueInitialized, setIsEditFormValueInitialized] = useState(false)

  useEffect(() => {
    setIsEditFormValueInitialized(false)
    setValue('rules', currentValidationRule ? prepareRulesToFormData(currentValidationRule.validationRules) : [])

    setTimeout(() => {
      setIsEditFormValueInitialized(true)
    })
  }, [currentValidationRule])

  return {
    isEditFormValueInitialized
  }
}

function useSubmitHandle(
  { getValues, setError }: UseFormReturn<TFormData>,
  currentValidationRule?: TValidationRule,
  onSubmitCallback?: () => void
) {
  const { mutate } = useToggleValidationRule()
  const { mutateAsync: mutateValidationRule } = useEditValidationRule()

  return useCallback(
    () => {
      const { rules } = getValues()
      if (!rules?.length) {
        setError('rules', { type: 'required' })
        return
      }
      mutateValidationRule({ id: currentValidationRule?.id, rules: prepareRulesToServer(rules) })
        .then(() => {
          onSubmitCallback?.()
          if (currentValidationRule && !currentValidationRule?.enabled) {
            mutate(currentValidationRule)
          }
        })
    },
    [currentValidationRule]
  )
}

function useContext({ validationRulesQuery }:
  { validationRulesQuery: UseQueryResult<TValidationRule[]> }) {
  const {
    selectedRow: currentValidationRule,
    setSelected: selectRow,
    selectedId: selectedRowId
  } = useEditingHandle(validationRulesQuery)

  const useFormReturn = useForm<TFormData>()
  const { control } = useFormReturn
  useFieldArray({ control, name: 'rules' })

  return {
    control,
    selectRow,
    selectedRowId,
    useFormReturn,
    ...useResetFormValue(useFormReturn, currentValidationRule),
    handleSubmit: useSubmitHandle(useFormReturn, currentValidationRule, () => selectRow(undefined))
  }
}

export const [FormContextProvider, useFormContext] = constate(useContext)
