import { ReactElement, useMemo } from 'react'
import styled from 'styled-components/macro'
import { Column } from 'react-table'
import CustomTable from '../../../core/components/table/Table'
import { HeaderCellStyled } from '../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../core/components/table/TableBodyRow'
import { useUsersContext } from '../state/useUserManagement'
import Spinner from '../../../core/components/Spinner'
import { flex, fontFamily } from '../../../core/styles/mixins'
import EditUser from './EditUser'
import DeleteUser from './DeleteUser'
import NoDataComponent, {
  CaptionStyled,
  ContainerStyled
} from '../../../core/components/NoDataComponent'
import { useHasPermission } from '../../auth/hooks'
import { UsersManagementIcon } from '../../../core/components/icons/UsersManagementIcon'
import { statusToHumanReadableFormat } from '../../../core/utils/statusToHumanReadableFormat'
import { TUser } from '../types'

const Wrapper = styled.div`
  ${HeaderCellStyled} {
    padding: 24px 16px 16px;
    font-weight: 400;
  }

  ${BodyCellStyled} {
    padding: 16px;
    font-weight: 400;
  }
`

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`

const NoDataWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 650px;
  color: ${({ theme }) => theme.colors.darkBlack};

  ${ContainerStyled} {
    margin-top: 0;
  }

  ${CaptionStyled} {
    ${fontFamily('Inter')};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
`

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 650px;
`

const Table = () => {
  const isAdmins = useHasPermission(['GENERAL_VIEW', 'PARTNERS_ADMIN_VIEW'])

  const {
    usersQuery: { data, isIdle, isLoading },
    setSelectedId,
    setSelectedRow,
    setCurrentPage
  } = useUsersContext()

  const users = useMemo(() => data?.content || [], [data])

  const columns = useMemo<Column<TUser>[]>(
    () => [
      {
        Header: 'Name',
        accessor: (row) => (
          <span>
            {row?.firstName}
            {' '}
            {row?.lastName}
          </span>
        )
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: (): ReactElement => (isAdmins ? <span>Organization</span> : <span />),
        accessor: 'organization',
        Cell: ({ value, row }) =>
          (isAdmins
            ? <span>{value?.name || row.original.partnerOrganization?.name}</span>
            : null
          )
      },
      {
        Header: (): ReactElement => (!isAdmins ? <span>Role</span> : <span />),
        accessor: 'roles',
        Cell: ({ value }) => (!isAdmins
          ? <span>{statusToHumanReadableFormat(value[0].name)}</span>
          : null)
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ value, row }) => (
          <IconsContainer>
            <EditUser
              onHandleClick={() => {
                setSelectedRow(row.original)
                setSelectedId(value)
              }}
            />
            <DeleteUser onHandleClick={() => setSelectedId(value)} />
          </IconsContainer>
        )
      }
    ],
    []
  )

  if (!data) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <Wrapper>
      <CustomTable
        columns={columns}
        data={users}
        paginationConfig={{
          isEnabled: data?.totalPages > 1,
          pageSize: 10,
          manual: true,
          pageCount: data?.totalPages,
          totalRows: data?.totalElements,
          onPageChange: (page) => setCurrentPage(page)
        }}
        showRowBorder
      />
      {!users.length && !isIdle && !isLoading && (
        <NoDataWrapper>
          <NoDataComponent
            text="There are no users yet"
            icon={<UsersManagementIcon width={54} height={54} />}
          />
        </NoDataWrapper>
      )}
    </Wrapper>
  )
}

export default Table
