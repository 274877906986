import styled, { createGlobalStyle } from 'styled-components/macro'
import { useCallback, useState } from 'react'
import { FormControlLabel } from '@mui/material'
import { useForm, UseFormReturn } from 'react-hook-form'
import { BaseButton } from '../../../core/components/Button'
import CustomModal from '../../../core/components/CustomModal'
import CustomInput from '../../../core/components/CustomInput'
import { TCreateValidationException } from '../types'
import { fontFamily } from '../../../core/styles/mixins'
import ValidationsSelect from './ValidationsSelect'
import { requiredRule } from '../../../core/utils/formRules'
import { useCreateValidationExceptionsMutation, useValidateExceptionMutation } from '../querries'

export const ModalGlobalStyles = createGlobalStyle`
  .muiRoot {
    &.MuiDialog-root {
      top: 200px;
    }

    .MuiDialog-container {
      align-items: flex-start;
    }
  }
`

export const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 24px;
`

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;

`
const InputWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 208px 208px;
  gap: 16px;

  .MuiFormControlLabel-root {
    align-items: start;
    justify-content: start;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .MuiTypography-root {
    ${fontFamily('Inter')};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.darkBlack};
    letter-spacing: 0;
  }
`

export const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 32px;
  width: 100%;
  justify-content: flex-end;
`

export const ButtonSubmitStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px 16px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }
  }
`

export const ButtonCancelStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 8px 16px;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

function useFormInit() {
  return useForm<TCreateValidationException>({
    defaultValues: {
      name: '',
      validationTypes: []
    },
    shouldUnregister: true
  })
}

const SOURCE_EXIST_ERROR = 'This source is already being used.'

function useOnSubmit(
  { setError }: UseFormReturn<TCreateValidationException>,
  onSubmitCallback: () => void
) {
  const { mutateAsync: createExceptionAsync } = useCreateValidationExceptionsMutation()
  const { mutateAsync: validateExceptionAsync, isError, error } = useValidateExceptionMutation()
  return useCallback((data: TCreateValidationException) => {
    validateExceptionAsync(data.name)
      .then((response) => {
        if (response) {
          setError('name', { message: SOURCE_EXIST_ERROR })
          return
        }
        createExceptionAsync(data)
          .then(() => onSubmitCallback())
      })
  }, [])
}

const AddException = () => {
  const [isModal, setIsModal] = useState(false)
  const formReturn = useFormInit()
  const onSubmit = useOnSubmit(formReturn, () => setIsModal(false))
  const { control, handleSubmit } = formReturn
  return (
    <>
      <ButtonSubmitStyled onClick={() => setIsModal(true)}>
        Add exception
      </ButtonSubmitStyled>
      <ModalGlobalStyles />
      <CustomModal isOpen={isModal} onClose={() => setIsModal(false)}>
        <TitleStyled>Add exception</TitleStyled>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <InputWrapperStyled>
            <FormControlLabel
              label="Source"
              labelPlacement="top"
              control={(
                <CustomInput
                  name="name"
                  control={control}
                  InputProps={{
                    inputProps: {
                      maxLength: 30
                    }
                  }}
                  rules={{
                    required: requiredRule('This field is required.')
                  }}
                />
              )}
            />

            <ValidationsSelect control={control} />
          </InputWrapperStyled>
          <ButtonGroupStyled>
            <ButtonCancelStyled onClick={() => setIsModal(false)}>Cancel</ButtonCancelStyled>
            <ButtonSubmitStyled type="submit">Add</ButtonSubmitStyled>
          </ButtonGroupStyled>
        </FormStyled>
      </CustomModal>
    </>
  )
}

export default AddException
