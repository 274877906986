import styled from 'styled-components/macro'
import React from 'react'
import Modal from '../Modal'
import { useAssessmentContext } from '../../state/useAssessment'
import { flex, fontFamily } from '../../../../../core/styles/mixins'
import { FileCopyIcon } from '../../../../../core/components/icons/FileCopyIcon'
import { useHandleCopy } from '../../../../../core/hooks/useHandleCopy'

const IconWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey600};
  vertical-align: middle;
  display: inline-flex;
`

const TextStyled = styled.div`
  ${fontFamily('Inter')};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  word-break: break-all;
`

export const SuccessModal = () => {
  const { setIsModalOpen, isModalOpen, reConnect: { reConnectLink } } = useAssessmentContext()
  const handleCopy = useHandleCopy()

  return (
    <Modal
      title="Re-connect link was generated"
      isHistoryLog
      content={(
        <TextStyled>
          <span>
            {reConnectLink}
          </span>
          <IconWrapper>
            <FileCopyIcon width={20} height={20} onClick={() => handleCopy(reConnectLink)} />
          </IconWrapper>
        </TextStyled>
      )}
      renderActionButton={() => null}
      isOpen={isModalOpen.reconnectLinkSuccess}
      handleClose={() => setIsModalOpen({ reconnectLinkSuccess: false })}
    />
  )
}
export default SuccessModal
