import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import styled from 'styled-components/macro'
import { fontFamily } from '../../../core/styles/mixins'
import { useApplicationHeaderContext } from '../state/useApplicationHeader'

const LoanDescriptionStyled = styled.div`
  ${fontFamily('Inter')};
  padding-bottom: 8px;
  width: 100%;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: default;
`

const LoanDescription: ForwardRefRenderFunction<HTMLDivElement | null> = (_, ref) => {
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()

  const isDebtConsolidation = headerData?.loanPurpose?.toLowerCase() === 'debt consolidation'

  if (!headerData?.loanPurposeDescription) {
    return null
  }

  return (
    <LoanDescriptionStyled ref={ref}>
      <>
        {isDebtConsolidation && (
          <>
            Debt consolidation:
            {' '}
          </>
        )}
        {headerData.loanPurposeDescription}
      </>
    </LoanDescriptionStyled>
  )
}

export default forwardRef(LoanDescription)
