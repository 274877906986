import styled from 'styled-components/macro'
import { FC } from 'react'
import { LegendProps } from 'recharts'
import { flex, fontFamily } from '../../styles/mixins'
import { formatLegendText, getTooltipContent } from '../../utils/legendUtils'
import { TChartAccountBalance } from '../../../features/applications/overview/types'
import CustomTooltip from '../CustomTooltip'
import { InformationIcon } from '../icons/InformationIcon'
import ChartTooltipContent from './ChartTooltipContent'
import { formatToPound } from '../../utils/currencyFormat'

const LegendListStyled = styled.ul`
    ${flex({ justify: 'flex-start' })};
    list-style-type: none;


    display: grid;
    justify-items: start;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-column-gap: 12px;
    margin: -24px 0 0 44px;
`

const LegendItemStyled = styled.li<{ isOpacity?: boolean }>`

    opacity: ${({ isOpacity }) => (isOpacity ? 0.5 : 1)};
    margin: 0;
`

const SectionStyled = styled.div`
    ${flex({ justify: 'flex-start', align: 'flex-start' })};

`

const WrapStyled = styled.div`
    ${flex({ justify: 'flex-start' })};
    flex-direction: column;
`

const LabelStyled = styled.span<{ isTextGrey?: boolean, noMargin?: boolean }>`
    ${fontFamily('Inter')};
    ${flex({})}
    color: ${({ theme, isTextGrey }) => (isTextGrey ? theme.colors.grey600 : theme.colors.darkBlack)};
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-left: ${({ noMargin }) => (noMargin ? 0 : 8)}px;
    overflow-wrap: break-word;
    text-transform: capitalize;

`

const CircleStyled = styled.div<TProps>`
    width: 14px;
    min-width: 14px;
    max-width: 14px;
    height: 14px;
    border-radius: 50px;
    background: ${({ color }) => color};
    margin-top: ${({ marginTop }) => (marginTop ? 4 : 0)}px;
`

const LegendValueStyled = styled.h3`
    ${fontFamily('Inter')};
    font-size: 18px;
    margin: 3px 0 0 20px;
    line-height: 22px;
    font-weight: bold;
`

const IconWrapper = styled.div<{ marginLeft?: boolean }>`
    ${flex({ justify: 'center', align: 'flex-start' })};
    margin-top: 2px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.grey600};
    margin-left: ${({ marginLeft }) => (marginLeft ? 6 : 0)}px;
`

const DescriptionStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    margin-left: 22px;
`
const AccountNameStyled = styled.p`
    ${fontFamily('Inter')};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.grey600};
    margin-left: 8px;
`

const SmallTextStyled = styled.p`
    ${fontFamily('Inter')};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.grey600};
`

const LabelWrapStyled = styled.div`
    display: flex;
    flex-direction: column;
`

export type TLegendProps = {
  label?: string
  approvedTotal?: number,
  declinedTotal?: number,
  cancelledTotal?: number,
  failedTotal?: number
  legendAdditionalInfo?: TChartAccountBalance[]
} & LegendProps

type TProps = {
  color?: string
  marginTop: boolean
}

type TTooltipContent = {
  key: string
  value: string
}
const ChartLegendBalance: FC<TLegendProps> = ({
  payload,
  label,
  approvedTotal,
  declinedTotal,
  cancelledTotal,
  failedTotal,
  legendAdditionalInfo
}) => {
  const TOTALS = [approvedTotal, declinedTotal, cancelledTotal, failedTotal]
  return (
    <LegendListStyled>
      {
        payload?.map(({ value, color }, idx) => {
          const { legendText, opacity, found } = formatLegendText(value, legendAdditionalInfo)

          const tooltipContent = legendAdditionalInfo?.length
            && getTooltipContent(legendAdditionalInfo, found?.accountId)
          const isAnyLabel = found?.institutionName || found?.accountMask
          return (
            <LegendItemStyled key={value} isOpacity={!opacity && opacity !== undefined}>
              <WrapStyled>
                <SectionStyled>
                  <CircleStyled marginTop={!!isAnyLabel} color={color} />
                  <LabelWrapStyled>
                    <LabelStyled>
                      {
                        isAnyLabel && (
                          <>
                            {`${found?.institutionName ?? ''} ${found?.accountMask ? `·  ${found?.accountMask}` : ''}`}
                          </>
                        )
                      }
                      {
                        tooltipContent && tooltipContent?.length ? (
                          <CustomTooltip
                            arrow
                            disableFocusListener
                            disableTouchListener
                            title={(
                              <ChartTooltipContent
                                tooltipContent={tooltipContent as TTooltipContent[]}
                              />
                            )}
                            placement="top"
                          >
                            <IconWrapper marginLeft={!!isAnyLabel}>
                              <InformationIcon width={16} height={16} />
                            </IconWrapper>
                          </CustomTooltip>
                        ) : null
                      }
                    </LabelStyled>
                    <AccountNameStyled>{legendText}</AccountNameStyled>
                  </LabelWrapStyled>

                </SectionStyled>
                <DescriptionStyled>
                  {/* <SmallTextStyled>{`Account Type: ${found?.accountType}`}</SmallTextStyled> */}
                  {!!found?.accountLimit && (
                    <SmallTextStyled>{`Overdraft limit: ${formatToPound(found?.accountLimit)}`}</SmallTextStyled>
                  )}

                </DescriptionStyled>
              </WrapStyled>

              <LegendValueStyled>{TOTALS[idx]}</LegendValueStyled>
            </LegendItemStyled>
          )
        })
      }
    </LegendListStyled>
  )
}

export default ChartLegendBalance
