import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { ScoreType, TDetailedScoreTable } from './types'
import { apiClient } from '../../../core/api/apiClient'
import { TSortBy } from '../../../core/components/table/TableContext'

export enum QueryKey {
  BorrowerScore = 'BorrowerScore',
}

type TPayload = {
  filter: ScoreType
  loanRequestId?: string
  sort: TSortBy
}

async function getBorrowerScore(
  { filter, loanRequestId, sort: sortParams }: TPayload,
  signal?: AbortSignal
): Promise<TDetailedScoreTable> {
  const params = {
    sort: `${sortParams.id},${sortParams.desc ? 'desc' : 'asc'}`,
    filter
  }
  try {
    const response = await apiClient(
      `/dashboard/loan-requests/${loanRequestId}/score`,
      {
        params,
        signal
      }
    )
    return response.data
  } catch (e) {
    throw new Error('Something went wrong ')
  }
}

export function useBorrowerScoreQuery(filter: ScoreType, sort: TSortBy) {
  const { loanRequestId } = useParams()

  const payload: TPayload = {
    loanRequestId,
    filter,
    sort
  }

  return useQuery(
    [QueryKey.BorrowerScore, payload],
    ({ signal }) => getBorrowerScore(payload, signal),
    {
      keepPreviousData: true,
      cacheTime: 0
    }
  )
}
