import { Control, useController } from 'react-hook-form'
import { FC } from 'react'
import { FormControlLabel } from '@mui/material'
import styled from 'styled-components/macro'
import CustomSelect, { CustomSelectWrapper } from '../../../core/components/CustomSelect'
import { fontFamily } from '../../../core/styles/mixins'
import { requiredRule } from '../../../core/utils/formRules'
import { TValidationOptions } from '../types'

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${CustomSelectWrapper} {
    width: 208px;
    margin-top: 0;

    & .MuiOutlinedInput-root {
      height: 40px;

      & .MuiSelect-select {
        padding: 10px 2px 10px 14px;
        width: 160px;
      }
    }
  }
}
`

const LabelStyled = styled(FormControlLabel)`
  .MuiFormControlLabel-root {
    align-items: start;
    justify-content: start;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .MuiTypography-root {
    ${fontFamily('Inter')};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.darkBlack};
    letter-spacing: 0;
  }
`
const ErrorMessageStyled = styled.p`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`

export const options: TValidationOptions[] = [
  {
    label: 'Main account',
    value: 'MAIN_ACCOUNT_VALIDATION'
  },
  {
    label: 'OB declines',
    value: 'OB_DECLINES_VALIDATION'
  },
  {
    label: 'Bureaux&Demographic',
    value: 'DEMOGRAPHIC_AND_BUREAUX_VALIDATION'
  }
]

type TProps = {
  control: Control<any>
}
const ValidationsSelect: FC<TProps> = ({ control }) => {
  const { field: { value: currentValue, onChange }, formState: { errors } } = useController({
    name: 'validationTypes',
    control,
    rules: {
      required: requiredRule('This field is required.')
    }
  })

  const isErrorMessage = !!errors?.validationTypes?.message
  return (
    <WrapperStyled>
      <LabelStyled
        label="Validations to be skipped"
        labelPlacement="top"
        control={(
          <CustomSelect
            multiple
            isError={isErrorMessage}
            width={208}
            options={options}
            placeholder="Select validation(s)"
            currentValue={currentValue}
            handleChange={onChange} />
        )}
      />
      {isErrorMessage && (
        <ErrorMessageStyled>
          {errors.validationTypes.message}
        </ErrorMessageStyled>
      )}
    </WrapperStyled>
  )
}

export default ValidationsSelect
