export type TBorrowerTable = {
  totalScore: number
  positiveScore: number
  negativeScore: number
  [key: string]: number
}

export type TDetailedTableData = {
  description: string
  score: string
  value: number
  key: string
}

export type TDetailedScoreTable = {
  data: TDetailedTableData[]
} & TBorrowerTable

export enum ScoreType {
  totalScore = 'TOTAL',
  negativeScore = 'NEGATIVE',
  positiveScore = 'POSITIVE'
}

export type TKey = 'totalScore' | 'positiveScore' | 'negativeScore'
