import { FC } from 'react'
import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../styles/mixins'

const ContentStyled = styled.div`
  ${fontFamily('Inter')};
  ${flex({ justify: 'flex-start', align: 'center' })}
  color: ${({ theme }) => theme.colors.darkBlack};

`

const KeyTextStyled = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`

const ValueTextStyled = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;
`

const legendTitleMap: any = {
  accountLimit: 'Current account limit:',
  availableBalance: 'Current available balance:',
  currentBalance: 'Current account balance:'
}
type TProps = {
  tooltipContent: {
    key: string
    value: string
  }[]
}
const ChartTooltipContent: FC<TProps> = ({ tooltipContent }) => (
  <>
    {
      tooltipContent?.map(({ key, value }) => (
        <ContentStyled>
          <KeyTextStyled>{legendTitleMap[key]}</KeyTextStyled>
          <ValueTextStyled>{value}</ValueTextStyled>
        </ContentStyled>
      ))
    }
  </>
)

export default ChartTooltipContent
