import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient } from '../../core/api/apiClient'
import { ClearScorePreview, ClearScorePricing } from './types'

const enum QueryKeys {
  ClearScorePreview = 'ClearScorePreview',
  ClearScorePricing = 'ClearScorePricing'
}

interface ClearScorePreviewParams {
  aoScore: number
}

const getClearScorePreview = async (data: ClearScorePreviewParams): Promise<ClearScorePreview> => {
  try {
    const response = await apiClient.post('/configuration/clearscore-pricing/preview', data)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useClearScorePreview = (filterData: ClearScorePreviewParams) => {
  const queryData = useQuery({
    queryKey: [QueryKeys.ClearScorePreview, filterData],
    queryFn: () => getClearScorePreview(filterData),
    keepPreviousData: true
  })

  const { data, isLoading } = queryData

  return { data, isClearScorePreviewLoading: isLoading }
}

const getClearScorePricing = async (): Promise<ClearScorePricing> => {
  try {
    const response = await apiClient.get('/configuration/clearscore-pricing')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useClearScorePricing = () => {
  const queryData = useQuery({
    queryKey: [QueryKeys.ClearScorePricing],
    queryFn: getClearScorePricing
  })

  const { data, isLoading } = queryData

  return { data, isClearScorePricingLoading: isLoading }
}

const updateScorePricingConfiguration = async (data: ClearScorePricing) => {
  try {
    await apiClient.put(`/configuration/clearscore-pricing/${data.id}`, data)
  } catch (error: any) {
    throw new Error(error)
  }
}

export const useUpdateScorePricing = () => {
  const queryClient = useQueryClient()
  const mutationQuery = useMutation({
    mutationFn: updateScorePricingConfiguration,
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.ClearScorePricing])
    }
  })

  const { mutateAsync: updateScorePricingAsync, isLoading: isUpdatingProcess } = mutationQuery
  return { updateScorePricingAsync, isUpdatingProcess }
}

const resetToDefaultScorePricing = async (
  pricingConfigurationId: number
): Promise<ClearScorePricing> => {
  try {
    const res = await apiClient.put(
      `/configuration/clearscore-pricing/${pricingConfigurationId}/default`
    )
    return res.data
  } catch (error: any) {
    throw new Error(error)
  }
}

export const useResetClearScorePricing = () => {
  const queryClient = useQueryClient()
  const mutationQuery = useMutation({
    mutationFn: resetToDefaultScorePricing,
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKeys.ClearScorePricing])
    }
  })

  const {
    mutateAsync: resetScorePricingAsync,
    isLoading: isResettingProcess
  } = mutationQuery
  return { resetScorePricingAsync, isResettingProcess }
}
