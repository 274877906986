import React from 'react'
import styled from 'styled-components/macro'
import { PlayCircleIcon } from '../../../../core/components/icons/PlayCircleIcon'
import { BaseButton } from '../../../../core/components/Button'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'

const StartButtonStyled = styled(BaseButton)`
  && {
    width: 234px;
    padding: 8px 16px;
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    margin-top: 16px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }
  }
`

const AssessmentStart = () => {
  const {
    headerQuery: headerData,
    headerUpdateMutation: { mutate, isLoading: isUpdating }
  } = useApplicationHeaderContext()

  if (!headerData) {
    return null
  }

  return (
    <StartButtonStyled
      onClick={() => {
        if (!isUpdating) {
          mutate({ status: 'IN_PROGRESS' })
        }
      }}
      variant="contained"
      startIcon={<PlayCircleIcon/>}>
      Start the assessment
    </StartButtonStyled>
  )
}

export default AssessmentStart
