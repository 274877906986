import styled from 'styled-components/macro'
import { FC } from 'react'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { TDetails } from '../type'
import { CheckIcon } from '../../../../core/components/icons/CheckIcon'
import { WarningIcon } from '../../../../core/components/icons/WarningIcon'
import { DeclineCircledFilledIcon } from '../../../../core/components/icons/DeclineCircledFilledIcon'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'

type TContainerProps = {
  valid: boolean
}

const ContainerStyled = styled.section<TContainerProps>`
  padding: 8px 16px;
  width: 100%;
  background: ${({ valid, theme }) => (!valid ? theme.colors.red200 : 'transparent')};
  ${flex({ justify: 'space-between', align: 'flex-start' })};
`

const IconContainer = styled.div`
  ${flex({ align: 'center' })};
  margin-right: 8px;
`

const TextStyled = styled.p`
  ${fontFamily('Inter')};
  font-size: 14px;
  line-height:20px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const TextDescription = styled(TextStyled)`
  font-weight: 400;
  width: 100%;
  max-width: 235px;
`

const TextValue = styled(TextStyled)`
  font-weight: 600;
  width: 100%;
  max-width: 90px;
  margin-left: 8px;
  text-align: right;
`

type TProps = {
  details: TDetails[]
}

const FailedListBody: FC<TProps> = ({ details }) => {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  return (
    <>
      {
        details.map(({ description, value, valid }) => {
          const showRedBackground = isSocialCreditOrganization || valid

          return (
            <ContainerStyled valid={showRedBackground}>
              <IconContainer>
                {valid
                  ? <CheckIcon width={20} height={20} />
                  : isSocialCreditOrganization
                    ? <DeclineCircledFilledIcon width={20} height={20} />
                    : <WarningIcon width={20} height={20} />}
              </IconContainer>
              <TextDescription>
                {description}
              </TextDescription>
              <TextValue>
                {value}
              </TextValue>
            </ContainerStyled>
          )
        })
      }
    </>
  )
}

export default FailedListBody
