import { useMemo } from 'react'
import { Column } from 'react-table'
import { format } from 'date-fns'
import styled, { useTheme } from 'styled-components/macro'
import CustomTable from '../../../core/components/table/Table'
import { SLASH_DATE_FORMAT } from '../../../core/const/date'
import { useExceptionQuery } from '../querries'
import Spinner from '../../../core/components/Spinner'
import NoDataComponent, { ContainerStyled } from '../../../core/components/NoDataComponent'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { BodyCellStyled } from '../../../core/components/table/TableBodyRow'
import { HeaderCellStyled } from '../../../core/components/table/TableHeadRow'
import DeleteException from './DeleteException'
import EditException from './EditException'
import CustomSelectChip from '../../../core/components/CustomChipSelect'
import { ExceptionFormProvider, useExceptionFormContext } from '../state/exceptionsState'
import { TException, TExceptionContent } from '../types'
import { prepareValidationOptions, validationTypesMap } from '../utils/prepareValidationOptions'
import { options } from './ValidationsSelect'

const WrapperStyled = styled.div`
  ${HeaderCellStyled} {
    ${fontFamily('Inter')};
    padding: 24px 16px 16px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }

  ${BodyCellStyled} {
    ${fontFamily('Inter')};
    font-weight: 400;
    padding: 0px 14px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    height: 56px;

    :first-child {
      width: 214px;
    }

    :nth-child(2) {
      width: 100px;
    }

    :nth-child(3) {
      width: 316px;
    }
  }
`

const NoDataWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 650px;

  ${ContainerStyled} {
    margin-top: 0;
  }
`

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 650px;
`

const IconWrapStyled = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`
const ValidationWrapStyled = styled.div<{ isChip: boolean }>`
  padding: ${({ isChip }) => (isChip ? 6 : 2)}px;
`
const ExceptionsTable = () => {
  const { data, isLoading } = useExceptionQuery()
  const {
    selectedRow,
    setSelectedRow,
    useFormReturn
  } = useExceptionFormContext()
  const { control } = useFormReturn

  const columns = useMemo<Column<TExceptionContent>[]>(() => [
    {
      Header: 'Source',
      accessor: 'name'
    },
    {
      Header: 'Last updated',
      accessor: 'updatedAt',
      Cell: ({ value }) => <span>{format(new Date(value), SLASH_DATE_FORMAT)}</span>
    },
    {
      Header: 'Validations',
      accessor: 'validationTypes',
      Cell: ({ value: validationTypes, row }) => {
        const isSelectedRow = selectedRow?.id === row.original.id
        return (
          <ValidationWrapStyled isChip={isSelectedRow}>
            {
              isSelectedRow
                ? (
                  <CustomSelectChip
                    isRemovable={useFormReturn.watch('validationTypes').length > 1}
                    handleChange={(value) => (value.length ? value : useFormReturn.watch('validationTypes'))}
                    options={options}
                    name="validationTypes"
                    control={control}
                  />
                )
                : (
                  <span>
                    {validationTypes
                      .map((validationType) => validationTypesMap[validationType])
                      .join(', ')}
                  </span>
                )
            }
          </ValidationWrapStyled>
        )
      }
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ value, row }) => (
        <IconWrapStyled>
          <EditException exceptionId={value} onHandleClick={() => setSelectedRow(row.original)} />
          <DeleteException exceptionId={value} name={row.original.name} />
        </IconWrapStyled>
      )

    }
  ], [data, selectedRow])
  const exceptionData = useMemo(() => data?.content || [], [data])
  return (
    <WrapperStyled>
      <CustomTable
        columns={columns}
        data={exceptionData}
        showRowBorder
      />
      {isLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {(!exceptionData?.length) && (
        <NoDataWrapper>
          <NoDataComponent iconWidth={52} iconHeight={48} text="There are no exceptions yet" />
        </NoDataWrapper>
      )}
    </WrapperStyled>
  )
}

export default () => (
  <ExceptionFormProvider>
    <ExceptionsTable />
  </ExceptionFormProvider>
)
