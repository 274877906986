import React, { FC, useEffect, useMemo, useState } from 'react'
import Tab from '../../../core/components/tabs/Tab'
import TabCustom from '../../../core/components/tabs/Tabs'
import { useValidationRulesContext } from '../state/validationRulesState'
import { TFilter } from '../types'
import { useIsPlendOrganization } from '../../auth/hooks'

const tabsMap: { name: string; key: TFilter }[] = [
  {
    name: 'Main Account',
    key: 'MAIN_ACCOUNT_VALIDATION'
  },
  {
    name: 'OB Declines',
    key: 'OB_DECLINES_VALIDATION'
  },
  {
    name: 'Bureaux & Demographic',
    key: 'DEMOGRAPHIC_AND_BUREAUX_VALIDATION'
  },
  {
    name: 'Exceptions',
    key: 'EXCEPTIONS'
  }
]

function useTabs() {
  const isPlendOrganization = useIsPlendOrganization()
  return isPlendOrganization ? tabsMap : tabsMap.filter((tab) => tab.key !== 'EXCEPTIONS')
}

function useTabsHandle() {
  const { setFiler, filter } = useValidationRulesContext()
  const tabs = useTabs()
  const initialFilterIndex = tabs.findIndex(({ key }) => key === filter) || 0

  const [filerIndex, setFilerIndex] = useState(initialFilterIndex)

  const filterKey = useMemo(() => tabs[filerIndex].key, [filerIndex])

  useEffect(() => setFiler(filterKey), [filterKey])

  return {
    filerIndex,
    setFilerIndex
  }
}

const Tabs: FC = () => {
  const { filerIndex, setFilerIndex } = useTabsHandle()
  const tabs = useTabs()
  const tabNames = useMemo(() => tabs.map(({ name }) => name), [])

  return (
    <TabCustom
      value={filerIndex}
      onChange={(_, newValue) => setFilerIndex(newValue)}
    >
      {tabNames.map((name) => (
        <Tab key={name} label={name} />
      ))}
    </TabCustom>
  )
}

export default Tabs
