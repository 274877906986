import { useMutation, useQuery } from 'react-query'
import { apiClient } from '../../../core/api/apiClient'
import { TReclassifyParams, TTaxonomyParams, TTaxonomyResponse } from './types'

enum QueryKey {
  Taxonomy = 'Taxonomy'
}

async function reclassify({ loanApplicationId, ...rest }: TReclassifyParams): Promise<void> {
  try {
    await apiClient.post(`/dashboard/loan-requests/${loanApplicationId}/transactions/reclassify`, rest)
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export const useReclassifyMutation = () => useMutation<void, Error, TReclassifyParams>(
  (params) => reclassify(params)
)

async function taxonomy(params: TTaxonomyParams): Promise<TTaxonomyResponse> {
  try {
    const response = await apiClient('/dashboard/loan-requests/taxonomy', { params })
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export const useTaxonomyQuery = ({ firstClass, secondClass }: TTaxonomyParams) => {
  const keys = [QueryKey.Taxonomy, firstClass, secondClass]
  return useQuery<TTaxonomyResponse, Error>(
    keys,
    () => taxonomy({ firstClass, secondClass }),
    {
      enabled: !!firstClass && !!secondClass
    }
  )
}
