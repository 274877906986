import styled from 'styled-components/macro'
import MaUTable from '@mui/material/Table'
import { useTableContext, TableProvider, TTableProviderProps } from './TableContext'
import TableHead from './TableHead'
import TableBody from './TableBody'
import Pagination from './Pagination'
import Spinner from '../Spinner'

type TProps<T extends object = object> = TTableProviderProps<T>

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TableWrapper = styled.div`
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.7;
  z-index: 10;
`

export const TableStyled: any = styled(MaUTable)`
  && {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkBlack};
  }
`

const Table = () => {
  const {
    tableInstance: { getTableProps },
    showLoader
  } = useTableContext()

  return (
    <Wrapper>
      <TableWrapper>
        <TableStyled stickyHeader {...getTableProps()}>
          <TableHead />
          <TableBody />
        </TableStyled>
        {showLoader && <Overlay><Spinner /></Overlay>}
      </TableWrapper>
      <Pagination />
    </Wrapper>
  )
}

export default <T extends object = object>(props: TProps<T>) => (
  <TableProvider {...props}>
    <Table />
  </TableProvider>
)
