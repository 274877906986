import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { VerticalLineIcon } from '../../../../../core/components/icons/VerticalLineIcon'
import Tab from '../../../../../core/components/tabs/Tab'
import Tabs from '../../../../../core/components/tabs/Tabs'
import { useAffordabilityFilterContext } from '../state/useAffordabilityFilterState'
import { TAffordabilityFilterKey } from '../../base/types'
import { useMetricsContext } from '../../base/state/useMetricsState'
import { useMetricsContentContext } from '../../base/state/useMetricsContentState'

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  .MuiTabs-flexContainer {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
`
const TabsStyled = styled(Tabs)`
  margin-left: 24px;
`
const VerticalLineIconStyled = styled.span`
  margin: 0 12px;
`

const Filters = () => {
  const { setFilterKey, filterKey } = useAffordabilityFilterContext()
  const { setActiveMetric, activeMetricKey } = useMetricsContext()
  useMetricsContentContext()

  useEffect(() => {
    const isActiveNonEssential = activeMetricKey === 'NON_ESSENTIAL_EXPENDITURE'
    const isActiveEssential = activeMetricKey === 'NON_DISCRETIONARY_EXPENDITURE'
    const isNotPartDiscretionarySpend = filterKey !== 'PART_DISCRETIONARY_SPEND'
    const isNotCommitted = filterKey !== 'COMMITTED_EXPENDITURE'
    const isNotBasic = filterKey !== 'BASIC_QUALITY_OF_LIVING'
    setFilterKey((prev) => {
      if (isActiveNonEssential && isNotPartDiscretionarySpend) return 'DISCRETIONARY_SPEND'
      if (isActiveEssential && isNotCommitted && isNotBasic) {
        return 'ESSENTIAL_EXPENDITURE'
      }
      return prev
    })
  }, [activeMetricKey])

  const handleClick = (filter: TAffordabilityFilterKey) => {
    setFilterKey(filter)
    const isAffordability = filter === 'PART_DISCRETIONARY_SPEND' || filter === 'DISCRETIONARY_SPEND'
    if (!isAffordability) {
      setActiveMetric('NON_DISCRETIONARY_EXPENDITURE')
    } else {
      setActiveMetric('NON_ESSENTIAL_EXPENDITURE')
    }
  }
  const subTabs: TAffordabilityFilterKey[] = [
    'ESSENTIAL_EXPENDITURE',
    'BASIC_QUALITY_OF_LIVING',
    'COMMITTED_EXPENDITURE',
    'PART_DISCRETIONARY_SPEND',
    'DISCRETIONARY_SPEND']

  return (
    <Wrapper>
      <TabsStyled
        value={subTabs.indexOf(filterKey) * 2}>
        <Tab label="Essential Expenditure" onClick={() => handleClick('ESSENTIAL_EXPENDITURE')} />
        <VerticalLineIconStyled>
          <VerticalLineIcon />
        </VerticalLineIconStyled>
        <Tab label="Basic Quality of Living" onClick={() => handleClick('BASIC_QUALITY_OF_LIVING')} />
        <VerticalLineIconStyled>
          <VerticalLineIcon />
        </VerticalLineIconStyled>
        <Tab label="Committed Expenditure" onClick={() => handleClick('COMMITTED_EXPENDITURE')} />
        <VerticalLineIconStyled>
          <VerticalLineIcon />
        </VerticalLineIconStyled>
        <Tab label="Part Discretionary Spend" onClick={() => handleClick('PART_DISCRETIONARY_SPEND')} />
        <VerticalLineIconStyled>
          <VerticalLineIcon />
        </VerticalLineIconStyled>
        <Tab label="Discretionary Spend" onClick={() => handleClick('DISCRETIONARY_SPEND')} />
      </TabsStyled>
    </Wrapper>
  )
}

export default Filters
