import { FC } from 'react'
import { IconWrapper } from './base/IconWrapper'
import { ReactComponent as Icon } from './svg/Close.svg'
import { TIconProps } from './types'

export const CloseIcon: FC<TIconProps> = ({ onClick }: any) => (
  <IconWrapper onClick={onClick}>
    <Icon />
  </IconWrapper>
)
