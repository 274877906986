import styled from 'styled-components/macro'
import SectionCard from './SectionCard'
import { AmountStyled } from './styled'
import { useOverview } from '../queries'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import Spinner from '../../../../core/components/Spinner'
import { flex } from '../../../../core/styles/mixins'

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  width: 100%;
  margin: 35px 0;
`

const Income = () => {
  const { data, isIdle, isLoading } = useOverview()
  if (!data || isIdle || isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner/>
      </SpinnerWrapper>
    )
  }
  const { averageIncome } = data
  return (
    <>
      <SectionCard navigationUrl="income" label="Income" caption="Average reliable income (90 days)"/>
      <AmountStyled>{formatToPound(averageIncome)}</AmountStyled>
    </>
  )
}

export default Income
