import { useQueryClient } from 'react-query'
import { useCallback } from 'react'
import { QueryKey } from 'react-query/types/core/types'

export function useQueryLoadingStateReset(queryKey: QueryKey) {
  const queryClient = useQueryClient()

  return useCallback(() => {
    queryClient
      .getQueryCache()
      .findAll(queryKey)
      .forEach((query) => {
        query.setState({ ...query.state, status: 'loading' })
      })
    queryClient.invalidateQueries(queryKey)
  }, [queryKey])
}
