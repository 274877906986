export function prepareAdditionalDeclineReasonsCode(
  additionalDeclineReasons?: Record<string, boolean>
): string[] {
  if (!additionalDeclineReasons) {
    return []
  }
  return Object.entries(additionalDeclineReasons)
    .reduce(
      (acc, [key, value]) => {
        if (value) {
          return [...acc, key]
        }
        return acc
      },
      [] as string[]
    )
}
