import React from 'react'
import styled from 'styled-components/macro'
import Modal from './Modal'
import { useAssessmentContext } from '../state/useAssessment'
import { BaseButton } from '../../../../core/components/Button'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { useTabsVisited } from '../queries'
import { fontFamily } from '../../../../core/styles/mixins'
import { RevertEmptyIcon } from '../../../../core/components/icons/RevertEmptyIcon'

const RevertButtonStyled = styled(BaseButton)`
  && {
    width: 144px;
    height: 32px;
    padding: 6px 12px;
    margin-right: 16px;
    ${fontFamily('Inter')};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const Title = styled.span`
margin-left: 8px;
`

const AssessmentRevertToNew = () => {
  const {
    setIsModalOpen,
    isModalOpen
  } = useAssessmentContext()
  const {
    headerUpdateMutation: { mutate, isLoading: isUpdating }
  } = useApplicationHeaderContext()

  const { refetch, data: isAllTabVisited } = useTabsVisited()

  return (
    <>
      <RevertButtonStyled
        onClick={() => {
          refetch().then(() => setIsModalOpen({ revert: true }))
        }}>
        <RevertEmptyIcon/>
        <Title>Revert to New</Title>
      </RevertButtonStyled>
      <Modal
        title="Revert application to 'New' status"
        content="Are you sure you want to revert this application to 'New' status?"
        buttonText="Revert"
        error={!isAllTabVisited || false}
        queryRequest={() => {
          if (!isUpdating) {
            mutate({ status: 'NEW' })
            setIsModalOpen({ revert: false })
          }
        }}
        isOpen={isModalOpen.revert}
        handleClose={() => setIsModalOpen({ revert: false })} />
    </>
  )
}

export default AssessmentRevertToNew
