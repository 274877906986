import styled from 'styled-components/macro'
import { FC } from 'react'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { CheckIcon } from '../../../../core/components/icons/CheckIcon'
import { WarningIcon } from '../../../../core/components/icons/WarningIcon'
import { DeclineCircledFilledIcon } from '../../../../core/components/icons/DeclineCircledFilledIcon'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'

const ContainerStyled = styled.section`
  ${flex({ align: 'center' })};
  width: 100%;
  padding: 16px;
`

const IconContainer = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  margin-right: 8px;
`

const TextStyled = styled.h3`
  ${fontFamily('Inter')};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`

const DeclineIconWrap = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  color: ${({ theme }) => theme.colors.red};
`

type TProps = {
  status: boolean
  text: string
}

const FailedListHeader: FC<TProps> = ({ status, text }) => {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  return (
    <ContainerStyled>
      <IconContainer>
        {status
          ? <CheckIcon width={24} height={24} />
          : isSocialCreditOrganization
            ? <DeclineIconWrap><DeclineCircledFilledIcon width={24} height={24} /></DeclineIconWrap>
            : <WarningIcon width={24} height={24} />}
      </IconContainer>
      <TextStyled>
        {text}
      </TextStyled>
    </ContainerStyled>
  )
}

export default FailedListHeader
