import constate from 'constate'
import { useSetState } from 'react-use'
import { useState } from 'react'
import { useReconnectRulesQuery } from '../queries'

type TModalState = {
  approve: boolean
  decline: boolean
  revert: boolean
  cancel: boolean
  generateSccLink: boolean
  getReconnectLink: boolean
  reconnectLinkSuccess: boolean
  declineAdditionalReasons: boolean
}

function useReConnect() {
  const [reConnectLink, setReConnectLink] = useState<string>()

  return {
    reConnectLink,
    setReConnectLink,
    reconnectRulesQuery: useReconnectRulesQuery()
  }
}

function useAssessment() {
  const [isModalOpen, setIsModalOpen] = useSetState<TModalState>({
    approve: false,
    decline: false,
    revert: false,
    cancel: false,
    generateSccLink: false,
    getReconnectLink: false,
    reconnectLinkSuccess: false,
    declineAdditionalReasons: false
  })

  return {
    isModalOpen,
    setIsModalOpen,
    reConnect: useReConnect()
  }
}

export const [
  AssessmentProvider,
  useAssessmentContext
] = constate(useAssessment)
