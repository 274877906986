import { useMemo } from 'react'
import { Column, Row } from 'react-table'
import styled from 'styled-components/macro'
import Table from '../../../../core/components/table/Table'
import { fontFamily } from '../../../../core/styles/mixins'
import { TitleStyled } from '../../../applications/applicationDetail/components/ApplicationDetail'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { useScoringContext } from '../state/useScoring'
import Spinner from '../../../../core/components/Spinner'
import { TVersionData } from '../types'
import { CheckFilledIcon } from '../../../../core/components/icons/CheckFilledIcon'
import HistoryDescription from './HistoryDescription'

const Wrapper = styled.div`
  ${fontFamily('Inter')};
  height: 85%;
  flex: 0.55;
  overflow: hidden;
  && .MuiTableCell-head {
    padding: 0;
  }
  && .Mui-selected {
    background-color: ${({ theme }) => theme.colors.blue200};
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue200};
    }
  }
  ${TitleStyled} {
    padding: 24px 24px 15px 24px;
  }

  ${HeaderCellStyled} {
    padding: 0;
  }

  ${BodyCellStyled} {
    ${fontFamily('Inter')};
    padding: 12px 24px;
  }
`

const LabelPreviewStyled = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 32px;
    padding: 6px 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.darkBlack};
    border-radius: 3px;
  }
`

const LabelText = styled.span`
  margin-left: 9px;
`

const SpinnerWrapperStyled = styled.div`
  flex: 0.55;
`

const VersionHistory = () => {
  const {
    historyVersionQuery: { data, isIdle, isLoading },
    setCurrentPage,
    currentPage,
    selectedVersion,
    setSelectedVersion,
    setIsPreviewing
  } = useScoringContext()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: '',
        accessor: 'lastUpdate',
        Cell: ({ row: { original: data } }) => <HistoryDescription data={data as TVersionData} />
      },
      {
        Header: ' ',
        accessor: '',
        Cell({ row: { index, isSelected } }) {
          const isFirstPage = currentPage === 1

          if (index === 0) setIsPreviewing(isFirstPage && isSelected)

          return (
            <>
              <LabelPreviewStyled>
                {isSelected && isFirstPage && !index
                  ? null
                  : isSelected
                    && (index || !isFirstPage) && (
                    <>
                      <CheckFilledIcon />
                      <LabelText>Previewing</LabelText>
                    </>
                  )}
              </LabelPreviewStyled>
            </>
          )
        }
      }
    ],
    [currentPage]
  )

  if (!data || isIdle || isLoading) {
    return (
      <SpinnerWrapperStyled>
        <Spinner />
      </SpinnerWrapperStyled>
    )
  }

  return (
    <Wrapper>
      <TitleStyled>Version history</TitleStyled>
      <Table
        columns={columns}
        data={data.content}
        selectionConfig={
          selectedVersion && {
            rowIdentityProp: 'createdAt',
            selectedRowIds: selectedVersion?.createdAt
          }
        }
        onRowClick={({ original }: Row<TVersionData>) => {
          setSelectedVersion(original)
        }}
        paginationConfig={{
          isEnabled: data.totalPages > 1,
          pageSize: 5,
          manual: true,
          pageCount: data.totalPages,
          totalRows: data.totalElements,
          onPageChange: (page) => setCurrentPage(page)
        }}
      />
    </Wrapper>
  )
}

export default VersionHistory
