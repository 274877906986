import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle
} from '@mui/material'
import { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { CloseLineIcon } from '../../../../core/components/icons/CloseLineIcon'
import { BaseButton } from '../../../../core/components/Button'

const Dialog = styled.div`
  ${flex({ align: 'flex-start' })};
  position: relative;
  width: 480px;
  background: ${({ theme }) => theme.colors.white};;
  border: none;
  outline: none;
  box-shadow: ${({ theme }) => theme.colors.shadow200};
  border-radius: 6px;
  padding: 24px;
  margin: 0;
`

const IconContainerStyled = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const DialogTitle = styled(MuiDialogTitle)`
  && {
    ${fontFamily('Inter')};
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 0;
  }
`

const DialogContent = styled(MuiDialogContent)`
  && {
    padding: 0;
    margin: 24px 0 32px 0;
    &.MuiDialogContent-root{
      width: 100%;
    }
  }
`

const DialogContentText = styled(MuiDialogContentText)`
  && {
    ${fontFamily('Inter')};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.darkBlack};

  }
`

const ErrorText = styled.p`
  ${fontFamily('Inter')};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.red};
`

const DialogActions = styled(MuiDialogActions)`
{
  align-self: flex-end;
  padding: 0 !important;
}
`

export const ButtonCancelStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    margin-right: 8px;
    padding: 8px 16px;
    max-width: 98px;
    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const ButtonActionStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    margin-left: -8px;
    padding: 8px 16px;
    max-width: 98px;
    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }
    
    &:disabled {
      color: ${({ theme }) => theme.colors.white};
      opacity: .5;
    }
  }
`

type TProps = {
  isOpen: boolean
  handleClose: () => void
  queryRequest?: () => void
  content: string | ReactNode
  title: string
  isActionDisabled?: boolean
  error?: boolean
  buttonText?: string
  closeButtonText?: string
  isHistoryLog?: boolean
  renderActionButton?: () => ReactNode,
}

const Modal: FC<TProps> = ({
  content,
  title,
  error,
  isActionDisabled,
  buttonText,
  closeButtonText,
  isOpen,
  handleClose,
  queryRequest,
  renderActionButton,
  isHistoryLog
}) => (
  <MuiDialog
    sx={{ overflow: 'hidden' }}
    open={isOpen}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    PaperComponent={Dialog}
  >
    <IconContainerStyled onClick={handleClose}>
      <CloseLineIcon />
    </IconContainerStyled>
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {error && <ErrorText>You haven't reviewed each data section. </ErrorText>}
          {content}
        </>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <ButtonCancelStyled onClick={handleClose}>{closeButtonText || (isHistoryLog ? 'Close' : 'Cancel')}</ButtonCancelStyled>
      {!renderActionButton && !isHistoryLog && (
        <ButtonActionStyled onClick={queryRequest} disabled={isActionDisabled} autoFocus>
          {buttonText}
        </ButtonActionStyled>
      )}
      {renderActionButton && renderActionButton()}
    </DialogActions>
  </MuiDialog>
)
export default Modal
