import styled from 'styled-components/macro'
import { usePartnerDashboardContext } from '../state/PartnerDashboardState'
import CustomSearch, { FormStyled } from '../../../core/components/CustomSearch'

const WrapperStyled = styled.div`
  margin-bottom: 8px;

  ${FormStyled} {
    margin-left: 0;
  }
`
const Search = () => {
  const { setSearch } = usePartnerDashboardContext()

  return (
    <WrapperStyled>
      <CustomSearch
        placeholder="Search name..."
        setSearch={setSearch} />
    </WrapperStyled>

  )
}

export default Search
