import { Dispatch, FC, SetStateAction } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { TooltipProps } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { humanizeTime } from '../../utils/formatUtils'
import { formatToPound } from '../../../../core/utils/currencyFormat'

const ToolTipStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 3px;
  max-height: 100px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.colors.shadow200};
`

const LabelStyled = styled.h4`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
`

const TooltipInfoStyled = styled.div<TooltipInfoProps>`
  ${flex({ align: 'center' })}
  color: ${({ color }) => color};
`

const ToolTipXValue = styled.p`
  ${fontFamily('Inter')};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`

const ToolTipYValueStyled = styled.p`
  ${fontFamily('Inter')};
  margin-right: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

type TTooltipProps = {
  type?: string
  formatType: 'statistics' | 'loanSize' | 'time'
  setTooltipActive?: Dispatch<SetStateAction<boolean | undefined>>
} & TooltipProps<ValueType, NameType>

type TooltipInfoProps = {
  color?: string
}

const formatValues = {
  statistics: {
    formatter: (value?: ValueType) => Number(value)
  },
  loanSize: {
    formatter: (value?: ValueType) => (value ? formatToPound(Number(value)) : '0')
  },
  time: {
    formatter: (value?: ValueType) => (value ? humanizeTime(value) : 0)
  }
}

const Tooltip: FC<TTooltipProps> = ({
  active,
  payload,
  type,
  formatType,
  setTooltipActive
}) => {
  const isYValue = type === 'line'
  const { colors: { darkBlack } } = useTheme()
  if (setTooltipActive) { setTooltipActive(active) }
  if (active && payload) {
    const { date } = payload[0].payload
    return (
      <ToolTipStyled>
        <LabelStyled>{date}</LabelStyled>
        <>
          {
            payload.map(({ color, value, name }) => {
              const result = formatValues[formatType].formatter(value)
              return (
                <TooltipInfoStyled key={color} color={isYValue ? darkBlack : color}>
                  <ToolTipYValueStyled>
                    {result}
                  </ToolTipYValueStyled>
                  {!isYValue && <ToolTipXValue>{name}</ToolTipXValue>}
                </TooltipInfoStyled>
              )
            })
          }
        </>
      </ToolTipStyled>
    )
  }
  return null
}

export default Tooltip
