export const colors: string[] = [
  '#00A870',
  '#5F7292',
  '#D35A21',
  '#8F0D8A',
  '#fe1e06',
  '#095733',
  '#cbb354',
  '#560433',
  '#980bca',
  '#3d2a4a',
  '#96847e',
  '#3634b2',
  '#6D460D',
  '#E34D59',
  '#0052D9',
  '#a12d0b',
  '#c870f0',
  '#e3cf37',
  '#fb1bc0',
  '#264358',
  '#d8a5a3',
  '#729d76',
  '#e5b334',
  '#148768',
  '#99e3b2',
  '#62a3f0',
  '#cb5a87',
  '#001F97',
  '#095733',
  '#fbc19f'
]
