import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import Card, { CardStyled } from '../../../../core/components/Card'
import { ArrowLeftIcon } from '../../../../core/components/icons/ArrowLeftIcon'
import InnerLayout from '../../../../core/components/InnerLayout'
import { TitleStyled } from '../../../applications/applicationDetail/components/ApplicationDetail'
import { ScoringProvider, useScoringContext } from '../state/useScoring'
import Rules from './Rules'
import VersionHistory from './VersionHistory'

const WrapStyled = styled.div`
  ${CardStyled} {
    width: 100%;
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    overflow: auto;
  }
`

const IconWrapper = styled.span`
  margin-right: 20px;
  cursor: pointer;
`

const ScoringEdit = () => {
  const navigate = useNavigate()
  const { configId } = useParams()
  const { scoringConf: { data: scoringConfigData } } = useScoringContext()
  const description = useMemo(
    () => scoringConfigData?.find((elem) => String(elem.id) === configId)?.description,
    [scoringConfigData]
  )

  return (
    <ScoringProvider>
      <WrapStyled>
        <InnerLayout
          header={(
            <TitleStyled>
              <IconWrapper onClick={() => navigate(-1)}>
                {' '}
                <ArrowLeftIcon />
              </IconWrapper>
              {description}
            </TitleStyled>
          )}
        >
          <>
            <Card noPadding>
              <Rules />
              <VersionHistory />
            </Card>
          </>
        </InnerLayout>
      </WrapStyled>
    </ScoringProvider>
  )
}

export default () => (
  <ScoringProvider>
    <ScoringEdit/>
  </ScoringProvider>
)
