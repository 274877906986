import { Control, useController } from 'react-hook-form'
import { FC } from 'react'
import styled from 'styled-components/macro'
import CustomSelect, { CustomSelectWrapper, TOption } from '../../../../core/components/CustomSelect'

const WrapperStyled = styled.div`
  width: 100%;

  ${CustomSelectWrapper} {
    max-width: 100%;
    margin-top: 0;
  }
`

type TProps = {
  name: string,
  control: Control<any>
  options: TOption[]
  disabled?: boolean
  reset?: () => void
}

const OBSelect: FC<TProps> = ({ control, name, options, disabled, reset }) => {
  const { field: { value: currentValue, onChange } } = useController({
    name,
    control
  })

  return (
    <WrapperStyled>
      <CustomSelect
        disabled={disabled}
        options={options}
        currentValue={currentValue}
        handleChange={(value) => {
          onChange(value)
          reset?.()
        }} />
    </WrapperStyled>
  )
}

export default OBSelect
