import React, { FC } from 'react'
import { Dialog as MuiDialog, DialogActions as MuiDialogActions, DialogTitle as MuiDialogTitle } from '@mui/material'
import styled from 'styled-components/macro'
import { fontFamily } from '../../../../core/styles/mixins'
import { BaseButton } from '../../../../core/components/Button'
import { CloseLineIcon } from '../../../../core/components/icons/CloseLineIcon'

const Dialog = styled.div`
  width: 480px;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  outline: none;
  box-shadow: ${({ theme }) => theme.colors.shadow200};
  border-radius: 6px;
  padding: 24px;
  margin: 0;
`

const DialogTitle = styled(MuiDialogTitle)`
  && {
    ${fontFamily('Inter')};
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 0;
    margin-bottom: 24px;
  }
`

const SubTitleStiled = styled.div`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const IconContainerStyled = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlack};
`
export const DialogActions = styled(MuiDialogActions)`
  padding: 0 !important;
`

const ButtonDiscardStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 8px 16px;
    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const ButtonSaveStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px 16px;
    margin-left: 18px;
    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }
    &:disabled{
      opacity: .5;
      color: ${({ theme }) => theme.colors.white};
      pointer-events: all;
      cursor: not-allowed;
    }
  }
`

type TProps = {
  isOpen: boolean
  isDisabled: boolean
  handleClose: () => void
  handleDiscard: () => void
  handleSave: () => void
}

const Modal: FC<TProps> = ({ isOpen, isDisabled, handleClose, handleDiscard, handleSave }) => (
  <MuiDialog open={isOpen} onClose={handleClose} transitionDuration={0}>
    <Dialog>
      <DialogTitle>Save changes?</DialogTitle>
      <IconContainerStyled onClick={handleClose}>
        <CloseLineIcon />
      </IconContainerStyled>
      <SubTitleStiled>Do you want to save your changes before leaving this page?</SubTitleStiled>
      <DialogActions>
        <ButtonDiscardStyled
          onClick={() => {
            handleDiscard()
            handleClose()
          }}
        >
          Discard changes
        </ButtonDiscardStyled>
        <ButtonSaveStyled type="submit" onClick={handleSave} disabled={isDisabled}>
          Save changes
        </ButtonSaveStyled>
      </DialogActions>
    </Dialog>
  </MuiDialog>
)

export default Modal
