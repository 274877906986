import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components/macro'
import CustomInput from '../../../core/components/CustomInput'
import { fontFamily } from '../../../core/styles/mixins'
import useOnBlur from '../hooks/useOnBlur'
import { ClearScorePricing } from '../types'
import { percentValidationRule } from '../utils/percentValidation'

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Title = styled.span`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const Label = styled.span`
  font-size: 16px;
  line-height: 32px;
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
`

const CellMonthHeader = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  text-align: center;
  height: 20px;
  margin-bottom: -4px;
`

const FinancialMetrics = () => {
  const { control, setValue } = useFormContext<ClearScorePricing>()
  const onBlur = useOnBlur(setValue)
  const { fields } = useFieldArray({
    control,
    name: 'financialMetrics',
    keyName: 'key'
  })

  return (
    <>
      <Title>Financial Metrics:</Title>
      <Row>
        <Col>
          <CellMonthHeader />
          <Label>Cost of Funds, %</Label>
          <Label>Loan Management, %</Label>
        </Col>
        {fields.map((field, index) => (
          <Col key={field.key}>
            <CellMonthHeader>
              {field.term}
              {' '}
              mo
            </CellMonthHeader>
            <CustomInput
              type="number"
              control={control}
              name={`financialMetrics.${index}.costOfFunds`}
              rules={percentValidationRule}
              onBlur={onBlur(`financialMetrics.${index}.costOfFunds`, 1)}
            />
            <CustomInput
              type="number"
              control={control}
              name={`financialMetrics.${index}.loanManagement`}
              rules={percentValidationRule}
              onBlur={onBlur(`financialMetrics.${index}.loanManagement`, 1)}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

export default FinancialMetrics
