import React from 'react'
import styled, { css } from 'styled-components/macro'

export type TActionMarker = 'neutral' | 'positive' | 'negative' | 'warning' | 'ambition' | 'calm' | 'warm'

const positiveMixin = css`
  background: ${({ theme }) => theme.colors.green300};
  color: ${({ theme }) => theme.colors.green800};
`
const negativeMixin = css`
  background: ${({ theme }) => theme.colors.red300};
  color: ${({ theme }) => theme.colors.red800};
`
const warningMixin = css`
  background: ${({ theme }) => theme.colors.yellow300};
  color: ${({ theme }) => theme.colors.yellow800};
`
const neutralMixin = css`
  background: ${({ theme }) => theme.colors.blue300};
  color: ${({ theme }) => theme.colors.blue800};
`
const ambitionMixin = css`
  background: ${({ theme }) => theme.colors.violet100};
  color: ${({ theme }) => theme.colors.chart.violet};
`

const calmMixin = css`
  background: ${({ theme }) => theme.colors.cyan};
  color: ${({ theme }) => theme.colors.darkTeal};
`

const warmMixin = css`
  background: ${({ theme }) => theme.colors.apricot};
  color: ${({ theme }) => theme.colors.deepRust};
`

const WrapStyled = styled.span<{ actionMarker: TActionMarker }>`
  font-size: 14px;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 3px;
  white-space: nowrap;

  ${({ actionMarker }) => {
    switch (actionMarker) {
    case 'positive':
      return positiveMixin
    case 'negative':
      return negativeMixin
    case 'warning':
      return warningMixin
    case 'ambition':
      return ambitionMixin
    case 'calm':
      return calmMixin
    case 'warm':
      return warmMixin
    case 'neutral':
    default:
      return neutralMixin
    }
  }};
`

type TProps = {
  actionMarker: TActionMarker
}

const StatusBadge: React.FC<TProps> = ({ actionMarker, children }) => (
  <WrapStyled actionMarker={actionMarker}>{children}</WrapStyled>
)

export default StatusBadge
