import constate from 'constate'
import { useEffect, useState } from 'react'
import { useDownloadMutation, useKeywordsQuery, useProgressQuery, useStatusQuery, useUploadMutation } from '../queries'
import { toBase64 } from '../utils/querieUtils'

function useUpload(onMutateCallback: () => void) {
  const [fileName, setFileName] = useState<string>('')
  const [uploadedFileId, setUploadedFileId] = useState<number>()

  const {
    mutateAsync,
    isLoading,
    isError,
    isSuccess,
    reset
  } = useUploadMutation(onMutateCallback)
  const { data: progressbarValue, isIdle } = useProgressQuery(uploadedFileId)
  const { data: statusValue, isSuccess: isStatusSuccess } = useStatusQuery()

  useEffect(() => {
    if (statusValue) {
      setUploadedFileId(statusValue?.id)
    }
  }, [statusValue])

  const uploadFile = (files: File[], callback?: () => void) => {
    if (!files || !files.length) {
      return
    }

    const file = files[0]

    setFileName(file.name)
    toBase64(file).then(() => {
      mutateAsync({ file })
        .then(({ id }: any) => {
          callback?.()
          setUploadedFileId(id)
        })
    })
  }

  const uploadReset = () => {
    reset()
    setFileName('')
  }

  return {
    uploadFile,
    uploadReset,
    fileName,
    uploadedFileId,
    setUploadedFileId,
    isLoading,
    isError,
    isSuccess,
    progressbarValue,
    isIdle,
    statusValue,
    isStatusSuccess
  }
}

function useKeywords() {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const keywordsQuery = useKeywordsQuery(currentPage - 1)

  return {
    upload: useUpload(() => keywordsQuery.refetch()),
    currentPage,
    setCurrentPage,
    keywordsQuery,
    downloadMutation: useDownloadMutation()
  }
}

export const [KeywordsProvider, useKeywordsContext] = constate(useKeywords)
