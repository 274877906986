import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { apiClient } from '../../../core/api/apiClient'
import { TSortBy } from '../../../core/components/table/TableContext'
import { prepareTablePaginationRequestParams } from '../../../core/utils/tableData'
import { TGamblingData, TTransactionData } from './types'
import { useQueryLoadingStateReset } from '../../../core/hooks/useQueryLoadingStateReset'

export enum QueryKey {
  Gambling = 'Gambling',
  Transactions = 'Transactions'
}

export function useRisksQuery() {
  const { loanRequestId } = useParams()

  return useQuery<TGamblingData[]>([QueryKey.Gambling], async () => {
    try {
      const { data } = await apiClient(`dashboard/loan-requests/${loanRequestId}/risks`)
      return data
    } catch (error) {
      throw new Error('Error data')
    }
  })
}

function useResetLoadingStateOnCategoryChange(selectedCategory?: TGamblingData) {
  const queryFullReset = useQueryLoadingStateReset(QueryKey.Transactions)

  useEffect(() => {
    if (selectedCategory) {
      queryFullReset()
    }
  }, [selectedCategory])
}

export function useTransactionsQuery(
  sortBy?: TSortBy,
  currentPage?: number | any,
  selectedCategory?: TGamblingData
) {
  const { loanRequestId } = useParams()

  useResetLoadingStateOnCategoryChange(selectedCategory)

  return useQuery<TTransactionData>(
    [QueryKey.Transactions, sortBy, currentPage, selectedCategory, loanRequestId],
    async ({ signal }) => {
      try {
        const { data } = await apiClient.post(
          `dashboard/loan-requests/${loanRequestId}/transactions`,
          {},
          {
            params: {
              category: selectedCategory?.category,
              ...prepareTablePaginationRequestParams(currentPage, 20, sortBy)
            },
            signal
          }
        )
        return data
      } catch (e) {
        throw new Error('useTransactionsQuery')
      }
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      enabled: !!selectedCategory
    }
  )
}
