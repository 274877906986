import { FC, useMemo } from 'react'
import { Control, useController } from 'react-hook-form'
import styled from 'styled-components/macro'
import CustomSelect, { CustomSelectWrapper, SelectMui } from '../../../core/components/CustomSelect'
import { useUsersContext } from '../state/useUserManagement'
import { formattedOrganizations } from '../utils'
import { fontFamily } from '../../../core/styles/mixins'

const Wrapper = styled.div<{ isError: boolean }>`
  &&{
    width: 100%;
    &&{
      ${SelectMui} {
        & > .MuiSelect-select[aria-expanded='true'] ~ fieldset.MuiOutlinedInput-notchedOutline {
        border: 2px solid ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.blue500)};
        }
        & > .MuiSelect-select:focus ~ fieldset.MuiOutlinedInput-notchedOutline {
          border: 1px solid ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.blue500)};
        }
        & > .MuiSelect-select:hover ~ fieldset.MuiOutlinedInput-notchedOutline{
          border: 1px solid ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.darkBlack)};
        }
     }
    }
  }
  
  && .MuiOutlinedInput-root {
    height: 40px;  
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, isError }) => (isError ? theme.colors.red : theme.colors.grey500)};
  }
  
  ${CustomSelectWrapper}{
    margin-top: 0;
  }
  }
`
const ErrorMessage = styled.div`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`

type TProps = {
  control: Control<any>
  name: string
}

const OrganizationsDropdown: FC<TProps> = ({ control, name }) => {
  const { organizationsQuery: { data } } = useUsersContext()
  const { field: { value: currentValue, onChange }, formState: { errors } } = useController({
    name,
    control,
    rules: { required: 'This field is required' } })

  if (!data) {
    return null
  }

  const options = useMemo(() => formattedOrganizations(data) || [], [data])

  return (
    <Wrapper isError={errors.organizationId}>
      <CustomSelect
        currentValue={currentValue}
        handleChange={onChange}
        options={options}

      />
      <ErrorMessage>
        {
          errors.organizationId
            ? errors.organizationId.message
            : ''
        }
      </ErrorMessage>
    </Wrapper>
  )
}

export default OrganizationsDropdown
