import styled from 'styled-components/macro'
import { Checkbox } from '@mui/material'

export const CustomCheckboxStyled = styled(Checkbox)`
  &&& {
    padding: 0;

    & .MuiSvgIcon-root {
      font-size: 20px;
    }

    &.Mui-checked {
      color: ${({ theme }) => theme.colors.blue500};
    }
  }
`
