import React, { useCallback } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { decimalFormat } from '../../../core/utils/formUtils'
import { ClearScorePricing, InputNames } from '../types'

const useOnBlur = (setValue: UseFormReturn<ClearScorePricing>['setValue']) => useCallback(
  (key: InputNames, decimalNumber?: number) => (e: React.FocusEvent<HTMLInputElement>) => {
    const formattedValue = e.target.value && decimalFormat(e.target.value, decimalNumber)
    setValue(key, formattedValue)
  },
  []
)

export default useOnBlur
