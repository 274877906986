import { useCallback, useMemo, useState } from 'react'
import constate from 'constate'
import { TFilter } from '../types'
import { useValidationRulesQuery } from '../../validationRulesBase/querries'

const useApplications = () => {
  const [filter, setFiler] = useState<TFilter>('MAIN_ACCOUNT_VALIDATION')
  const isBureaux = useMemo(() => filter === 'DEMOGRAPHIC_AND_BUREAUX_VALIDATION', [filter])

  return {
    filter,
    setFiler,
    isBureaux,
    validationRulesQuery: useValidationRulesQuery(filter),
    changeFilter: useCallback((filter: TFilter) => setFiler(filter), [])
  }
}

export const [ValidationRulesProvider, useValidationRulesContext] = constate(useApplications)
