import styled from 'styled-components/macro'
import ReactRouterPrompt from 'react-router-prompt'
import Modal from '../Modal'
import { flex, fontFamily } from '../../../../../core/styles/mixins'
import { useApplicationHeaderContext } from '../../../../applicationHeader/state/useApplicationHeader'

const ContentWrapStyled = styled.div`
  ${flex({})};
  flex-direction: column;
`

const TextStyled = styled.p`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const LeaveRescoreAvailableApplication = () => {
  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()

  return (
    <ReactRouterPrompt
      when={({ nextLocation }) => {
        const isNotApplicationDetails = nextLocation.pathname.split('/').length < 3

        return isNotApplicationDetails && !!headerData?.rescoreAvailable
      }}
    >
      {({ isActive, onCancel, onConfirm }) => {
        if (!isActive) {
          return <></>
        }
        return (
          <Modal
            title="Leave application"
            buttonText="Leave"
            isOpen
            handleClose={onCancel}
            queryRequest={onConfirm}
            content={(
              <ContentWrapStyled>
                <TextStyled>
                  Please be aware that you
                  leave the application without starting the rescoring process.
                </TextStyled>
                <TextStyled>
                  Your classification changes will be saved.
                </TextStyled>
              </ContentWrapStyled>
            )}
          />
        )
      }}
    </ReactRouterPrompt>
  )
}
export default LeaveRescoreAvailableApplication
