import { useCallback, useState } from 'react'
import constate from 'constate'
import { TFilter } from '../types'
import { useValidationRulesQuery } from '../../validationRulesBase/querries'

const useApplications = () => {
  const [filter, setFiler] = useState<TFilter>('ELIGIBILITY_PRE_APPROVAL')

  return {
    filter,
    setFiler,
    validationRulesQuery: useValidationRulesQuery(filter),
    changeFilter: useCallback((filter: TFilter) => setFiler(filter), [])
  }
}

export const [EligibilityProvider, useEligibilityContext] = constate(useApplications)
