import { FC, useMemo } from 'react'
import { Control, useController, useWatch } from 'react-hook-form'
import styled from 'styled-components/macro'
import CustomSelect, { CustomSelectWrapper } from '../../../core/components/CustomSelect'
import { TFormData } from '../types'
import { useOptionsContext } from '../state/useOptions'

const Wrapper = styled.div`
  ${CustomSelectWrapper} {
    margin-bottom: 4px;
    margin-top: 0;
  }
`

type TProps = {
  subName: 'rule' | 'daysPeriod' | 'value'
  control: Control<any>
  idx?: number
}

const RuleDropDown: FC<TProps> = ({ subName, control, idx }) => {
  const {
    field: { value: currentValue, onChange }
  } = useController({
    name: `rules.${idx}.${subName}`,
    control
  })
  const rules: TFormData['rules'] = useWatch({ control, name: 'rules' })
  const disabledValues = useMemo(
    () => rules
      .map(({ value }) => value)
      .filter((value) => value !== currentValue),
    [rules]
  )
  const options = useOptionsContext()

  return (
    <Wrapper>
      <CustomSelect
        disabledValues={disabledValues}
        options={options[subName]}
        handleChange={onChange}
        currentValue={currentValue}
      />
    </Wrapper>
  )
}

export default RuleDropDown
