import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import { ButtonSubmit, ContainerStyled, FormStyled, LabelStyled } from './styled'
import CustomInput from '../../../core/components/CustomInput'
import { emailValidation, requiredRule } from '../../../core/utils/formRules'
import { WarningIcon } from '../../../core/components/icons/WarningIcon'
import { MailIcon } from '../../../core/components/icons/MailIcon'
import { TResetLinkFormData } from '../types'
import { BaseButton } from '../../../core/components/Button'
import Card from '../../../core/components/Card'
import { flex } from '../../../core/styles/mixins'
import { useResetLinkMutation } from '../queries'

const ButtonGroupStyled = styled.div`
  ${flex({})};
  flex-direction: column;
`

const CancelButtonStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    height: 40px;
    margin-top: 8px;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const ForgotPassword = () => {
  const navigate = useNavigate()
  const { mutate } = useResetLinkMutation()
  const { handleSubmit, control } = useForm<TResetLinkFormData>({
    defaultValues: {
      email: ''
    }
  })

  const handleCancel = () => navigate('/login')
  return (
    <ContainerStyled>
      <Card>
        <LabelStyled>Forgot password ?</LabelStyled>
        <FormStyled onSubmit={handleSubmit((data) => {
          mutate(data)
        })}>
          <CustomInput
            size="small"
            name="email"
            control={control}
            placeholder="Email"
            rules={{
              required: requiredRule('Please fill in all required fields.'),
              validate: emailValidation
            }}
            iconError={<WarningIcon width={20} height={20} />}
            icon={<MailIcon />}
          />
          <ButtonGroupStyled>
            <ButtonSubmit data-testid="submit" type="submit">Send me a password reset link</ButtonSubmit>
            <CancelButtonStyled data-testid="cancel" onClick={handleCancel}>Cancel</CancelButtonStyled>
          </ButtonGroupStyled>
        </FormStyled>
      </Card>
    </ContainerStyled>
  )
}
export default ForgotPassword
