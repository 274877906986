import { FormControlLabel as Label } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { FC } from 'react'
import styled from 'styled-components/macro'
import { CustomCheckboxStyled } from '../../../../core/components/CustomCheckbox'
import { fontFamily } from '../../../../core/styles/mixins'

const WrapperStyled = styled.div`
  &&& {
    .MuiTypography-root {
      ${fontFamily('Inter')};
      font-style: normal;
      font-size: 14px;
      margin-left: 4px;
      margin-bottom: 0;
      color: ${({ theme }) => theme.colors.darkBlack};;
      font-weight: 400;
      line-height: 22px;
    }

    .MuiFormControlLabel-root {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin: 0;
    }
  }

`

type TProps = {
  control: Control<any>
}
const ApplyChanges: FC<TProps> = ({ control }) => (
  <WrapperStyled>
    <Label
      label="Apply changes to all other transactions in application that have the same description"
      control={(
        <Controller
          name="applyToSimilarDescriptions"
          control={control}
          render={({ field }) => (
            <CustomCheckboxStyled
              checked={field.value}
              onChange={(event, checked) =>
                field.onChange(checked)} />
          )}
        />
      )}
      labelPlacement="end"
    />
  </WrapperStyled>
)

export default ApplyChanges
