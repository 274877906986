import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Column } from 'react-table'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import CustomTable from '../../../../core/components/table/Table'
import { useScoringConf, useToggleScoringConf } from '../queries'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import CustomSwitch from '../../../../core/components/CustomSwitch'
import Spinner from '../../../../core/components/Spinner'
import ScoringRulesText from './ScoringRulesText'
import Card, { CardStyled } from '../../../../core/components/Card'
import { TScoring } from '../types'
import { ruleFormat } from '../utils/tableRuleFormat'
import { SLASH_DATE_FORMAT } from '../../../../core/const/date'
import { DisabledTextStyled } from '../../../validationRulesBase/components/table/Table'

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  margin-top: 400px;
`

const WrapperStyled = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 24px;

  ${CardStyled} {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
    min-height: 800px;
  }

  ${HeaderCellStyled} {
    padding: 24px 12px;

    :first-child {
      padding-left: 16px;
    }

    :last-child {
      padding-right: 16px;
    }
  }

  ${BodyCellStyled} {
    ${fontFamily('Inter')};
    padding: 16px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.darkBlack};
    vertical-align: top;

    :first-child {
      padding-left: 16px;
      width: 44px;
    }

    :nth-child(2) {
      width: 475px
    }

    :nth-child(3) {
      width: 100px;
    }

    :nth-child(4) {
      width: 428px;
    }

    :last-child {
      padding-right: 16px;
      width: 102px;
    }
  }
`

function isDisabled({ enabled }: TScoring) {
  return !enabled
}

const ScoringConfigTable = () => {
  const navigate = useNavigate()
  const { mutate } = useToggleScoringConf()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: 'Status',
        accessor: 'enabled',
        Cell: ({ value, row }) => (
          <CustomSwitch
            checked={value}
            onClick={(e) => {
              e.stopPropagation()
              mutate((row.original as TScoring))
            }} />
        )
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }) =>
          (isDisabled((row.original as TScoring))
            ? <DisabledTextStyled>{value}</DisabledTextStyled>
            : value)
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        Cell: ({ value, row }) => {
          const lastUpdated = format(new Date(value), SLASH_DATE_FORMAT)
          return isDisabled((row.original as TScoring))
            ? <DisabledTextStyled>{lastUpdated}</DisabledTextStyled>
            : lastUpdated
        }
      },
      {
        Header: 'Rules',
        accessor: 'scoringRules',
        Cell: ({ value, row }) => {
          const isDisabledRow = isDisabled(row.original as TScoring)
          const scoring = row.original as TScoring
          return value.length === 1
            ? isDisabledRow
              ? (
                <DisabledTextStyled>
                  {ruleFormat(scoring)}
                </DisabledTextStyled>
              )
              : ruleFormat(scoring)
            : <ScoringRulesText disabled={isDisabledRow} scoringData={scoring} />
        }
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: () => ''
      }
    ],
    []
  )

  const { data: tableData, isLoading, isIdle } = useScoringConf()

  const scoringConf = useMemo(() => tableData || [], [tableData])

  return (
    <WrapperStyled>
      <Card noPadding>
        <CustomTable
          columns={columns}
          data={scoringConf || []}
          onRowClick={({ original }) => navigate((original as TScoring)?.id.toString())}
          showRowBorder />
        {(isIdle || isLoading)
          && (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
      </Card>
    </WrapperStyled>
  )
}

export default ScoringConfigTable
