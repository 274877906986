import styled from 'styled-components/macro'
import { TooltipProps } from '@mui/material'
import { FC, useMemo } from 'react'
import CustomTooltip from '../../../core/components/CustomTooltip'
import { InformationIcon } from '../../../core/components/icons/InformationIcon'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { useApplicationHeaderContext } from '../state/useApplicationHeader'
import { TLoanApplicationMatches } from '../type'
import { useHasCurrentUserCustomerServiceRole, useIsPlendOrganization } from '../../auth/hooks'
import { prepareMarkApplication } from '../utils/prepareMarkApplication'

const IconWrapperStyled = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.red};
  ${flex({ justify: 'center', align: 'center' })}
`

const ContentStyled = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
`

const TitleStyled = styled.p`
  ${fontFamily('Inter')};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`

const LinkStyled = styled.a`
  ${fontFamily('Inter')};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.blue500}
`

const EmptyDivStyled = styled.div<{ marginLeft?: number }>`
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
`

type TProps = {
  placement: TooltipProps['placement']
  width: number,
  iconWidthHeight: 16 | 20 | 18
  match: TLoanApplicationMatches[]

}

const MarkApplication: FC<TProps> = ({
  match,
  placement,
  width,
  iconWidthHeight

}) => {
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()
  const isPlendOrganization = useIsPlendOrganization()

  const content = useMemo(() => {
    if (!headerData || !headerData?.loanApplicationMatches) {
      return []
    }
    return prepareMarkApplication(headerData.loanApplicationMatches, match)
  }, [headerData])
  const isCustomerService = useHasCurrentUserCustomerServiceRole()

  const showMarkApplicationStatuses = isCustomerService ? true : headerData
    && ['APPROVED_PENDING_RETRIEVER', 'APPROVED', 'CANCELLED', 'DECLINED', 'IN_PROGRESS'].includes(headerData.status)
  if (!headerData || !content.length || !showMarkApplicationStatuses || !isPlendOrganization) {
    return <EmptyDivStyled marginLeft={iconWidthHeight} />
  }

  return (
    <CustomTooltip
      arrow
      disableFocusListener
      disableTouchListener
      placement={placement}
      maxWidth={width}
      title={(
        <ContentStyled width={width - 16}>
          {
            content.map((data) => (
              <>
                <TitleStyled>{data?.title}</TitleStyled>
                {
                  data?.links.map((link) =>
                    <LinkStyled href={link} target="_blank">{link}</LinkStyled>)
                }
              </>
            ))
          }
        </ContentStyled>
      )}
    >
      <IconWrapperStyled>
        <InformationIcon
          width={iconWidthHeight}
          height={iconWidthHeight}
        />
      </IconWrapperStyled>
    </CustomTooltip>
  )
}

export default MarkApplication
