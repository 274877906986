import { useEffect, useState } from 'react'
import constate from 'constate'
import { TSortBy } from '../../../../core/components/table/TableContext'
import { useRisksQuery, useTransactionsQuery } from '../querries'
import { TGamblingData } from '../types'

function useCategorySelection(gamblingData?: TGamblingData[]) {
  const [category, setSelectedCategory] = useState<TGamblingData>()

  useEffect(() => setSelectedCategory(gamblingData?.[0]), [gamblingData])

  return {
    selectedCategory: category,
    setSelectedCategory
  }
}

function useTransactions(selectedRisk?: TGamblingData) {
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState<TSortBy | undefined>({
    id: 'date',
    desc: true
  })

  return {
    query: useTransactionsQuery(sortBy, currentPage, selectedRisk),
    setCurrentPage,
    sortBy,
    setSortBy
  }
}

function useRisk() {
  const risksQuery = useRisksQuery()
  const { selectedCategory, setSelectedCategory } = useCategorySelection(risksQuery.data)

  return {
    risksQuery,
    selectedCategory,
    setSelectedCategory,
    transactionsState: useTransactions(selectedCategory)
  }
}

export const [RiskProvider, useRiskContext] = constate(useRisk)
