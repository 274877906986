import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { LocationIcon } from '../../../core/components/icons/LocationIcon'
import { useApplicationHeaderContext } from '../state/useApplicationHeader'
import { fontFamily } from '../../../core/styles/mixins'
import AddressInformationModal from './AddressInformationModal'
import { useIsPlendOrganization } from '../../auth/hooks'

const ButtonStyled = styled.button`
  all: unset;
  ${fontFamily('Inter')};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue500};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-content: center;
  gap: 4px;
`

const AddressInformation = () => {
  const [open, setOpen] = useState(false)
  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()
  const close = useCallback(() => setOpen(false), [])
  const isPlendOrganization = useIsPlendOrganization()
  const isAddressInformation = ['APPROVED', 'DECLINED', 'CANCELLED', 'APPROVED_PENDING_RETRIEVER'].includes(headerData?.status ?? '')
    && isPlendOrganization

  if (!isAddressInformation) {
    return <></>
  }

  return (
    <>
      <ButtonStyled onClick={() => setOpen(true)}>
        <LocationIcon />
        Address information
      </ButtonStyled>
      <AddressInformationModal open={open} close={close} status={headerData?.status} />
    </>
  )
}

export default AddressInformation
