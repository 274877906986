import styled from 'styled-components/macro'
import React, { memo } from 'react'
import { fontFamily } from '../../../../core/styles/mixins'
import CustomTooltip from '../../../../core/components/CustomTooltip'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'

const Wrapper = styled.div`
  ${fontFamily('Inter')};
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const TextWrap = styled.div`
  cursor: pointer;
`
const DataOneIdentity = () => {
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()

  if (!headerData || !headerData?.dataOneBorrowerIdentityNames?.length) {
    return <></>
  }
  const { dataOneBorrowerIdentityNames } = headerData

  const title = dataOneBorrowerIdentityNames
    .slice(1)
    .join(', ')

  return (
    <Wrapper>
      {
        dataOneBorrowerIdentityNames.length > 1 ? (
          <CustomTooltip arrow title={title} placement="top">
            <TextWrap>
              {`D One name: ${dataOneBorrowerIdentityNames[0]} , +${Number(dataOneBorrowerIdentityNames.length) - 1}`}
            </TextWrap>
          </CustomTooltip>
        ) : (
          <div>{dataOneBorrowerIdentityNames[0] ? `D One name: ${dataOneBorrowerIdentityNames[0]}` : null}</div>
        )
      }
    </Wrapper>
  )
}

export default DataOneIdentity
