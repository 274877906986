import { useState } from 'react'
import styled from 'styled-components/macro'
import { useForm, FormProvider } from 'react-hook-form'
import Button, { BaseButton } from '../../../core/components/Button'
import Modal from './Modal'
import { requiredRule } from '../../../core/utils/formRules'
import { WarningIcon } from '../../../core/components/icons/WarningIcon'
import { useAddUserQuery, useCreatePartnerOrganizationMutation } from '../queries'
import { TForm } from '../types'
import { useErrorHandling } from '../hooks'
import { useHasPermission } from '../../auth/hooks'
import { useUsersContext } from '../state/useUserManagement'

const Wrapper = styled.div`
  ${BaseButton} {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue500};
    border-radius: 3px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue500};
    }
  }
`
const formDefaultValues: TForm = {
  firstName: '',
  lastName: '',
  email: '',
  organizationId: '',
  organizationName: ''
}

const AddUser = () => {
  const [openModal, setOpenModal] = useState(false)
  const methods = useForm<TForm>({
    defaultValues: formDefaultValues
  })
  const { partnerAdminQuery: { data: partnerUsers } } = useUsersContext()
  const {
    mutateAsync: addUserMutateAsync,
    isError: isAddUserError,
    isLoading: isAddUserLoading,
    error: addUserError
  } = useAddUserQuery()
  const {
    mutateAsync: createPartnerMutationAsync,
    isLoading: isCreatePartnerOrganizationLoading
  } = useCreatePartnerOrganizationMutation()
  const isPartnerAdmin = useHasPermission(['PARTNERS_ADMIN_VIEW'])

  useErrorHandling(isAddUserError, methods, addUserError?.response?.status)

  function addUser(data: TForm) {
    addUserMutateAsync(data)
      .then(() => {
        methods.reset(formDefaultValues)
        setOpenModal(false)
      })
  }

  const onSubmit = (data: TForm) => {
    const { organizationName, ...restFormData } = data
    if (isPartnerAdmin) {
      const isOrganization = partnerUsers?.content
        .find(({ name }) => name === organizationName)
      if (!isOrganization) {
        createPartnerMutationAsync(organizationName!)
          .then((res) => addUser({ ...restFormData, organizationId: res.id }))
        return
      }

      addUser({ ...restFormData, organizationId: isOrganization!.id })
      return
    }
    addUser(restFormData)
  }

  return (
    <Wrapper>
      <Button text="Add User" onClick={() => setOpenModal(true)} />
      <FormProvider {...methods}>
        <Modal
          onSubmit={methods.handleSubmit(onSubmit)}
          isOpen={openModal}
          title="Add User"
          buttonText="Add User"
          isButtonDisable={isAddUserLoading || isCreatePartnerOrganizationLoading}
          rules={{
            required: requiredRule('This field is required')
          }}
          iconError={<WarningIcon width={16.67} height={16.67} />}
          handleClose={() => {
            setOpenModal(false)
            methods.reset()
          }}
        />
      </FormProvider>
    </Wrapper>
  )
}

export default AddUser
