import styled from 'styled-components/macro'
import ScoringConfigTable from './ScoringConfigTable'
import InnerLayout from '../../../../core/components/InnerLayout'
import { fontFamily } from '../../../../core/styles/mixins'

const Wrapper = styled.div`
   height: 100%;
`

const TitleStyled = styled.h1`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const ScoringConfig = () => (
  <Wrapper>
    <InnerLayout header={
      <TitleStyled>Scoring Configuration</TitleStyled>
    }>
      <ScoringConfigTable/>
    </InnerLayout>
  </Wrapper>
)

export default ScoringConfig
