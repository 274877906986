import React, { FC } from 'react'
import styled, { css } from 'styled-components/macro'
import Modal from './Modal'
import { useAssessmentContext } from '../state/useAssessment'
import { BaseButton } from '../../../../core/components/Button'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { useTabsVisited } from '../queries'
import { fontFamily } from '../../../../core/styles/mixins'
import { CheckCircledIcon } from '../../../../core/components/icons/CheckedCircledIcon'
import { useIsPlendOrganization } from '../../../auth/hooks'
import { TStatusAssessment } from '../type'

const mixinMainButton = css`
    && {
        min-width: 158px;
        padding: 8px 16px;
        background: ${({ theme }) => theme.colors.blue500};
        color: ${({ theme }) => theme.colors.white};
        margin-right: 16px;

        &:hover {
            background: ${({ theme }) => theme.colors.blue700};
        }
    }`

const mixinSecondaryButton = css`
    && {
        width: 105px;
        height: 32px;
        padding: 12px 6px 14px;
        ${fontFamily('Inter')};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        background: ${({ theme }) => theme.colors.grey300};
        color: ${({ theme }) => theme.colors.darkBlack};

        &:hover {
            background: ${({ theme }) => theme.colors.grey500};
        }
    }
`

const ApproveButtonStyled: any = styled(BaseButton)<{ isSecondaryButton: boolean }>`
    && {
        ${({ isSecondaryButton }) => (isSecondaryButton ? mixinSecondaryButton : mixinMainButton)}
    }
`

const Title = styled.span`
    margin-left: 8px;
`

type TProps = {
  isSecondaryButton?: boolean
}

const AssessmentApprove: FC<TProps> = ({ isSecondaryButton }) => {
  const {
    setIsModalOpen,
    isModalOpen
  } = useAssessmentContext()
  const isPlendOrganization = useIsPlendOrganization()
  const { refetch, data: isAllTabVisited } = useTabsVisited()
  const {
    headerUpdateMutation: { mutate, isLoading: isUpdating }, headerQuery: { data }
  } = useApplicationHeaderContext()
  const isSendApprovedPendingRetriever = !!(
    data
    && ['IN_PROGRESS', 'ACCOUNT_REQUESTED', 'ACCOUNT_UPDATED'].includes(data?.status as TStatusAssessment)
    && isPlendOrganization
  )

  const isInProgress = data?.status === 'IN_PROGRESS'

  return (
    <>
      <ApproveButtonStyled
        onClick={() => {
          if (isSendApprovedPendingRetriever) {
            mutate({ status: 'APPROVED_PENDING_RETRIEVER' })
            return
          }

          refetch()
            .then(() => setIsModalOpen({ approve: true }))
        }}
        isSecondaryButton={isSecondaryButton}
        variant="contained">
        {isSecondaryButton && <CheckCircledIcon />}
        <Title>{isInProgress ? 'KYC Check' : 'Approve'}</Title>
      </ApproveButtonStyled>
      <Modal
        title="Approve Application"
        content="Are you sure you want to approve this application?"
        buttonText="Approve"
        error={!isAllTabVisited || false}
        queryRequest={() => {
          if (!isUpdating) {
            mutate({ status: 'APPROVED' })
            setIsModalOpen({ approve: false })
          }
        }}
        isOpen={isModalOpen.approve}
        handleClose={() => setIsModalOpen({ approve: false })} />
    </>
  )
}

export default AssessmentApprove
