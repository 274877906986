import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import styled from 'styled-components/macro'
import { TChangePasswordFormData } from '../types'
import { ButtonSubmit, ContainerStyled, FormStyled, LabelStyled } from './styled'
import CustomInput from '../../../core/components/CustomInput'
import {
  confirmPasswordValidation,
  passwordValidation,
  requiredRule
} from '../../../core/utils/formRules'
import { WarningIcon } from '../../../core/components/icons/WarningIcon'
import { PasswordIcon } from '../../../core/components/icons/PasswordIcon'
import Card from '../../../core/components/Card'
import { useChangePasswordMutation } from '../queries'
import { fontFamily } from '../../../core/styles/mixins'

const HelperTextStyled = styled.p`
  ${fontFamily('Inter')}
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.grey600};
  margin-bottom: 12px;
  width: 100%;
  max-width: 300px;
`

const passwordValidationErrorMessage = 'Entered password doesn\'t meet the requirements, please choose a stronger one.'
const ChangePassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { mutate } = useChangePasswordMutation()
  const { handleSubmit, control, watch } = useForm<TChangePasswordFormData>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  })
  const userId = searchParams.get('userId')

  useEffect(() => {
    if (!userId) navigate('/forgot-password')
  }, [userId])

  function handleSubmitForm(data: TChangePasswordFormData) {
    mutate({ ...data, userId: userId! })
  }

  return (
    <ContainerStyled>
      <Card>
        <LabelStyled marginBottom={4}>Change password</LabelStyled>
        <HelperTextStyled>
          At least 8 symbols,
          1 uppercase letter,
          1 lowercase letter,
          1 number,
          1 special symbol (!@#$ etc.)
        </HelperTextStyled>
        <FormStyled onSubmit={handleSubmit(handleSubmitForm)}>
          <CustomInput
            type="password"
            size="small"
            name="password"
            control={control}
            placeholder="New password"
            rules={{
              required: requiredRule('Please fill in all required fields.'),
              validate: (value) => passwordValidation(value, passwordValidationErrorMessage)
            }}
            iconError={<WarningIcon width={20} height={20} />}
            icon={<PasswordIcon />}
          />
          <CustomInput
            type="password"
            size="small"
            name="confirmPassword"
            control={control}
            placeholder="Confirm new password"
            rules={{
              required: requiredRule('Please fill in all required fields.'),
              validate: (value) => confirmPasswordValidation(value, watch('password'))
            }}
            iconError={<WarningIcon width={20} height={20} />}
            icon={<PasswordIcon />}
          />
          <ButtonSubmit type="submit">
            Save changes
          </ButtonSubmit>
        </FormStyled>
      </Card>
    </ContainerStyled>
  )
}
export default ChangePassword
