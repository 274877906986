import constate from 'constate'
import { useCallback, useMemo, useState } from 'react'
import { useClearScorePreview, useClearScorePricing } from '../queries'

function useContext() {
  const [aoScore, setAoScore] = useState(550)

  const resetAoScoreFilter = useCallback(() => setAoScore(550), [])

  const handleChangeAoScoreFilter = useCallback((value: string) => setAoScore(Number(value)), [])
  const clearScorePricingData = useClearScorePricing()

  const aoScoreOptions = useMemo(
    () =>
      clearScorePricingData.data?.creditAdjustments?.map((adj) => ({
        value: adj.aoScoreRange,
        label: `${adj.aoScoreRange}`
      })) ?? [],
    [clearScorePricingData]
  )

  return {
    clearScorePreviewData: useClearScorePreview({ aoScore }),
    onChangeAoFilter: handleChangeAoScoreFilter,
    resetAoScoreFilter,
    clearScorePricingData,
    aoScoreOptions,
    aoScore
  }
}

export const [ClearScorePricingProvider, useClearScorePricingContext] = constate(useContext)
