import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useForm, FormProvider } from 'react-hook-form'
import { BaseButton } from '../../../core/components/Button'
import { EditIcon } from '../../../core/components/icons/EditIcon'
import Modal from './Modal'
import { WarningIcon } from '../../../core/components/icons/WarningIcon'
import { requiredRule } from '../../../core/utils/formRules'
import { useCreatePartnerOrganizationMutation, useEditUserQuery } from '../queries'
import { TEdit, TForm } from '../types'
import { useErrorHandling } from '../hooks'
import { useUsersContext } from '../state/useUserManagement'
import { fontFamily } from '../../../core/styles/mixins'
import { CheckFilledIcon } from '../../../core/components/icons/CheckFilledIcon'
import { useHasPermission } from '../../auth/hooks'

const Wrapper = styled.div``

const ResentPassStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    margin-right: 72px;
    padding: 8px 16px;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: all;
      cursor: not-allowed;
    }
  }
`

const EditButtonWrapper = styled.button<{ isOpen?: boolean }>`
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  cursor: pointer;
`

const PasswordStatus = styled.div`
  ${fontFamily('Inter')};
  display: flex;
  align-items: center;
  margin-right: 60px;
  color: ${({ theme }) => theme.colors.grey600};
`

const StatusText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-left: 5.6px;
`

type TProp = {
  onHandleClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

const EditUser: FC<TProp> = ({ onHandleClick }) => {
  const { partnerAdminQuery: { data: partnerUsers } } = useUsersContext()
  const [openModal, setOpenModal] = useState(false)
  const [isResended, setIsResended] = useState(false)
  const isAdmins = useHasPermission(['GENERAL_VIEW', 'PARTNERS_ADMIN_VIEW'])
  const isPartnerAdminView = useHasPermission(['PARTNERS_ADMIN_VIEW'])
  const {
    mutateAsync: createPartnerMutationAsync,
    isLoading: isCreatePartnerOrganizationLoading
  } = useCreatePartnerOrganizationMutation()
  const {
    selectedId,
    selectedRow,
    resentPassword: { refetch, isRefetching }
  } = useUsersContext()

  const { mutateAsync, isError, error, isLoading } = useEditUserQuery()
  const methods = useForm<TForm>({
    defaultValues: useMemo(
      () => ({
        firstName: selectedRow?.firstName,
        lastName: selectedRow?.lastName,
        email: selectedRow?.email,
        organizationId: selectedRow?.organization?.id,
        organizationName: selectedRow?.partnerOrganization?.name,
        role: isAdmins ? undefined : selectedRow?.roles[0].name
      }),
      [selectedRow]
    )
  })

  const handleModal = () => {
    methods.reset()
    setOpenModal(false)
    setIsResended(false)
  }
  const editUserMutation = (data: TEdit) => {
    mutateAsync(data)
      .then(handleModal)
  }

  useEffect(() => {
    if (selectedRow) {
      const {
        firstName,
        lastName,
        email,
        organization,
        roles,
        partnerOrganization
      } = selectedRow

      methods.reset({
        firstName,
        lastName,
        email,
        organizationId: organization?.id,
        organizationName: partnerOrganization?.name,
        role: isAdmins ? undefined : roles[0].name
      })
    }
  }, [selectedRow])

  useErrorHandling(isError, methods, error?.response?.status)

  const onSubmit = (data: TForm) => {
    const { organizationName, ...restFormData } = data

    if (isPartnerAdminView) {
      const isOrganization = partnerUsers?.content
        .find(({ name }) => name === organizationName)
      if (!isOrganization) {
        createPartnerMutationAsync(organizationName!)
          .then((res) => editUserMutation({
            user: { ...restFormData, organizationId: res.id },
            id: selectedId
          }))
        return
      }
      editUserMutation({
        user: {
          ...restFormData,
          organizationId: isOrganization!.id
        },
        id: selectedId
      })
      return
    }
    editUserMutation({ user: restFormData, id: selectedId })
  }

  return (
    <Wrapper onClick={onHandleClick}>
      <EditButtonWrapper onClick={() => setOpenModal(true)}>
        <EditIcon onClick={() => setOpenModal(true)} />
      </EditButtonWrapper>
      <FormProvider {...methods}>
        <Modal
          onSubmit={methods.handleSubmit(onSubmit)}
          isOpen={openModal}
          isEditPartnerAdminDisabled
          title="Edit User"
          buttonText="Save"
          isButtonDisable={isLoading || isCreatePartnerOrganizationLoading}
          rules={{
            required: requiredRule('This field is required')
          }}
          iconError={<WarningIcon width={16} height={16} />}
          handleClose={handleModal}
        >
          {!isResended ? (
            <ResentPassStyled
              disabled={isLoading || isRefetching}
              onClick={
                () => refetch()
                  .then(() => setIsResended(true))
              }>
              Send new password
            </ResentPassStyled>
          ) : (
            <PasswordStatus>
              <CheckFilledIcon width={16} height={16} />
              <StatusText>New password has been sent</StatusText>
            </PasswordStatus>
          )}
        </Modal>
      </FormProvider>
    </Wrapper>
  )
}

export default EditUser
