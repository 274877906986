import constate from 'constate'
import { useCallback, useMemo, useState } from 'react'
import { addMonths, subMonths, isAfter } from 'date-fns'
import {
  useApprovalStatisticsQuery,
  useSizeStatisticsQuery,
  useStatusStatisticsQuery
} from '../queries'
import { useHasPermission } from '../../auth/hooks'
import { useAuthContext } from '../../auth/state/useAuthState'

export const allUnderwriters = 'ALL_UNDERWRITERS'

function useUnderwriterFilter() {
  const [underwriter, setUnderwriter] = useState<string>(allUnderwriters)

  return {
    setUnderwriter,
    underwriter
  }
}

function useStatistics(date: Date, underwriter: string) {
  const isOrganization = useHasPermission(['ORGANIZATION_ADMIN_VIEW'])
  const { authData } = useAuthContext()
  const userId = String(isOrganization ? underwriter : authData?.id)

  return {
    approvalStatisticsQuery: useApprovalStatisticsQuery(date, userId),
    sizeStatisticsQuery: useSizeStatisticsQuery(date, userId),
    statusStatisticsQuery: useStatusStatisticsQuery(date, userId)
  }
}

function useMonthHandle() {
  const [date, setDate] = useState<Date>(new Date())

  const handlePrevMonth = useCallback(() => setDate(subMonths(date, 1)), [date])

  const handleNextMonth = useCallback(() => {
    const result = addMonths(date, 1)
    setDate(isAfter(result, new Date()) ? date : result)
  }, [date])

  const isNextMonthDisabled = useMemo(() => isAfter(addMonths(date, 1), new Date()), [date])

  return {
    date,
    handlePrevMonth,
    handleNextMonth,
    isNextMonthDisabled
  }
}

const useHome = () => {
  const monthHandle = useMonthHandle()
  const underwriterFilter = useUnderwriterFilter()

  return ({
    ...useStatistics(monthHandle.date, underwriterFilter.underwriter),
    ...monthHandle,
    ...underwriterFilter
  })
}

export const [HomeProvider, useHomeContext] = constate(useHome)
