import { format, intervalToDuration } from 'date-fns'
import { ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { TConfigurationUsers } from '../types'
import { TOption } from '../../../core/components/CustomSelect'
import { allUnderwriters } from '../state/useHome'

export const formatToDay = (tickItem: string) => {
  if (tickItem !== 'auto') {
    const [day, month, year] = tickItem.split('/')
    return format(new Date(Number(year), Number(month) - 1, Number(day)), 'd')
  }
  return ' '
}

export const formatSeconds = (time?: number) => {
  const { days, hours } = intervalToDuration({ start: 0, end: Number(time) * 1000 })
  const day = days ? `${days}d` : ''
  const hour = hours ? `${hours}hr` : ''
  return `${day} ${hour}`
}

export const humanizeTime = (value?: ValueType) => {
  const { days, hours, minutes } = intervalToDuration({ start: 0, end: Number(value) * 1000 })
  const day = days ? `${days}d` : ''
  const hour = hours ? `${hours}hr` : ''
  const minute = minutes ? `${minutes}min` : ''
  return `${day} ${hour} ${minute}`
}

export const formatConfigurationUsers = (users: TConfigurationUsers): TOption[] => {
  const formattedUsers = users.content.map(({ firstName, lastName, id }) => ({
    label: `${firstName} ${lastName}`,
    value: id.toString()
  }))

  return [
    { label: 'All underwriters', value: allUnderwriters },
    ...formattedUsers
  ]
}
