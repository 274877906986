import styled from 'styled-components/macro'
import DetailedTransactionTable from '../../base/components/DetailedTransactionTable'
import MetricsCategoryTable from '../../base/components/MetricsCategoryTable'
import { MetricsContentProvider, useMetricsContentContext } from '../../base/state/useMetricsContentState'
import CategoryChart from '../../base/components/CategoryChart'
import EditTransaction from '../../../editTransaction/components/EditTransaction'
import { flex } from '../../../../../core/styles/mixins'

const Wrapper = styled.section`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1px;
`

const CategoriesStyled = styled.div`
  flex: 0.5;
  border-right: 1px solid ${({ theme }) => theme.colors.grey300};
`

const TransactionStyled = styled.div`
  flex: 0.5;

`

const EditTransactionWrap = styled.div`
  ${flex({ justify: 'flex-end' })};
  margin-top: 16px;
  padding-right: 16px;
`

const Income = () => {
  const { selectedTransactions, handleClearTransactions } = useMetricsContentContext()
  return (
    <Wrapper>
      <CategoriesStyled>
        <MetricsCategoryTable />
        <CategoryChart />
      </CategoriesStyled>
      <TransactionStyled>
        <EditTransactionWrap>
          <EditTransaction
            handleClearTransactions={handleClearTransactions}
            transactions={selectedTransactions} />
        </EditTransactionWrap>
        <DetailedTransactionTable />
      </TransactionStyled>
    </Wrapper>
  )
}
export default () => (
  <MetricsContentProvider>
    <Income />
  </MetricsContentProvider>
)
