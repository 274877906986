import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { useTransactionContext } from '../state/useTransactions'
import CustomMultipleSelect from '../../../../core/components/CustomMultipleSelect'
import { BaseButton } from '../../../../core/components/Button'
import { TClasses } from '../types'
import CustomSearch from '../../../../core/components/CustomSearch'
import { CloseIcon } from '../../../../core/components/icons/CloseIcon'
import { useIsPlendOrganization } from '../../../auth/hooks'

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const FormStyled = styled.form`
  ${flex({ align: 'center' })};
`

const ButtonStyled = styled(BaseButton)`
  && {
    ${flex({ justify: 'space-between', align: 'center' })};
    ${fontFamily('Inter')};
    width: 102px !important;
    height: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.darkBlack};
    background: ${({ theme }) => theme.colors.grey200};
    border-radius: 3px;
  }
`

const SearchWrapper = styled.div`
  margin-left: -8px;
  padding: 0 8px 5px 0;

  && {
    .MuiOutlinedInput-root,
    .MuiTextField-root {
      width: 100%;
    }

    .MuiButton-root {
      margin-left: 8px !important;
    }
  }
`

export const initialState = {
  firstClasses: [],
  secondClasses: []
}

const Filter = () => {
  const {
    classes: {
      setSelectedFirstClasses,
      setSelectedSecondClasses,
      firstClassOptionsQuery: { data: firstClassOptions },
      secondClassOptionsQuery: { data: secondClassOptions },
      setFirstClassSearch,
      setSecondClassSearch,
      selectedFirstClasses,
      selectedSecondClasses,
      setFirstClassExactMatch,
      setSecondClassExactMatch
    }
  } = useTransactionContext()
  const isPlendOrganization = useIsPlendOrganization()

  const { watch, control, reset, resetField } = useForm<TClasses>({
    mode: 'onChange',
    defaultValues: initialState
  })

  const firstClassesValue = watch('firstClasses')
  const secondClassesValue = watch('secondClasses')

  useEffect(() => {
    if (!firstClassesValue?.length && selectedFirstClasses?.length) {
      setSelectedFirstClasses([])
    }

    if (!secondClassesValue?.length && selectedSecondClasses?.length) {
      setSelectedSecondClasses([])
    }
  }, [firstClassesValue, secondClassesValue])

  const onSubmit = () => {
    setSelectedFirstClasses(firstClassesValue)
    setSelectedSecondClasses(secondClassesValue)
    setFirstClassExactMatch(true)
    setSecondClassExactMatch(true)
  }

  const handlerResetFirstClass = () => {
    resetField('firstClasses')
    setSelectedFirstClasses([])
    setFirstClassSearch('')
    setFirstClassExactMatch(false)
  }

  const handleResetSecondClass = () => {
    resetField('secondClasses')
    setSelectedSecondClasses([])
    setSecondClassSearch('')
    setSecondClassExactMatch(false)
  }

  const handleReset = () => {
    reset(initialState)
    setSelectedFirstClasses([])
    setSelectedSecondClasses([])
    setFirstClassSearch('')
    setSecondClassSearch('')
  }

  useEffect(() => () => {
    if (!firstClassOptions.length) resetField('firstClasses')
    if (!secondClassOptions.length) resetField('secondClasses')
  }, [])

  const showClearAllButton = !isPlendOrganization
    && (firstClassesValue?.length || secondClassesValue?.length)

  return (
    <>
      <TitleStyled>Filters:</TitleStyled>
      <FormStyled id="multiselectForm">
        <CustomMultipleSelect
          name="firstClasses"
          control={control}
          options={firstClassOptions?.firstClasses || []}
          title="Plend OB Class 1"
          onApply={onSubmit}
          onReset={handlerResetFirstClass}
          isOptionChecked={!!selectedFirstClasses?.length}
          onClose={() => !selectedFirstClasses?.length && resetField('firstClasses')}
          search={(
            <SearchWrapper>
              <CustomSearch
                setIsExactMatch={setFirstClassExactMatch}
                placeholder="Search class..."
                setSearch={setFirstClassSearch}
              />
            </SearchWrapper>
          )}
        />
        <CustomMultipleSelect
          name="secondClasses"
          control={control}
          options={secondClassOptions?.secondClasses || []}
          title="Plend OB Class 2"
          onApply={onSubmit}
          onReset={handleResetSecondClass}
          isOptionChecked={!!selectedSecondClasses?.length}
          onClose={() => !selectedSecondClasses?.length && resetField('secondClasses')}
          search={(
            <SearchWrapper>
              <CustomSearch
                setIsExactMatch={setSecondClassExactMatch}
                placeholder="Search class..."
                setSearch={setSecondClassSearch}
              />
            </SearchWrapper>
          )}
        />
        {showClearAllButton ? (
          <ButtonStyled onClick={() => handleReset()}>
            <CloseIcon />
            Clear all
          </ButtonStyled>
        ) : null}
      </FormStyled>
    </>
  )
}

export default Filter
