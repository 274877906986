import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import styled from 'styled-components/macro'
import isEqual from 'lodash/isEqual'
import { useParams } from 'react-router-dom'
import Button, { BaseButton } from '../../../../core/components/Button'
import CustomInput, { CustomTextField } from '../../../../core/components/CustomInput'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import Spinner from '../../../../core/components/Spinner'
import { TitleStyled } from '../../../applications/applicationDetail/components/ApplicationDetail'
import { useScoringContext } from '../state/useScoring'
import Modal from './Modal'
import { useEditRulesQuery } from '../queries'
import { TOperators, TVersionRule } from '../types'
import { floatFormat, rulesFormat } from '../../../../core/utils/formUtils'

const mapOperators: TOperators = {
  LESS_OR_EQUAL: '≤',
  MORE: '>',
  MORE_OR_EQUAL: '≥',
  LESS: '<',
  EQUAL: '=',
  FROM_TO: '>',
  FROM_TO_INCLUSIVE: '≥'
}

const Wrapper = styled.div`
  ${fontFamily('Inter')};
  flex: 0.45;
  padding: 24px 0 24px 24px;
  ${CustomTextField} {
    width: 64px;
    height: 32px;
    margin: 0 8px;
  }
  && .MuiOutlinedInput-root {
    width: 64px;
    height: 32px;
    padding: 6px 8px 6px 12px;
  }
  && .Mui-disabled {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue500};
    opacity: 0.5;
  }
  ${BaseButton} {
    padding: 8px 16px;
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue500};
    border-radius: 3px;
    &:hover {
      background: ${({ theme }) => theme.colors.blue500};
    }
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const FormWrapStyled = styled.div`
  margin-top: 24px;
`

const RulesStyled = styled.div`
  & :last-child {
    margin-bottom: 0;
  }
`

const Rule = styled.div`
  ${flex({ align: 'center' })};
  margin-bottom: 12px;
`

const RawBaseScore = styled.div`
  ${flex({ justify: 'flex-start', align: 'baseline' })};
`

const ErrorMessage = styled.div`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.red};
`

const SpinnerWrapperStyled = styled.div`
  flex: 0.45;
  height: fit-content;
`

type TFormData = {
  rules: {
    from?: string
    to?: string
    point?: string
    id?: string
  }[]
}

function prepareRulesToFormData(rules?: TVersionRule[]): TFormData['rules'] {
  if (rules) {
    return rules.map(({ from, to, point, id }) => ({
      from: String(from),
      to: String(to),
      point: String(point),
      id: String(id)
    }))
  }
  return []
}

function useIsSubmitDisabled({ watch }: UseFormReturn<TFormData>) {
  const {
    selectedVersion,
    rulesQuery: { data: rules }
  } = useScoringContext()

  const formData = watch()

  return useMemo(
    () => (
      selectedVersion
        ? isEqual(formData.rules, prepareRulesToFormData(rules))
        : false
    ),
    [formData, selectedVersion]
  )
}

function useResetFieldsHandle({ setValue }: UseFormReturn<TFormData>) {
  const {
    selectedVersion,
    rulesQuery: { data: rules }
  } = useScoringContext()

  const [isEditFormValuesInitialized, setIsEditFormValuesInitialized] = useState(false)

  const resetRuleFields = useCallback(
    (withResetInitialization: boolean = true) => {
      if (withResetInitialization) {
        setIsEditFormValuesInitialized(false)
      }

      setValue('rules', prepareRulesToFormData(rules))

      setTimeout(() => {
        setIsEditFormValuesInitialized(true)
      })
    },
    [rules]
  )

  useEffect(() => {
    if (selectedVersion) {
      resetRuleFields(false)
    }
  }, [selectedVersion, rules])

  return {
    resetRuleFields,
    isEditFormValuesInitialized
  }
}

function useFormSubmit({ getValues }: UseFormReturn<TFormData>) {
  const { mutateAsync: editRulesMutate } = useEditRulesQuery()
  const { configId } = useParams()

  const {
    selectedVersion,
    rulesQuery: { data: rules, refetch: refetchRules }
  } = useScoringContext()

  return useCallback(
    async (callback: () => void) => {
      await editRulesMutate({
        rules: getValues('rules'),
        version: selectedVersion?.version,
        snapshot: selectedVersion?.snapshot,
        id: configId
      })

      refetchRules()
      callback?.()
    },
    [selectedVersion, configId, rules]
  )
}

function useFormHandle() {
  const formReturn = useForm<TFormData>()
  const { control, watch, handleSubmit } = formReturn

  useFieldArray({ control, name: 'rules' })

  return {
    watch,
    control,
    ...useResetFieldsHandle(formReturn),
    handleSubmit,
    isSubmitDisabled: useIsSubmitDisabled(formReturn),
    submit: useFormSubmit(formReturn)
  }
}

const Rules = () => {
  const [isModalOpen, setModalIsOpen] = useState(false)

  const {
    rulesQuery: {
      data,
      isFetching: rulesIsFetching,
      isIdle: rulesIsIdle,
      isLoading: rulesIsLoading
    },
    historyVersionQuery: { isFetching, isIdle, isLoading },
    selectedVersion,
    isPreviewing,
    scoringConf: { data: scoringConfigData }
  } = useScoringContext()
  const { configId } = useParams()

  const {
    submit,
    control,
    isSubmitDisabled,
    resetRuleFields,
    watch,
    handleSubmit,
    isEditFormValuesInitialized
  } = useFormHandle()

  const rulesFields = watch('rules')
  const isEmptyFields = !!rulesFields?.find((elem) => !elem.from || !elem.point)

  const rulesData = useMemo<TVersionRule[]>(
    () => data || [],
    [control, data, configId, selectedVersion]
  )

  const handleModalClose = () => setModalIsOpen(false)

  const onSubmit = () => setModalIsOpen(true)
  if (!scoringConfigData || !configId) {
    return null
  }

  const rowTextOptions = scoringConfigData.find((score) => score.id === Number(configId))?.valueType

  return (
    <Wrapper>
      <TitleStyled>Rules</TitleStyled>
      <FormWrapStyled>
        {!rulesData
        || rulesIsIdle
        || rulesIsLoading
        || isIdle
        || isLoading
        || !selectedVersion
        || !isEditFormValuesInitialized
          ? (
            <SpinnerWrapperStyled>
              <Spinner />
            </SpinnerWrapperStyled>
          )
          : (
            <>
              <RulesStyled>
                {rulesData?.map((rule, index: number) => {
                  if (!rulesFields.find(({ id }) => id === String(rule.id))) {
                    return null
                  }

                  return (
                    <>
                      { rowTextOptions === 'AOSCORE' ? (
                        <RawBaseScore key={rule.id}>
                          TransUnion Score (AOSCORE) *
                          <CustomInput
                            handleChange={floatFormat}
                            name={`rules.${index}.from`}
                            control={control}
                            rules={{
                              required: true
                            }}
                          />
                          {' '}
                          = Plend score number
                        </RawBaseScore>
                      ) : (
                        <Rule key={rule.id}>
                          if value
                          {' '}
                          {mapOperators[rule?.rule]}
                          <CustomInput
                            handleChange={floatFormat}
                            name={`rules.${index}.from`}
                            control={control}
                            rules={{
                              required: true
                            }}
                          />
                          {rowTextOptions === 'YEAR' && 'years'}
                          {' '}
                          then assign
                          <CustomInput
                            handleChange={rulesFormat}
                            name={`rules.${index}.point`}
                            control={control}
                            rules={{
                              required: true
                            }}
                          />
                          points
                        </Rule>
                      )}
                    </>
                  )
                })}
              </RulesStyled>
              {isEmptyFields && <ErrorMessage> Please fill in all required fields</ErrorMessage>}
              <Button
                text="Save changes"
                onClick={handleSubmit(onSubmit)}
                disabled={isPreviewing && isSubmitDisabled}
              />
            </>
          )}

      </FormWrapStyled>
      <Modal
        isOpen={isModalOpen}
        isDisabled={isFetching || rulesIsFetching || rulesIsIdle || rulesIsLoading}
        handleClose={handleModalClose}
        handleDiscard={resetRuleFields}
        handleSave={() => submit(handleModalClose)}
      />
    </Wrapper>
  )
}

export default Rules
