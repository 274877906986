import { THeaderMapKey, TValidations, TValidationValues } from '../type'

const HEADERS: { [key in THeaderMapKey]: string } = {
  mainAccountValidation: 'Main account validation',
  obDeclinedValidation: 'OB declined validation',
  demographicAndBureauxValidation: 'Demographic and Bureaux criteria',
  borrowerAgeValidation: 'Borrower age validation',
  otherSocialLoanValidation: 'Other social loan validation in the DB',
  jaywingValidation: 'Jaywing validation',
  welshPostcodeValidation: 'Welsh postcode validation',
  englishScottishPostcodeValidation: 'English/ Scottish postcode validation'
}

const validationMap = [
  'mainAccountValidation',
  'obDeclinedValidation',
  'demographicAndBureauxValidation',
  ['otherSocialLoanValidation', 'welshPostcodeValidation', 'englishScottishPostcodeValidation', 'borrowerAgeValidation', 'jaywingValidation']
]

export function organizeValidationData(
  validationData: TValidations
): (TValidationValues | TValidationValues[])[] {
  function getDataByKey(key: string) {
    const validationDataValue = validationData[key as THeaderMapKey]
    if (!validationDataValue.length) {
      return null
    }
    return {
      header: HEADERS[key as THeaderMapKey],
      status: validationDataValue.every((item) => item.valid),
      value: validationDataValue
    }
  }

  const preparedValidationData = validationMap
    .map((mapItem) =>
      (Array.isArray(mapItem)
        ? mapItem.map((key) => getDataByKey(key))
        : getDataByKey(mapItem)))

  return removeNull(preparedValidationData)
}

function removeNull(array: any) {
  return array
    .filter((item: TValidationValues) => item)
    .map((item: TValidationValues) => (Array.isArray(item) ? removeNull(item) : item))
}
