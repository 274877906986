import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'
import React, { FC } from 'react'
import Sidebar, { sidebarWidth } from './Sidebar'
import Header, { headerHeight } from './Header'

const WrapStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`

const SidebarWrapStyled = styled.div`
  height: calc(100% - ${headerHeight}px);
`

const OutletWrapStyled = styled.div`
  height: calc(100% - ${headerHeight}px);
  width: calc(100% - ${sidebarWidth}px);
  background: ${({ theme }) => theme.colors.grey100};
`

const Main: FC = ({ children }) => (
  <WrapStyled>
    <Header/>
    <SidebarWrapStyled>
      <Sidebar/>
    </SidebarWrapStyled>
    <OutletWrapStyled>
      {children || <Outlet/>}
    </OutletWrapStyled>
  </WrapStyled>
)

export default Main
