import constate from 'constate'
import { useState } from 'react'
import { TAffordabilityFilterKey } from '../../base/types'

const useMetrics = () => {
  const [filterKey, setFilterKey] = useState<TAffordabilityFilterKey>('ESSENTIAL_EXPENDITURE')

  return {
    filterKey,
    setFilterKey
  }
}

export const [AffordabilityFilterProvider, useAffordabilityFilterContext] = constate(useMetrics)
