import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../../core/api/apiClient'
import { TAccountModify, TChartAccountBalance, TChartBalance, TChartIncome, TOverview } from './types'
import { useApplicationHeaderContext } from '../../applicationHeader/state/useApplicationHeader'
import { QueryKey } from '../applicationDetail/queries'

enum QueryKeys {
  BalanceChart = 'BalanceChart',
  Overview = 'Overview',
  IncomeChart = 'IncomeChart',
  RequestAccount = 'RequestAccount',
  AccountModify = 'AccountModify'
}

type TBalanceChartParams = {
  loanRequestId?: string
  chartType: 'COMBINED' | 'SEPARATE'
}

async function getOverview(loanRequestId?: string): Promise<TOverview> {
  try {
    const response = await apiClient(`/dashboard/loan-requests/${loanRequestId}/overview`)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function getIncomeChartData(loanRequestId?: string): Promise<TChartIncome[]> {
  try {
    const response = await apiClient.get<TChartIncome[]>(`/dashboard/loan-requests/${loanRequestId}/overview/chart/income`)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function getBalanceChartData(params: TBalanceChartParams): Promise<TChartBalance> {
  const { loanRequestId, chartType } = params
  try {
    const response = await apiClient(`/dashboard/loan-requests/${loanRequestId}/overview/chart/balance?chartType=${chartType}`)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useOverview(score?: number) {
  const key = QueryKeys.Overview
  const { headerQuery } = useApplicationHeaderContext()
  const { loanRequestId } = useParams()
  return useQuery(
    [key, loanRequestId],
    () => getOverview(loanRequestId),
    {
      enabled: !score && (!!headerQuery.data && headerQuery.data.status !== 'FAILED')
    }
  )
}

export function useIncomeChart() {
  const key = QueryKeys.IncomeChart
  const { loanRequestId } = useParams()
  return useQuery(
    [key, loanRequestId],
    () => getIncomeChartData(loanRequestId)
  )
}

export function useBalanceCharts(chartType: 'COMBINED' | 'SEPARATE') {
  const key = QueryKeys.BalanceChart
  const { loanRequestId } = useParams()
  const params = {
    loanRequestId,
    chartType
  }
  return useQuery(
    [key, params],
    () => getBalanceChartData(params),
    {
      cacheTime: 0
    }
  )
}

async function additionalAccount(loanRequestId?: number | string, underwriterId?: number) {
  try {
    const { data } = await apiClient(`dashboard/loan-requests/${loanRequestId}/underwriter/${underwriterId}/request-account`)
    return data
  } catch (e) {
    throw new Error('Error data')
  }
}

export function useAdditionalAccount() {
  const queryClient = useQueryClient()
  const { loanRequestId } = useParams()
  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()
  return useQuery(
    [QueryKeys.RequestAccount, loanRequestId],
    () => additionalAccount(loanRequestId, headerData?.underwriterId),
    {
      onSuccess: () => queryClient.invalidateQueries('HeaderQuery'),
      enabled: false
    }
  )
}

export function useAccountModifyQuery() {
  const { loanRequestId } = useParams()
  const keys = [QueryKeys.AccountModify, loanRequestId]
  return useQuery<TChartAccountBalance[], Error>(
    keys,
    async () => {
      try {
        const response = await apiClient(`/dashboard/loan-requests/${loanRequestId}/accounts`)
        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    }
  )
}

async function accountModify(accounts: TAccountModify, loanRequestId: string): Promise<void> {
  try {
    await apiClient.post(`/dashboard/loan-requests/${loanRequestId}/accounts`, accounts)
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useAccountModifyMutation() {
  const queryClient = useQueryClient()
  const { loanRequestId } = useParams()
  return useMutation<void, Error, TAccountModify>((params) =>
    accountModify(params, loanRequestId!), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.AccountModify)
      await queryClient.invalidateQueries('HeaderQuery')
      await queryClient.invalidateQueries(QueryKey.HistoryLog)
    }
  })
}
