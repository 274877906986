import { Column } from 'react-table'
import styled from 'styled-components/macro'
import { useMemo } from 'react'
import Table from '../../../core/components/table/Table'
import { HeaderCellStyled } from '../../../core/components/table/TableHeadRow'
import { formatToPound } from '../../../core/utils/currencyFormat'
import { BodyCellStyled } from '../../../core/components/table/TableBodyRow'
import { ClearScorePreview } from '../types'

interface PreviewTableProps {
  sourceData: ClearScorePreview
}

const TableWrapper = styled.div`
  max-width: 900px;

  ${HeaderCellStyled} {
    font-weight: 600;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkBlack};

    &:first-of-type {
      text-align: start;
    }

    &:last-of-type {
      text-align: center !important;
    }
  }

  ${BodyCellStyled} {
    font-weight: 400;
    text-align: center;

    &:last-of-type {
      text-align: center;
    }

    &:first-of-type {
      text-align: start;
      font-weight: 600;
    }
  }
`

const defaultColumn: Column = {
  Header: 'Notional Term',
  accessor: 'loanAmount',
  Cell: ({ value }) => formatToPound(value)
}

const PreviewTable = ({ sourceData }: PreviewTableProps) => {
  const preparedData = Object.values(sourceData.calculations)

  const columns = useMemo(() => {
    const dynamicColumns = sourceData.columnHeaders.map(
      (columnHeader): Column => ({
        Header: `${columnHeader} mo`,
        accessor: columnHeader,
        Cell: ({ value }) => `${value}%`
      })
    )

    return [defaultColumn, ...dynamicColumns]
  }, [sourceData.columnHeaders])

  return (
    <TableWrapper>
      <Table data={preparedData} columns={columns} />
    </TableWrapper>
  )
}

export default PreviewTable
