import React, { FC } from 'react'
import styled from 'styled-components/macro'
import Card, { CardStyled } from '../../../core/components/Card'
import InnerLayout, { HeaderStyled } from '../../../core/components/InnerLayout'
import Tabs from './Tabs'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { EligibilityProvider, useEligibilityContext } from '../state/eligibilityState'
import { ValidationRulesBaseTable } from '../../validationRulesBase'

const WrapStyled = styled.div`
  height: 100%;

  ${HeaderStyled} {
    ${flex({ justify: 'space-between', align: 'flex-start' })};
    flex-direction: column;
    min-height: 116px;
    position: relative;
    padding-bottom: 0;
  }

  ${CardStyled} {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
    overflow: auto;
    height: auto;
  }
`

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
`

const Eligibility: FC = () => {
  const { validationRulesQuery } = useEligibilityContext()
  return (
    <WrapStyled>
      <InnerLayout
        header={(
          <>
            <TitleStyled>Eligibility</TitleStyled>
            <Tabs />
          </>
        )}
      >
        <Card noPadding>
          <ValidationRulesBaseTable isEligibility validationRulesQuery={validationRulesQuery} />
        </Card>
      </InnerLayout>
    </WrapStyled>
  )
}

export default () => (
  <EligibilityProvider>
    <Eligibility />
  </EligibilityProvider>
)
