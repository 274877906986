import React, { FC, useState } from 'react'
import styled from 'styled-components/macro'
import { MenuItem, Menu, MenuProps } from '@mui/material'
import CustomModal from '../../../core/components/CustomModal'
import { fontFamily } from '../../../core/styles/mixins'
import { MoreIcon } from '../../../core/components/icons/MoreIcon'
import { ButtonCancelStyled, ButtonGroupStyled, ButtonSubmitStyled, TitleStyled } from './AddException'
import { useExceptionFormContext } from '../state/exceptionsState'
import { useDeleteValidationExceptionsMutation } from '../querries'

const DescriptionStyled = styled.p`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))`
  && .MuiPaper-root {
    border-radius: 3px;
    margin-top: 2px;
    min-width: 160px;
    color: ${({ theme }) => theme.colors.darkBlack};
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

    & .MuiMenuItem-root {
      ${fontFamily('Inter')};
      color: ${({ theme }) => theme.colors.darkBlack};
      padding: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    }

    & .MuiMenu-list {
      padding: 0;
    }
  }
`

const MoreButtonWrapper = styled.button<{ isOpen: boolean }>`
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 4px 7px 4px;
  background: ${({ isOpen, theme }) => (isOpen ? theme.colors.grey200 : 'transparent')};
`

type TProps = {
  exceptionId: string
  name: string
}
const DeleteException: FC<TProps> = ({ exceptionId, name }) => {
  const [isOpen, setOpenModal] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { selectedRow } = useExceptionFormContext()
  const { mutateAsync } = useDeleteValidationExceptionsMutation()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDelete = () => {
    mutateAsync(exceptionId)
      .then(() => {
        setAnchorEl(null)
        setOpenModal(false)
      })
  }
  if (selectedRow?.id === exceptionId) {
    return <></>
  }
  return (
    <>
      <MoreButtonWrapper isOpen={isOpen} onClick={handleClick}>
        <MoreIcon onClick={handleClick} />
      </MoreButtonWrapper>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setOpenModal(true)
            setAnchorEl(null)
          }}
        >
          Delete
        </MenuItem>
      </StyledMenu>
      <CustomModal isOpen={isOpen} onClose={() => setOpenModal(false)}>
        <TitleStyled>Delete exception</TitleStyled>
        <DescriptionStyled>{`Are you sure you want to delete ${name}?`}</DescriptionStyled>
        <ButtonGroupStyled>
          <ButtonCancelStyled onClick={() => setAnchorEl(null)}>Cancel</ButtonCancelStyled>
          <ButtonSubmitStyled onClick={handleDelete}>Delete</ButtonSubmitStyled>
        </ButtonGroupStyled>
      </CustomModal>
    </>
  )
}

export default DeleteException
