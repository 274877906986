import { FC, Fragment, ReactNode } from 'react'
import styled from 'styled-components/macro'
import Card, { CardStyled } from '../../../core/components/Card'
import { flex } from '../../../core/styles/mixins'
import Spinner from '../../../core/components/Spinner'

const WrapperStyled = styled(Fragment)`
  ${CardStyled}{
    ${flex({ justify: 'flex-start', align: 'flex-start' })};
    flex-direction: column;
    min-height: 400px;
  }
`

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  margin-top: 200px;
  width: 100%;
`

type TProps = {
  children: ReactNode,
  showSpinner: boolean
}

const ChartTemplate: FC<TProps> = ({ children, showSpinner }) => (
  <WrapperStyled>
    <Card>
      {
        showSpinner
          ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )
          : children
      }

    </Card>
  </WrapperStyled>
)

export default ChartTemplate
