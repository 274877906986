import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { ChevronRightIcon } from '../../../../core/components/icons/ChevronRightIcon'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'
import { TStatusAssessment } from '../type'

const LinkStyled = styled(NavLink)`
  margin-left: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  color ${({ theme }) => theme.colors.blue500}
`

const IconWrapStyled = styled.span`
  margin-left: 2px;
  display: inline-flex;
  align-items: center;
`

type TProps = {
  status: TStatusAssessment,
  id: number
}

const DeclinedDetailsLink: FC<TProps> = ({ status, id }) => {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  if (!isSocialCreditOrganization || status !== 'DECLINED') {
    return null
  }

  return (
    <LinkStyled end to={`/declined-details/${id}`}>
      <span>See details</span>
      <IconWrapStyled>
        <ChevronRightIcon width={16} height={16} />
      </IconWrapStyled>
    </LinkStyled>
  )
}

export default DeclinedDetailsLink
