import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'
import InnerLayout, { HeaderStyled } from '../../../../core/components/InnerLayout'
import ApplicationHeader from '../../../applicationHeader/components/ApplicationHeader'
import TabNavigation from './TabNavigation'
import Button, { BaseButton } from '../../../../core/components/Button'
import { ExportIcon } from '../../../../core/components/icons/ExportIcon'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import Assessment from './Assessment'
import { useExport } from '../queries'
import { useDeboucedCallback } from '../../../../core/hooks/useDeboucedCallback'
import { ApplicationHeaderProvider } from '../../../applicationHeader/state/useApplicationHeader'
import { useIsRescoring } from '../../../applicationHeader/hooks/useIsRescoring'

export const WrapStyled = styled.div`
  height: 100%;
  width: 100%;

  ${HeaderStyled} {
    padding: 0;
  }

`

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleStyled = styled.h1`
  ${fontFamily('Inter')};
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const TabsContainerStyled = styled.div<{ overlay?: boolean }>`
  ${flex({ justify: 'space-between', align: 'center' })};
  min-width: 1038px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
  margin: 0 0 0 -24px;
  position: relative;
  opacity: ${({ overlay }) => (overlay ? 0.5 : 1)};
  pointer-events: ${({ overlay }) => (overlay ? 'none' : 'auto')};

  ${BaseButton} {
    color: ${({ theme }) => theme.colors.darkBlack};
    background: ${({ theme }) => theme.colors.grey300};
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
    margin-right: 24px;

    :hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const ApplicationDetail = () => {
  const { refetch } = useExport()
  const isRescoring = useIsRescoring()
  const debouncedRefetch = useDeboucedCallback(refetch)

  return (
    <WrapStyled>
      <InnerLayout
        overlay={isRescoring}
        noBodyPadding
        header={(
          <ApplicationHeader
            header={(
              <ContentStyled>
                <Assessment />
              </ContentStyled>
            )}
            footer={(
              <TabsContainerStyled overlay={isRescoring}>
                <TabNavigation />
                <Button onClick={() => debouncedRefetch()} text="Export" startIcon={<ExportIcon />} />
              </TabsContainerStyled>
            )}
          />
        )}>
        <Outlet />
      </InnerLayout>
    </WrapStyled>
  )
}

export default () => (
  <ApplicationHeaderProvider>
    <ApplicationDetail />
  </ApplicationHeaderProvider>
)
