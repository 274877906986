import styled from 'styled-components/macro'
import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import { BaseButton } from '../../../../../core/components/Button'
import { flex, fontFamily } from '../../../../../core/styles/mixins'
import { CloseLineIcon } from '../../../../../core/components/icons/CloseLineIcon'
import { useClearReclassificationMutation, useRescoreMutation } from '../../queries'
import Modal from '../Modal'
import LeaveRescoreAvailableApplication from './LeaveRescoreAvailableApplication'
import { useHasPermission } from '../../../../auth/hooks'
import { useApplicationHeaderContext } from '../../../../applicationHeader/state/useApplicationHeader'

const ButtonGroupStyled = styled.div`
  ${flex({})};
  gap: 16px;
  margin: 16px 0 -8px 0;
`

const RescoreButtonStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px 16px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.white};
      opacity: .5;
    }
  }
`

const ClearButtonStyled = styled(BaseButton)`
  && {
    ${flex({ align: 'center', justify: 'center' })};
    gap: 8px;
    padding: 8px 16px;
    color: ${({ theme }) => theme.colors.red};
    background: ${({ theme }) => theme.colors.grey300};

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }

    & > p {
      ${fontFamily('Inter')};
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
`

function useInvalidate() {
  const queryClient = useQueryClient()
  return useCallback(async () => {
    await queryClient.invalidateQueries('HeaderQuery')
    await queryClient.invalidateQueries('Transactions')
    await queryClient.invalidateQueries('Categories')
    await queryClient.invalidateQueries('CategoriesTransactions')
  }, [])
}

const Rescore = () => {
  const invalidate = useInvalidate()
  const [isModalOpen, setModalOpen] = useState(false)
  const { mutateAsync: rescoreMutateAsync, isLoading: isRescoreLoading } = useRescoreMutation()
  const {
    mutateAsync: clearMutateAsync,
    isLoading: isClearLoading
  } = useClearReclassificationMutation()
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()
  const isUnderwriter = useHasPermission(['UNDERWRITER_VIEW'])

  if (!isUnderwriter || !headerData?.rescoreAvailable) {
    return <></>
  }
  return (
    <>
      <LeaveRescoreAvailableApplication />
      <Modal
        title="Clear reclassification"
        isOpen={isModalOpen}
        handleClose={() => setModalOpen(false)}
        isActionDisabled={isClearLoading}
        content={<p>Are you sure you want to clear the classification changes?</p>}
        buttonText="Clear"
        queryRequest={() => clearMutateAsync()
          .then(() => setModalOpen(false))
          .then(invalidate)}
      />
      <ButtonGroupStyled>
        <RescoreButtonStyled
          disabled={isRescoreLoading}
          onClick={() => rescoreMutateAsync()
            .then(invalidate)}
        >
          Rescore
        </RescoreButtonStyled>
        <ClearButtonStyled onClick={() => setModalOpen(true)}>
          <CloseLineIcon />
          <p>Clear changes</p>
        </ClearButtonStyled>
      </ButtonGroupStyled>
    </>
  )
}

export default Rescore
