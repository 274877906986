import styled from 'styled-components/macro'
import Card, { CardStyled } from '../../../../core/components/Card'
import { FailedValidationProvider } from '../state/useFailedValidationContext'
import InnerLayout, { HeaderStyled } from '../../../../core/components/InnerLayout'
import { Table } from './Table'
import { fontFamily } from '../../../../core/styles/mixins'
import Search from './Search'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'

const WrapStyled = styled.div`

  ${HeaderStyled} {
    position: relative;
  }

  ${CardStyled} {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;
    overflow: auto;
  }
`

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
`

const FailedValidation = () => {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  return (
    <FailedValidationProvider>
      <WrapStyled>
        <InnerLayout
          header={<TitleStyled>{isSocialCreditOrganization ? 'Declined details' : 'Failed Validation'}</TitleStyled>}>
          <Card noPadding>
            <Search />
            <Table />
          </Card>
        </InnerLayout>
      </WrapStyled>
    </FailedValidationProvider>
  )
}

export default FailedValidation
