import { useTheme } from 'styled-components/macro'
import { FC, useMemo } from 'react'
import ChartComposed from '../../../../core/components/charts/ChartComposed'
import Spinner from '../../../../core/components/Spinner'
import { useIncomeChart } from '../queries'

const Expenditure: FC = () => {
  const {
    colors: { red, blue500 }
  } = useTheme()

  const { isLoading, data } = useIncomeChart()

  const sortedChartData = useMemo(
    () =>
      (data
        ? data.sort((a, b) => (b.date > a.date ? -1 : 1))
        : []),
    [data]
  )

  if (!data || isLoading) {
    return <Spinner />
  }

  return (
    <ChartComposed
      isChartTypeBar
      chartData={sortedChartData}
      chartConfig={{
        isLegend: true,
        nameDataKey: 'date',
        valuesConfig: [
          {
            color: blue500,
            dataKey: 'data.Income'
          },
          {
            color: red,
            dataKey: 'data.Expenditure'
          }
        ]
      }}
    />
  )
}
export default Expenditure
