import constate from 'constate'
import { useState } from 'react'
import {
  useInProgressRequestsQuery,
  useOrganizationsQuery, usePartnerAdminQuery,
  useResendPasswordQuery,
  useUsersQuery
} from '../queries'
import { TUser } from '../types'

const useUsers = () => {
  const [selectedId, setSelectedId] = useState<number>()
  const [selectedRow, setSelectedRow] = useState<TUser>()
  const [currentPage, setCurrentPage] = useState(1)

  return {
    selectedId,
    setSelectedId,
    selectedRow,
    setSelectedRow,
    currentPage,
    setCurrentPage,
    usersQuery: useUsersQuery(currentPage),
    partnerAdminQuery: usePartnerAdminQuery(),
    resentPassword: useResendPasswordQuery(selectedId),
    inProgressRequestQuery: useInProgressRequestsQuery(selectedId),
    organizationsQuery: useOrganizationsQuery()
  }
}

export const [UsersProvider, useUsersContext] = constate(useUsers)
