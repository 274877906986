import { FC } from 'react'
import styled from 'styled-components/macro'
import CustomTooltip from '../../../../core/components/CustomTooltip'
import ChartTooltipContent from '../../../../core/components/charts/ChartTooltipContent'
import { InformationIcon } from '../../../../core/components/icons/InformationIcon'
import { TChartAccountBalance } from '../types'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import { useIsPlendOrganization } from '../../../auth/hooks'

const WrapperStyled = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
    gap: 4px;
`

const TextStyled = styled.span`
    ${fontFamily('Inter')};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
`

const IconWrapper = styled.div`
    ${flex({ justify: 'center', align: 'center' })};
    cursor: pointer;
    color: ${({ theme }) => theme.colors.grey600};
`
const CheckboxLabel: FC<TChartAccountBalance> = ({
  currentBalance,
  availableBalance, institutionName, accountMask, accountName
}) => {
  const tooltipContent = [
    {
      key: 'currentBalance',
      value: formatToPound(currentBalance)
    },
    {
      key: 'availableBalance',
      value: formatToPound(availableBalance)
    }
  ]
  const isPlend = useIsPlendOrganization()

  const isAnyLabel = institutionName || accountMask
  return (
    <WrapperStyled>
      {
        isAnyLabel && isPlend && (
          <TextStyled>
            {`${institutionName ?? ''} ${accountMask ? `·  ${accountMask}` : ''}`}
          </TextStyled>
        )
      }
      {
        !isPlend && (
          <TextStyled>
            {accountName}
          </TextStyled>
        )
      }

      <CustomTooltip
        arrow
        disableFocusListener
        disableTouchListener
        title={(
          <ChartTooltipContent
            tooltipContent={tooltipContent}
          />
        )}
        placement="top"
      >
        <IconWrapper>
          <InformationIcon width={isPlend ? 16 : 13} height={isPlend ? 16 : 13} />
        </IconWrapper>
      </CustomTooltip>
    </WrapperStyled>
  )
}
export default CheckboxLabel
