import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { FC, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { flex, fontFamily } from '../../../core/styles/mixins'
import {
  emailValidation,
  passwordValidation,
  requiredRule
} from '../../../core/utils/formRules'
import CustomInput from '../../../core/components/CustomInput'
import { MailIcon } from '../../../core/components/icons/MailIcon'
import { PasswordIcon } from '../../../core/components/icons/PasswordIcon'
import { useLoginMutation } from '../queries'
import { TFormData } from '../types'
import Card from '../../../core/components/Card'
import Logo from '../assets/Logo.svg'
import { WarningIcon } from '../../../core/components/icons/WarningIcon'
import { useAuthContext } from '../state/useAuthState'
import { ButtonSubmit, ContainerStyled, FormStyled } from './styled'

const LogoStyled = styled.img`
  margin: 8px 0 32px 0;
`

const TitleStyled = styled.h1`
  ${fontFamily('Inter')};
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const ArticleStyled = styled.div`
  width: 100%;
  ${flex({ justify: 'center', align: 'center' })};
  flex-direction: column;
`

const PasswordFieldContainer = styled.div`
  ${flex({})};
  flex-direction: column;
`

const InactivityMessage = styled.div`
  ${fontFamily('Inter')};
  width: 300px;
  height: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.red};
`

const ForgetPassword = styled(NavLink)`
  && {
    ${fontFamily('Inter')};
    width: auto;
    color: ${({ theme }) => theme.colors.grey600};
    justify-self: flex-end;
    align-self: flex-end;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;

    &:hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.grey600};
    }
`

const Login: FC = () => {
  const { handleSubmit, control, setError } = useForm<TFormData>({
    defaultValues: {
      email: '',
      password: ''
    }
  })
  const { mutate, isError, error } = useLoginMutation()
  const { isInactive, setIsInactive } = useAuthContext()
  useEffect(() => {
    if (isError) setError('email', { message: error?.message })
  }, [isError])

  return (
    <ContainerStyled>
      <Card>
        <ArticleStyled>
          <TitleStyled>Welcome to</TitleStyled>
          <LogoStyled src={Logo} key="logo" />
          {
            isInactive
            && (
              <InactivityMessage data-testid="inactivity-message">
                You have been logged out due to
                inactivity.
              </InactivityMessage>
            )
          }
        </ArticleStyled>

        <FormStyled onSubmit={handleSubmit(async (data) => {
          mutate(data)
          setIsInactive(false)
        })}>
          <CustomInput
            size="small"
            name="email"
            control={control}
            placeholder="Email"
            rules={{
              required: requiredRule('Please fill in all required fields.'),
              validate: emailValidation
            }}
            iconError={<WarningIcon width={20} height={20} />}
            icon={<MailIcon />}
          />
          <PasswordFieldContainer>
            <CustomInput
              type="password"
              size="small"
              name="password"
              control={control}
              placeholder="Password"
              rules={{
                required: requiredRule('Please fill in all required fields.'),
                validate: passwordValidation
              }}
              iconError={<WarningIcon width={20} height={20} />}
              icon={<PasswordIcon />}
            />
            <ForgetPassword to="/forgot-password">
              Forgot password ?
            </ForgetPassword>
          </PasswordFieldContainer>
          <ButtonSubmit data-testid="submit" type="submit">Log in</ButtonSubmit>
        </FormStyled>
      </Card>
    </ContainerStyled>
  )
}

export default Login
