import React, { FC, useEffect, useMemo, useState } from 'react'
import Tab from '../../../core/components/tabs/Tab'
import TabCustom from '../../../core/components/tabs/Tabs'
import { TFilter } from '../types'
import { useEligibilityContext } from '../state/eligibilityState'

const tabsMap: { name: string; key: TFilter }[] = [
  {
    name: 'Pre-approval',
    key: 'ELIGIBILITY_PRE_APPROVAL'
  },
  {
    name: 'Eligibility − 80%',
    key: 'ELIGIBILITY_80'
  },
  {
    name: 'Eligibility − 50%',
    key: 'ELIGIBILITY_50'
  }
]

function useTabsHandle() {
  const { setFiler, filter } = useEligibilityContext()

  const initialFilterIndex = tabsMap.findIndex(({ key }) => key === filter) || 0

  const [filerIndex, setFilerIndex] = useState(initialFilterIndex)

  const filterKey = useMemo(() => tabsMap[filerIndex].key, [filerIndex])

  useEffect(() => setFiler(filterKey), [filterKey])

  return {
    filerIndex,
    setFilerIndex
  }
}

const Tabs: FC = () => {
  const { filerIndex, setFilerIndex } = useTabsHandle()

  const tabNames = useMemo(() => tabsMap.map(({ name }) => name), [])

  return (
    <TabCustom
      value={filerIndex}
      onChange={(_, newValue) => setFilerIndex(newValue)}
    >
      {tabNames.map((name) => (
        <Tab key={name} label={name} />
      ))}
    </TabCustom>
  )
}

export default Tabs
