import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import { usePartnerDashboardContext } from '../../state/PartnerDashboardState'
import Modal from '../Modal'
import { BaseButton } from '../../../../core/components/Button'
import { fontFamily } from '../../../../core/styles/mixins'
import { DOT_DATE_FORMAT } from '../../../../core/const/date'

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const TextStyled = styled.span<{ isBold?: boolean }>`
  ${fontFamily('Inter')};
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isBold }) => (isBold ? 500 : 400)};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const ButtonStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 8px 16px;
    max-width: 70px;
    align-self: flex-end;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`
const FailedModal = () => {
  const { modalOpen, setModalOpen, checkLeadMutation: { data } } = usePartnerDashboardContext()
  const onClose = useCallback(() => setModalOpen({ failed: false }), [])
  if (!data) {
    return <></>
  }

  const {
    borrowerFirstName,
    borrowerLastName,
    createdAt,
    loanEndDate
  } = data

  return (
    <Modal
      open={modalOpen.failed}
      onClose={onClose}
      title="Partner link wasn't generated"
    >
      <ContentStyled>
        <TextStyled>
          Partner link for
          {' '}
          <TextStyled isBold>{`${borrowerFirstName} ${borrowerLastName}`}</TextStyled>
          {' '}
          wasn’t generated because this borrower has
          another outstanding Social Credit Cymru loan
          from
          {' '}
          <TextStyled isBold>
            {createdAt && format(new Date(createdAt), DOT_DATE_FORMAT)}
          </TextStyled>
          {' '}
          to
          {' '}
          <TextStyled isBold>
            {loanEndDate && `${format(new Date(loanEndDate), DOT_DATE_FORMAT)}.`}
          </TextStyled>
          {' '}
        </TextStyled>
        <ButtonStyled type="button" onClick={onClose}>Close</ButtonStyled>
      </ContentStyled>
    </Modal>
  )
}

export default FailedModal
