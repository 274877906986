import { FC } from 'react'
import { HeaderGroup } from 'react-table'
import { SortIcon } from '../icons/SortIcon'
import { SortDownIcon } from '../icons/SortDownIcon'
import { SortUpIcon } from '../icons/SortUpIcon'

type TProps = {
  headerGroup: HeaderGroup
}

const TableSortIcon: FC<TProps> = ({ headerGroup }) => {
  if (!headerGroup.isSorted) {
    return <SortIcon />
  }

  return headerGroup.isSortedDesc
    ? <SortDownIcon />
    : <SortUpIcon />
}

export default TableSortIcon
