import { useFailedValidationContext } from '../state/useFailedValidationContext'
import CustomSearch, { SearchWrapperStyled } from '../../../../core/components/CustomSearch'
import { integerFormat } from '../../../../core/utils/formUtils'

const Search = () => {
  const { setSearch } = useFailedValidationContext()
  return (
    <SearchWrapperStyled>
      <CustomSearch
        setSearch={setSearch}
        placeholder="Type application name"
        handleChange={integerFormat}
      />
    </SearchWrapperStyled>
  )
}

export default Search
