import styled, { css } from 'styled-components/macro'
import { format } from 'date-fns'
import { ChevronLeftIcon } from '../../../core/components/icons/ChevronLeftIcon'
import { ChevronRightIcon } from '../../../core/components/icons/ChevronRightIcon'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { useHomeContext } from '../state/useHome'
import { MONTH_YEAR_FORMAT } from '../../../core/const/date'

const ContainerStyled = styled.div`
  ${flex({ justify: 'space-between', align: 'center' })};
  color: ${({ theme }) => theme.colors.darkBlack};
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`

const TitleStyled = styled.p`
  ${fontFamily('Inter')};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 130px;
  margin: 0 8px;
  text-align: center;
`
const mixin = css`
  opacity: 0.5;
  cursor: not-allowed;
 `

const IconWrapper = styled.div<{isDisabled?: boolean}>`
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && mixin}
`

const MonthChanging = () => {
  const { date, handlePrevMonth, handleNextMonth, isNextMonthDisabled } = useHomeContext()

  return (
    <ContainerStyled>
      <IconWrapper>
        <ChevronLeftIcon onClick={handlePrevMonth}/>
      </IconWrapper>
      <TitleStyled>{format(date, MONTH_YEAR_FORMAT)}</TitleStyled>
      <IconWrapper isDisabled={isNextMonthDisabled}>
        <ChevronRightIcon onClick={handleNextMonth} />
      </IconWrapper>
    </ContainerStyled>
  )
}

export default MonthChanging
