import styled from 'styled-components/macro'
import NoDataComponent from './NoDataComponent'
import { SearchIcon } from './icons/SearchIcon'
import { flex, fontFamily } from '../styles/mixins'

const NoSearchResults = styled.div`
  ${flex({ align: 'center' })};
  flex-direction: column;
`

const TitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
  margin-top: 30px;
`

const MessageStyled = styled.div`
  ${fontFamily('Inter')};
  ${flex({ justify: 'center', align: 'center' })};
  flex-direction: column;
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey600};
`

const NoResults = () => (
  <NoDataComponent icon={<SearchIcon width={25} height={25} />}>
    <NoSearchResults>
      <TitleStyled>No results found.</TitleStyled>
      <MessageStyled>
        <div>Try adjusting your search to find what you’re looking for.</div>
        <div>
          If you are searching by ID,
          make sure this is an exact match for the application you are looking for.
        </div>
      </MessageStyled>
    </NoSearchResults>
  </NoDataComponent>
)

export default NoResults
