import { useMemo } from 'react'
import { Column } from 'react-table'
import styled from 'styled-components/macro'
import CustomTable from '../../../core/components/table/Table'
import { useKeywordsContext } from '../state/useKeywords'
import Spinner from '../../../core/components/Spinner'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { HeaderCellStyled } from '../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../core/components/table/TableBodyRow'

import Loader from './Loader'

const WrapperStyled = styled.div`
  min-height: 702px;
  ${HeaderCellStyled} {
    padding: 24px 24px 12.5px 24px;
    :first-child{
      padding-left: 16px;
    }
    :last-child{
      padding-right: 16px;
    }
  }

  ${BodyCellStyled} {
    ${fontFamily('Inter')};
    padding: 13.5px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.darkBlack};
    :first-child{
      padding-left: 16px;
      width: 84px;
    }
    :last-child{
      width: 24px;
      padding-right: 16px;
    }
  }
`

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 650px;
`

const KeywordsTable = () => {
  const {
    keywordsQuery: {
      data: tableData,
      isIdle,
      isLoading
    },
    setCurrentPage
  } = useKeywordsContext()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: 'Upload Date',
        accessor: 'uploadDate'
      },
      {
        Header: 'File Name',
        accessor: 'fileName'
      },
      {
        Header: 'Keywords',
        accessor: 'keywordNumber'
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ value }) => <Loader fileId={value} />
      }
    ],
    []
  )

  const keywords = useMemo(() => tableData?.content || [], [tableData])

  return (
    <WrapperStyled>
      <CustomTable
        columns={columns}
        data={keywords}
        paginationConfig={tableData && {
          isEnabled: tableData.totalPages > 1,
          pageSize: 10,
          manual: true,
          totalRows: tableData.totalElements,
          pageCount: tableData.totalPages,
          onPageChange: (page) => setCurrentPage(page)
        }}
        showRowBorder
      />
      {(isIdle || isLoading)
        && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
    </WrapperStyled>
  )
}

export default KeywordsTable
