import styled from 'styled-components/macro'
import { fontFamily } from '../../../../core/styles/mixins'

export const AmountStyled = styled.h1`
  ${fontFamily('Inter')};
  font-weight: 700;
  margin-top: 8px;
  font-size: 38px;
  line-height: 46px;
  color: ${({ theme }) => theme.colors.darkBlack};
`
