import { FC } from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { TVersionData } from '../types'
import { SLASH_DATE_FORMAT, TIME_FORMAT } from '../../../../core/const/date'
import { AUTH_LOCAL_STORAGE_KEY } from '../../../../core/const/localStorage'

const HistoryInfoStyled = styled.div`
  ${flex({})};
  flex-direction: column;
`

const UpdateStyled = styled.div`
  ${fontFamily('Inter')};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const DescriptionStyled = styled.div`
  ${fontFamily('Inter')};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey600};
`

type TProps = {
  data: TVersionData
}

const HistoryDescription: FC<TProps> = ({ data: { createdAt, updatedAt, snapshot, version } }) => {
  const { firstName, lastName } = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || ' ')

  return (
    <HistoryInfoStyled>
      <UpdateStyled>
        {`№${version}${snapshot > 0 ? `.${snapshot}` : ''} - ${format(new Date(createdAt), SLASH_DATE_FORMAT)} ${format(
          new Date(createdAt),
          TIME_FORMAT
        )} by ${firstName} ${lastName}`}
      </UpdateStyled>
      <DescriptionStyled>
        {
          !updatedAt
            ? (
              <>
                Current version used from
                {' '}
                {format(new Date(createdAt), SLASH_DATE_FORMAT)}
                {' '}
                {format(new Date(createdAt), TIME_FORMAT)}
              </>
            ) : (
              <>
                Used from
                {' '}
                {format(new Date(createdAt), SLASH_DATE_FORMAT)}
                {' '}
                {format(new Date(createdAt), TIME_FORMAT)}
                {' '}
                to
                {' '}
                {format(new Date(updatedAt), SLASH_DATE_FORMAT)}
                {' '}
                {format(new Date(updatedAt), TIME_FORMAT)}
                {' '}
              </>
            )
        }
      </DescriptionStyled>
    </HistoryInfoStyled>
  )
}

export default HistoryDescription
