import { useQuery } from 'react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  TAffordabilityFilterKey,
  TCategoriesData,
  TCategoriesGraphData,
  TCategory,
  TIncomeMetricsData,
  TMetricsKey,
  TTransactionData
} from './types'
import { apiClient } from '../../../../core/api/apiClient'
import { TSortBy } from '../../../../core/components/table/TableContext'
import { prepareTablePaginationRequestParams } from '../../../../core/utils/tableData'
import { useQueryLoadingStateReset } from '../../../../core/hooks/useQueryLoadingStateReset'

export enum QueryKey {
  Metrics = 'Metrics',
  Categories = 'Categories',
  CategoriesTransactions = 'CategoriesTransactions'
}

export function useMetricsQuery() {
  const { loanRequestId } = useParams()

  return useQuery<TIncomeMetricsData>([QueryKey.Metrics, loanRequestId], async () => {
    try {
      const { data } = await apiClient(`/dashboard/loan-requests/${loanRequestId}/metrics`)
      return data
    } catch (e) {
      throw new Error('useMetricsQuery')
    }
  })
}

export function useCategoriesQuery(type: TMetricsKey, filterKey?: TAffordabilityFilterKey | null) {
  const { loanRequestId } = useParams()
  const enabledQuery = () => {
    if (type === 'NON_ESSENTIAL_EXPENDITURE' || type === 'NON_DISCRETIONARY_EXPENDITURE') {
      if (type === 'NON_ESSENTIAL_EXPENDITURE' && filterKey === 'ESSENTIAL_EXPENDITURE') return false
      if (type === 'NON_DISCRETIONARY_EXPENDITURE'
        && (filterKey === 'DISCRETIONARY_SPEND' || filterKey === 'PART_DISCRETIONARY_SPEND')) return false
      return !!filterKey
    } return !filterKey
  }

  return useQuery<TCategoriesData>(
    [QueryKey.Categories, type, filterKey, loanRequestId],
    async ({ signal }) => {
      try {
        const { data } = await apiClient(`/dashboard/loan-requests/${loanRequestId}/categories`, {
          params: {
            type,
            category: filterKey
          },
          signal
        })
        return data
      } catch (e) {
        throw new Error('useCategoriesQuery')
      }
    },
    {
      enabled: enabledQuery()
    }
  )
}

export function useCategoriesGraphQuery(
  type: TMetricsKey,
  category?: TCategory,
  filterKey?: TAffordabilityFilterKey | null
) {
  const { loanRequestId } = useParams()

  return useQuery<TCategoriesGraphData>(
    [QueryKey.Categories, type, filterKey, loanRequestId, category?.firstClass],
    async ({ signal }) => {
      try {
        const { data } = await apiClient(`/dashboard/loan-requests/${loanRequestId}/categories/graph`, {
          params: {
            type,
            category: filterKey,
            taxonomyClass: category?.firstClass
          },
          signal
        })
        return data
      } catch (e) {
        throw new Error('useCategoriesGraphQuery')
      }
    },
    {
      enabled: !!category,
      cacheTime: 0
    }
  )
}

function useResetLoadingStateOnCategoryChange(selectedCategory?: TCategory) {
  const queryFullReset = useQueryLoadingStateReset(QueryKey.CategoriesTransactions)

  useEffect(() => {
    if (selectedCategory) {
      queryFullReset()
    }
  }, [selectedCategory])
}

export function useTransactionsQuery(
  type: TMetricsKey,
  page: number,
  sortBy?: TSortBy,
  selectedCategory?: TCategory,
  filterKey?: TAffordabilityFilterKey
) {
  useResetLoadingStateOnCategoryChange(selectedCategory)

  const { loanRequestId } = useParams()
  const enabledQuery = () => {
    if (type === 'NON_ESSENTIAL_EXPENDITURE' || type === 'NON_DISCRETIONARY_EXPENDITURE') {
      if (type === 'NON_ESSENTIAL_EXPENDITURE' && selectedCategory?.firstClass) return !!filterKey
      if (type === 'NON_DISCRETIONARY_EXPENDITURE' && selectedCategory?.firstClass) return !!filterKey
    }
    return !!selectedCategory?.firstClass
  }
  return useQuery<TTransactionData>(
    [QueryKey.CategoriesTransactions, selectedCategory?.firstClass, page, sortBy, loanRequestId],
    async ({ signal }) => {
      try {
        const { data } = await apiClient(
          `/dashboard/loan-requests/${loanRequestId}/taxonomy/transactions`,
          {
            params: {
              ...prepareTablePaginationRequestParams(
                page,
                10,
                sortBy,
                selectedCategory?.firstClass
              ),
              type,
              category: filterKey
            },
            signal
          }
        )
        return data
      } catch (e) {
        throw new Error('useTransactionsQuery')
      }
    },
    {
      keepPreviousData: !!selectedCategory?.firstClass,
      cacheTime: 0,
      enabled: enabledQuery()
    }
  )
}
