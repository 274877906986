import styled from 'styled-components/macro'
import { Switch, SwitchProps } from '@mui/material'
import { FC } from 'react'

const SwitchStyled = styled(Switch)<SwitchProps>`
  &&{
    width: 44px;
    height: 24px;
    padding: 0;
    & .MuiSwitch-switchBase{
      margin: 2px 2px 1px 2px;
      padding: 0; 
      &.Mui-checked {
        transform: translateX(20px);
        color: ${({ theme }) => theme.colors.white};
        & + .MuiSwitch-track {
          background-color: ${({ theme }) => theme.colors.blue500};
          opacity: 1;
          border: none;
        }
      }
      &.Mui-focusVisible .MuiSwitch-thumb {
        color: ${({ theme }) => theme.colors.blue500};
        border:none;
      }
    }
  & .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
  },
  & .MuiSwitch-track{
    border-radius: 13px;
    border: none;
    background-color: ${({ theme }) => theme.colors.grey600a6};
    opacity: 1;
    transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1),
    border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`

const CustomSwitch: FC<SwitchProps> = (props) => (
  <SwitchStyled
    disableRipple
    focusVisibleClassName=".Mui-focusVisible"
    {...props}/>
)

export default CustomSwitch
