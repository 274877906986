import React, { useMemo } from 'react'
import { Column } from 'react-table'
import { format } from 'date-fns'
import styled, { css } from 'styled-components/macro'
import CustomTable from '../../../core/components/table/Table'
import { TLoanLinkStatus, TPartnerDashboardContent } from '../types'
import { SLASH_DATE_FORMAT } from '../../../core/const/date'
import { HeaderCellStyled } from '../../../core/components/table/TableHeadRow'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { BodyCellStyled } from '../../../core/components/table/TableBodyRow'
import { FileCopyIcon } from '../../../core/components/icons/FileCopyIcon'
import { useHandleCopy } from '../../../core/hooks/useHandleCopy'
import { usePartnerDashboardContext } from '../state/PartnerDashboardState'
import { WrapStyled } from '../../../core/components/table/Pagination'
import Spinner from '../../../core/components/Spinner'
import NoDataComponent, { ContainerStyled } from '../../../core/components/NoDataComponent'
import { TitleStyled } from '../../applications/applicationDetail/components/ApplicationDetail'

const tablePaddingMixin = css`
  :first-child {
    padding: 16px 16px 16px 0;
  }

  :nth-child(2) {
    padding: 16px 16px 16px 0;
  }

  :nth-child(3) {
    padding: 16px 16px 16px 0;
  }

  :nth-child(4) {
    padding: 16px 16px 16px 0;
  }

  :last-child {
    padding: 16px 0;
  }
`

const WrapperStyled = styled.div`
  &&& {

    ${WrapStyled} {
      margin: 24px 0 0 0;
    }

    ${HeaderCellStyled} {
      ${fontFamily('Inter')};
      padding: 24px 16px 16px;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      ${tablePaddingMixin};

      :first-child {
        min-width: 172px;
        width: 188px;
      }

      :nth-child(3) {
        min-width: 96px;
      }

      :last-child {
        text-align: left;
        min-width: 90px;
        padding-left: 0;
      }

    }

    ${BodyCellStyled} {
      ${fontFamily('Inter')};
      padding: 16px;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      ${tablePaddingMixin};
    }
  }
`
const LinkWrapperStyled = styled.div`
  ${flex({ align: 'center' })};
  gap: 4px;


  & > p {
    ${fontFamily('Inter')};
    color: ${({ theme: { colors } }) => colors.darkBlack};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 528px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const IconWrapperStyled = styled.div`
  ${flex({ justify: 'center' })}
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.grey600};
`

const StatusStyled = styled.div <{ status: TLoanLinkStatus }>`
  ${fontFamily('Inter')}
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 3px;
  color: ${({ theme: { colors }, status }) => (status === 'ACTIVE' ? colors.green800 : colors.red800)};
  background: ${({ theme: { colors }, status }) => (status === 'ACTIVE' ? colors.green300 : colors.red300)};
  padding: 2px 12px;
  text-align: center;
`

const NoDataWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 600px;

  ${ContainerStyled} {
    margin-top: 0;
  }
`

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 650px;
`

const MessageStyled = styled.span`
  ${fontFamily('Inter')};
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey600};
`

const NoResultsStyled = styled.div`
  ${flex({ align: 'center' })};
  flex-direction: column;
`

const statusMap: { [key in TLoanLinkStatus]: string } = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired'
}

const PartnerDashboardTable = () => {
  const {
    partnerDashboardQuery: { data, isLoading },
    setPage,
    search
  } = usePartnerDashboardContext()
  const handleCopy = useHandleCopy()
  const columns = useMemo<Column<TPartnerDashboardContent>[]>(() => [
    {
      Header: 'Name',
      accessor: (row) => {
        const { firstName, lastName } = row
        return `${firstName} ${lastName}`
      }
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => format(new Date(value), SLASH_DATE_FORMAT)
    },
    {
      Header: 'Link',
      accessor: 'loanLink',
      Cell: ({ value }) => (
        <LinkWrapperStyled>
          <p>{value}</p>
          <IconWrapperStyled>
            <FileCopyIcon width={20} height={20} onClick={() => handleCopy(value)} />
          </IconWrapperStyled>
        </LinkWrapperStyled>
      )
    },
    {
      Header: 'Status',
      accessor: 'loanLinkStatus',
      Cell: ({ value }) => (
        <StatusStyled status={value}>
          {statusMap[value]}
        </StatusStyled>
      )
    }

  ], [data])

  return (
    <WrapperStyled>
      <CustomTable<TPartnerDashboardContent>
        columns={columns}
        data={data?.content || []}
        paginationConfig={data && {
          isEnabled: data?.totalPages > 1,
          pageSize: 10,
          manual: true,
          pageCount: data?.totalPages,
          totalRows: data?.totalElements,
          onPageChange: (page) => setPage(page)
        }}
        showRowBorder
      />
      {isLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!data?.content.length && (
        <NoDataWrapper>
          <NoDataComponent
            iconWidth={52}
            iconHeight={48}
            text={!search ? 'It seems like you don\'t have any data yet' : ''}>
            {
              search && (
                <NoResultsStyled>
                  <TitleStyled>No results found</TitleStyled>
                  <MessageStyled>
                    Try adjusting your search to find what you’re looking for
                  </MessageStyled>
                </NoResultsStyled>
              )
            }
          </NoDataComponent>
        </NoDataWrapper>
      )}
    </WrapperStyled>
  )
}

export default PartnerDashboardTable
