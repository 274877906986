import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import constate from 'constate'
import { usePrevious } from 'react-use'
import { useMetricsQuery } from '../queries'
import { TMetricsKey } from '../types'

const incomePathname = 'income'
const affordabilityPathname = 'affordability'

function useIsIncomeMetric(activeMetricKey: TMetricsKey) {
  return (['TOTAL_INCOME', 'ELIGIBLE_INCOME', 'INELIGIBLE_INCOME'] as TMetricsKey[]).includes(activeMetricKey)
}

function useMetricChangeHandle(activeMetricKey: TMetricsKey) {
  const isIncomeMetric = useIsIncomeMetric(activeMetricKey)

  const navigate = useNavigate()

  useEffect(() => {
    navigate(isIncomeMetric ? incomePathname : affordabilityPathname)
  }, [isIncomeMetric])
}

function useLocationChangeHandle(
  activeMetricKey: TMetricsKey,
  setActiveMetricKey: (key: TMetricsKey) => void
) {
  const { pathname } = useLocation()
  const isIncomeMetric = useIsIncomeMetric(activeMetricKey)

  const previousPathName = usePrevious(pathname)

  useEffect(() => {
    if (pathname.includes('affordability') && previousPathName && previousPathName.includes('income') && isIncomeMetric) {
      setActiveMetricKey('NON_DISCRETIONARY_EXPENDITURE')
      return
    }

    if (pathname.includes('income') && previousPathName && previousPathName.includes('affordability') && !isIncomeMetric) {
      setActiveMetricKey('TOTAL_INCOME')
    }
  }, [pathname])
}

const useMetrics = () => {
  const defaultActiveMetricKey: TMetricsKey = useLocation().pathname.includes(incomePathname) ? 'TOTAL_INCOME' : 'NON_DISCRETIONARY_EXPENDITURE'
  const [activeMetricKey, setActiveMetricKey] = useState<TMetricsKey>(defaultActiveMetricKey)
  useMetricChangeHandle(activeMetricKey)
  useLocationChangeHandle(activeMetricKey, setActiveMetricKey)

  const metricsQuery = useMetricsQuery()

  return {
    metricsQuery,
    activeMetricKey,
    setActiveMetric: useCallback((type: TMetricsKey) => setActiveMetricKey(type), [])
  }
}

export const [MetricsProvider, useMetricsContext] = constate(useMetrics)
