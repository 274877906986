import { ScoreType, TDetailedScoreTable, TKey } from '../types'

type TTitles = {
  totalScore: string
  negativeScore: string
  positiveScore: string
  [key: string]: string
}

const titles: TTitles = {
  totalScore: 'Plend score',
  negativeScore: 'Negative score',
  positiveScore: 'Positive score'
}

export const formatObjectToArray = (detailedScore?: TDetailedScoreTable) => {
  if (detailedScore) {
    const { data, ...scores } = detailedScore
    return Object
      .keys(scores)
      .map((key) => ({
        title: titles[key],
        score: scores[key],
        scoreType: ScoreType[key as TKey]
      }))
  }
  return []
}
