import { useCallback, useState } from 'react'
import constate from 'constate'
import { useParams } from 'react-router-dom'
import { useTransactionsClassesOptionsQuery, useTransactionsQuery } from '../queries'
import { TSortBy } from '../../../../core/components/table/TableContext'
import { TTransaction } from '../types'

function useClasses() {
  const [selectedFirstClasses, setSelectedFirstClasses] = useState<string[] | undefined>()
  const [selectedSecondClasses, setSelectedSecondClasses] = useState<string[] | undefined>()
  const [firstClassExactMatch, setFirstClassExactMatch] = useState(false)
  const [secondClassExactMatch, setSecondClassExactMatch] = useState(false)

  const [firstClassSearch, setFirstClassSearch] = useState('')
  const [secondClassSearch, setSecondClassSearch] = useState('')

  return {
    setSelectedFirstClasses,
    setSelectedSecondClasses,
    selectedFirstClasses,
    selectedSecondClasses,
    setFirstClassSearch,
    setSecondClassSearch,
    setFirstClassExactMatch,
    setSecondClassExactMatch,
    firstClassOptionsQuery: useTransactionsClassesOptionsQuery(
      'first',
      firstClassSearch,
      selectedSecondClasses?.join('; '),
      false,
      firstClassExactMatch
    ),
    secondClassOptionsQuery: useTransactionsClassesOptionsQuery(
      'second',
      selectedFirstClasses?.join('; '),
      secondClassSearch,
      false,
      secondClassExactMatch
    )
  }
}

const useTransactions = () => {
  const [selectedTransactions, setSelectedTransactions] = useState<TTransaction[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState<TSortBy | undefined>({
    id: 'date',
    desc: true
  })

  const { loanRequestId } = useParams()

  const classes = useClasses()

  return {
    currentPage,
    search,
    sortBy,
    selectedTransactions,
    setSelectedTransactions,
    setCurrentPage,
    setSearch,
    setSortBy,
    classes,
    handleClearTransactions: useCallback(() => setSelectedTransactions([]), []),
    transactionsQuery: useTransactionsQuery(
      currentPage,
      search,
      {
        firstClasses: classes.selectedFirstClasses,
        secondClasses: classes.selectedSecondClasses
      },
      sortBy,
      loanRequestId
    )
  }
}

export const [TransactionsProvider, useTransactionContext] = constate(useTransactions)
