import { useMutation, useQuery } from 'react-query'
import { apiClient } from '../../../core/api/apiClient'
import { prepareTablePaginationRequestParams } from '../../../core/utils/tableData'
import { TFailedValidationData, TLoanDetailsDocument, TTableState } from './types'
import { useIsSocialCreditOrganization } from '../../auth/hooks'
import { TBureauxDocument } from '../../applications/bureaux/types'

export enum QueryKey {
  FailedValidation = 'FailedValidation',
  LoanDetailsDocument = 'LoanDetailsDocument'
}

export async function getFailedValidation(
  tableState: TTableState,
  status: string,
  signal?: AbortSignal
): Promise<TFailedValidationData> {
  const { currentPage, search, sortBy } = tableState
  try {
    const { data } = await apiClient('dashboard/loan-requests', {
      params: {
        ...prepareTablePaginationRequestParams(currentPage, 10, sortBy),
        status,
        search
      },
      signal
    })
    return data
  } catch (error) {
    throw new Error('Error data')
  }
}

export function useFailedValidationQuery(tableState: TTableState) {
  const key = [QueryKey.FailedValidation, tableState]

  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  return useQuery(
    key,
    ({ signal }) => getFailedValidation(tableState, isSocialCreditOrganization ? 'DECLINED' : 'FAILED', signal),
    {
      keepPreviousData: true,
      cacheTime: 0
    }
  )
}
