import styled from 'styled-components/macro'
import React, { useCallback } from 'react'
import Modal from '../Modal'
import { FileCopyIcon } from '../../../../core/components/icons/FileCopyIcon'
import { useHandleCopy } from '../../../../core/hooks/useHandleCopy'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { BaseButton } from '../../../../core/components/Button'
import { usePartnerDashboardContext } from '../../state/PartnerDashboardState'

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const TextWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TextStyled = styled.p`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  max-width: 398px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const TextCopyWrapperStyled = styled.div`
  ${flex({ align: 'center' })};
  gap: 8px;

`
const IconWrapperStyled = styled.div`
  ${flex({ justify: 'center' })}
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.grey600};
`

const ButtonStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 8px 16px;
    max-width: 70px;
    align-self: flex-end;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const SuccessModal = () => {
  const handleCopy = useHandleCopy()
  const { modalOpen, setModalOpen, generateLinkMutation: { data } } = usePartnerDashboardContext()
  const onClose = useCallback(() => setModalOpen({ success: false }), [])
  if (!data) {
    return <></>
  }
  const { firstName, lastName, loanLink } = data
  return (
    <Modal
      open={modalOpen.success}
      onClose={onClose}
      title="Partner link was generated"
    >
      <ContentStyled>
        <TextWrapperStyled>
          <TextStyled>{`Partner link for ${firstName} ${lastName}:`}</TextStyled>
          <TextCopyWrapperStyled>
            <TextStyled>
              {loanLink}
            </TextStyled>
            <IconWrapperStyled>
              <FileCopyIcon width={20} height={20} onClick={() => handleCopy(loanLink)} />
            </IconWrapperStyled>
          </TextCopyWrapperStyled>
        </TextWrapperStyled>
        <ButtonStyled type="button" onClick={onClose}>Close</ButtonStyled>
      </ContentStyled>
    </Modal>
  )
}

export default SuccessModal
