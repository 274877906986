import { Column, Row } from 'react-table'
import { ReactNode, useMemo } from 'react'
import styled from 'styled-components/macro'
import Table from '../../../../core/components/table/Table'
import { WarningIcon } from '../../../../core/components/icons/WarningIcon'
import { ChevronRightIcon } from '../../../../core/components/icons/ChevronRightIcon'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { useRiskContext } from '../state/useRisk'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { HelpIcon } from '../../../../core/components/icons/HelpIcon'
import { CheckIcon } from '../../../../core/components/icons/CheckIcon'
import { TGamblingData, TGamblingStatus } from '../types'
import Spinner from '../../../../core/components/Spinner'

const Wrapper = styled.div`
  && .Mui-selected {
    background-color: ${({ theme }) => theme.colors.blue300};

    &:hover {
      background-color: ${({ theme }) => theme.colors.blue300};
    }
  }

  padding-top: 24px;

  && {
    ${HeaderCellStyled} {
      display: none;
    }

    ${BodyCellStyled} {
      padding: 8px 0;
    }
  }
`

const HeaderInfoContainerStyled = styled.div`
  ${flex({ justify: 'flex-start', align: 'center' })};

  & > div {
    margin-left: 8px;
    ${flex({ justify: 'center', align: 'flex-start' })};
    flex-direction: column;
  }

  color: ${({ theme }) => theme.colors.darkBlack};
  padding-left: 16px;
`

const LabelStyled = styled.span<{ selected: boolean }>`
  ${fontFamily('Inter')};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  font-size: 16px;
  line-height: 22px;
`

const CaptionStyled = styled.span<{ selected: boolean }>`
  ${fontFamily('Inter')};
  font-weight: ${({ selected }) => (selected ? 400 : 'normal')};
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme, selected }) => (selected ? theme.colors.darkBlack : theme.colors.grey600)};
`

const IconStyled = styled.div`
  color: ${({ theme }) => theme.colors.grey600};
`

const RateContainerStyled = styled.div`
  ${flex({ justify: 'flex-end', align: 'center' })};
  padding-right: 16px;
`
const RateStyled = styled.span`
  ${fontFamily('Inter')};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-right: 8px;
`

const ICONS: { [key in TGamblingStatus]: ReactNode } = {
  NEGATIVE: <WarningIcon width={24} height={24} />,
  NEUTRAL: <HelpIcon width={24} height={24} />,
  POSITIVE: <CheckIcon width={24} height={24} />
}

const CustomTable = () => {
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: '',
        accessor: 'name',
        Cell: ({
          row: {
            original: { name, description, status },
            isSelected
          }
        }: any) => (
          <HeaderInfoContainerStyled>
            {ICONS[status as TGamblingStatus]}
            <div>
              <LabelStyled selected={isSelected}>{name}</LabelStyled>
              <CaptionStyled selected={isSelected}>{description}</CaptionStyled>
            </div>
          </HeaderInfoContainerStyled>
        )
      },
      {
        Header: 'Value',
        accessor: 'value',
        Cell: ({ value }) => (
          <RateContainerStyled>
            <RateStyled>{value}</RateStyled>
            <IconStyled>
              <ChevronRightIcon width={24} height={24} />
            </IconStyled>
          </RateContainerStyled>
        )
      }
    ],
    []
  )

  const {
    risksQuery: { data, isLoading },
    selectedCategory,
    setSelectedCategory
  } = useRiskContext()

  const tableData = useMemo(() => data || [], [data, selectedCategory])

  if (!data && !selectedCategory && isLoading) return <Spinner />
  return (
    <Wrapper>
      <Table
        columns={columns}
        data={tableData}
        selectionConfig={
          selectedCategory && {
            rowIdentityProp: 'category',
            selectedRowIds: selectedCategory.category
          }
        }
        onRowClick={({ original }: Row<TGamblingData>) => setSelectedCategory(original)}
      />
    </Wrapper>
  )
}

export default CustomTable
