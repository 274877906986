import { FormControlLabel as Label } from '@mui/material'
import React, { FC, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import CustomAutoComplete from '../../../core/components/CustomAutoComplete'
import { requiredRule } from '../../../core/utils/formRules'
import { useUsersContext } from '../state/useUserManagement'

function useOptions() {
  const { partnerAdminQuery: { data } } = useUsersContext()

  return useMemo(() => (
    data ? data.content.map(({ name }) => ({ value: name, label: name }))
      : []
  ), [])
}

type TProps = {
  isDisabled?: boolean
}

const PartnerAdminDropDown: FC<TProps> = ({ isDisabled }) => {
  const { control } = useFormContext()
  const options = useOptions()

  return (
    <Label
      control={(
        <CustomAutoComplete
          disabled={isDisabled}
          name="organizationName"
          placeholder="Select or search"
          control={control}
          options={options}
          rules={{
            required: requiredRule('This field is required')
          }}
        />
      )}
      label="Organization"
      labelPlacement="top"
    />

  )
}

export default PartnerAdminDropDown
