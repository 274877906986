import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { CardStyled } from '../../../../core/components/Card'
import { useBureauxQuery, useDownloadBureauxQuery } from '../queries'
import Spinner from '../../../../core/components/Spinner'
import BureauxCard from './BureauxCard'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { InformationIcon } from '../../../../core/components/icons/InformationIcon'
import Button, { BaseButton } from '../../../../core/components/Button'
import { DownloadIcon } from '../../../../core/components/icons/DownloadIcon'
import { useDeboucedCallback } from '../../../../core/hooks/useDeboucedCallback'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px;

  ${BaseButton} {
    color: ${({ theme }) => theme.colors.darkBlack};
    background: ${({ theme }) => theme.colors.grey300};
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
    margin-right: 24px;

    :hover {
      background: ${({ theme }) => theme.colors.grey500};
    }
  }
`

const ContentStyled = styled.div`
  ${flex({ justify: 'space-between', align: 'flex-start' })};
`

const TableWrapper = styled.div`
  ${flex({ justify: 'center' })};
  width: 100%;
  padding: 24px 24px;

  & > :first-child {
    max-width: 588px;
    width: 100%;
    margin-right: 24px;
  }

  & > :last-child {
    max-width: 588px;
    width: 100%;
  }

  ${CardStyled} {
    height: fit-content;
    padding: 16px 24px 10px;
    margin-bottom: 24px;
    cursor: default;
  }
`

const ColumnStyled = styled.div`
  & > :last-child {
    margin-bottom: 0;
  }
`

const InfoMessage = styled.div`
  display: flex;
  margin-left: 30px;
`

const InfoText = styled.div`
  margin-left: 6px;
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const Bureaux = () => {
  const { data } = useBureauxQuery()
  const { refetch: downloadBureaux } = useDownloadBureauxQuery()

  const debouncedDownloadBureaux = useDeboucedCallback(downloadBureaux)

  const bureauxData = useMemo(() => data || [], [data])

  const firstColumn = bureauxData.slice(0, 6)
  const secontColumn = bureauxData.slice(6)

  if (!data) return <Spinner />

  return (
    <Wrapper>
      <ContentStyled>
        <InfoMessage>
          <InformationIcon />
          <InfoText>Hover over the values to see the definitions</InfoText>
        </InfoMessage>
        <Button
          onClick={() => debouncedDownloadBureaux()}
          text="Download bureaux data"
          startIcon={<DownloadIcon width={16} height={16} />}
        />
      </ContentStyled>
      <TableWrapper>
        <ColumnStyled>
          {firstColumn.map((elem) => <BureauxCard bureauxData={elem} />)}
        </ColumnStyled>
        <ColumnStyled>
          {secontColumn.map((elem) => <BureauxCard bureauxData={elem} />)}
        </ColumnStyled>
      </TableWrapper>
    </Wrapper>
  )
}

export default Bureaux
