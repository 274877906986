import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient } from '../../core/api/apiClient'
import {
  TCreateLinkParams,
  TGenerateLinkForm,
  TLeadCheckResponse,
  TPartnerDashboard, TPartnerDashboardContent,
  TPartnerDashboardParams
} from './types'
import { prepareTablePaginationRequestParams } from '../../core/utils/tableData'

enum QueryKeys {
  PartnerDashboard = 'PartnerDashboard',
}

async function partnerDashboard(
  { search, page }: TPartnerDashboardParams
): Promise<TPartnerDashboard> {
  try {
    const response = await apiClient('/dashboard/leads', {
      params: {
        search,
        ...prepareTablePaginationRequestParams(page, 10)
      }
    })
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export const usePartnerDashboardQuery = (params: TPartnerDashboardParams) => {
  const keys = [QueryKeys.PartnerDashboard, params.page, params.search]

  return useQuery<TPartnerDashboard, Error>(
    keys,
    () => partnerDashboard(params),
    {
      keepPreviousData: true
    }
  )
}

async function checkLead(params: TGenerateLinkForm): Promise<TLeadCheckResponse> {
  try {
    const response = await apiClient('/dashboard/leads/create/check-availability', { params })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCheckLeadMutation = () => useMutation<TLeadCheckResponse, Error, TGenerateLinkForm>(
  (params) => checkLead(params)
)

async function generateLink(params: TCreateLinkParams): Promise<TPartnerDashboardContent> {
  try {
    const response = await apiClient.post('/dashboard/leads/create', params)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export const useGenerateLinkMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<
    TPartnerDashboardContent, Error, TCreateLinkParams>(
      (params) => generateLink(params),
      {
        onSuccess: () => queryClient.invalidateQueries([QueryKeys.PartnerDashboard])
      }
    )
}
