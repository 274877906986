import styled from 'styled-components/macro'
import React, { ChangeEvent } from 'react'
import { flex, fontFamily } from '../../../core/styles/mixins'
import CustomProgressBar from './CustomProgressBar'
import { useKeywordsContext } from '../state/useKeywords'

const TitleStyled = styled.h1`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.darkBlack};
  margin-bottom: 8px;
`

const ContentStyled = styled.div`
  ${flex({ align: 'center' })};
`

const LabelStyled = styled.label<{isDisabled: boolean}>`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.blue700 : theme.colors.blue500)};
  margin-right: 32px;
  cursor: pointer;
  border-radius: 3px;
  :hover{
      background: ${({ theme, isDisabled }) => (!isDisabled ? theme.colors.blue700 : theme.colors.blue500)};
  }
`

const InputStyled = styled.input`
  display: none;
`

const KeywordsHeader = () => {
  const {
    upload: {
      uploadFile,
      uploadReset,
      isLoading,
      progressbarValue
    }
  } = useKeywordsContext()

  const isDisabled = (isLoading || progressbarValue?.progress < 100)

  return (
    <>
      <TitleStyled>
        Keywords
      </TitleStyled>
      <ContentStyled>
        <LabelStyled isDisabled={isLoading}>
          Upload Keywords
          <InputStyled
            disabled={isDisabled}
            accept=".csv, text/csv"
            type="file"
            onClick={uploadReset}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target && e.target.files) {
                uploadFile(
                  Array.from(e.target.files) || [],
                  () => {
                    e.target.value = ''
                  }
                )
              }
            }}/>
        </LabelStyled>
        <CustomProgressBar/>
      </ContentStyled>
    </>
  )
}

export default KeywordsHeader
