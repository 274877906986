import constate from 'constate'
import { useHeaderQuery, useHeaderUpdateMutation } from '../queries'

function useApplicationHeader() {
  return {
    headerQuery: useHeaderQuery(),
    headerUpdateMutation: useHeaderUpdateMutation()
  }
}

export const [
  ApplicationHeaderProvider,
  useApplicationHeaderContext
] = constate(useApplicationHeader)
