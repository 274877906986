import { FC, useState, useMemo, Dispatch, SetStateAction } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { ButtonGroup } from '@mui/material'
import ChartComposed from '../../../../core/components/charts/ChartComposed'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import SectionCard from './SectionCard'
import { BaseButton } from '../../../../core/components/Button'
import Spinner from '../../../../core/components/Spinner'
import { useAdditionalAccount, useBalanceCharts } from '../queries'
import { colors } from '../data'
import { PlusIcon } from '../../../../core/components/icons/PlusIcon'
import Modal from './Modal'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { SpinnerIcon } from '../../../../core/components/icons/SpinnerIcon'
import { useDeboucedCallback } from '../../../../core/hooks/useDeboucedCallback'
import { useIsPlendOrganization, useIsSocialCreditOrganization } from '../../../auth/hooks'

const SectionStyled = styled.div`
    ${flex({ justify: 'space-between', align: 'center' })};
    width: 100%;
`

const ButtonStyled = styled(BaseButton)<{ separate: boolean }>`
    && {
        color: ${({ theme, separate }) => (separate ? theme.colors.white : theme.colors.grey600)};
        background: ${({ theme, separate }) => (separate ? theme.colors.blue500 : 'transparent')};
        font-size: 12px;
        padding: 3px 8px;
        line-height: 18px;
        border: 1px solid ${({ theme }) => theme.colors.blue500};
        border-radius: 4px;
        cursor: ${({ separate }) => (separate ? 'auto' : 'pointer')};

        &:hover {
            background: ${({ theme, separate }) => (separate ? theme.colors.blue500 : theme.colors.blue200)};
            color: ${({ theme, separate }) => (separate ? theme.colors.white : theme.colors.grey600)};
            border: 1px solid ${({ theme }) => theme.colors.blue500};
        }
    }
`

const AccountTitle = styled.div`
    ${flex({ align: 'center' })};
`

const AdditionalAccount = styled.div<{ isRequested?: boolean }>`
    font-family: ${fontFamily('Inter')};
    ${flex({ align: 'baseline' })};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: 12px;
    color: ${({ theme, isRequested }) => (isRequested ? theme.colors.blue500 : theme.colors.grey600)};
    cursor: ${({ isRequested }) => (isRequested ? 'pointer' : 'default')};

    & > div {
        margin-left: 5px;
    }
`

type TProps = {
  setAccountHeightCoefficient: Dispatch<SetStateAction<number>>
}

const accountHeightCoefficient = 4
const AccountBalance: FC<TProps> = ({ setAccountHeightCoefficient }) => {
  const [separate, setSeparate] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { refetch, isRefetching, isLoading: requestIsLoading } = useAdditionalAccount()

  const { headerQuery: { data: headerData } } = useApplicationHeaderContext()
  const isInProgress = headerData?.status === 'IN_PROGRESS'
  const isAccountRequested = headerData?.status === 'ACCOUNT_REQUESTED'
  const isAccountUpdated = headerData?.status === 'ACCOUNT_UPDATED'

  const isPlendOrganization = useIsPlendOrganization()

  const isAdditionalAccountRequestShow = !useIsSocialCreditOrganization()

  const { isLoading, data: chartData, isIdle } = useBalanceCharts(
    separate ? 'COMBINED' : 'SEPARATE'
  )
  const { colors: { blue500 } } = useTheme()

  const debouncedRefetchCallback = useDeboucedCallback(refetch)

  const sortedChart = useMemo(
    () =>
      (chartData
        ? chartData.dailyAmounts.sort((a, b) => (b.date > a.date ? -1 : 1))
        : []),
    [chartData?.dailyAmounts]
  )
  if (isLoading || !chartData || isIdle) {
    return (
      <>
        <SectionCard label="Account Balance" isIcon={false} />
        <Spinner />
      </>
    )
  }

  const chartDataObjectToArray = chartData.dailyAmounts.length
    ? Object.keys(chartData.dailyAmounts[0].data)
    : []

  const valuesConfig = chartDataObjectToArray
    .map((key, i) => ({
      color: colors[i] || blue500,
      dataKey: `data.${key}`
    }))

  setAccountHeightCoefficient(Math.ceil(chartDataObjectToArray.length / accountHeightCoefficient))

  return (
    <>
      <SectionStyled>
        <AccountTitle>
          <SectionCard label="Account Balance" isIcon={false} />
          <Modal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            queryRequest={() => {
              debouncedRefetchCallback()
              setIsModalOpen(false)
            }}
            content="By confirming this action borrower receives the email with additional account linking request."
            title="Request additional account"
            disabled={isRefetching || requestIsLoading}
          />
          {isAdditionalAccountRequestShow
            && (isInProgress || isAccountUpdated)
            && (
              <AdditionalAccount
                isRequested={!isAccountRequested}
                onClick={() => setIsModalOpen(true)}
              >
                <PlusIcon />
                <div>Request additional account</div>
              </AdditionalAccount>
            )}
          {isAdditionalAccountRequestShow && isAccountRequested
            && (
              <AdditionalAccount isRequested={!isAccountRequested}>
                <SpinnerIcon width={12} height={12} />
                <div>Additional account requested</div>
              </AdditionalAccount>
            )}
        </AccountTitle>
        <ButtonGroup>
          <ButtonStyled
            disableTouchRipple={!separate}
            onClick={() => setSeparate(false)}
            separate={!separate}
          >
            Separated
          </ButtonStyled>
          <ButtonStyled
            disableTouchRipple={separate}
            onClick={() => setSeparate(true)}
            separate={separate}>
            Combined
          </ButtonStyled>
        </ButtonGroup>
      </SectionStyled>
      <ChartComposed
        chartData={sortedChart}
        chartConfig={{
          isLegend: true,
          nameDataKey: 'date',
          valuesConfig
        }}
        balanceLegend={isPlendOrganization && !separate}
        legendAdditionalInfo={chartData.accounts}
      />
    </>
  )
}

export default AccountBalance
