import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'
import { MetricsProvider } from '../state/useMetricsState'
import MetricsHeader from './MetricsHeader'

const WrapStyled = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.white};
  min-height: 100%;
`

const MetricsBase: FC = () => (
  <WrapStyled>
    <MetricsHeader/>
    <Outlet/>
  </WrapStyled>
)

export default () => (
  <MetricsProvider>
    <MetricsBase />
  </MetricsProvider>
)
