import { FC, useState } from 'react'
import styled from 'styled-components/macro'
import { ArrowUpIcon } from '../../../../core/components/icons/ArrowUpIcon'
import { ArrowDownIcon } from '../../../../core/components/icons/ArrowDownIcon'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { ruleFormat } from '../utils/tableRuleFormat'
import { TScoring } from '../types'

const ContentStyled = styled.div`
  ${flex({ justify: 'flex-start', align: 'center' })};
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.darkBlack};
  cursor: pointer;
`

const ShowMoreTextStyled = styled.p<{isDisabled: boolean}>`
  ${fontFamily('Inter')};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
  margin-right: 6px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`

const DisabledWrapStyled = styled.div<{isDisabled: boolean}>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`

const IconWrapStyled = styled.div`
  padding-top: .4px;
`

type TProps = {
  scoringData: TScoring
  disabled: boolean
}

const ScoringRulesText: FC<TProps> = ({ scoringData, disabled }) => {
  const [isShowMore, setIsShowMore] = useState<boolean>(false)
  const formattedRules = ruleFormat(scoringData)

  return (
    <>
      <DisabledWrapStyled isDisabled={disabled}>
        {
          isShowMore
            ? Array.isArray(formattedRules) && formattedRules.map((item) => <p>{item}</p>)
            : formattedRules[0]
        }
      </DisabledWrapStyled>
      <ContentStyled
        onClick={(e) => {
          e.stopPropagation()
          setIsShowMore(!isShowMore)
        }}>
        <ShowMoreTextStyled isDisabled={disabled}>
          {isShowMore
            ? 'Show Less'
            : `Show More ${scoringData.scoringRules.length} Columns`}
        </ShowMoreTextStyled>
        <DisabledWrapStyled isDisabled={disabled}>
          {
            isShowMore
              ? <ArrowUpIcon/>
              : (
                <IconWrapStyled>
                  <ArrowDownIcon width={12} height={8}/>
                </IconWrapStyled>
              )
          }
        </DisabledWrapStyled>
      </ContentStyled>
    </>
  )
}
export default ScoringRulesText
