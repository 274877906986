import {
  CartesianGrid,
  Line,
  LineChart as RechartLineChart,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  XAxis,
  YAxis
} from 'recharts'
import { FC } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import Tooltip from './Tooltip'
import { formatToDay } from '../../utils/formatUtils'
import CustomizedTick from '../../../../core/components/charts/CustomizedTick'
import { TApprovalDaily } from '../../types'
import { formatAxis } from '../../utils/chartUtils'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 16px;
  margin-left: -7px;
`

type TLoanSizeDaily = {
  loanSize: number
  date: string
}

type TLineChartProps = {
  data?: TApprovalDaily[] | TLoanSizeDaily[]
  dataKey: 'loanSize' | 'time'
}

const LineChart: FC<TLineChartProps> = ({
  data,
  dataKey
}) => {
  const { colors: { blue500, darkBlack } } = useTheme()
  return (
    <Wrapper>
      <ResponsiveContainer width="100%" height={283}>
        <RechartLineChart data={data}>
          <XAxis
            padding={{ left: 1, right: 10 }}
            interval={0}
            tickLine={false}
            axisLine={false}
            dataKey="date"
            tick={(props) => {
              const toDay = formatToDay(props.payload.value)
              return (
                <CustomizedTick
                  fontSize={10}
                  {...props}
                  formattedValue={toDay}
                  dy={12}
                />
              )
            }}
          />
          <YAxis
            tick={(props) => {
              const result = formatAxis(
                {
                  formatType: dataKey,
                  value: props.payload.value
                }
              )
              return (
                <CustomizedTick
                  {...props}
                  formattedValue={result}
                />
              )
            }}
            tickLine={false}
            axisLine={false} />
          <CartesianGrid vertical={false} horizontal />
          <RechartTooltip
            cursor={false}
            content={(
              <Tooltip
                formatType={dataKey}
                type="line"
              />
            )}
          />
          <Line
            type="linear"
            dataKey={dataKey}
            strokeWidth={2}
            stroke={blue500}
            dot={false}
            activeDot={{ r: 3, stroke: darkBlack, fill: darkBlack }} />
        </RechartLineChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}
export default LineChart
