import { FormControlLabel } from '@mui/material'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import Modal from './Modal'
import { useAssessmentContext } from '../state/useAssessment'
import { CustomCheckboxStyled } from '../../../../core/components/CustomCheckbox'
import { fontFamily } from '../../../../core/styles/mixins'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { useAdditionalDeclineReasonQuery } from '../queries'
import { TDeclineReason } from '../type'
import {
  prepareAdditionalDeclineReasonsCode
} from '../utils/prepareAdditionalDeclineReasons'
import { prepareDeclineReasonLabel } from '../utils/prepareDeclineReasonLabel'

const TextWrap = styled.div<{ marginBottom?: number }>`
  ${fontFamily('Inter')};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
  margin-bottom: ${({ marginBottom }) => marginBottom || 10}px;
`

const TextBoldStyled = styled.span`
  font-weight: 700;
`

const CheckboxWrap = styled.div`
  display: flex;
  flex-direction: column;

  .MuiFormControlLabel-root {
    margin-top: 8px;
    margin-left: 0;
  }

  .MuiTypography-root {
    ${fontFamily('Inter')};
    margin-left: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.darkBlack}
  }
`

type TProps = {
  declineReason?: TDeclineReason
}
const DeclineAdditionalReasons: FC<TProps> = ({ declineReason }) => {
  const {
    headerUpdateMutation: { mutate, isLoading: isUpdating }
  } = useApplicationHeaderContext()
  const { setIsModalOpen, isModalOpen } = useAssessmentContext()
  const [
    additionalDeclineReasons,
    setAdditionalDeclineReasons
  ] = useState<Record<string, boolean>>()
  const {
    data: additionalDeclineReasonsData
  } = useAdditionalDeclineReasonQuery(isModalOpen.declineAdditionalReasons)

  useEffect(() => {
    if (isModalOpen.declineAdditionalReasons) {
      setAdditionalDeclineReasons(undefined)
    }
  }, [isModalOpen.declineAdditionalReasons])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAdditionalDeclineReasons({
      ...additionalDeclineReasons,
      [event.target.name]: event.target.checked
    })
  }

  return (
    <Modal
      title="Decline application"
      content={(
        <div>

          <TextWrap>
            Decline reason -
            {' '}
            <TextBoldStyled>
              {prepareDeclineReasonLabel(declineReason?.value)}
            </TextBoldStyled>
            .
          </TextWrap>
          <TextWrap marginBottom={2}>
            Please specify the additional reasons you want to decline this application:
          </TextWrap>
          <CheckboxWrap>
            {
              additionalDeclineReasonsData?.map(({ value, code, id }) => (
                <FormControlLabel
                  key={id}
                  label={value}
                  control={(
                    <CustomCheckboxStyled
                      name={code}
                      checked={additionalDeclineReasons?.[code]}
                      onChange={handleChange}
                    />
                  )}
                />
              ))
            }
          </CheckboxWrap>
        </div>
      )}
      buttonText="Decline"
      isOpen={isModalOpen.declineAdditionalReasons}
      handleClose={() => setIsModalOpen({ declineAdditionalReasons: false })}
      queryRequest={() => {
        if (!isUpdating) {
          mutate({
            status: 'DECLINED',
            declineReasonCode: declineReason?.code,
            additionalDeclineReasonCodes:
              prepareAdditionalDeclineReasonsCode(additionalDeclineReasons)
          })
          setIsModalOpen({ declineAdditionalReasons: false })
        }
      }}
    />
  )
}

export default DeclineAdditionalReasons
