import { useState, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { useTransactionContext } from '../state/useTransactions'
import { SearchIcon } from '../../../../core/components/icons/SearchIcon'
import { CloseIcon } from '../../../../core/components/icons/CloseIcon'
import Button, { BaseButton } from '../../../../core/components/Button'
import CustomInput, { CustomTextField } from '../../../../core/components/CustomInput'
import { fontFamily } from '../../../../core/styles/mixins'

const SearchIconWrapper = styled.div<{ isOnFocus: boolean }>`
  color: ${({ theme, isOnFocus }) => (isOnFocus ? theme.colors.blue500 : theme.colors.darkBlack)};
`

const CloseIconWrapper = styled.span`
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
`

const FormStyled = styled.form`
  padding: 24px 0;
  margin-left: 26px;
`

const Wrapper = styled.div<{ isOnFocus: boolean }>`
  && {
    && .MuiOutlinedInput-root {
      height: 32px;
    }

    ${BaseButton} {
      margin-left: 16px;
    }

    ${CustomTextField} {
      width: 300px;
      height: 32px;
      color: black;
    }

    .MuiOutlinedInput-root {
      height: 32px;
    }

    && input {
      padding: 10px 20px 10px 0;
      height: 20px;

      ::placeholder {
        ${fontFamily('Inter')};
        color: ${({ theme }) => theme.colors.darkBlack};
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        order: 1;
        flex-grow: 1;
      }
    }

    &&.MuiOutlinedInput-input::placeholder {
      color: ${({ theme }) => theme.colors.grey200};
    }

    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, isOnFocus }) => (isOnFocus ? theme.colors.blue500 : theme.colors.grey600)};
    }
  }
`

const Search = () => {
  const [isOnFocus, setIsOnFocus] = useState(false)
  const { setSearch } = useTransactionContext()
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      searchedValue: ''
    }
  })

  const searchedValue = watch('searchedValue')

  useEffect(() => {
    if (!searchedValue) {
      setSearch('')
    }
  }, [searchedValue])

  const onSubmit = ({ searchedValue }: FieldValues) => (
    searchedValue
      ? setSearch(searchedValue.toLowerCase()
        .trim())
      : null)

  const handleDelete = () => {
    reset({ searchedValue: '' }, { keepValues: false })
  }

  return (
    <Wrapper isOnFocus={isOnFocus}>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="searchedValue"
          control={control}
          placeholder="Search transactions..."
          icon={(
            <>
              <SearchIconWrapper isOnFocus={isOnFocus}>
                <SearchIcon />
              </SearchIconWrapper>
              {watch('searchedValue') && (
                <CloseIconWrapper>
                  <CloseIcon onClick={() => handleDelete()} />
                </CloseIconWrapper>
              )}
            </>
          )}
          iconError={<CloseIcon />}
          onFocus={() => setIsOnFocus(true)}
          onBlur={() => setIsOnFocus(false)}
        />
        <Button text="Search" type="submit" />
      </FormStyled>
    </Wrapper>
  )
}

export default Search
