import { useMutation } from 'react-query'
import { useAuthContext } from '../auth/state/useAuthState'
import { apiClient } from '../../core/api/apiClient'

async function refreshToken(authData: any) {
  const response = await apiClient.post(
    '/auth/refresh-token',
    { refreshToken: authData?.refreshToken }
  )
  return response
}

export const useRefreshTokenMutation = () => {
  const { authData } = useAuthContext()
  return useMutation(() => refreshToken(authData))
}
