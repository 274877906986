import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../../core/api/apiClient'
import { prepareTablePaginationRequestParams } from '../../../core/utils/tableData'
import { TVersion, TVersionData, TVersionEdit, TVersionRule } from './types'

enum QueryKeys {
  VersionHistory = 'VersionHistory'
}

async function getVersionHistory(currentPage: number, id?: string): Promise<TVersion> {
  try {
    const { data } = await apiClient(`configuration/scoring/${id}/history`, {
      params: {
        ...prepareTablePaginationRequestParams(currentPage, 5)
      }
    })
    return data
  } catch (e) {
    throw new Error('Error data')
  }
}

export function useVersionHistoryQuery(currentPage: number) {
  const { configId } = useParams()

  return useQuery(
    [QueryKeys.VersionHistory, currentPage, configId],
    () => getVersionHistory(currentPage, configId),
    {
      keepPreviousData: true
    }
  )
}

async function getRules(
  version?: number,
  snapshot?: number,
  id?: string
): Promise<TVersionRule[] | undefined> {
  try {
    const { data } = await apiClient(`/configuration/scoring/${id}/rules?version=${version}&snapshot=${snapshot}`)
    return data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useRulesQuery(version?: number, snapshot?: number) {
  const { configId } = useParams()

  return useQuery(
    [QueryKeys.VersionHistory, version, snapshot, configId],
    () => getRules(version, snapshot, configId),
    {
      enabled: !!version
    }
  )
}

async function editRules(
  rules?: Omit<TVersionRule, TVersionRule['rule']>,
  version?: number,
  snapshot?: number,
  id?: string
): Promise<TVersionEdit> {
  try {
    const { data } = await apiClient.put(`/configuration/scoring/${id}/rules?version=${version}&snapshot=${snapshot}`, rules)
    return data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useEditRulesQuery() {
  const queryClient = useQueryClient()
  return useMutation(
    ({ rules, version, snapshot, id }: any) => editRules(rules, version, snapshot, id),
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKeys.VersionHistory),
      onError: () => queryClient.invalidateQueries(QueryKeys.VersionHistory)
    }
  )
}
