import styled from 'styled-components/macro'
import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import xor from 'lodash/xor'
import { BaseButton } from '../../../../core/components/Button'
import { fontFamily } from '../../../../core/styles/mixins'
import Modal from '../../applicationDetail/components/Modal'
import { useAccountModifyMutation, useAccountModifyQuery } from '../queries'
import CheckboxLabel from './CheckboxLabel'
import { prepareAccountExclude } from '../utils/prepareAccountExclude'
import { CustomCheckboxStyled } from '../../../../core/components/CustomCheckbox'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import { useIsPlendOrganization } from '../../../auth/hooks'

const WrapStyled = styled.div`
    display: flex;
    flex-direction: column;

    && {
        .MuiFormLabel-root {
            ${fontFamily('Inter')};
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            color: ${({ theme }) => theme.colors.darkBlack}
        }

        .MuiCheckbox-root, .MuiRadio-root {
            padding-top: 0;
            padding-bottom: 0;

        }

        .MuiFormControlLabel-root .MuiFormControlLabel-label {
            ${fontFamily('Inter')};
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            color: ${({ theme }) => theme.colors.darkBlack}
        }

        .MuiFormControlLabel-root {
            margin-top: 8px;
        }

        .MuiFormGroup-root {
            margin-left: 10px;
        }
    }
`

const ContentStyled = styled.div`
    display: flex;
    flex-direction: column;
`

const ModifyAccountsButton = styled(BaseButton)`
    && {
        margin-left: 46px;
        margin-top: 20px;
        padding: 6px 12px;
        ${fontFamily('Inter')};
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        background: ${({ theme }) => theme.colors.grey300};
        color: ${({ theme }) => theme.colors.darkBlack};

        &:hover {
            background: ${({ theme }) => theme.colors.grey500};
        }
    }
`

const DescriptionStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-left: 10px;
`
const AccountNameStyled = styled.p`
    ${fontFamily('Inter')};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.grey600};

`

const SmallTextStyled = styled.p`
    ${fontFamily('Inter')};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.grey600};
`

function useModifyAccountsForm(open: boolean) {
  const [initialExcludedAccounts, setInitialExcludedAccounts] = useState<number[]>([])
  const [accounts, setAccounts] = useState<number[]>([])
  const { data } = useAccountModifyQuery()
  const toggle = (value: number) => {
    setAccounts(xor(accounts, [value]))
  }

  useEffect(() => {
    if (!data) {
      return
    }
    const preparedAccounts = data.map(({ includedIntoProcessing, accountId }) => {
      if (!includedIntoProcessing) {
        return accountId
      }
      return undefined
    })
      .filter(Boolean)
    setInitialExcludedAccounts(preparedAccounts as unknown as number[])
    setAccounts(preparedAccounts as unknown as number[])
  }, [data, open])
  return {
    initialExcludedAccounts,
    accounts,
    toggle,
    clear: () => {
      setInitialExcludedAccounts([])
      setAccounts([])
    }
  }
}

function usePrepareModifyAccounts() {
  const { data } = useAccountModifyQuery()
  return useMemo(() => {
    if (!data) {
      return []
    }
    return data
  }, [data])
}

const ModifyAccounts = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { toggle, accounts, initialExcludedAccounts, clear } = useModifyAccountsForm(open)
  const { mutateAsync, isLoading } = useAccountModifyMutation()
  const accountOptions = usePrepareModifyAccounts()
  const handleClose = () => {
    clear()
    setOpen(false)
  }
  const isPlend = useIsPlendOrganization()
  return (
    <>
      <ModifyAccountsButton
        onClick={() => setOpen(true)}
      >
        Modify account(s)
      </ModifyAccountsButton>
      <Modal
        isOpen={open}
        title="Exclude account"
        closeButtonText="Cancel"
        buttonText="Rescore"
        isActionDisabled={isLoading || (!accounts.length && !initialExcludedAccounts.length)}
        handleClose={handleClose}
        queryRequest={async () => {
          mutateAsync(prepareAccountExclude(accounts, accountOptions))
            .then(() => handleClose())
        }}
        content={(
          <WrapStyled>
            <FormControl>
              <FormLabel>
                Choose bank account(s) to exclude from application values calculation
              </FormLabel>
              <FormGroup>
                {accountOptions?.map((account) => (
                  <ContentStyled key={account.accountId}>
                    <FormControlLabel
                      key={account.accountId}
                      control={(
                        <CustomCheckboxStyled
                          disabled={
                            accounts.length === accountOptions.length - 1
                            && !accounts.includes(Number(account.accountId))
                          }
                          checked={accounts.includes(Number(account.accountId))}
                          onChange={() => {
                            if (accountOptions.length === 1) {
                              return
                            }
                            toggle(account.accountId)
                          }} />
                      )}
                      label={<CheckboxLabel {...account} />}
                    />
                    {
                      isPlend && (
                        <DescriptionStyled>
                          <AccountNameStyled>{account?.accountName}</AccountNameStyled>
                          {/* <SmallTextStyled>
                      {`Account Type: ${found?.accountType}`}
                      </SmallTextStyled> */}
                          {!!account?.accountLimit && (
                            <SmallTextStyled>{`Overdraft limit: ${formatToPound(account?.accountLimit)}`}</SmallTextStyled>
                          )}

                        </DescriptionStyled>
                      )
                    }
                  </ContentStyled>
                ))}
              </FormGroup>
            </FormControl>
          </WrapStyled>
        )}
      />
    </>
  )
}

export default ModifyAccounts
