import { UseFormReturn } from 'react-hook-form'
import { useEffect } from 'react'
import { TForm, TUser } from './types'

export const useErrorHandling = (
  isError: boolean,
  { setError }: UseFormReturn<TForm> | UseFormReturn<TUser>,
  errorStatus?: number
) => {
  useEffect(() => {
    if (isError && errorStatus === 500) {
      setError('email', { message: 'This email address is already being used' })
    }
  }, [isError])
}
