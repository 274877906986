import styled from 'styled-components/macro'
import { FC } from 'react'
import CustomTooltip from '../../../../core/components/CustomTooltip'
import { InformationIcon } from '../../../../core/components/icons/InformationIcon'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import SectionCard from './SectionCard'
import { useOverview } from '../queries'
import Spinner from '../../../../core/components/Spinner'

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  width: 100%;
  margin: 23px 0;
`

export const TitleContainerStyled = styled.div`
  ${fontFamily('Inter')};
  ${flex({ justify: 'space-between', align: 'center' })};
  width: 100%;
`

export const IconWrapperStyled = styled.div`
  margin-left: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey600a6};
`

const ScoreBoxStyled = styled.h1`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin-top: 8px;
  padding: 8px 0 6px 0;
  border-radius: 10px;
`

type TProps = {
  titleTooltip: string
  score?: number
  isIcon?: boolean
  navigationUrl?: string
}

const PlendScore: FC<TProps> = ({
  titleTooltip,
  score,
  isIcon,
  navigationUrl }) => {
  const { data, isIdle, isLoading } = useOverview(score)

  if ((!data || isLoading || isIdle) && !score) {
    return (
      <SpinnerWrapper>
        <Spinner/>
      </SpinnerWrapper>
    )
  }
  return (
    <>
      <TitleContainerStyled>
        <SectionCard navigationUrl={navigationUrl} isIcon={isIcon} label="Plend Score"/>
        <CustomTooltip arrow disableFocusListener disableTouchListener title={titleTooltip} placement="top">
          <IconWrapperStyled>
            <InformationIcon/>
          </IconWrapperStyled>
        </CustomTooltip>
      </TitleContainerStyled>
      <ScoreBoxStyled>
        {data?.plendScore || score}
      </ScoreBoxStyled>
    </>
  )
}
export default PlendScore
