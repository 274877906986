import { useMetricsContentContext } from '../state/useMetricsContentState'
import { differenceTransaction, useCheckAllChecked } from '../../../transactions/components/TableHeadCheckbox'
import { CustomCheckboxStyled } from '../../../../../core/components/CustomCheckbox'

const TableHeadCheckbox = () => {
  const {
    transactionsState: { query: { data } },
    selectedTransactions, setSelectedTransactions
  } = useMetricsContentContext()

  return (
    <CustomCheckboxStyled
      checked={useCheckAllChecked({
        data: data?.content,
        transactions: selectedTransactions
      })}
      onChange={(event, checked) => {
        const chunkTransactions = data!.content
        setSelectedTransactions(checked
          ? [
            ...selectedTransactions,
            ...differenceTransaction(chunkTransactions, selectedTransactions)
          ]
          : differenceTransaction(selectedTransactions, chunkTransactions))
      }}
    />
  )
}

export default TableHeadCheckbox
