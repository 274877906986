import styled from 'styled-components/macro'
import { flex, fontFamily } from '../../../core/styles/mixins'
import Button, { BaseButton } from '../../../core/components/Button'
import { usePartnerDashboardContext } from '../state/PartnerDashboardState'

const WrapperStyled = styled.div`
  ${flex({ align: 'center', justify: 'space-between' })};

  ${BaseButton} {
    padding: 8px 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.blue500};
    border-radius: 3px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue500};
    }
  }
`

const TitleStyled = styled.h1`
  ${fontFamily('Inter')};
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`

const PartnerDashboardHeader = () => {
  const { setModalOpen } = usePartnerDashboardContext()
  return (
    <WrapperStyled>
      <TitleStyled>Partner dashboard</TitleStyled>
      <Button text="Generate Link" onClick={() => setModalOpen({ generateLink: true })} />
    </WrapperStyled>
  )
}

export default PartnerDashboardHeader
