import constate from 'constate'
import { useSetState } from 'react-use'
import { useCallback } from 'react'
import { useFailedValidationQuery } from '../queries'
import { TTableState } from '../types'
import { TSortBy } from '../../../../core/components/table/TableContext'

const useFailedValidation = () => {
  const [tableState, setTableState] = useSetState<TTableState>({
    currentPage: 1,
    search: '',
    sortBy: {
      id: 'createdAt',
      desc: true
    }
  })

  const setSortBy = useCallback(
    (sort?: TSortBy[]) => setTableState({ sortBy: sort && sort[0] }),
    []
  )

  const setSearch = useCallback((search: string) => setTableState({ search }), [])

  const setCurrentPage = useCallback((currentPage: number) => setTableState({ currentPage }), [])

  return {
    ...tableState,
    setSortBy,
    setSearch,
    setCurrentPage,
    failedValidationQuery: useFailedValidationQuery(tableState)
  }
}

export const [FailedValidationProvider, useFailedValidationContext] = constate(useFailedValidation)
