import { THeaderData, TLoanApplicationMatches } from '../type'

const matchMap: { [key in TLoanApplicationMatches]: string } = {
  FULL_NAME: 'Full name',
  POSTCODE: 'Postcode',
  EMAIL: 'Email',
  PHONE: 'Phone number',
  FULL_ADDRESS: 'Full address'
}

export function prepareMarkApplication(
  loanApplicationMatches: THeaderData['loanApplicationMatches'],
  match: TLoanApplicationMatches[]
) {
  if (!loanApplicationMatches) {
    return []
  }
  return match
    .map((match) => {
      const loanApplication = loanApplicationMatches[match]
      if (loanApplication) {
        return {
          title: `The ${matchMap[match]} has already been used:`,
          links: loanApplication.map(({ id, wlcfId, status }) => {
            const isFailedValidation = status === 'FAILED'
            return `${window.location.origin}/${isFailedValidation ? 'failed-validation' : 'applications'}/${wlcfId || id}`
          })
        }
      }
      return undefined
    })
    .filter((value) => value)
}
