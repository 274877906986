import styled from 'styled-components/macro'
import Table from './Table'
import DetailedTransactionTable from './DetailedTransactionTable'
import { RiskProvider } from '../state/useRisk'

const Wrapper = styled.section`
  height: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1px;
`

const CardStyled = styled.div`
  padding-bottom: 32px;
  background: ${({ theme }) => theme.colors.white};
`

const Risk = () => (
  <RiskProvider>
    <Wrapper>
      <CardStyled>
        <Table />
      </CardStyled>
      <CardStyled>
        <DetailedTransactionTable />
      </CardStyled>
    </Wrapper>
  </RiskProvider>
)

export default Risk
