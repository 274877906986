import styled from 'styled-components/macro'
import { FC } from 'react'
import { flex } from '../../../../core/styles/mixins'
import { TValidationRule } from '../../types'
import RuleDropDown from '../RuleDropDown'
import { getRuleValue, isDisabled } from '../../utils/tableUtils'
import { useFormContext } from '../../state/formState'

export const DisabledTextStyled = styled.div`
  opacity: 0.5;
`

const RuleDropDownContainer = styled.div`
  ${flex({ justify: 'flex-start' })};
  flex-direction: column;
`

export const employmentStatusDescription = 'Employment status'
export const loanPurposeDescription = 'Loan purpose'

type TProps = {
  validationRule: TValidationRule
}

const RuleTableCell: FC<TProps> = ({ validationRule }) => {
  const { selectedRowId, control, isEditFormValueInitialized } = useFormContext()

  const { validationRules, id, description } = validationRule

  const isEmploymentStatus = description === employmentStatusDescription
  const isLoanPurpose = description === loanPurposeDescription

  if (selectedRowId === String(id)
    && isEditFormValueInitialized
    && !isEmploymentStatus && !isLoanPurpose) {
    return (
      <>
        {validationRules.map((item, idx) => (
          <RuleDropDownContainer key={item.id}>
            <RuleDropDown subName="rule" idx={idx} control={control} />
          </RuleDropDownContainer>
        ))}
      </>
    )
  }
  const content = (
    <>
      {isEmploymentStatus || isLoanPurpose
        ? <div>is equal to</div>
        : validationRules.map(({ rule }) => (
          <div>{getRuleValue(rule)}</div>
        ))}
    </>
  )

  return (
    <>
      {isDisabled(validationRule)
        ? (
          <DisabledTextStyled>
            {content}
          </DisabledTextStyled>
        )
        : (
          <div>
            {content}
          </div>
        )}
    </>
  )
}

export default RuleTableCell
