import { ClickAwayListener } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import CustomTooltip from './CustomTooltip'
import { FileCopyIcon } from './icons/FileCopyIcon'
import { useHandleCopy } from '../hooks/useHandleCopy'
import { flex } from '../styles/mixins'

const IconWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey600};
`

const CopyButtonWrapper = styled.div`
  all: unset;
  cursor: pointer;
`

interface CopyButtonProps {
  copiedValue: string
  title: string
  width?: number
  height?: number
  children?: JSX.Element
}

const CopyButton = ({ copiedValue, title, width = 16, height = 16, children }: CopyButtonProps) => {
  const handleCopy = useHandleCopy()

  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)
  const handleCopyInfo = () => {
    handleCopy(copiedValue)
    setOpenCopyTooltip(true)
  }

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null
    if (openCopyTooltip) {
      timerId = setTimeout(() => {
        setOpenCopyTooltip(false)
      }, 2000)
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [openCopyTooltip])

  return (
    <CustomTooltip
      arrow
      disableFocusListener
      disableTouchListener
      title={`Copy ${title}`}
      placement="top"
    >
      <div>
        <ClickAwayListener onClickAway={() => setOpenCopyTooltip(false)}>
          <div>
            <CustomTooltip
              open={openCopyTooltip}
              title="Copied to clipboard"
              placement="right"
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
            >
              <CopyButtonWrapper onClick={handleCopyInfo}>
                {children ?? (
                  <IconWrapper>
                    <FileCopyIcon width={width} height={height} />
                  </IconWrapper>
                )}
              </CopyButtonWrapper>
            </CustomTooltip>
          </div>
        </ClickAwayListener>
      </div>
    </CustomTooltip>
  )
}

export default CopyButton
