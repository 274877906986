import { FC, useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components/macro'
import { Column } from 'react-table'
import { format } from 'date-fns'
import { UseQueryResult } from 'react-query/types/react/types'
import CustomTable from '../../../../core/components/table/Table'
import Spinner from '../../../../core/components/Spinner'
import NoDataComponent, { ContainerStyled } from '../../../../core/components/NoDataComponent'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { FormContextProvider, useFormContext } from '../../state/formState'
import { EditIcon } from '../../../../core/components/icons/EditIcon'
import { TValidationRule, TValueTypeOptions } from '../../types'
import { SelectedIcon } from '../../../../core/components/icons/SelectedIcon'
import { CloseLineIcon } from '../../../../core/components/icons/CloseLineIcon'
import CustomSwitch from '../../../../core/components/CustomSwitch'
import { SLASH_DATE_FORMAT } from '../../../../core/const/date'
import CustomTooltip from '../../../../core/components/CustomTooltip'
import { InformationIcon } from '../../../../core/components/icons/InformationIcon'
import { isDisabled } from '../../utils/tableUtils'
import ValidationRuleCell from './ValidaionRuleCell'
import RuleTableCell from './RuleTableCell'
import ValueTableCell from './ValueTableCell'
import { useToggleValidationRule } from '../../querries'
import { OptionsProvider } from '../../state/useOptions'

const bureauxMixin = css`
  :nth-child(3) {
    min-width: 100px;
  }

  :nth-child(4) {
    min-width: 300px;
  }

  :nth-child(5) {
    min-width: 160px;
  }
`

const notBureaxMixin = css`
  :nth-child(4) {
    min-width: 100px;
  }

  :nth-child(5) {
    min-width: 300px;
  }

  :nth-child(6) {
    min-width: 100px;
  }
`

const Wrapper = styled.div<{ isBureaux: boolean }>`
  ${HeaderCellStyled} {
    ${fontFamily('Inter')};
    padding: 24px 16px 16px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }

  ${BodyCellStyled} {
    ${fontFamily('Inter')};
    padding: 16px;
    height: 69px;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;

    :nth-child(2) {
      width: max-content;
    }

    :last-child {
      width: 110px;
    }

    ${({ isBureaux }) => (isBureaux ? bureauxMixin : notBureaxMixin)}
  }

  && .MuiOutlinedInput-input {
    padding: 6px 0;
    font-size: 14px;
  }

  && .MuiTableCell-root > div {
    margin-top: 0;
  }

  && .MuiSelect-select {
    padding-left: 12px;
  }

  .gSOhZn.gSOhZn .MuiOutlinedInput-root {
    width: 233px;
  }
`

const ContentStyled = styled.div`
  ${flex({})};
`

const IconWrapper = styled.span`
  ${flex({ justify: 'center', align: 'center' })};
  color: ${({ theme }) => theme.colors.grey600};
  margin-left: 6px;
  cursor: pointer;
`

const NoDataWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 650px;

  ${ContainerStyled} {
    margin-top: 0;
  }
`

const SpinnerWrapper = styled.div`
  ${flex({ justify: 'center', align: 'center' })};
  height: 650px;
`

export const DisabledTextStyled = styled.div`
  opacity: 0.5;
`

const IconStyled = styled.span<{ isDisabled?: boolean; color?: string }>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  color: ${({ color }) => color || 'inherit'};
`

const IconWrapStyled = styled.div`
  width: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`

const ValueTypeStyled = styled.span`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.blue800};
`

const valueTypeOptions: TValueTypeOptions = {
  NONE: '',
  POUND: '(£)',
  NUMBER_OF_TIMES: '(№ of times)',
  EMPLOYMENT_STATUS: '',
  NUMBER_OF_PAYMENTS: '(№ of payments)',
  PERCENT_FROM_INCOME: '(% from income)',
  NUMBER_OF_TRANSACTIONS: '(№ of transactions)',
  NUMBER_OF_TRANSACTION_ITEMS: '(№ of transactions items)'
}
type TProps = {
  isBureaux?: boolean
  validationRulesQuery: UseQueryResult<TValidationRule[], unknown>
}
const Table: FC<TProps> = ({ isBureaux, validationRulesQuery }) => {
  const { data: validationRules, isIdle, isLoading } = validationRulesQuery
  const { mutate: statusToggle } = useToggleValidationRule()
  const {
    handleSubmit,
    selectRow,
    selectedRowId,
    isEditFormValueInitialized
  } = useFormContext()

  const { colors: { green } } = useTheme()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: 'Status',
        accessor: 'enabled',
        Cell: ({ value, row }) => (
          <CustomSwitch
            disabled={!!selectedRowId}
            onClick={() => statusToggle(row.original as TValidationRule)}
            checked={value}
          />
        )
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }: any) => {
          const isCCJRule = row.original.id === 20
          const formattedValue = value.split('\n')
            .map((elem: string) => <div>{elem}</div>)
          const rowOriginal = row.original as TValidationRule

          const CustomRule = isDisabled(rowOriginal) ? DisabledTextStyled : 'span'

          return (
            <CustomRule>
              {!isCCJRule ? value : formattedValue}
              {isDisabled(rowOriginal)
                ? valueTypeOptions[rowOriginal.valueType]
                : <ValueTypeStyled>{valueTypeOptions[rowOriginal.valueType]}</ValueTypeStyled>}
            </CustomRule>
          )
        }
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        Cell: ({ value, row }) => {
          const formattedDate = format(new Date(value), SLASH_DATE_FORMAT)
          return isDisabled(row.original as TValidationRule) ? (
            <DisabledTextStyled>{formattedDate}</DisabledTextStyled>
          ) : (
            <span>{formattedDate}</span>
          )
        }
      },
      {
        Header: () => (
          <ContentStyled>
            <span>Period, Days</span>
            <CustomTooltip
              maxWidth={142}
              arrow
              disableFocusListener
              disableTouchListener
              title="Number of days from application date"
              placement="top"
            >
              <IconWrapper>
                <InformationIcon />
              </IconWrapper>
            </CustomTooltip>
          </ContentStyled>
        ),
        accessor: 'validationRules',
        Cell: ({ row }) => <ValidationRuleCell validationRule={row.original as TValidationRule} />
      },
      {
        Header: 'Rule',
        id: 'rule',
        Cell: ({ row }) => <RuleTableCell validationRule={row.original as TValidationRule} />
      },
      {
        Header: 'Value',
        id: 'value',
        Cell: ({ row }) => (
          <ValueTableCell validationRule={row.original as TValidationRule} />
        )
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ value, row }) =>
          (
            isDisabled(row.original as TValidationRule)
              ? <DisabledTextStyled>{value}</DisabledTextStyled>
              : value
          )
      },
      {
        Header: '',
        id: 'icon',
        Cell: ({ row }) =>
          (selectedRowId === String((row.original as TValidationRule)?.id) ? (
            <IconWrapStyled>
              <IconStyled color={green} onClick={handleSubmit}>
                <SelectedIcon />
              </IconStyled>
              <IconStyled onClick={() => selectRow(undefined)}>
                <CloseLineIcon />
              </IconStyled>
            </IconWrapStyled>
          ) : (
            <IconStyled onClick={() => selectRow(row.original as TValidationRule)}>
              <EditIcon />
            </IconStyled>
          ))
      }
    ],
    [selectedRowId, validationRules, isEditFormValueInitialized]
  )

  return (
    <Wrapper isBureaux={!!isBureaux}>
      <CustomTable
        columns={columns}
        hiddenColumns={isBureaux ? ['validationRules'] : []}
        data={validationRules || []}
        showRowBorder
      />
      {(isIdle || isLoading) && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {(!validationRules?.length) && (
        <NoDataWrapper>
          <NoDataComponent iconWidth={52} iconHeight={48} text="There are no validation rules yet" />
        </NoDataWrapper>
      )}
    </Wrapper>
  )
}

type TProvider = {
  isEligibility?: boolean
}
export default (props: TProps & TProvider) => {
  const { isBureaux, validationRulesQuery, isEligibility } = props
  return (
    <FormContextProvider validationRulesQuery={validationRulesQuery}>
      <OptionsProvider isEmploymentStatusQueryEnabled={!!isBureaux || !!isEligibility}>
        <Table {...props} />
      </OptionsProvider>
    </FormContextProvider>
  )
}
