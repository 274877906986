import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider
} from 'react-router-dom'
import React, { useRef } from 'react'
import Main from './features/main/components/Main'
import Login from './features/auth/component/Login'
import Home from './features/home/components/Home'
import FailedValidation from './features/failedValidation/list/components/FailedValidation'
import Income from './features/applications/metrics/income/components/Income'
import MetricsBase from './features/applications/metrics/base/components/MetricsBase'
import ApplicationDetail from './features/applications/applicationDetail/components/ApplicationDetail'
import Overview from './features/applications/overview/components/Overview'
import Logout from './features/auth/component/Logout'
import AuthGuard from './features/auth/guards/AuthGuard'
import ApplicationsList from './features/applications/applicationsList/components/ApplicationsList'
import Affordability from './features/applications/metrics/affordability/components/Affordability'
import Transactions from './features/applications/transactions/components/Transactions'
import PlendScore from './features/applications/plendScore/components/PlendScore'
import Risk from './features/applications/risk/components/Risk'
import FailedValidationDetails from './features/failedValidation/details/components/FailedValidationDetails'
import Bureaux from './features/applications/bureaux/components/Bureaux'
import NotFound from './features/notFound/components/NotFound'
import RoleGuard from './features/auth/guards/RoleGuard'
import {
  useGetPermission,
  useIsCurrentOrganization,
  useIsPlendOrganization
} from './features/auth/hooks'
import NotFoundGuard from './features/notFound/guards/NotFoundGuard'
import ValidationRules from './features/validationRules/components/ValidationRules'
import UserManagement from './features/userManagement/components/UserManagement'
import ScoringConfig from './features/scoringConfig/list/components/ScoringConfig'
import Keywords from './features/keywords/component/Keywords'
import ScoringEdit from './features/scoringConfig/edit/components/ScoringEdit'
import ApiClient from './features/apiClient/components/ApiClient'
import Notification from './features/notifications/components/Notifications'
import BureauxGuard from './features/applications/bureaux/guard/BureauxGuard'
import ChangePassword from './features/auth/component/ChangePassword'
import ForgotPassword from './features/auth/component/ForgotPassword'
import PartnerDashboard from './features/partnerDashboard/components/PartnerDashboard'
import { TPermission } from './core/types/permissions'
import Eligibility from './features/eligibility/components/Eligibility'
import ClearScorePricing from './features/clearScorePricing/components/ClearScorePricing'

export const initialNavigateMap: { [key in TPermission]?: string } = {
  GENERAL_VIEW: '/user-management',
  PARTNERS_ADMIN_VIEW: '/user-management',
  PARTNERS_EMPLOYEE_VIEW: '/partner-dashboard'
}

function useOrganizationInitialNavigate() {
  const organization = useGetPermission()
  return organization
    ? initialNavigateMap[useGetPermission()!] || '/home'
    : undefined
}

const RouteWrap = () => (
  <>
    <ApiClient />
    <Notification />
    <Outlet />
  </>
)

function useRouter() {
  const isNotPlendOrganization = !useIsPlendOrganization()
  const disableFailedValidation = useIsCurrentOrganization(['lendology', 'purpleshoots', 'robertowen'])
  const isCymruOrganization = useIsCurrentOrganization(['socialcreditcymru'])
  const navigate = useOrganizationInitialNavigate()

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<RouteWrap />}>
        <Route
          path="/"
          element={(
            <AuthGuard>
              <NotFoundGuard>
                <Main />
              </NotFoundGuard>
            </AuthGuard>
          )}
        >
          {navigate && (
            <Route
              index
              element={<Navigate to={navigate} />} />
          )}

          <Route
            path="home"
            element={(
              <RoleGuard permission={['ORGANIZATION_ADMIN_VIEW', 'UNDERWRITER_VIEW', 'CUSTOMER_SERVICE_VIEW']}>
                <Home />
              </RoleGuard>
            )}
          />
          <Route
            path="applications"
            element={(
              <RoleGuard permission={['UNDERWRITER_VIEW', 'CUSTOMER_SERVICE_VIEW']}>
                <Outlet />
              </RoleGuard>
            )}
          >
            <Route index element={<ApplicationsList />} />
            <Route path=":loanRequestId" element={<ApplicationDetail />}>
              <Route index element={<Overview />} />
              <Route path="risk" element={<Risk />} />
              <Route
                path="bureaux"
                element={(
                  <BureauxGuard>
                    <Bureaux />
                  </BureauxGuard>
                )} />
              <Route path="score" element={<PlendScore />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="" element={<MetricsBase />}>
                <Route path="income" element={<Income />} />
                <Route path="affordability" element={<Affordability />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="failed-validation"
            element={(
              <RoleGuard
                permission={['UNDERWRITER_VIEW', 'CUSTOMER_SERVICE_VIEW']}
                extraRestriction={disableFailedValidation}>
                <Outlet />
              </RoleGuard>
            )}
          >
            <Route index element={<FailedValidation />} />
            <Route path=":failedValidationId" element={<FailedValidationDetails />} />
          </Route>
          <Route
            path="declined-details"
            element={(
              <RoleGuard
                permission={['UNDERWRITER_VIEW', 'CUSTOMER_SERVICE_VIEW']}
                extraRestriction={isCymruOrganization && !isCymruOrganization}>
                <Outlet />
              </RoleGuard>
            )}
          >
            <Route index element={<FailedValidation />} />
            <Route path=":failedValidationId" element={<FailedValidationDetails />} />
          </Route>
          <Route
            path="user-management"
            element={(
              <RoleGuard permission={['ORGANIZATION_ADMIN_VIEW', 'GENERAL_VIEW', 'PARTNERS_ADMIN_VIEW']}>
                <UserManagement />
              </RoleGuard>
            )}
          />
          <Route
            path="validation-rules"
            element={(
              <RoleGuard
                permission={['ORGANIZATION_ADMIN_VIEW']}
                extraRestriction={isNotPlendOrganization && !isCymruOrganization}>
                <ValidationRules />
              </RoleGuard>
            )}
          />
          <Route
            path="scoring-configuration"
            element={(
              <RoleGuard
                permission={['ORGANIZATION_ADMIN_VIEW']}
                extraRestriction={isNotPlendOrganization && !isCymruOrganization}>
                <Outlet />
              </RoleGuard>
            )}
          >
            <Route index element={<ScoringConfig />} />
            <Route path=":configId" element={<ScoringEdit />} />
          </Route>
          <Route
            path="eligibility"
            element={(
              <RoleGuard
                permission={['ORGANIZATION_ADMIN_VIEW']}
                extraRestriction={isNotPlendOrganization && !isCymruOrganization}>
                <Eligibility />
              </RoleGuard>
            )}
          />
          <Route
            path="clearscore-pricing"
            element={(
              <RoleGuard
                permission={['ORGANIZATION_ADMIN_VIEW']}
                extraRestriction={isNotPlendOrganization && !isCymruOrganization}>
                <ClearScorePricing />
              </RoleGuard>
            )}
          />
          <Route
            path="keywords"
            element={(
              <RoleGuard permission={['ORGANIZATION_ADMIN_VIEW']} extraRestriction={isNotPlendOrganization}>
                <Keywords />
              </RoleGuard>
            )}
          />
          <Route
            path="partner-dashboard"
            element={(
              <RoleGuard permission={['PARTNERS_EMPLOYEE_VIEW']}>
                <PartnerDashboard />
              </RoleGuard>
            )}
          />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="logout" element={<Logout />} />
        <Route
          path="*"
          element={(
            <AuthGuard>
              <NotFound />
            </AuthGuard>
          )}
        />
      </Route>
    )
  )

  return useRef<any>(router).current
}

export default () => <RouterProvider router={useRouter()} />
