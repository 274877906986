import styled from 'styled-components/macro'
import React, { FC } from 'react'
import { flex } from '../../../core/styles/mixins'
import UserInfo from './UserInfo'
import NotesArea from './NotesArea'
import { ApplicationHeaderProvider } from '../state/useApplicationHeader'
import { useIsPlendOrganization } from '../../auth/hooks'

export const ContainerStyled = styled.div<{ isPlendOrganization: boolean }>`
  ${flex({ justify: 'space-between', align: 'flex-start' })};
  min-height: 196px;
  padding: ${({ isPlendOrganization }) => (isPlendOrganization ? 16 : 24)}px 24px 0;
`

type TProps = {
  header: JSX.Element
  footer?: JSX.Element
}

const ApplicationHeader: FC<TProps> = ({ header, footer }) => {
  const isPlendOrganization = useIsPlendOrganization()
  return (
    <ApplicationHeaderProvider>
      <ContainerStyled isPlendOrganization={isPlendOrganization}>
        {header}
        <UserInfo />
        <NotesArea />
      </ContainerStyled>
      {footer}
    </ApplicationHeaderProvider>
  )
}

export default ApplicationHeader
