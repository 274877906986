import { useMutation, useQuery, useQueryClient } from 'react-query'
import { TCreateValidationException, TEditValidationException, TException } from './types'
import { apiClient } from '../../core/api/apiClient'

enum QueryKeys {
  ExceptionsList = 'ExceptionsList',
  ValidationException = 'ValidationException'
}

async function getException(): Promise<TException> {
  try {
    const response = await apiClient('configuration/validation/exceptions/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useExceptionQuery = () => {
  const keys = [QueryKeys.ExceptionsList]
  return useQuery<TException, Error>(keys, getException)
}

async function checkException(exception: string): Promise<boolean> {
  try {
    const response = await apiClient(`configuration/validation/exceptions/name/validate?name=${exception}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useValidateExceptionMutation = () => {
  const keys = [QueryKeys.ValidationException]
  return useMutation<boolean, Error, string>(keys, (name) => checkException(name))
}

async function createException(data: TCreateValidationException): Promise<void> {
  try {
    await apiClient.post('/configuration/validation/exceptions', data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCreateValidationExceptionsMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TCreateValidationException>(
    (data) => createException(data),
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKeys.ExceptionsList)
    }
  )
}

async function deleteException(exceptionSourceId: string): Promise<void> {
  try {
    await apiClient.delete(`/configuration/validation/exceptions/${exceptionSourceId}`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDeleteValidationExceptionsMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, string>(
    (exceptionSourceId) => deleteException(exceptionSourceId),
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKeys.ExceptionsList)
    }
  )
}

async function editException({
  exceptionSourceId, ...rest
}: TEditValidationException): Promise<void> {
  try {
    await apiClient.put(`/configuration/validation/exceptions/${exceptionSourceId}`, rest)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useEditValidationExceptionsMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TEditValidationException>(
    (data) => editException(data),
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKeys.ExceptionsList)
    }
  )
}
