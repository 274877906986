import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { TAddress, THeaderData } from './type'
import { apiClient } from '../../core/api/apiClient'
import { useIsPlendOrganization } from '../auth/hooks'

const REFETCH_INTERVAL_HEADER_QUERY = 30000

enum QueryKey {
  HeaderQuery = 'HeaderQuery',
  HeaderAddress = 'HeaderAddress'
}

type TMutationProps = {
  declineReasonCode: string,
  note: string,
  status: 'DECLINED' | 'APPROVED' | 'IN_PROGRESS' | 'NEW' | 'CANCELLED' | 'APPROVED_PENDING_RETRIEVER'
  cancelReasonCode: string
  additionalDeclineReasonCodes: string[]
}

async function getHeaderData(requestId?: string): Promise<THeaderData> {
  try {
    const response = await apiClient(`dashboard/loan-requests/${requestId}`)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function updateHeader(
  note?: string,
  status?: string,
  requestId?: string,
  declineReasonCode?: string,
  cancelReasonCode?: string,
  additionalDeclineReasonCodes?: string[]
): Promise<THeaderData> {
  try {
    const response = await apiClient.post(`/dashboard/loan-requests/${requestId}`, {
      note,
      status,
      declineReasonCode,
      cancelReasonCode,
      additionalDeclineReasonCodes
    })
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useHeaderQuery() {
  const key = QueryKey.HeaderQuery
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { loanRequestId, failedValidationId } = useParams()

  const requestId = loanRequestId || failedValidationId
  return useQuery(
    [key, requestId],
    () => getHeaderData(requestId),
    {
      refetchInterval: (data) => (data && data.status === 'RESCORING'
        ? REFETCH_INTERVAL_HEADER_QUERY
        : false),
      onSuccess: (data) => {
        if (pathname.includes('applications') && data.status === 'FAILED') {
          navigate(`/failed-validation/${data.id}`)
        }
        if (pathname.includes('failed-validation') && data.status !== 'FAILED') {
          navigate(`/applications/${data.id}`)
        }
      }
    }
  )
}

export function useHeaderUpdateMutation() {
  const { loanRequestId, failedValidationId } = useParams()
  const requestId = loanRequestId || failedValidationId
  const queryClient = useQueryClient()

  return useMutation<THeaderData, Error, Partial<TMutationProps>>(
    ({
      note,
      status,
      declineReasonCode,
      cancelReasonCode,
      additionalDeclineReasonCodes
    }) => (
      updateHeader(
        note,
        status,
        requestId,
        declineReasonCode,
        cancelReasonCode,
        additionalDeclineReasonCodes
      )
    ),
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKey.HeaderQuery),
      onError: () => queryClient.invalidateQueries(QueryKey.HeaderQuery)
    }
  )
}

async function getAddress(requestId?: string): Promise<TAddress> {
  try {
    const response = await apiClient(`/dashboard/loan-requests/${requestId}/address`)
    return response.data
  } catch (error: any) {
    throw new Error('Something went wrong')
  }
}

export function useAddressQuery() {
  const isPlendOrganization = useIsPlendOrganization()
  const { data } = useHeaderQuery()
  const { loanRequestId } = useParams()

  const enabled = ['APPROVED', 'DECLINED', 'CANCELLED', 'APPROVED_PENDING_RETRIEVER'].includes(data?.status ?? '') && isPlendOrganization
  const keys = [QueryKey.HeaderAddress, enabled]

  return useQuery<TAddress, Error>(keys, () => getAddress(loanRequestId), { enabled })
}
