import { useQuery, useQueryClient } from 'react-query'
import { useEffect } from 'react'
import { apiClient } from '../../../core/api/apiClient'
import { TApplicationsData, TApplicationState } from './types'
import { prepareTablePaginationRequestParams } from '../../../core/utils/tableData'
import { useAuthContext } from '../../auth/state/useAuthState'
import { TLoanDetailsDocument } from '../../failedValidation/list/types'
import { QueryKey } from '../../failedValidation/list/queries'

export enum QueryKeys {
  Applications = 'Applications',
  SourceFilter = 'SourceFilter'
}

function isMyLoanFilter({ filter }: TApplicationState) {
  return filter === 'MY_LOANS'
}

function prepareStatus(state: TApplicationState) {
  return isMyLoanFilter(state) ? undefined : state.filter
}

async function getApplications(
  tableParams: TApplicationState,
  signal?: AbortSignal,
  underwriterId?: number,
  sources?: string[]
): Promise<TApplicationsData> {
  const { search, sortBy, currentPage } = tableParams

  try {
    const { data } = await apiClient.post('/dashboard/loan-requests', { sources }, {
      params: {
        ...prepareTablePaginationRequestParams(currentPage, 10, sortBy),
        status: prepareStatus(tableParams),
        search,
        underwriterId: isMyLoanFilter(tableParams) ? underwriterId : undefined
      },
      signal
    })

    return data
  } catch (error) {
    throw new Error('Error data')
  }
}

export function useApplicationsQuery(tableParams: TApplicationState, sources?: string[]) {
  const key = [QueryKeys.Applications, tableParams, sources]
  const { authData } = useAuthContext()
  const cache = useQueryClient()

  useEffect(() => {
    cache.invalidateQueries(key)
  }, [tableParams?.filter])

  return useQuery(
    key,
    ({ signal }) => getApplications(tableParams, signal, authData?.id, sources),
    {
      keepPreviousData: true,
      cacheTime: 0
    }
  )
}

async function loanDetailsDocument(): Promise<void> {
  try {
    const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'

    const { data } = await apiClient.get<TLoanDetailsDocument>('dashboard/loan-requests/report/export')
    const link = document.createElement('a')
    link.href = mediaType + data.contentBase64
    link.download = `${data.fileName}.xlsx`
    link.click()
  } catch (e: any) {
    throw new Error('Error data')
  }
}

export function useLoanDetailsDocumentQuery() {
  const keys = [QueryKey.LoanDetailsDocument]
  return useQuery<void, Error>(keys, () => loanDetailsDocument(), { enabled: false })
}

async function getSourceFilterOptions({ search }: {search: string}): Promise<string[]> {
  try {
    const { data } = await apiClient(
      'dashboard/loan-requests/source',
      { params: { search } }
    )

    return data.sources
  } catch (e: any) {
    throw new Error('Error data')
  }
}

export function useSourceFilterQuery(params: {search: string}) {
  return useQuery<string[], Error>(
    [QueryKeys.SourceFilter, params],
    () => getSourceFilterOptions(params),
    {
      enabled: true
    }
  )
}
