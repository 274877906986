import { DialogActions as MuiDialogActions, FormControlLabel as Label } from '@mui/material'
import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { useForm, UseFormReset } from 'react-hook-form'
import Modal from '../Modal'
import CustomInput from '../../../../core/components/CustomInput'
import { emailValidation, requiredRule } from '../../../../core/utils/formRules'
import { fontFamily } from '../../../../core/styles/mixins'
import { BaseButton } from '../../../../core/components/Button'
import { TGenerateLinkForm } from '../../types'
import { usePartnerDashboardContext } from '../../state/PartnerDashboardState'
import { CYMRU_ORGANIZATION_ID } from '../../constants/partnerDashboard'

const FormStyled = styled.form`
  .MuiTypography-root {
    ${fontFamily('Inter')};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.darkBlack};
  }

  .MuiFormControlLabel-root {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
  }
`
const FieldWrapperStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px 16px;
`

const ButtonCancelStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 8px 16px;
    max-width: 98px;

    &:hover {
      background: ${({ theme }) => theme.colors.grey500};
    }

    &:disabled {
      opacity: .5;
      pointer-events: all;
      cursor: not-allowed;
    }
  }
`

const ButtonActionStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px 16px;
    margin-left: 18px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }

    &:disabled {
      opacity: .5;
      pointer-events: all;
      cursor: not-allowed;
    }
  }
`

const DialogActions = styled(MuiDialogActions)`
  && {
    padding: 24px 0 0 0;
  }
`

function useFormInit() {
  return useForm<TGenerateLinkForm>({
    shouldUnregister: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: ''
    }
  })
}

function useOnSubmit(reset: UseFormReset<TGenerateLinkForm>) {
  const {
    setModalOpen,
    generateLinkMutation: { mutateAsync: generateLinkMutateAsync },
    checkLeadMutation: { mutateAsync: checkLeadMutateAsync }
  } = usePartnerDashboardContext()
  return useCallback((data: TGenerateLinkForm) => {
    checkLeadMutateAsync(data)
      .then((response) => {
        if (response.hasApprovedSocialLoan) {
          reset()
          setModalOpen({
            generateLink: false,
            failed: true
          })
          return
        }
        generateLinkMutateAsync({ ...data, organizationId: CYMRU_ORGANIZATION_ID })
          .then(() => {
            reset()
            setModalOpen({
              generateLink: false,
              success: true
            })
          })
      })
  }, [])
}

const GenerateLinkModal = () => {
  const { modalOpen, setModalOpen } = usePartnerDashboardContext()
  const { control, handleSubmit, reset } = useFormInit()
  const onSubmit = useOnSubmit(reset)

  const handleClose = useCallback(() => {
    reset()
    setModalOpen({ generateLink: false })
  }, [])

  return (
    <Modal
      open={modalOpen.generateLink}
      onClose={handleClose}
      title="Generate link">
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapperStyled>
          <Label
            control={(
              <CustomInput
                name="firstName"
                control={control}
                rules={{
                  required: requiredRule('This field is required')
                }}
              />
            )}
            label="First Name"
            labelPlacement="top"
          />
          <Label
            control={(
              <CustomInput
                name="lastName"
                control={control}
                rules={{
                  required: requiredRule('This field is required')
                }}
              />
            )}
            label="Last Name"
            labelPlacement="top"
          />
          <Label
            control={(
              <CustomInput
                name="email"
                control={control}
                rules={{
                  required: requiredRule('This field is required'),
                  validate: emailValidation
                }}
              />
            )}
            label="Email"
            labelPlacement="top"
          />
        </FieldWrapperStyled>
        <DialogActions>
          <div>
            <ButtonCancelStyled
              onClick={handleClose}>
              Cancel
            </ButtonCancelStyled>
            <ButtonActionStyled
              type="submit">
              Generate link
            </ButtonActionStyled>
          </div>
        </DialogActions>
      </FormStyled>
    </Modal>
  )
}

export default GenerateLinkModal
