import React, { FC, ReactNode } from 'react'
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle
} from '@mui/material'
import styled from 'styled-components/macro'
import { CloseLineIcon } from '../../../core/components/icons/CloseLineIcon'
import { fontFamily } from '../../../core/styles/mixins'

const DialogStyled = styled.div`
  width: 480px;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  outline: none;
  box-shadow: ${({ theme }) => theme.colors.shadow200};
  border-radius: 6px;
  padding: 24px;
  margin: 0;
`

const IconContainerStyled = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const DialogTitle = styled(MuiDialogTitle)`
  && {
    ${fontFamily('Inter')};
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.darkBlack};
    padding: 0;
    margin-bottom: 24px;
  }
`

type TProps = {
  open: boolean,
  onClose: () => void
  title: string
  children: ReactNode
}
const Modal: FC<TProps> = ({ open, onClose, title, children }) => (
  <MuiDialog open={open} onClose={onClose}>
    <DialogStyled>
      <DialogTitle>{title}</DialogTitle>
      <IconContainerStyled onClick={onClose}>
        <CloseLineIcon />
      </IconContainerStyled>
      {children}
    </DialogStyled>
  </MuiDialog>
)

export default Modal
