import { useMemo } from 'react'
import styled, { css } from 'styled-components/macro'
import { Column } from 'react-table'
import { format } from 'date-fns'
import { useTransactionContext } from '../state/useTransactions'
import Table from '../../../../core/components/table/Table'
import NoDataComponent from '../../../../core/components/NoDataComponent'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import Spinner from '../../../../core/components/Spinner'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { SLASH_DATE_FORMAT } from '../../../../core/const/date'
import { CustomCheckboxStyled } from '../../../../core/components/CustomCheckbox'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import TableHeadCheckbox from './TableHeadCheckbox'
import { useIsReClassifiable } from '../../applicationDetail/hooks/useIsReClassifiable'
import { TTransaction } from '../types'
import CustomTooltip from '../../../../core/components/CustomTooltip'
import { ExclamationIcon } from '../../../../core/components/icons/ExclamationIcon'
import { CheckIcon } from '../../../../core/components/icons/CheckIcon'
import ClassificationText from '../../../../core/components/ClassificationText'
import { useIsPlendOrganization } from '../../../auth/hooks'

const reclassifyBodyMixin = css`
    :first-child {
        width: 2%;
    }

    :nth-child(2) {
        width: 10%;
    }

    :nth-child(3) {
        width: 39%;
    }

    :nth-child(4) {
        width: 15%;
    }

    :nth-child(5) {
        width: 25%;
    }

    :nth-child(6) {
        width: 10%;
    }

    :last-child {
        width: 8%;
    }
`

const defaultBodyMixin = css`
    :first-child {
        width: 10%;
    }

    :nth-child(2) {
        width: 30%;
    }

    :nth-child(3) {
        width: 15%;
    }

    :nth-child(4) {
        width: 25%;
    }

    :nth-child(5) {
        width: 10%;
    }

    :last-child {
        width: 10%;
    }

`

const Wrapper = styled.div<{ isReClassifiable?: boolean }>`

    ${HeaderCellStyled} {

        padding: 24px 16px 16px 16px;
        font-weight: 400;

        :nth-child(2) {
            padding-left: ${({ isReClassifiable }) => (isReClassifiable ? 0 : 16)}px;
        }
    }

    ${BodyCellStyled} {
        padding: 16px;
        font-weight: 400;

        :first-child {
            padding-right: 8px;
        }

        :nth-child(2) {
            padding-left: 0;
        }

        ${({ isReClassifiable }) => (isReClassifiable ? reclassifyBodyMixin : defaultBodyMixin)}
    }
`

const NoDataWrapper = styled.div`
    height: 480px;
    margin-top: 0;
`

const NoResults = styled.div`
    ${flex({ align: 'center' })};
    flex-direction: column;
`

const TitleStyled = styled.p`
    ${fontFamily('Inter')};
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.darkBlack};
    margin-top: 30px;
`

const MessageStyled = styled.span`
    ${fontFamily('Inter')};
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey600};
`

const NameWrapStyled = styled.div`
    ${flex({ align: 'center' })};
    gap: 4px;
`

const IconStyled = styled.div<{ isColorBlue?: boolean }>`
    ${flex({ justify: 'center', align: 'center' })};
    cursor: pointer;
    color: ${({ theme, isColorBlue }) => (
    isColorBlue ? theme.colors.blue500 : 'transparent')};
`

const TransactionsTable = () => {
  const {
    transactionsQuery: { data, isFetching },
    sortBy,
    setCurrentPage,
    setSortBy,
    selectedTransactions,
    setSelectedTransactions
  } = useTransactionContext()

  const isPlendOrganization = useIsPlendOrganization()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: <TableHeadCheckbox />,
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <CustomCheckboxStyled
            checked={selectedTransactions.some((transaction) => transaction.id === value)}
            onChange={(event, checked) => {
              setSelectedTransactions((prevTransactions) => (checked ? [
                ...prevTransactions,
                row.original as TTransaction
              ] : prevTransactions.filter(
                (transaction) => transaction.id !== (row.original as TTransaction).id
              )))
            }}
          />
        )
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => <span>{format(new Date(value), SLASH_DATE_FORMAT)}</span>,
        sortDescFirst: true
      },
      {
        Header: 'Description',
        accessor: 'name',
        Cell: ({ value, row }) => {
          const {
            nonAppliedClassifications,
            appliedClassifications
          } = row.original as TTransaction
          return (
            <NameWrapStyled>
              <span>{value}</span>
              {nonAppliedClassifications.length ? (
                <CustomTooltip
                  maxWidth={400}
                  arrow
                  placement="top"
                  title={(
                    <ClassificationText
                      classification={nonAppliedClassifications}
                    />
                  )}>
                  <IconStyled isColorBlue>
                    <ExclamationIcon width={16} height={16} />
                  </IconStyled>
                </CustomTooltip>
              ) : null}
              {
                appliedClassifications.length ? (
                  <CustomTooltip
                    arrow
                    maxWidth={400}
                    placement="top"
                    title={(
                      <ClassificationText
                        classification={appliedClassifications}
                      />
                    )}>
                    <IconStyled>
                      <CheckIcon width={16} height={16} />
                    </IconStyled>
                  </CustomTooltip>
                ) : null
              }
            </NameWrapStyled>
          )
        }
      },
      {
        Header: 'OB Class 1',
        accessor: 'taxonomyFirstClass'
      },
      {
        Header: 'OB Class 2',
        accessor: 'taxonomySecondClass'
      },
      {
        Header: 'FCA Category',
        accessor: 'fcaCategory'
      },
      {
        Header: 'Transaction Code',
        accessor: 'transactionCode'
      },
      {
        Header: 'Account №',
        accessor: 'accountMask',
        Cell: ({ value }) => <span>{value ? `· ${value}` : null}</span>,
        sortDescFirst: true
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => <span>{formatToPound(value)}</span>,
        sortDescFirst: true
      }
    ],
    [selectedTransactions]
  )

  const transactionsData = useMemo(() => data?.content || [], [data])

  if (!data) {
    return (
      <NoDataWrapper>
        <Spinner />
      </NoDataWrapper>
    )
  }

  return (
    <Wrapper>
      <Table
        hiddenColumns={isPlendOrganization ? [] : ['accountMask']}
        columns={columns}
        data={transactionsData}
        showLoader={isFetching}
        paginationConfig={{
          isEnabled: data.totalPages > 1,
          pageSize: data.size,
          manual: true,
          totalRows: data.totalElements,
          pageCount: data.totalPages,
          onPageChange: (page) => setCurrentPage(page)
        }}
        sortingConfig={{
          sortable: true,
          manual: true,
          initialSortBy: sortBy && [sortBy],
          onSortByChange: (sortBy) => setSortBy(sortBy && sortBy[0])
        }}
      />
      {!transactionsData.length && (
        <NoDataWrapper>
          <NoDataComponent iconWidth={52} iconHeight={48}>
            <NoResults>
              <TitleStyled>No results found</TitleStyled>
              <MessageStyled>
                Try adjusting your search to find what you’re looking for
              </MessageStyled>
            </NoResults>
          </NoDataComponent>
        </NoDataWrapper>
      )}
    </Wrapper>
  )
}

export default TransactionsTable
