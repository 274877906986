import { useQuery } from 'react-query'
import { format } from 'date-fns'
import { apiClient } from '../../core/api/apiClient'
import { TApprovalTime, TConfigurationUsers, TLoanSize, TStatuses } from './types'
import { allUnderwriters, useHomeContext } from './state/useHome'
import { useAuthContext } from '../auth/state/useAuthState'
import { useHasPermission } from '../auth/hooks'
import { DASH_DATE_FORMAT } from '../../core/const/date'

enum QueryKeys {
  ApprovalStatistics = 'ApprovalStatistics',
  SizeStatistics = 'SizeStatistics',
  StatusStatistics = 'StatusStatistics',
  ConfigurationUsers = 'ConfigurationUsers'
}

async function getStatistics(
  url: string,
  date: Date,
  signal?: AbortSignal,
  userId?: string
): Promise<any> {
  try {
    const response = await apiClient.get<any>(url, {
      params: {
        userId: userId === allUnderwriters ? null : userId,
        date: format(date, DASH_DATE_FORMAT)
      },
      signal
    })
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function getConfigurationUsers(): Promise<TConfigurationUsers> {
  try {
    const response = await apiClient.get<TConfigurationUsers>('/configuration/users?unpaged=true')
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useConfigurationUsersQuery() {
  const key = QueryKeys.ConfigurationUsers
  const isOrganization = useHasPermission(['ORGANIZATION_ADMIN_VIEW'])

  return useQuery(key, getConfigurationUsers, { enabled: isOrganization })
}

export function useApprovalStatisticsQuery(date: Date, userId: string) {
  return useQuery<TApprovalTime, Error>(
    [QueryKeys.ApprovalStatistics, userId, date],
    ({ signal }) => getStatistics('/dashboard/statistic/approvals', date, signal, userId),
    {
      keepPreviousData: true
    }
  )
}

export function useSizeStatisticsQuery(date: Date, userId: string) {
  return useQuery<TLoanSize, Error>(
    [QueryKeys.SizeStatistics, userId, date],
    ({ signal }) => getStatistics('/dashboard/statistic/size', date, signal, userId),
    {
      keepPreviousData: true
    }
  )
}

export function useStatusStatisticsQuery(date: Date, userId: string) {
  return useQuery<TStatuses, Error>(
    [QueryKeys.StatusStatistics, userId, date],
    ({ signal }) => getStatistics('/dashboard/statistic/statuses', date, signal, userId),
    {
      keepPreviousData: true
    }
  )
}
