import styled from 'styled-components/macro'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup, FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useSetState } from 'react-use'
import xor from 'lodash/xor'
import { fontFamily } from '../../../../../core/styles/mixins'
import Modal from '../Modal'
import { useAssessmentContext } from '../../state/useAssessment'
import { BaseButton } from '../../../../../core/components/Button'
import { TGetReConnectLinkFormData } from '../../type'
import { useReConnectLinkQuery } from '../../queries'

const SubTitleStyled = styled.div`
  ${fontFamily('Inter')};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey600}
`

const ActionButtonStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    margin-left: -8px;
    padding: 8px 16px;
    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.white};
      opacity: .5;
    }
  }
`

const WrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  
  && {
    .MuiFormControl-root {
      margin-top: 20px;
    }

    .MuiFormLabel-root {
      ${fontFamily('Inter')};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.darkBlack}
    }
    
    .MuiCheckbox-root, .MuiRadio-root {
      padding-top: 0;
      padding-bottom: 0;
    }
    
    .MuiFormControlLabel-root {
      margin-top: 8px;
    }
  }
`

type TOption = {
  label: string
  value: string
}

function useOptions() {
  const {
    reConnect: {
      reconnectRulesQuery: { data: reconnectRules }
    }
  } = useAssessmentContext()

  return {
    validationTypes: reconnectRules?.validationTypes.map(({ value, code }) => ({
      label: value,
      value: code
    })) ?? [],
    repullTypes: reconnectRules?.repullTypes.map(({ value, code }) => ({
      label: value,
      value: code
    })) ?? [],
    repostingReasons: reconnectRules?.repostReasons.map(({ value, code, id }) => ({
      label: value,
      value: code
    })) ?? []
  }
}

function useSubmitHandle(formData: TGetReConnectLinkFormData) {
  const { setIsModalOpen, reConnect: { setReConnectLink } } = useAssessmentContext()
  const { refetch } = useReConnectLinkQuery(formData)

  return useCallback(() => {
    refetch().then(({ data }) => {
      setReConnectLink(data?.reconnectLink)
      setIsModalOpen({ getReconnectLink: false })
      setIsModalOpen({ reconnectLinkSuccess: true })
    })
  }, [formData])
}

function useIsReadyToSubmit({ rePull, repostingReason }: TGetReConnectLinkFormData) {
  return !!rePull.length && !!repostingReason
}

function useFromData() {
  const { isModalOpen } = useAssessmentContext()

  const initialState: TGetReConnectLinkFormData = {
    validationLayer: [],
    rePull: [],
    repostingReason: undefined
  }

  const [formData, setFormData] = useSetState<TGetReConnectLinkFormData>(initialState)

  useEffect(() => {
    setFormData(initialState)
  }, [isModalOpen])

  return {
    formData,
    setRepostingReason: (value: string) => setFormData({ repostingReason: value }),
    toggleValidationLayer: (value: string) => setFormData({
      validationLayer: xor(formData.validationLayer, [value])
    }),
    toggleRePull: (value: string) => setFormData({
      rePull: xor(formData.rePull, [value])
    })
  }
}

export const GetLinkModal = () => {
  const { repullTypes, validationTypes, repostingReasons } = useOptions()
  const {
    formData,
    toggleValidationLayer,
    setRepostingReason,
    toggleRePull
  } = useFromData()
  const isReadyToSubmit = useIsReadyToSubmit(formData)

  const submitHandle = useSubmitHandle(formData)
  const { setIsModalOpen, isModalOpen } = useAssessmentContext()

  return (
    <Modal
      title="Send a re-connect link"
      content={(
        <WrapStyled>
          <FormControl>
            <FormLabel>Select the validation layers:</FormLabel>
            <FormGroup>
              {validationTypes.map(({ label, value }) => (
                <FormControlLabel
                  key={label}
                  control={<Checkbox onChange={() => toggleValidationLayer(value)}/>}
                  label={label}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel>
              Re-pull:
              <br/>
              <SubTitleStyled>Select at least one</SubTitleStyled>
            </FormLabel>
            <FormGroup>
              {repullTypes.map(({ label, value }) => (
                <FormControlLabel
                  key={label}
                  control={<Checkbox />}
                  label={label}
                  onChange={() => toggleRePull(value)}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Reason for reposting:</FormLabel>
            <RadioGroup onChange={({ target }) => {
              setRepostingReason(target.value)
            }}>
              {repostingReasons.map(({ label, value }) => (
                <FormControlLabel
                  key={label}
                  value={label}
                  control={<Radio />}
                  label={label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </WrapStyled>
      )}
      renderActionButton={() => (
        <ActionButtonStyled
          disabled={!isReadyToSubmit}
          onClick={submitHandle}
          autoFocus
        >
          Send link
        </ActionButtonStyled>
      )}
      isOpen={isModalOpen.getReconnectLink}
      handleClose={() => setIsModalOpen({ getReconnectLink: false })}
    />
  )
}
export default GetLinkModal
