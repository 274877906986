import styled from 'styled-components/macro'
import { Menu, MenuItem, MenuProps } from '@mui/material'
import React, { useState } from 'react'
import { fontFamily } from '../../../../core/styles/mixins'
import { MoreIcon } from '../../../../core/components/icons/MoreIcon'
import Modal from './Modal'
import { useAssessmentContext } from '../state/useAssessment'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { useTabsVisited } from '../queries'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))`
  && .MuiPaper-root {
    border-radius: 3px;
    margin-top: 2px;
    min-width: 160px;
    color: ${({ theme }) => theme.colors.darkBlack};
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);

    & .MuiMenuItem-root {
      ${fontFamily('Inter')};
      color: ${({ theme }) => theme.colors.darkBlack};
      padding: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    }

    & .MuiMenu-list {
      padding: 0;
    }
  }
`

const MoreButtonWrapper = styled.button`
  width: 30px;
  height: 40px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 4px 7px 4px;
  margin: 0 20px;
  background: ${({ theme }) => theme.colors.grey200};
`

export const AssessmentDropOut = () => {
  const [openModal, setOpenModal] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const {
    setIsModalOpen,
    isModalOpen
  } = useAssessmentContext()
  const {
    headerUpdateMutation: { mutate, isLoading: isUpdating }
  } = useApplicationHeaderContext()

  const { refetch, data: isAllTabVisited } = useTabsVisited()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <>
      <MoreButtonWrapper onClick={handleClick}>
        <MoreIcon onClick={handleClick} />
      </MoreButtonWrapper>
      <StyledMenu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            refetch().then(() => {
              setIsModalOpen({ revert: true })
              setOpenModal(true)
              setAnchorEl(null)
            })
          }}
        >
          Drop out of the assessment
        </MenuItem>
      </StyledMenu>
      <Modal
        title="Revert application to 'New' status"
        content="Are you sure you want to revert this application to 'New' status?"
        buttonText="Revert"
        error={!isAllTabVisited || false}
        queryRequest={() => {
          if (!isUpdating) {
            mutate({ status: 'NEW' })
            setIsModalOpen({ revert: false })
          }
        }}
        isOpen={isModalOpen.revert}
        handleClose={() => setIsModalOpen({ revert: false })} />
    </>
  )
}
export default AssessmentDropOut
