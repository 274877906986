import { FC, useEffect, useMemo, useState } from 'react'
import { Control, useWatch, useFieldArray, Controller } from 'react-hook-form'
import styled from 'styled-components/macro'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { useOptionsContext } from '../state/useOptions'
import { CustomSelectWrapper } from '../../../core/components/CustomSelect'
import { TFormData, TSelectValue } from '../types'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { CloseLineIcon } from '../../../core/components/icons/CloseLineIcon'
import { ChevronDownIcon } from '../../../core/components/icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../../core/components/icons/ChevronUpIcon'
import { useFormContext } from '../state/formState'
import CustomTooltip from '../../../core/components/CustomTooltip'

const Wrapper = styled.div`
  ${CustomSelectWrapper} {
    margin-bottom: 4px;
    margin-top: 0;
  }
`

const FormControlStyled = styled(FormControl)`
  && {
    width: 200px;
  }
`

const CustomSelect = styled(Select)`
  && {
    & .Mui-error {
      border: 2px solid ${({ theme }) => theme.colors.red};
    }
  }
`

const ChipContainerStyled = styled.div`
  ${flex({})};
  flex-direction: column;
`

const ChipStyled = styled(Chip)`
  && {
    ${flex({ justify: 'flex-start', align: 'center' })};
    background: ${({ theme }) => theme.colors.grey300};
    border-radius: 6px;
    max-width: 176px;
    margin: 4px 0;
    width: max-content;
    
    .MuiChip-label {
      padding: 0;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
`

const ChipLabel = styled.p`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
`

const CustomInputStyled = styled(OutlinedInput)`
  && {
    & .MuiSvgIcon-root {
      top: 6px;
      right: 8px;
    }
  }
`

const IconComponentStyled = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const IconDeleteWrapper = styled.div`
  width: 16px;
  height: 16px;
  ${flex({ justify: 'center', align: 'center' })};
`

const MenuItemStyled = styled(MenuItem)`
  && {
    ${fontFamily('Inter')};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.darkBlack};

    & .Mui-disabled {
      color: ${({ theme }) => theme.colors.grey600};
    }
  }
`

const PlaceholderStyled = styled.p`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grey600};
`

type TProps = {
  control: Control<any>,
  isLoanPurpose: boolean
}

const RuleChipDropDown: FC<TProps> = ({ control, isLoanPurpose }) => {
  const [open, setOpen] = useState(false)
  const { useFormReturn: { clearErrors } } = useFormContext()
  const [ruleValues, setRuleValues] = useState({})
  const { remove, append, fields } = useFieldArray({
    control,
    name: 'rules',
    keyName: 'key'
  })

  useEffect(() => {
    if (fields.length) {
      const rules = fields as unknown as TFormData['rules']
      setRuleValues({
        daysPeriod: rules[0].daysPeriod,
        version: rules[0].version,
        rule: rules[0].rule
      })
    }
  }, [fields])

  const { value: employmentStatusOptions, loanPurposeOptions } = useOptionsContext()
  const rules: TFormData['rules'] = useWatch({ control, name: 'rules' })

  const options = isLoanPurpose ? loanPurposeOptions : employmentStatusOptions

  const availableOptions = useMemo(() => {
    const filteredRuleValue = rules.map(({ value }) => value)
    return options.filter(({ value }) => !filteredRuleValue.includes(+value))
  }, [rules])

  const IconComponent = useMemo(() => (open ? ChevronUpIcon : ChevronDownIcon), [open])

  return (
    <Wrapper>
      <FormControlStyled>
        <Controller
          control={control}
          name="rules"
          render={({ field, fieldState: { error } }) => (
            <CustomSelect
              {...field}
              multiple
              error={!!error}
              displayEmpty
              value={fields as unknown as TSelectValue[]}
              open={open}
              IconComponent={() => (
                <IconComponentStyled>
                  <IconComponent width={16} height={16} />
                </IconComponentStyled>
              )}
              input={<CustomInputStyled onClick={() => setOpen(!open)} />}
              onChange={(event) => {
                clearErrors()
                const value = (event.target.value as TFormData['rules'] & string).at(-1)
                append({
                  value,
                  ...ruleValues
                })
              }}
              renderValue={(values) => {
                const selectedValues = values as TSelectValue[]
                return selectedValues?.length
                  ? (
                    <ChipContainerStyled>
                      {selectedValues?.map((selectedValue, index) => {
                        const label = options.find(
                          ({ value }) => value === selectedValue?.value
                        )?.label

                        const content = (
                          <ChipStyled
                            deleteIcon={(
                              <IconDeleteWrapper>
                                <CloseLineIcon width={16} height={16} />
                              </IconDeleteWrapper>
                            )}
                            onDelete={() => remove(index)}
                            key={selectedValue.key}
                            label={<ChipLabel>{label}</ChipLabel>}
                            onMouseDown={(event) => {
                              event.stopPropagation()
                            }}
                          />
                        )

                        return (
                          isLoanPurpose
                            ? (
                              <CustomTooltip
                                maxWidth={195}
                                arrow
                                disableFocusListener
                                disableTouchListener
                                title={label as any}
                                placement="top"
                              >
                                {content}
                              </CustomTooltip>
                            )
                            : content
                        )
                      })}
                    </ChipContainerStyled>
                  )
                  : <PlaceholderStyled>Select statuses...</PlaceholderStyled>
              }}
            >
              {availableOptions.length
                ? availableOptions.map(({ label, value }) => (
                  <MenuItemStyled key={value} value={value}>
                    {label}
                  </MenuItemStyled>
                ))
                : (
                  <MenuItemStyled disabled>
                    No other options
                  </MenuItemStyled>
                )}
            </CustomSelect>
          )} />

      </FormControlStyled>
    </Wrapper>
  )
}

export default RuleChipDropDown
