import constate from 'constate'
import { useEffect, useState } from 'react'
import { useRulesQuery, useVersionHistoryQuery } from '../queries'
import { TVersionData } from '../types'
import { useScoringConf } from '../../list/queries'

function useVersionSelection(versionData?: TVersionData[]) {
  const [selectedVersion, setSelectedVersion] = useState<TVersionData>()

  useEffect(() => setSelectedVersion(versionData?.[0]), [versionData])
  return {
    selectedVersion,
    setSelectedVersion
  }
}

function useHistoryVersion() {
  const [currentPage, setCurrentPage] = useState(1)

  return {
    currentPage,
    setCurrentPage,
    historyQuery: useVersionHistoryQuery(currentPage)
  }
}

function useScoring() {
  const [isPreviewing, setIsPreviewing] = useState(false)

  const { historyQuery, currentPage, setCurrentPage } = useHistoryVersion()
  const { selectedVersion, setSelectedVersion } = useVersionSelection(historyQuery?.data?.content)

  return {
    isPreviewing,
    setIsPreviewing,
    currentPage,
    setCurrentPage,
    selectedVersion,
    setSelectedVersion,
    historyVersionQuery: historyQuery,
    rulesQuery: useRulesQuery(selectedVersion?.version, selectedVersion?.snapshot),
    scoringConf: useScoringConf()
  }
}
export const [ScoringProvider, useScoringContext] = constate(useScoring)
