import React, {
  ChangeEvent,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styled, { css } from 'styled-components/macro'
import { useClickAway } from 'react-use'
import { TextareaAutosize } from '@mui/material'
import { CSSTransition } from 'react-transition-group'
import { SizeDownIcon } from '../../../core/components/icons/SizeDownIcon'
import { SizeUpIcon } from '../../../core/components/icons/SizeUpIcon'
import { fontFamily } from '../../../core/styles/mixins'
import { useApplicationHeaderContext } from '../state/useApplicationHeader'
import LoanDescription from './LoanDescription'
import { useIsRescoring } from '../hooks/useIsRescoring'
import { useIsPlendOrganization } from '../../auth/hooks'
import { formatToPound } from '../../../core/utils/currencyFormat'

const minWidth = 344
const maxWidth = 844
const minHeight = 140
const maxHeight = 400
const buttonMinTop = 115
const buttonMaxTop = 376

const TextAriaContainerStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: ${minWidth}px;
`

const transitionName = 'opening'
const animationDuration = 400

const TextAriaContentStyled = styled.div<{ isOpen?: boolean }>`
  ${fontFamily('Inter')};
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  padding: 8px;
  position: absolute;
  top: 0;
  z-index: 100;
  right: 0;
  border-radius: 3px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: ${minHeight}px;
  background: ${({ theme }) => theme.colors.yellow100};
  ${({ isOpen }) =>
    !isOpen
    && css`
      &::-webkit-scrollbar {
        display: none;
      }
    `}
`

const TextAriaShadesContainerStyled = styled.div`
  position: absolute;
  top: 0;
  z-index: 110;
  pointer-events: none;
  right: 0;
  width: 100%;
  height: ${minHeight}px;
  border: 1px solid ${({ theme }) => theme.colors.yellow500};
  border-radius: 3px;
`

const ButtonResizerStyled = styled.div<{ isRescoring?: boolean }>`
  height: 16px;
  position: absolute;
  z-index: 158;
  top: ${buttonMinTop}px;
  color: ${({ theme }) => theme.colors.grey600};
  right: 8px;
  cursor: ${({ isRescoring }) => (isRescoring ? 'auto' : 'pointer')};
  opacity: ${({ isRescoring }) => (isRescoring ? 0.5 : 1)};
`

const TransitionContainerStyled = styled.div`
  ${TextAriaContentStyled}, ${ButtonResizerStyled}, ${TextAriaShadesContainerStyled} {
    transition: all ${animationDuration}ms ease-in;
  }

  &.${transitionName}-enter-active, &.${transitionName}-enter-done {
    ${TextAriaContentStyled}, ${TextAriaShadesContainerStyled} {
      width: ${maxWidth}px;
      height: ${maxHeight - 4}px;
    }

    ${ButtonResizerStyled} {
      top: ${buttonMaxTop}px;
    }
  }
`

const UpSideBlurStyled = styled.div`
  z-index: 156;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  min-width: calc(100% - 4px);
  height: 40px;
  border-radius: 3px;
  background: linear-gradient(0deg, rgba(255, 253, 232, 0) 0%, #fffde8 100%);
`

const DownSideBlurStyled = styled.div`
  z-index: 156;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  min-width: calc(100% - 4px);
  height: 40px;
  border-radius: 3px;
  background: linear-gradient(180deg, rgba(255, 253, 232, 0) 0%, #fffde8 100%);
`

const TextareaAutosizeStyled = styled.div`
  textarea {
    ${fontFamily('Inter')};
    width: 100%;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkBlack};
    display: block;
    border: none !important;
    outline: none;
    resize: none;
    background: transparent;
  }
`
const OverlayStyled = styled.div`
  position: absolute;
  opacity: 0.5;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
`

function useShadeHandle(scrollableAreaRef: RefObject<HTMLDivElement | null>) {
  const [state, setState] = useState<{
    isScrollExist: boolean
    isScrollOnMiddle: boolean
    isScrollOnBottom: boolean
    isScrollOnTop: boolean
  }>()

  const checkShades = () => {
    const { scrollHeight, clientHeight, scrollTop } = scrollableAreaRef.current!

    const height = scrollHeight - clientHeight

    setState({
      isScrollExist: scrollHeight > clientHeight,
      isScrollOnMiddle: height > Math.ceil(scrollTop) && scrollTop > 0,
      isScrollOnBottom: Math.ceil(scrollTop) >= height,
      isScrollOnTop: scrollTop === 0
    })
  }

  return {
    topShade: useMemo(
      () => state && state.isScrollExist && (state.isScrollOnBottom || state.isScrollOnMiddle),
      [state]
    ),
    bottomShade: useMemo(
      () => state && state.isScrollExist && (state.isScrollOnTop || state.isScrollOnMiddle),
      [state]
    ),
    checkShades
  }
}

const generateDefaultNote = (loanPurpose?: string, loanLength?: number, amount?: number) => {
  const loanTerm = `Loan term: ${loanLength?.toString() ? loanLength : '-'} months`
  const loanAmount = `Loan amount: ${amount?.toString() ? formatToPound(amount) : '-'}`
  return `${loanTerm}\n${loanAmount}\nLoan purpose: ${loanPurpose}`
}

function useNote() {
  const {
    headerQuery: { data: headerData, isLoading },
    headerUpdateMutation: { mutate }
  } = useApplicationHeaderContext()
  const [note, setNote] = useState<string>('')
  const [isTextareaOpen, setIsTextareaOpen] = useState<boolean>(false)
  const isPlendOrganization = useIsPlendOrganization()
  const defaultNote = generateDefaultNote(
    headerData?.loanPurpose,
    headerData?.loanLength,
    headerData?.loanAmount
  )
  const handleChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(value)
  }

  const handleBlur = () => {
    if (note === headerData?.note) {
      return
    }
    mutate({ note })
  }

  useEffect(() => {
    if (headerData && headerData.note) setNote(headerData.note)
    if (headerData && headerData.note === null && isPlendOrganization) mutate({ note: defaultNote })
  }, [headerData?.note])

  return {
    isTextareaOpen,
    note,
    isNoteLoading: isLoading,
    setIsTextareaOpen,
    handleChange,
    handleBlur
  }
}

function useTextareaMinHeightHandle(
  textareaHeaderRef: RefObject<HTMLDivElement | null>,
  textareaContentRef: RefObject<HTMLDivElement | null>
) {
  const [textareaMinHeight, setTextareaMinHeight] = useState('auto')

  const recalculateTextareaMinHeight = useCallback(() => {
    setTimeout(() => {
      const textareaContentHeight: any = textareaContentRef.current?.getBoundingClientRect()?.height
      const textareaHeaderHeight: any = textareaHeaderRef.current?.getBoundingClientRect()?.height

      setTextareaMinHeight(
        `calc(${textareaContentHeight}px - ${Math.ceil(textareaHeaderHeight)}px)`
      )
    }, 100)
  }, [textareaContentRef, textareaHeaderRef])

  useLayoutEffect(() => recalculateTextareaMinHeight(), [])

  return {
    textareaMinHeight,
    textareaHeaderRef,
    textareaContentRef,
    recalculateTextareaMinHeight,
    resetTextareaMinHeight: useCallback(() => setTextareaMinHeight('auto'), [])
  }
}

const NotesArea: FC = () => {
  const {
    isTextareaOpen,
    note,
    isNoteLoading,
    setIsTextareaOpen,
    handleChange,
    handleBlur } = useNote()

  const textareaContainerRef = useRef<HTMLDivElement | null>(null)
  const textareaHeaderRef = useRef<HTMLDivElement | null>(null)
  const textareaContentRef = useRef<HTMLDivElement | null>(null)
  const isRescoring = useIsRescoring()
  const { topShade, checkShades, bottomShade } = useShadeHandle(textareaContentRef)

  useLayoutEffect(() => {
    if (!isNoteLoading) {
      setTimeout(() => checkShades())
    }
  }, [isNoteLoading])

  useClickAway(
    textareaContainerRef,
    (e: any) => !textareaContainerRef.current?.contains(e.target) && setIsTextareaOpen(false),
    ['click']
  )

  const {
    textareaMinHeight,
    resetTextareaMinHeight,
    recalculateTextareaMinHeight
  } = useTextareaMinHeightHandle(textareaHeaderRef, textareaContentRef)

  return (
    <TextAriaContainerStyled ref={textareaContainerRef}>
      <CSSTransition
        in={isTextareaOpen}
        timeout={animationDuration}
        onEnter={() => {
          resetTextareaMinHeight()
          checkShades()
        }}
        onExit={() => {
          resetTextareaMinHeight()
          checkShades()
        }}
        onExited={() => {
          recalculateTextareaMinHeight()
          checkShades()
        }}
        onEntered={() => {
          recalculateTextareaMinHeight()
          checkShades()
        }}
        classNames={transitionName}
      >
        <TransitionContainerStyled>
          <TextAriaShadesContainerStyled>
            {topShade && <UpSideBlurStyled />}
            {bottomShade && <DownSideBlurStyled />}
          </TextAriaShadesContainerStyled>
          <TextAriaContentStyled
            isOpen={isTextareaOpen}
            ref={textareaContentRef}
            onScroll={checkShades}
          >
            {isRescoring && <OverlayStyled />}
            <LoanDescription ref={textareaHeaderRef} />

            <TextareaAutosizeStyled>
              <TextareaAutosize
                placeholder="Add some notes to this application"
                value={note}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ minHeight: textareaMinHeight }}
              />
            </TextareaAutosizeStyled>
          </TextAriaContentStyled>
          <ButtonResizerStyled isRescoring={isRescoring}>
            <SizeDownIcon
              style={{ display: isTextareaOpen ? 'block' : 'none' }}
              onClick={!isRescoring ? () => setIsTextareaOpen(false) : undefined}
            />
            <SizeUpIcon
              style={{ display: isTextareaOpen ? 'none' : 'block' }}
              onClick={!isRescoring ? () => setIsTextareaOpen(true) : undefined}
            />
          </ButtonResizerStyled>
        </TransitionContainerStyled>
      </CSSTransition>
    </TextAriaContainerStyled>
  )
}

export default NotesArea
