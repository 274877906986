import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { TValidations } from './type'
import { apiClient } from '../../../core/api/apiClient'

export enum QueryKey {
  FailedValidationDetails = 'FailedValidationDetails'
}

async function getFailedValidationDetails(
  failedValidationId?: string
): Promise<TValidations> {
  try {
    const response = await apiClient(`/dashboard/loan-requests/${failedValidationId}/validation`)
    return response.data
  } catch (error) {
    throw new Error('Someting went wrong')
  }
}

export function useFailedValidationDetailsQuery() {
  const key = QueryKey.FailedValidationDetails
  const { failedValidationId } = useParams()

  return useQuery(
    [key, failedValidationId],
    () => getFailedValidationDetails(failedValidationId)
  )
}
