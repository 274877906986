import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { fontFamily } from '../../../../core/styles/mixins'

const WrapperStyled = styled.div`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey600};
`

type TProps = {
  identityNames: string[] | null
  identityCheck: boolean | null
  name: 'd.One' | 'Plaid'
}
const IdentityInfo: FC<TProps> = ({ identityNames, identityCheck, name }) => {
  if (!identityNames?.length || identityCheck) {
    return null
  }
  const numberOfNames = identityNames
  && identityNames.length > 1
    ? `, +${Number(identityNames.length) - 1}`
    : null

  return (
    <>
      <WrapperStyled>
        {`${name} name: `}
        {identityNames?.[0]}
        {numberOfNames}
      </WrapperStyled>
    </>
  )
}

export default IdentityInfo
