import { useMutation, useQuery, useQueryClient } from 'react-query'
import { TScoring } from './types'
import { apiClient } from '../../../core/api/apiClient'

enum QueryKeys {
  Status = 'Status',
  ScoringConf = 'ScoringConf'
}

async function toggleSwitch({ id, enabled }: Partial<TScoring>): Promise<void> {
  try {
    await apiClient.put(`/configuration/scoring/${id}/status`, { enabled: !enabled })
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

async function getScoringConf(): Promise<TScoring[]> {
  try {
    const response = await apiClient('/configuration/scoring')
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useToggleScoringConf() {
  const queryClient = useQueryClient()

  return useMutation<void, Error, Partial<TScoring>, { previousData?: TScoring[] }>(
    ({ id, enabled }) => toggleSwitch({ id, enabled }),
    {
      onMutate: (data) => {
        const previousData = queryClient.getQueryData<TScoring[]>(QueryKeys.ScoringConf)
        queryClient.setQueriesData<TScoring[]>(
          QueryKeys.ScoringConf,
          (old) => old!.map((item) => {
            if (item.id === data.id) {
              return { ...item, enabled: !item.enabled }
            }
            return item
          })
        )
        return { previousData }
      },
      onError: (err, newTodo, context) => {
        if (context) queryClient.setQueryData(QueryKeys.ScoringConf, context.previousData)
      },
      onSettled: () => queryClient.invalidateQueries(QueryKeys.ScoringConf)
    }
  )
}

export function useScoringConf() {
  const key = QueryKeys.ScoringConf

  return useQuery<TScoring[], Error>(key, getScoringConf)
}
