import { FC } from 'react'
import { IconWrapper } from './base/IconWrapper'
import { ReactComponent as Icon } from './svg/CircleCheckFilled.svg'
import { TIconProps } from './types'

export const CheckFilledIcon: FC<TIconProps> = () => (
  <IconWrapper>
    <Icon />
  </IconWrapper>
)
