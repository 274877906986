import { useMemo } from 'react'
import { Column } from 'react-table'
import { format } from 'date-fns'
import styled from 'styled-components/macro'
import { SLASH_DATE_FORMAT } from '../../../../core/const/date'
import Table from '../../../../core/components/table/Table'
import { useRiskContext } from '../state/useRisk'
import { HeaderCellStyled } from '../../../../core/components/table/TableHeadRow'
import { BodyCellStyled } from '../../../../core/components/table/TableBodyRow'
import { fontFamily } from '../../../../core/styles/mixins'
import { WrapStyled as PaginationWrapper } from '../../../../core/components/table/Pagination'
import NoDataComponent from '../../../../core/components/NoDataComponent'
import { formatToPound } from '../../../../core/utils/currencyFormat'
import Spinner from '../../../../core/components/Spinner'

const Wrapper = styled.div`
  && {
    ${HeaderCellStyled} {
      padding: 24px 16px 4px 0;
    }

    ${BodyCellStyled} {
      ${fontFamily('Inter')};
      padding: 10px 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }

    ${PaginationWrapper} {
      margin-top: 6px;
      margin-left: 16px;
    }
  }
`

const AlignLeftStyled = styled.span`
  padding-left: 16px;
`

const AlignRightStyled = styled.span`
  padding-right: 16px;
`

const SpinnerWrapperStyled = styled.div`
  height: 350px;
`

const DetailedTransactionTable = () => {
  const columns = useMemo<Column[]>(
    () => [
      {
        Header: () => <AlignLeftStyled>Date</AlignLeftStyled>,
        accessor: 'date',
        Cell: ({ value }) => (
          <AlignLeftStyled>{format(new Date(value), SLASH_DATE_FORMAT)}</AlignLeftStyled>
        ),
        sortDescFirst: true
      },
      {
        Header: 'Description',
        accessor: 'name'
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => <AlignRightStyled>{formatToPound(value)}</AlignRightStyled>,
        sortDescFirst: true
      }
    ],
    []
  )
  const {
    transactionsState: {
      query: { data, isIdle, isLoading, isFetching },
      setCurrentPage,
      setSortBy,
      sortBy
    },
    selectedCategory
  } = useRiskContext()

  return (
    <Wrapper>
      {
        (isIdle || isLoading || !selectedCategory)
          ? (
            <SpinnerWrapperStyled>
              <Spinner />
            </SpinnerWrapperStyled>
          )
          : (
            <Table
              columns={columns}
              data={data?.content || []}
              showLoader={isFetching}
              paginationConfig={
                data && {
                  isEnabled: data?.totalPages > 1,
                  manual: true,
                  pageCount: data?.totalPages,
                  totalRows: data?.totalElements,
                  pageSize: data?.size,
                  onPageChange: (page) => setCurrentPage(page)
                }
              }
              sortingConfig={{
                sortable: true,
                initialSortBy: sortBy && [sortBy],
                manual: true,
                onSortByChange: (sortBy) => setSortBy(sortBy && sortBy[0])
              }}
            />
          )
      }

      {!isIdle && !isLoading && !data?.content.length && (
        <NoDataComponent text="There are no transactions" />
      )}
    </Wrapper>
  )
}
export default DetailedTransactionTable
