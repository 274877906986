import React from 'react'
import { VerifiedIcon } from '../../../../core/components/icons/VerifiedIcon'
import { NoMatchesIcon } from '../../../../core/components/icons/NoMatchesIcon'

export function getIdentityCheckIconCondition({
  plaidIdentityCheck,
  dataOneIdentityCheck
}: TIdentityCheck) {
  if (plaidIdentityCheck !== null && dataOneIdentityCheck !== null) {
    return plaidIdentityCheck && dataOneIdentityCheck
  }

  return plaidIdentityCheck === null ? dataOneIdentityCheck : plaidIdentityCheck
}

type TIdentityCheck = {
  plaidIdentityCheck: boolean | null
  dataOneIdentityCheck: boolean | null
}

type TProps = {
  iconSize: 12 | 18 | 20
} & TIdentityCheck

const IdentityIcon = ({ plaidIdentityCheck, dataOneIdentityCheck, iconSize }: TProps) => (
  <>
    {getIdentityCheckIconCondition({ plaidIdentityCheck, dataOneIdentityCheck })
      ? <VerifiedIcon width={iconSize} height={iconSize} />
      : <NoMatchesIcon width={iconSize} height={iconSize} />}
  </>
)

export default IdentityIcon
