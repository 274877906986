import { TValidationTypes } from '../types'

export const validationTypesMap: { [key in TValidationTypes]: string } = {
  MAIN_ACCOUNT_VALIDATION: 'Main account',
  OB_DECLINES_VALIDATION: 'OB declines',
  DEMOGRAPHIC_AND_BUREAUX_VALIDATION: 'Bureaux&Demographic'
}

export const prepareValidationOptions = (validationTypes: TValidationTypes[]) =>
  validationTypes.map((value) => ({ label: validationTypesMap[value], value }))
